import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const OFFICES = '/offices';
const REVISION = '/pic/revision';

class revisionAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  addRevision(data) {
    const url = `${OFFICES}/${data.officeUuid}/pic/revision`;

    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateRevision(officeUuid, data) {
    const url = `${OFFICES}/${officeUuid}${REVISION}/${data.uuid}`;

    return this.client
      .put(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteRevision(officeUuid, revisionUuid) {
    const url = `${OFFICES}/${officeUuid}${REVISION}/${revisionUuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default revisionAPI;

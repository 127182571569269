import OfficeFactory from '../domain/services/OfficeFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';
import { calculateOffset, getOffsetUrl } from '../helpers/RouterHelper';
import toQuerystring from '../helpers/URLHelper';

const OFFICES = '/offices';

class officeAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllOffices(data) {
    const offset = calculateOffset(data.currentPage, data.itemsPerPage);
    const url = getOffsetUrl(OFFICES, offset);
    const params = {
      companyId: typeof data.companyId !== 'undefined' ? data.companyId : null,
      projectId: data.projectId || null,
      supplierId: data.supplierId || null,
      phase: data.phase || null,
      phaseStatus: data.phaseStatus || null,
      type: data.type || null,
      freeFilter: data.freeFilter || null,
      userSupplier: data.userSupplier || null,
      lot: data.lot || null,
      onlyInactive: data.onlyInactive || null,
    };

    const parsedParams = toQuerystring(params);
    const urlWithParams = `${url}?${parsedParams}`;

    return this.client
      .get(urlWithParams)
      .then((res) => OfficeFactory.getAllOfficesRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  getOffice(uuid) {
    const url = `${OFFICES}/${uuid}`;

    return this.client
      .get(url)
      .then((res) => OfficeFactory.getOffice(res.data?.resource))
      .catch((error) => Promise.reject(error));
  }

  addOffice(data) {
    return this.client
      .post(OFFICES, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateOffice(data) {
    const url = `${OFFICES}/${data.uuid}`;

    return this.client
      .put(url, data.officeData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteOffice(uuid) {
    const url = `${OFFICES}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getOfficeReport(data) {
    const url = `${OFFICES}/report`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';
    const supplierId = typeof data.supplierId !== 'undefined' ? `&supplierId=${data.supplierId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}${supplierId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => OfficeFactory.getOfficeReport(res.data?.data))
      .catch((error) => Promise.reject(error));
  }

  getOfficeInstallationReport(data) {
    const url = `${OFFICES}/report/installation`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      // eslint-disable-next-line max-len
      .then((res) => OfficeFactory.getOfficeInstallationReports(res.data?.data.installationPhaseCounters))
      .catch((error) => Promise.reject(error));
  }

  getOfficeCertificationReport(data) {
    const url = `${OFFICES}/report/certification`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      // eslint-disable-next-line max-len
      .then((res) => OfficeFactory.getOfficeCertificationReports(res.data?.data.certificationPhaseCounters))
      .catch((error) => Promise.reject(error));
  }

  getOfficeStatusReport(data) {
    const url = `${OFFICES}/report/status`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';
    const supplierId = (typeof data.supplierId !== 'undefined' && data.supplierId !== null) ? `&supplierId=${data.supplierId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}${supplierId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => OfficeFactory.getOfficeStatusReport(res.data?.data))
      .catch((error) => Promise.reject(error));
  }

  uploadFile(url, formData) {
    return this.client
      .post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      .then((res) => OfficeFactory.getImportResponse(res.data?.resource))
      .catch((error) => Promise.reject(error));
  }

  uploadOffice(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/offices`;

    return this.uploadFile(url, formData);
  }

  uploadMeasurement(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/measurements`;

    return this.uploadFile(url, formData);
  }

  uploadBudget(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/budgets`;

    return this.uploadFile(url, formData);
  }

  uploadPic(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/pics`;

    return this.uploadFile(url, formData);
  }

  uploadCertification(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/certifications`;

    return this.uploadFile(url, formData);
  }

  uploadInstallation(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/installations`;

    return this.uploadFile(url, formData);
  }

  uploadForecast(projectUuid, formData) {
    const url = `/projects/${projectUuid}/import/forecasts`;

    return this.uploadFile(url, formData);
  }

  exportStatusOffices(requestData) {
    const url = `${OFFICES}/report/status/file`;

    const companyId = typeof requestData.companyId !== 'undefined' ? `?companyId=${requestData.companyId}` : '';
    const projectId = (typeof requestData.projectId !== 'undefined' && requestData.projectId !== null) ? `&projectId=${requestData.projectId}` : '';
    const supplierId = (typeof requestData.supplierId !== 'undefined' && requestData.supplierId !== null) ? `&supplierId=${requestData.supplierId}` : '';
    const format = (typeof requestData.format !== 'undefined' && requestData.format !== null) ? `&format=${requestData.format}` : '';

    const urlWithParams = `${url}${companyId}${projectId}${supplierId}${format}`;

    return this.client
      .get(urlWithParams,
        {
          responseType: 'blob',
        })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  exportPhaseOffices(requestData) {
    const url = `${OFFICES}/report/phase/file`;

    const params = {
      companyId: requestData.companyId || null,
      projectId: requestData.projectId || null,
      supplierId: requestData.supplierId || null,
      phase: requestData.phase || null,
      phaseStatus: requestData.phaseStatus || null,
      freeFilter: requestData.freeFilter || null,
      type: requestData.type || null,
      lot: requestData.lot || null,
      format: requestData.format || null,
    };

    const parsedParams = toQuerystring(params);

    const urlWithParams = `${url}?${parsedParams}`;

    return this.client
      .get(urlWithParams, {
        responseType: 'blob',
      })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  getPicRevisionReport(data) {
    const url = `${OFFICES}/report/pic`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => OfficeFactory.getPicRevisionReports(res.data?.data.picPhaseCounters))
      .catch((error) => Promise.reject(error));
  }

  getOfficeTypes(data) {
    const url = `${OFFICES}/types`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => res.data?.resource?.types)
      .catch((error) => Promise.reject(error));
  }

  downloadAllDocuments(requestData) {
    const url = `${OFFICES}/${requestData.officeId}/documents`;
    return this.client
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  getLots(requestData) {
    const url = `${OFFICES}/lots`;
    const companyId = `?companyId=${requestData.companyId}`;
    const projectId = `&projectId=${requestData.projectId}`;
    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => res.data.collection)
      .catch((error) => Promise.reject(error));
  }
}

export default officeAPI;

import PhaseApi from '../../api/PhaseApi';

export function updateMeasurementPhase({ commit }, { data }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.updatePhase(data);

  return phase
    .then((res) => {
      const { officeUuid } = data;
      commit('updateMeasurementOffice', { res, officeUuid });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateBudgetPhase({ commit }, { data }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.updatePhase(data);

  return phase
    .then((res) => {
      const { officeUuid } = data;
      commit('updateBudgetOffice', { res, officeUuid });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updatePicPhase({ commit }, { data }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.updatePhase(data);

  return phase
    .then((res) => {
      const { officeUuid } = data;
      commit('updatePicOffice', { res, officeUuid });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateCertificationPhase({ commit }, { data }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.updatePhase(data);

  return phase
    .then((res) => {
      const { officeUuid } = data;
      commit('updateCertificationOffice', { res, officeUuid });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateInstallationPhase({ commit }, { data }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.updatePhase(data);

  return phase
    .then((res) => {
      const { officeUuid } = data;
      commit('updateInstallationOffice', { res, officeUuid });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function phaseRevision() {

}

export function uploadPhaseDocument({ commit }, { requestData, formData }) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.uploadPhaseDocument(requestData, formData);

  return phase
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function downloadPhaseDocument({ commit }, requestData) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.downloadPhaseDocument(requestData);

  return phase
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getPhaseByOffice({ commit }, officeUuid) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.getPhaseByOffice(officeUuid);

  return phase
    .then((res) => {
      commit('updatePhases', res);
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function deletePhaseDocument({ commit }, requestData) {
  const phaseApi = new PhaseApi();
  const phase = phaseApi.deletePhaseDocument(requestData);

  return phase
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

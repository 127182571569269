<template>
  <form>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <SelectorForm
          selectLabel="Estado"
          :selectFormOptions="selectFormOptions"
          @handlerSelect="onChangeStatus"
          :selectedOption="this.measurementData.status"
          :isValid="this.measurementDataValidation.status"
          :disabled="!this.$store.getters.hasProviderPermission"
        />
      </b-col>
      <b-col>
        <b-form-group
          label="Fecha Realización"
          :hidden="!this.$store.getters.hasAdminPermission"
        >
        <b-form-datepicker
          id="performanceDate"
          v-model="performanceDate"
          placeholder="Fecha Realización"
          @context="onChangePerformanceDate"
          :date-format-options="{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }"
          :state="this.measurementDataValidation.performanceDate"
          start-weekday="1"
          :reset-button="true"
          label-reset-button="Borrar fecha seleccionada"
        />
      </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import { getStatusSelectorOptionsMeasurement } from '@/helpers/StatusHelper';

export default {
  name: 'FormMeasurementContent',
  components: { SelectorForm },
  props: {
    handleMeasurementData: {},
    measurementData: {
      type: Object,
      default: () => {},
    },
    measurementDataValidation: Object,
  },
  data() {
    return {
      selectFormOptions: getStatusSelectorOptionsMeasurement(),
      performanceDate: this.measurementData.performanceDate,
    };
  },
  methods: {
    onChangeStatus(data) {
      this.$emit('onChangeStatus', data);
    },
    onChangePerformanceDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangePerformanceDate', data.activeYMD);
      } else {
        this.$emit('onChangePerformanceDate', null);
      }
    },
  },
};
</script>

export default function exportFile(filename, data, open = false) {
  if (open) {
    const fileURL = window.URL.createObjectURL(data);
    window.open(fileURL);
  } else {
    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
}

import SupplierFactory from '../domain/services/SupplierFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const SUPPLIERS = '/suppliers';

class supplierAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllSuppliers(projectId) {
    let suppliersUrl = `${SUPPLIERS}?`;
    if (projectId) {
      const encodedProjectId = encodeURIComponent(projectId);
      suppliersUrl += `projectId=${encodedProjectId}`;
    }
    return this.client
      .get(suppliersUrl)
      .then((res) => SupplierFactory.getSuppliersRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  addSupplier(data) {
    return this.client
      .post(SUPPLIERS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateSupplier(data) {
    const url = `${SUPPLIERS}/${data.uuid}`;

    return this.client
      .put(url, data.supplierData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteSupplier(uuid) {
    const url = `${SUPPLIERS}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getSupplierReport(data) {
    const url = `${SUPPLIERS}/report`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => SupplierFactory.getSupplierReport(res.data?.data))
      .catch((error) => Promise.reject(error));
  }
}

export default supplierAPI;

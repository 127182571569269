import UserFactory from '../domain/services/UserFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';
import { calculateOffset, getOffsetUrl } from '../helpers/RouterHelper';

const USERS = '/users';

class userAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getUsers(currentPage, itemsPerPage) {
    const offset = calculateOffset(currentPage, itemsPerPage);
    const url = getOffsetUrl(USERS, offset);

    return this.client
      .get(url)
      .then((res) => UserFactory.getUsersRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  getUser(UserUuid) {
    const url = `${USERS}/${UserUuid}`;

    return this.client
      .get(url)
      .then((res) => UserFactory.getUser(res.data.resource))
      .catch((error) => Promise.reject(error));
  }

  addUser(userData) {
    return this.client
      .post(USERS, userData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateAddUser(data) {
    const url = `${USERS}/${data.uuid}`;

    return this.client
      .put(url, data.userData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteUser(uuid) {
    const url = `${USERS}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getClients() {
    const url = `${USERS}/clients`;

    return this.client
      .get(url)
      .then((res) => UserFactory.getUsersClientRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  uploadAvatar(requestData, formData) {
    const url = `${USERS}/${requestData.userUuid}/avatar`;

    return this.client
      .post(url, formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      .then()
      .catch((error) => Promise.reject(error));
  }

  getAvatar(userUuid) {
    const url = `${USERS}/${userUuid}/avatar`;

    return this.client
      .get(url,
        {
          responseType: 'blob',
        })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  updatePassword(passwordData) {
    const url = `${USERS}/password`;

    return this.client
      .post(url, passwordData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  inviteUser(userData) {
    const url = `${USERS}/${userData.uuid}/invitation`;

    return this.client
      .put(url, { email: userData.email })
      .then()
      .catch((error) => Promise.reject(error));
  }

  resetPassword(resetData) {
    const url = `${USERS}/forgot-password`;

    return this.client
      .put(url, resetData)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default userAPI;

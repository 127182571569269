<template>
  <ReportView title="Resumen fase instalación">
    <OfficeInstallationReport/>
  </ReportView>
</template>

<script>
import OfficeInstallationReport from '@/components/molecules/Dashboard/OfficeInstallationReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'OfficeInstallationReportView',
  components: {
    OfficeInstallationReport,
    ReportView,
  },
};
</script>

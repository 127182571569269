var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('b-row',[_c('b-col',[_c('SelectorForm',{attrs:{"selectLabel":"Estado","selectFormOptions":_vm.selectFormOptions,"selectedOption":_vm.status,"isValid":this.installationDataValidation.status,"disabled":!this.$store.getters.hasProviderPermission},on:{"handlerSelect":_vm.onChangeStatus}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha Inicio Instalación","hidden":!this.$store.getters.hasProviderPermission}},[_c('b-form-datepicker',{attrs:{"id":"startDate","placeholder":"Fecha Inicio","date-format-options":{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          },"state":this.installationDataValidation.startDate,"start-weekday":"1","reset-button":true,"label-reset-button":"Borrar fecha seleccionada"},on:{"context":_vm.onChangeStartDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha Fin Instalación","hidden":!this.$store.getters.hasProviderPermission}},[_c('b-form-datepicker',{attrs:{"id":"endDate","placeholder":"Fecha Fin","date-format-options":{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          },"state":this.installationDataValidation.endDate,"start-weekday":"1","reset-button":true,"label-reset-button":"Borrar fecha seleccionada"},on:{"context":_vm.onChangeEndDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha parada por permisos","hidden":!this.$store.getters.hasProviderPermission}},[_c('b-form-datepicker',{attrs:{"id":"stopPermitDate","placeholder":"Fecha parada por permisos","date-format-options":{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          },"state":this.installationDataValidation.stopPermitDate,"start-weekday":"1","reset-button":true,"label-reset-button":"Borrar fecha seleccionada"},on:{"context":_vm.onChangeStopPermitDate},model:{value:(_vm.stopPermitDate),callback:function ($$v) {_vm.stopPermitDate=$$v},expression:"stopPermitDate"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
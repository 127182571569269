import { render, staticRenderFns } from "./Issues.vue?vue&type=template&id=aeb8b638&scoped=true&"
import script from "./Issues.vue?vue&type=script&lang=js&"
export * from "./Issues.vue?vue&type=script&lang=js&"
import style0 from "./Issues.vue?vue&type=style&index=0&id=aeb8b638&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeb8b638",
  null
  
)

export default component.exports
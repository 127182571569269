export default class Phases {
  constructor(phasesData) {
    this.measurementPhase = phasesData.measurementPhase;
    this.picPhase = phasesData.picPhase;
    this.budgetPhase = phasesData.budgetPhase;
    this.installationPhase = phasesData.installationPhase;
    this.certificationPhase = phasesData.certificationPhase;
  }

  get getMeasurementPhase() {
    return this.measurementPhase;
  }

  get getPicPhase() {
    return this.picPhase;
  }

  get getBudgetPhase() {
    return this.budgetPhase;
  }

  get getInstallationPhase() {
    return this.installationPhase;
  }

  get getCertificationPhase() {
    return this.certificationPhase;
  }
}

<template>
  <Modal v-show="true">
    <FormCard
      title="Añadir Avatar"
      @accept="addAvatar"
      @cancel="cancelAction"
    >
      <AvatarUploader
        ref="avatarUploader"
        :companyUuid="companyUuid"
        @handleGetAvatar="$emit('handleGetAvatar')"
        @handleClose="cancelAction"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import AvatarUploader from '@/components/atoms/User/AvatarUploader.vue';

export default {
  name: 'AddAvatarForm',
  components: {
    Modal,
    FormCard,
    AvatarUploader,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    companyUuid: {
      type: String,
      default: '',
    },
  },
  methods: {
    addAvatar() {
      this.$refs.avatarUploader.uploadFile();
    },
    cancelAction() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <div class="officeDocuments">
    <div class="uploader">
      <PhaseDocumentUploader
        :officeUuid = "officeUuid"
        :measurementPhaseData="measurementPhaseData"
        :budgetPhaseData="budgetPhaseData"
        :picPhaseData="picPhaseData"
        :certificationPhaseData="certificationPhaseData"
        :installationPhaseData="installationPhaseData"
        @manageDocumentChanges = "manageDocumentChanges"
      />
    </div>
    <b-card>
      <div class="d-flex justify-content-between">
      <p class="phase-title">Medición</p>
      <CollapseIcon v-b-toggle.measurement class="m1" />
      </div>
    </b-card>
    <b-collapse id="measurement" :visible="phaseOpened === 'measurement'">
      <b-card>
        <Documents :officeUuid="officeUuid" :phaseData="measurementPhaseData"/>
      </b-card>
    </b-collapse>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="phase-title">Presupuesto</p>
        <CollapseIcon v-b-toggle.budget class="m1" />
      </div>
    </b-card>
    <b-collapse id="budget" :visible="phaseOpened === 'budget'">
      <b-card>
        <Documents :officeUuid="officeUuid" :phaseData="budgetPhaseData"/>
      </b-card>
    </b-collapse>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="phase-title">PIC</p>
        <CollapseIcon v-b-toggle.pic class="m1" />
      </div>
    </b-card>
    <b-collapse id="pic" :visible="phaseOpened === 'PIC'">
      <b-card>
        <Documents :officeUuid="officeUuid" :phaseData="picPhaseData"/>
      </b-card>
    </b-collapse>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="phase-title">Instalación</p>
        <CollapseIcon v-b-toggle.installation class="m1" />
      </div>
    </b-card>
    <b-collapse id="installation" :visible="phaseOpened === 'installation'">
      <b-card>
        <Documents :officeUuid="officeUuid" :phaseData="installationPhaseData"/>
      </b-card>
    </b-collapse>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="phase-title">Certificación</p>
        <CollapseIcon v-b-toggle.certification class="m1" />
      </div>
    </b-card>
    <b-collapse id="certification" :visible="phaseOpened === 'certification'">
      <b-card>
        <Documents :officeUuid="officeUuid" :phaseData="certificationPhaseData"/>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import Documents from '@/components/molecules/Documents/Documents.vue';
import PhaseDocumentUploader from '@/components/atoms/PhaseDocumentUploader/PhaseDocumentUploader.vue';
import CollapseIcon from '@/components/atoms/CollapseIcon/CollapseIcon.vue';

export default {
  name: 'OfficeDocuments',
  components: {
    PhaseDocumentUploader,
    Documents,
    CollapseIcon,
  },
  data() {
    return {
      phaseOpened: '',
    };
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    measurementPhaseData: {
      type: Object,
      default: () => {},
    },
    budgetPhaseData: {
      type: Object,
      default: () => {},
    },
    picPhaseData: {
      type: Object,
      default: () => {},
    },
    installationPhaseData: {
      type: Object,
      default: () => {},
    },
    certificationPhaseData: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    const hashObject = {};
    const hashContent = document.location.hash.split('#')[1];
    if (!hashContent) {
      return;
    }
    hashContent.split(',').forEach((param) => {
      const [key, value] = param.split('=');
      hashObject[key] = value;
    });

    if (hashObject.phase) {
      this.phaseOpened = hashObject.phase;
      await this.runDelayed(() => document.querySelector(`#${hashObject.phase}`).scrollIntoView());
      await this.runDelayed(() => {
        const image = document.querySelector(`#${hashObject.documentName}`);
        if (image) {
          image.click();
        }
      });
    }
  },
  methods: {
    async runDelayed(callback) {
      await new Promise((resolve) => setTimeout(() => {
        callback();
        resolve();
      }, 500));
    },
    setSelected(data) {
      this.selectedPhaseUuid = data;
    },
    manageDocumentChanges() {
      const uuid = this.officeUuid;
      const officeData = this.$store.getters.getOffices.find((office) => office.uuid === uuid);

      this.$store
        .dispatch('getPhaseByOffice', officeData);
    },

  },
};
</script>

<style scoped lang="sass">
  .officeDocuments
    display: flex
    flex-direction: column

  .phase-title
    color: #5B6770
    width: 113px
    height: 24px
</style>

export default class IssueReasonReport {
  constructor(issueReasonReportData) {
    this.uuid = issueReasonReportData.uuid;
    this.name = issueReasonReportData.name;
    this.countersByTypology = issueReasonReportData.countersByTypology;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getCountersByTypology() {
    return this.countersByTypology;
  }
}

<template>
  <div class="Status">
    <Measurement
      :office-uuid="officeUuid"
      :phase-data="measurementPhaseData"
      />
    <Budget
      :officeUuid="officeUuid"
      :phaseData="budgetPhaseData"
    />
    <Pic
      :office-uuid="officeUuid"
      :phase-data="picPhaseData"
    />
    <Installation
      :office-uuid="officeUuid"
      :phaseData="installationPhaseData"
    />
    <Certification
      :office-uuid="officeUuid"
      :phase-data="certificationPhaseData"
    />
    <Notes
      :officeUuid="officeUuid"
    />
  </div>

</template>

<script>
import Measurement from '@/components/molecules/Offices/Phases/Measurement/Measurement.vue';
import Budget from '@/components/molecules/Offices/Phases/Budget/Budget.vue';
import Installation from '@/components/molecules/Offices/Phases/Installation/Installation.vue';
import Pic from '@/components/molecules/Offices/Phases/Pic/Pic.vue';
import Certification from '@/components/molecules/Offices/Phases/Certification/Certification.vue';
import Notes from '@/components/molecules/Notes/Notes.vue';

export default {
  name: 'OfficeStatus',
  components: {
    Notes,
    Certification,
    Pic,
    Installation,
    Budget,
    Measurement,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    measurementPhaseData: {
      type: Object,
      default: () => {},
    },
    budgetPhaseData: {
      type: Object,
      default: () => {},
    },
    picPhaseData: {
      type: Object,
      default: () => {},
    },
    certificationPhaseData: {
      type: Object,
      default: () => {},
    },
    installationPhaseData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
  .phaseHeader {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
  }
  .phase-name {
    color: #5D676F;
    font-size: 1.2em;
  }
</style>

<style scoped lang="sass">
  ::v-deep .table thead th
    font-weight: 100
</style>

<template>
  <div class="img-container">
    <b-img
      v-if="isImage(img.name)"
      :id="img.docId"
      :src="img.imgSrc"
      thumbnail
      alt="img"
      @click="getClick"
      class="imageListItem"/>
    <b-img
      v-if="isPdf(img.name)"
      :id="img.docId"
      :src="img.imgSrc"
      thumbnail
      alt="img"
      @click="getClick"
      class="imageListItem"/>
    <div v-if="!isPdf(img.name) && !isImage(img.name)">
      <b-img
        :id="img.docId"
        :src="img.imgSrc"
        thumbnail
        alt="img"
        @click="getClick"
        class="imageListItem"/>
    </div>
    <div v-if="!isImage(img.name)" class="caption">
      {{ img.name }}
    </div>
  </div>

</template>

<script>
export default {
  name: 'ImageListItem',
  props: {
    img: {
      type: Object,
      default: null,
    },
    documentUuid: {
      type: String,
      default: null,
    },
  },
  methods: {
    getClick() {
      this.$emit('getClick', this.documentUuid);
    },
    isImage(imageName) {
      if (!imageName) {
        return false;
      }
      const exts = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
      const ext = imageName.split('.').reverse()[0].toLowerCase();
      return exts.indexOf(ext) > -1;
    },
    isPdf(pdfName) {
      if (!pdfName) {
        return false;
      }
      const ext = pdfName.split('.').reverse()[0].toLowerCase();
      return ext === 'pdf';
    },
  },
};
</script>
<style lang="sass" scoped>
  .imageListItem
    width: 150px
    height: 110px !important

  .caption
    position: absolute
    top: 5px
    min-height: 25px
    width: 150px
    border: 1px solid black
    justify-content: center
    background: white center center
    font-size: 0.7rem
    text-align: center

  .img-container
    max-height: 110px
    position: relative

</style>

<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualizar Certificación"
      @accept="updateCertification"
      @cancel="cancelAction"
    >
      <FormCertificationContent
        :certificationData="this.certificationData"
        :certificationDataValidation="this.validationStatus"
        @onChangeStatus="onChangeStatus"
        @onChangeReceivedDate="onChangeReceivedDate"
        @onChangeRevisedDate="onChangeRevisedDate"
        @onChangeAprovedDate="onChangeAprovedDate"
        @onChangeComments="onChangeComments"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import { PHASE } from '@/helpers/PhaseHelper';
import FormCertificationContent from './FormCertificationContent.vue';

export default {
  name: 'UpdateCertificationForm',
  components: {
    Modal,
    FormCard,
    FormCertificationContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    certificationData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateOffice: {},
  },
  data: () => ({
    certificationDataValidation: {
      status: null,
      receivedDate: null,
      revisedDate: null,
      approvedDate: null,
      comments: null,
    },
    validationStatus: {
      status: null,
    },
  }),
  mounted() {
    if (Object.keys(this.certificationData).length > 0) {
      this.certificationDataValidation.status = this.certificationData.status;
      this.certificationDataValidation.receivedDate = this.certificationData.receivedDate;
      this.certificationDataValidation.revisedDate = this.certificationData.revisedDate;
      this.certificationDataValidation.approvedDate = this.certificationData.approvedDate;
      this.certificationDataValidation.comments = this.certificationData.comments;
    }
  },
  methods: {
    updateCertification() {
      if (this.isValidData()) {
        const phaseData = {
          status: this.certificationDataValidation.status,
          receivedDate: this.certificationDataValidation.receivedDate,
          revisedDate: this.certificationDataValidation.revisedDate,
          approvedDate: this.certificationDataValidation.approvedDate,
          comments: this.certificationDataValidation.comments,
        };

        const certificationData = {
          officeUuid: this.officeUuid,
          phase: PHASE.CERTIFICATION.value,
          phaseData,
        };

        this.blockScreen();

        this.$store.dispatch('updateCertificationPhase', {
          data: certificationData,
        })
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });

        this.closeForm();
      } else {
        this.validationStatus = {
          status: getValidationState(this.certificationDataValidation?.status),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.certificationDataValidation?.status);
    },
    onChangeStatus(status) {
      this.certificationDataValidation.status = status;
    },
    onChangeReceivedDate(receivedDate) {
      this.certificationDataValidation.receivedDate = receivedDate;
    },
    onChangeRevisedDate(revisedDate) {
      this.certificationDataValidation.revisedDate = revisedDate;
    },
    onChangeAprovedDate(approvedDate) {
      this.certificationDataValidation.approvedDate = approvedDate;
    },
    onChangeComments(comments) {
      this.certificationDataValidation.comments = comments;
    },
  },
};
</script>

import ForecastApi from '../../api/ForecastApi';

export function getAllForecasts(state, data) {
  const forecastApi = new ForecastApi();
  const forecasts = forecastApi.getAllForecasts(data);

  return forecasts
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function addForecast(state, data) {
  const forecastApi = new ForecastApi();
  const forecast = forecastApi.addForecast(data);

  return forecast
    .then()
    .catch((error) => Promise.reject(error));
}

export function updateForecast(state, data) {
  const forecastApi = new ForecastApi();
  const forecast = forecastApi.updateForecast(data);

  return forecast
    .then()
    .catch((error) => Promise.reject(error));
}

export function deleteForecast(state, { uuid }) {
  const forecastApi = new ForecastApi();
  const forecast = forecastApi.deleteForecast(uuid);

  return forecast
    .then()
    .catch((error) => Promise.reject(error));
}

export function getForecastStatusReport(state, data) {
  const forecastApi = new ForecastApi();
  const forecasts = forecastApi.getForecastStatusReport(data);

  return forecasts
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

import COLOR from './Colors';
import { PHASE } from '@/helpers/PhaseHelper';

export const STATUS = {
  RECEIVED: {
    value: 'received',
    name: 'Recibido',
  },
  PENDING: {
    value: 'pending',
    name: 'Pendiente',
  },
  REVISED: {
    value: 'revised',
    name: 'Revisado',
  },
  PENDING_LOGOPOST: {
    value: 'pending_logopost',
    name: 'Pendiente Logopost',
  },
  PENDING_SUPPLIER: {
    value: 'pending_supplier',
    name: 'Pendiente Proveedor',
  },
  OK: {
    value: 'ok',
    name: 'Ok',
  },
  STOPPED: {
    value: 'stopped',
    name: 'Parada',
  },
  ON_GOING: {
    value: 'on_going',
    name: 'En curso',
  },
  INCIDENT: {
    value: 'incident',
    name: 'Incidencia',
  },
};

export function getStatusSelectorOptions() {
  const options = [
    { value: null, text: 'Seleccionar estado' },
  ];

  Object.entries(STATUS).forEach(([key, value]) => {
    const option = {
      value: STATUS[key].value,
      text: value.name,
    };

    options.push(option);
  });

  return options;
}

function iniatilizeSelectorOptions() {
  const options = [
    { value: null, text: 'Seleccionar estado' },
  ];
  const optionPending = {
    value: STATUS.PENDING.value,
    text: STATUS.PENDING.name,
  };
  options.push(optionPending);

  return options;
}

export function getStatusSelectorOptionsMeasurement() {
  const options = iniatilizeSelectorOptions();

  const optionOk = {
    value: STATUS.OK.value,
    text: STATUS.OK.name,
  };
  options.push(optionOk);

  return options;
}

function getSelectorOptionsBudgetAndPIC(isProvider) {
  const options = iniatilizeSelectorOptions();

  const optionReceived = {
    value: STATUS.RECEIVED.value,
    text: STATUS.RECEIVED.name,
  };
  const optionPendingSupplier = {
    value: STATUS.PENDING_SUPPLIER.value,
    text: STATUS.PENDING_SUPPLIER.name,
  };
  const optionOk = {
    value: STATUS.OK.value,
    text: STATUS.OK.name,
  };
  options.push(optionReceived);
  if (isProvider) {
    return options;
  }
  options.push(optionPendingSupplier);
  options.push(optionOk);

  return options;
}

export function getStatusSelectorOptionsBudget(isProvider) {
  return getSelectorOptionsBudgetAndPIC(isProvider);
}

export function getStatusSelectorOptionsPIC(isProvider) {
  return getSelectorOptionsBudgetAndPIC(isProvider);
}

export function getStatusSelectorOptionInstallation() {
  const options = iniatilizeSelectorOptions();
  const optionOnGoing = {
    value: STATUS.ON_GOING.value,
    text: STATUS.ON_GOING.name,
  };
  const optionStopped = {
    value: STATUS.STOPPED.value,
    text: STATUS.STOPPED.name,
  };
  const optionOk = {
    value: STATUS.OK.value,
    text: STATUS.OK.name,
  };
  options.push(optionOnGoing);
  options.push(optionStopped);
  options.push(optionOk);

  return options;
}

export function getStatusSelectorOptionCertification() {
  const options = iniatilizeSelectorOptions();
  const optionReceived = {
    value: STATUS.RECEIVED.value,
    text: STATUS.RECEIVED.name,
  };
  options.push(optionReceived);
  const optionIncidence = {
    value: STATUS.INCIDENT.value,
    text: STATUS.INCIDENT.name,
  };
  options.push(optionIncidence);
  const optionOk = {
    value: STATUS.OK.value,
    text: STATUS.OK.name,
  };
  options.push(optionOk);

  return options;
}

export function getStatusLabel(status) {
  switch (status) {
    case STATUS.RECEIVED.value:
      return STATUS.RECEIVED.name;
    case STATUS.PENDING.value:
      return STATUS.PENDING.name;
    case STATUS.REVISED.value:
      return STATUS.REVISED.name;
    case STATUS.PENDING_LOGOPOST.value:
      return STATUS.PENDING_LOGOPOST.name;
    case STATUS.PENDING_SUPPLIER.value:
      return STATUS.PENDING_SUPPLIER.name;
    case STATUS.OK.value:
      return STATUS.OK.name;
    case STATUS.ON_GOING.value:
      return STATUS.ON_GOING.name;
    case STATUS.INCIDENT.value:
      return STATUS.INCIDENT.name;
    case STATUS.STOPPED.value:
      return STATUS.STOPPED.name;
    default:
      return '';
  }
}
export function getStatusSelectorOptionsByPhase(phase) {
  switch (phase) {
    case PHASE.MEASUREMENT.value:
      return getStatusSelectorOptionsMeasurement();
    case PHASE.PIC.value:
      return getSelectorOptionsBudgetAndPIC();
    case PHASE.BUDGET.value:
      return getSelectorOptionsBudgetAndPIC();
    case PHASE.INSTALLATION.value:
      return getStatusSelectorOptionInstallation();
    case PHASE.CERTIFICATION.value:
      return getStatusSelectorOptionCertification();
    default:
      return getStatusSelectorOptionsBudget();
  }
}

export function getStatusColor(status) {
  switch (status) {
    case STATUS.RECEIVED.value:
      return COLOR.SECONDARY;
    case STATUS.PENDING.value:
      return COLOR.SECONDARY;
    case STATUS.REVISED.value:
      return COLOR.SECONDARY;
    case STATUS.PENDING_LOGOPOST.value:
      return COLOR.SECONDARY;
    case STATUS.PENDING_SUPPLIER.value:
      return COLOR.SECONDARY;
    case STATUS.OK.value:
      return COLOR.PRIMARY;
    default:
      return '';
  }
}

<template>
  <div class="imageListItemContainer">
    <ImageListItem v-for="(image, index) in listImages"
                   :key="index"
                   :img="image"
                   :documentUuid="index"
                    @getClick="getClick"
    />
  </div>

</template>

<script>
import ImageListItem from '@/components/atoms/Image/ImageListItem.vue';

export default {
  name: 'ImageList',
  components: { ImageListItem },
  props: {
    listImages: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getClick(id) {
      this.$emit('setImageCarousel', id);
    },
  },
};
</script>

<style scoped lang="sass">
.imageListItemContainer
  display: flex
  flex-wrap: wrap
  width: 500px
  padding: 0
  margin-right: 2em
</style>

<template>
  <div class="ClientSelector">
    <SelectorMultiple
      select-label="Cliente"
      :select-form-options="options"
      @handlerSelect="getSelectedClients"
      :selectedClients="this.clients"
    />
  </div>
</template>

<script>
import SelectorMultiple from '@/components/atoms/Forms/SelectorMultiple/SelectorMultiple.vue';

export default {
  name: 'ClientSelector',
  components: {
    SelectorMultiple,
  },
  created() {
    this.getClients();
  },
  props: {
    isValid: {
      type: Boolean,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedClient: this.client,
      selectedClients: this.clients,
      options: [],
    };
  },
  computed: {
    hasClients() {
      return this.options.length > 1;
    },
  },
  methods: {
    getClients() {
      if (this.$store.getters.hasClients) {
        this.addClients(this.$store.getters.getClients);
      } else {
        this.blockScreen();

        this.$store.dispatch('getClients')
          .then((res) => {
            this.addClients(res);

            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    getClient(client) {
      this.$emit('handleChangeClient', client);
    },
    getSelectedClients(clients) {
      this.$emit('handleChangeClients', clients);
    },
    addClientOption(option) {
      this.options.push(option);
    },
    addClients(clients) {
      this.options = [
        { value: null, text: 'Sin cliente' },
      ];

      clients.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.email,
        };

        this.addClientOption(option);
      });
    },
  },
};
</script>

export default class ForecastStatusReport {
  constructor(forecastStatusReportData) {
    this.phase = forecastStatusReportData.phase;
    this.previewedOfficesCount = forecastStatusReportData.previewedOfficesCount;
    this.receivedPhaseInRangeCount = forecastStatusReportData.receivedPhaseInRangeCount;
    this.percentCompletion = forecastStatusReportData.percentCompletion;
  }

  get getPhase() {
    return this.phase;
  }

  get getPreviewedOfficesCount() {
    return this.previewedOfficesCount;
  }

  get getReceivedPhaseInRangeCount() {
    return this.receivedPhaseInRangeCount;
  }

  get getPercentCompletion() {
    return this.percentCompletion;
  }
}

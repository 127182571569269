import { ACCESS_TOKEN, ACCESS_REFRESH_TOKEN } from './AccesToken';
import STORAGE_DATA from './StorageHelper';

class StorageManager {
  constructor() {
    this.localStorage = localStorage;
  }

  saveToken(token) {
    this.localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken) {
    this.localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken);
  }

  getToken() {
    return this.localStorage.getItem(ACCESS_TOKEN);
  }

  removeToken() {
    this.localStorage.removeItem(ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.localStorage.getItem(ACCESS_REFRESH_TOKEN);
  }

  removeRefreshToken() {
    this.localStorage.removeItem(ACCESS_REFRESH_TOKEN);
  }

  saveCompany(company) {
    this.localStorage.setItem(STORAGE_DATA.SELECTED_COMPANY, company);
  }

  getCompany() {
    return this.localStorage.getItem(STORAGE_DATA.SELECTED_COMPANY);
  }

  removeCompany() {
    this.localStorage.removeItem(STORAGE_DATA.SELECTED_COMPANY);
  }

  saveProject(project) {
    this.localStorage.setItem(STORAGE_DATA.SELECTED_PROJECT, project);
  }

  getProject() {
    return this.localStorage.getItem(STORAGE_DATA.SELECTED_PROJECT);
  }

  removeProject() {
    this.localStorage.removeItem(STORAGE_DATA.SELECTED_PROJECT);
  }

  saveProjectName(project) {
    this.localStorage.setItem(STORAGE_DATA.SELECTED_PROJECT_NAME, project);
  }

  getProjectName() {
    return this.localStorage.getItem(STORAGE_DATA.SELECTED_PROJECT_NAME);
  }

  removeProjectName() {
    this.localStorage.removeItem(STORAGE_DATA.SELECTED_PROJECT_NAME);
  }

  getOfficeTypes() {
    return JSON.parse(this.localStorage.getItem(STORAGE_DATA.FILTERED_OFFICE_TYPES));
  }

  saveOfficeTypes(officeTypes) {
    this.localStorage.setItem(STORAGE_DATA.FILTERED_OFFICE_TYPES, JSON.stringify(officeTypes));
  }

  removeOfficeTypes() {
    this.localStorage.removeItem(STORAGE_DATA.FILTERED_OFFICE_TYPES);
  }

  removeLocalData() {
    this.removeToken();
    this.removeRefreshToken();
    this.removeCompany();
    this.removeProject();
    this.removeProjectName();
    this.removeOfficeTypes();
  }
}

export default new StorageManager();

export default class OfficeReport {
  constructor(officeReportData) {
    this.totalOffices = officeReportData.totalOffices;
    this.totalActiveOffices = officeReportData.totalActiveOffices;
  }

  get getTotalOffices() {
    return this.totalOffices;
  }

  get getTotalActiveOffices() {
    return this.totalActiveOffices;
  }
}

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="saveUser"
      @cancel="cancelAction"
    >
      <FormUserContent
        @handleUserData="setUserData"
        cols="3"
        showPassword
        :userData="this.userData"
        :userDataValidation="this.userDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormUserContent from '@/components/molecules/Users/FormUserContent.vue';
import {
  hasData, isValidEmail, getValidationState, getEmailValidationState,
} from '@/helpers/ValidationHelper';

export default {
  name: 'FormNewUser',
  components: {
    Modal,
    FormCard,
    FormUserContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateUsers: {},
  },
  data: () => ({
    title: 'Nuevo usuario',
    userData: {},
    userDataValidation: {
      email: null,
      password: null,
      role: null,
      name: null,
      surnames: null,
      supplier: null,
    },
  }),
  methods: {
    setUserData(userData) {
      this.userData = userData;
    },
    saveUser() {
      if (this.isValidData()) {
        this.blockScreen();

        this.$store.dispatch('addUser', this.userData)
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateUsers();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.userDataValidation = {
          email: getEmailValidationState(this.userData?.email),
          password: getValidationState(this.userData?.password),
          role: getValidationState(this.userData?.role),
          name: getValidationState(this.userData?.name),
          surnames: getValidationState(this.userData?.surnames),
          supplier: getValidationState(this.userData?.supplier),
        };
      }
    },
    isValidData() {
      return isValidEmail(this.userData?.email)
        && hasData(this.userData?.password)
        && hasData(this.userData?.role)
        && hasData(this.userData?.name)
        && hasData(this.userData?.surnames);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

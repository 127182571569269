<template>
  <div class="ActionCrumbButton">
    <button
      class="ActionCrumbButton__button"
      @click.prevent="handleClick"
    >{{ text }}</button>
  </div>
</template>

<script>

export default {
  name: 'ActionCrumbButton',
  props: {
    text: String,
  },
  methods: {
    handleClick() {
      this.$emit('handleClick');
    },
  },
};
</script>

<style lang="sass">
  @import './ActionCrumbButton.sass'
</style>

<template>
  <div class="">
    <Header />
    <div class="App__navigationView">
      <NavMenu
        v-show="this.isShowedNavMenu"
      />
      <slot name="private-template"></slot>
    </div>
  </div>
</template>
<script>
import Header from '@/components/molecules/Header/Header.vue';
import NavMenu from '@/components/molecules/NavMenu/NavMenu.vue';
import { breakpoint } from '../helpers/SizeHelper';

export default {
  name: 'PrivateTemplate',
  components: {
    NavMenu,
    Header,
  },
  computed: {
    isShowedNavMenu() {
      if (this.$screen.width > breakpoint) {
        return true;
      }

      return this.$store.getters.isShowedNavMenu;
    },
  },
};
</script>

<style scoped>

</style>

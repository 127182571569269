<template>
  <div v-if="$store.getters.hasProviderPermission || $store.getters.hasClientPermission">
    <UploadDocumentForm
      v-if="isShowedUploadForm"
      :officeUuid="officeUuid"
      :showModal="true"
      :measurementPhaseData="measurementPhaseData"
      :budgetPhaseData="budgetPhaseData"
      :picPhaseData="picPhaseData"
      :certificationPhaseData="certificationPhaseData"
      :installationPhaseData="installationPhaseData"
      :file="file"
      @cancel="cancelAction"
      @manageDocumentChanges="manageDocumentChanges"
    />
    <div class="Separator__vertical__st" />
    <p>Archivos</p>
    <p />
    <hr />
    <div class="uploaderContainer">
      <b-form-file
        v-model="file"
        multiple
        :state="Boolean(file)"
        placeholder="Selecciona o arrastra un archivo."
        drop-placeholder="Arrastra el archivo aquí..."
        class="documentUploader"
      ></b-form-file>
      <b-button
        variant="secondary"
        type="button"
        @click.prevent="uploadFile"
        :disabled="this.file === null"
      >
        Subir archivo
      </b-button>
      <DownloadAllDocumentsButton :officeUuid = "officeUuid" />
    </div>
    <div class="Separator__vertical__st" />
  </div>
  <div v-else>
    <div class="Separator__vertical__st" />
  </div>
</template>

<script>
import UploadDocumentForm from '@/components/atoms/PhaseDocumentUploader/UploadDocumentForm.vue';
import DownloadAllDocumentsButton
from '@/components/atoms/DownloadAllDocumentsButton/DownloadAllDocumentsButton.vue';

export default {
  name: 'PhaseDocumentUploader',
  components: { DownloadAllDocumentsButton, UploadDocumentForm },
  props: {
    officeUuid: String,
    measurementPhaseData: {
      type: Object,
      default: () => {},
    },
    budgetPhaseData: {
      type: Object,
      default: () => {},
    },
    picPhaseData: {
      type: Object,
      default: () => {},
    },
    installationPhaseData: {
      type: Object,
      default: () => {},
    },
    certificationPhaseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      isShowedUploadForm: false,
    };
  },
  methods: {
    showUploadForm() {
      this.isShowedUploadForm = true;
    },
    cancelAction() {
      this.isShowedUploadForm = false;
    },
    uploadFile() {
      this.isShowedUploadForm = true;
    },
    manageDocumentChanges() {
      this.$emit('manageDocumentChanges');
    },
  },
};
</script>
<style scoped lang="sass">
  .documentUploader
    width: 70%

  .uploaderContainer
    display: flex
    flex-direction: row
    justify-content: space-evenly
</style>

<template>
  <div class="OfficeStatusReport">
    <TableContainer title="Informe estado oficinas">
      <div class="TableSimpleBlock__content">
        <b-row align-v="center">
          <b-col class="col-12 col-lg-6">
            <SelectorSimple
              label="Proveedor"
              @handleSearch="getOfficeStatusReport"
              :options="options"
              :isLoading="isSuppliersLoading"
              :selectedOption="selectedSupplier"
            />
          </b-col>
          <b-col class="col-12 col-lg-6 text-right">
            <b-button
              v-if="$store.getters.hasAdminPermission"
              variant="primary"
              type="button"
              v-b-modal.exportation-format-modal
            >
              Exportar oficinas
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="TableSimpleBlock__content">
        <TableSimple
          :items="officeStatusReportData"
          :fields="fields"
          :isLoading="isOfficeStatusReportLoading"
          hasHtmlContent
        />
      </div>
      <b-modal id="exportation-format-modal" centered ok-only
        title="Elige el formato de exportación"
        @ok="exportStatusOffices"
        @close="() => {this.exportationFormat = 'excel';}"
      >
        <b-form-group>
          <b-form-radio v-model="exportationFormat" name="excel-radio" value="excel">
            Excel
          </b-form-radio>
          <b-form-radio v-model="exportationFormat" name="csv-radio" value="csv">
            CSV
          </b-form-radio>
        </b-form-group>
      </b-modal>
    </TableContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorSimple from '@/components/atoms/Forms/SelectorSimple/SelectorSimple.vue';
import TableSimple from '@/components/atoms/Tables/TableSimple/TableSimple.vue';
import { PHASE } from '@/helpers/PhaseHelper';
import exportFile from '@/helpers/FileHelper';

export default {
  name: 'OfficeStatusReport',
  components: {
    TableContainer,
    SelectorSimple,
    TableSimple,
  },
  data() {
    return {
      isSuppliersLoading: false,
      options: [
        { value: null, text: 'Sin selección' },
      ],
      officeStatusReportData: [],
      isOfficeStatusReportLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'totalPendingReceive', label: 'Pte. recibir', sortable: true },
        { key: 'totalReceived', label: 'Total recibido', sortable: true },
        { key: 'totalRevised', label: 'Revisado', sortable: true },
        { key: 'totalPendingLogopost', label: 'Pte. Logopost', sortable: true },
        { key: 'totalPendingSupplier', label: 'Pte. Proveedor', sortable: true },
        { key: 'totalOK', label: 'Ok', sortable: true },
      ],
      selectedSupplier: null,
      exportationFormat: 'excel',
    };
  },
  created() {
    this.getSuppliers();
    this.getOfficeStatusReport(this.$store.getters.getUserSupplier);
  },
  methods: {
    getSuppliers() {
      if (this.$store.getters.hasSuppliers) {
        this.addSuppliers(this.$store.state.suppliers);
      } else {
        this.setSuppliersLoading();
        const data = {
          projectId: this.$store.getters.getActiveProject,
        };
        this.$store.dispatch('getAllSuppliers', data)
          .then((res) => {
            this.addSuppliers(res.suppliers);

            this.setSuppliersNotLoading();
          })
          .catch(() => {
            this.setSuppliersNotLoading();
          });
      }
      if (this.$store.getters.getUserSupplier) {
        this.selectedSupplier = this.$store.getters.getUserSupplier;
        this.setOfficeStatusReportLoading();
        this.getOfficeStatusReport(this.selectedSupplier);
        this.setOfficeStatusReportNotLoading();
      } else {
        this.setSuppliersLoading();
        const userUuid = this.$store.state.user.uuid;
        this.$store.dispatch('getUser', userUuid)
          .then(() => {
            this.selectedSupplier = this.$store.state.user.supplier;
            this.setOfficeStatusReportLoading();
            this.getOfficeStatusReport(this.selectedSupplier);
            this.setSuppliersNotLoading();
            this.setOfficeStatusReportNotLoading();
          })
          .catch(() => {
            this.setSuppliersNotLoading();
          });
      }
    },
    addSupplierOption(option) {
      this.options.push(option);
    },
    addSuppliers(suppliers) {
      suppliers.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addSupplierOption(option);
      });
    },
    setSuppliersLoading() {
      this.isSuppliersLoading = true;
    },
    setSuppliersNotLoading() {
      this.isSuppliersLoading = false;
    },
    getOfficeStatusReport(supplierUuid) {
      this.selectedSupplier = supplierUuid;
      this.setOfficeStatusReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
        supplierId: supplierUuid || null,
      };

      this.$store.dispatch('getOfficeStatusReport', data)
        .then((res) => {
          this.officeStatusReportData = this.setOfficeStatusData(res);
          this.setOfficeStatusReportNotLoading();
        })
        .catch(() => {
          this.setOfficeStatusReportNotLoading();
        });
    },
    setOfficeStatusReportLoading() {
      this.isOfficeStatusReportLoading = true;
    },
    setOfficeStatusReportNotLoading() {
      this.isOfficeStatusReportLoading = false;
    },
    linkWrap(value, phase, status, supplierId) {
      const changes = {
        measurement: {
          received: 'ok',
        },
      };
      const params = {};
      if (phase) {
        params.phase = phase;
      }
      if (status) {
        params.status = status;
        if (changes[phase] && changes[phase][status]) {
          params.status = changes[phase][status];
        }
      }
      if (supplierId) {
        params.supplierId = supplierId;
      }
      const p = new URLSearchParams(params).toString();
      return `<a href="/offices?${p}">${value}</a>`;
    },
    getOfficeStatusOption(name, originalName, phase) {
      const supplierId = this.selectedSupplier;
      let totalPendingSupplerLink = '';
      if (originalName === 'certification') {
        totalPendingSupplerLink = this.linkWrap(phase.totalPendingSupplier, originalName, 'incident', supplierId);
      }
      if (originalName !== 'measurement') {
        totalPendingSupplerLink = this.linkWrap(phase.totalPendingSupplier, originalName, 'pending_supplier', supplierId);
      }
      return {
        name,
        originalName,
        totalReceived: this.linkWrap(phase.totalReceived, originalName, 'received', supplierId),
        totalPendingReceive: this.linkWrap(phase.totalPendingReceive, originalName, 'pending', supplierId),
        totalRevised: phase.totalRevised,
        totalPendingLogopost: originalName !== 'measurement'
          ? this.linkWrap(phase.totalPendingLogopost, originalName, 'received', supplierId)
          : '',
        totalPendingSupplier: totalPendingSupplerLink,
        totalOK: this.linkWrap(phase.totalOK, originalName, 'ok', supplierId),
      };
    },
    setOfficeStatusData(res) {
      const officeStatusData = [];

      officeStatusData.push(
        this.getOfficeStatusOption(
          PHASE.MEASUREMENT.name,
          PHASE.MEASUREMENT.value,
          res.measurement,
        ),
      );
      officeStatusData.push(
        this.getOfficeStatusOption(PHASE.BUDGET.name, PHASE.BUDGET.value, res.budget),
      );
      officeStatusData.push(
        this.getOfficeStatusOption(PHASE.PIC.name, PHASE.PIC.value, res.pic),
      );
      officeStatusData.push(
        this.getOfficeStatusOption(
          PHASE.CERTIFICATION.name,
          PHASE.CERTIFICATION.value,
          res.certification,
        ),
      );

      return officeStatusData;
    },
    exportStatusOffices() {
      this.blockScreen();

      const companyId = this.$store.getters.getActiveCompany;
      const projectId = this.$store.getters.getActiveProject;
      const supplierId = this.selectedSupplier;
      const format = this.exportationFormat;

      const requestData = {
        companyId,
        projectId,
        supplierId,
        format,
      };

      this.$store.dispatch('exportStatusOffices', requestData)
        .then((res) => {
          exportFile(`oficinas.${format === 'excel' ? 'xlsx' : 'csv'}`, res.data);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        const supplier = this.selectedSupplier ? this.selectedSupplier : null;
        this.getOfficeStatusReport(supplier);
      }
    },
  },
};
</script>

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="updateSupplier"
      @cancel="cancelAction"
    >
      <FormSupplierContent
        @handleSupplierData="setSupplierData"
        :supplierData="this.supplierData"
        :supplierDataValidation="this.supplierDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormSupplierContent from '@/components/molecules/Supplier/FormSupplierContent.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';

export default {
  name: 'FormUpdateSupplier',
  components: {
    Modal,
    FormCard,
    FormSupplierContent,
  },
  props: {
    showModal: Boolean,
    supplierData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateSuppliers: {},
  },
  data: () => ({
    title: 'Actualizar proveedor',
    supplierDataValidation: {
      name: null,
    },
  }),
  methods: {
    setSupplierData(supplierData) {
      this.supplierData = supplierData;
    },
    updateSupplier() {
      if (this.isValidData()) {
        const supplierdata = {
          name: this.supplierData.name,
        };

        this.blockScreen();

        this.$store.dispatch('updateSupplier', {
          uuid: this.supplierData.uuid,
          supplierData: supplierdata,
        })
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateSuppliers();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.supplierDataValidation = {
          name: getValidationState(this.supplierData?.name),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.supplierData?.name);
    },
  },
};
</script>

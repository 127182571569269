<template>
  <div class="TableSimple">
    <b-container
      v-if="isLoading"
      fluid
      class="d-flex justify-content-center m-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </b-container>
    <slot>
      <b-table
        v-if="!isLoading"
        :items="items"
        :fields="fields"
        striped
        hover
        bordered
      >
        <template v-if="hasHtmlContent" #cell()="data">
          <span v-html="data.value">1</span>
        </template>
      </b-table>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TableSimple',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    hasHtmlContent: Boolean,
  },
};
</script>

<style lang="sass">
  @import './TableSimple.sass'
</style>

<template>
  <div class="OfficeTabView">
     <b-card no-body>
        <b-tabs card>
          <b-tab title="Estado">
            <OfficeStatus
              :officeUuid="officeUuid"
              :measurementPhaseData="measurementPhaseData"
              :budget-phase-data="budgetPhaseData"
              :pic-phase-data="picPhaseData"
              :installation-phase-data="installationPhaseData"
              :certification-phase-data="certificationPhaseData"
            />
          </b-tab>
          <b-tab title-link-class="documentation" title="Documentación">
              <OfficeDocuments
                :officeUuid="officeUuid"
                :measurementPhaseData="measurementPhaseData"
                :budgetPhaseData="budgetPhaseData"
                :picPhaseData="picPhaseData"
                :installationPhaseData="installationPhaseData"
                :certificationPhaseData="certificationPhaseData"
              />
          </b-tab>
          <b-tab title="Incidencias">
            <Issues :officeUuid="officeUuid"/>
          </b-tab>
        </b-tabs>
      </b-card>
  </div>
</template>

<script>
import OfficeStatus from '@/components/molecules/Offices/Status/Status.vue';
import OfficeDocuments from '@/components/molecules/Documents/OfficeDocuments.vue';
import Issues from '@/components/molecules/Issues/Issues.vue';

export default {
  name: 'OfficeTabView',
  components: {
    Issues,
    OfficeDocuments,
    OfficeStatus,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    measurementPhaseData: {
      type: Object,
      default: () => {},
    },
    budgetPhaseData: {
      type: Object,
      default: () => {},
    },
    picPhaseData: {
      type: Object,
      default: () => {},
    },
    certificationPhaseData: {
      type: Object,
      default: () => {},
    },
    installationPhaseData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="sass">
  @import './OfficeTabView.sass'
</style>

<template>
  <form>
    <b-row cols="1" :cols-lg="this.cols">
      <b-col>
        <SimpleInput
          inputLabel="Name"
          inputId="newCompanyName"
          inputType="text"
          inputPlaceholder="Nombre"
          :inputValue="this.companyData.name"
          @handlerText="getName"
          isRequired
          :isValid="this.companyDataValidation.name"
        ></SimpleInput>
      </b-col>
      <b-col>
        <SimpleInput
          inputLabel="Información de contacto"
          inputId="newCompanyInfo"
          inputType="text"
          inputPlaceholder="Información de contacto"
          :inputValue="this.companyData.contactInfo"
          @handlerText="getContactInfo"
          isRequired
          :isValid="this.companyDataValidation.contactInfo"
        ></SimpleInput>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';

export default {
  name: 'FormcompanyContent',
  components: { SimpleInput },
  props: {
    handlecompanyData: {},
    cols: String,
    companyData: Object,
    companyDataValidation: Object,
  },
  methods: {
    getName(data) {
      this.companyData.name = data;
      this.$emit('handlecompanyData', this.companyData);
    },
    getContactInfo(data) {
      this.companyData.contactInfo = data;
      this.$emit('handlecompanyData', this.companyData);
    },
  },
};
</script>

<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Incidencias"/>
        <div class="viewContainer__content">
          <IssuesSearchBlock
            ref="issuesSearchBlock"
          />
          <div v-if="!$store.getters.hasIssues">
            <div class="container__simple">
              No hay resultados asociados a los criterios de búsqueda.
            </div>
          </div>
          {{ requestData }}
          <IssuesListView
            v-if="$store.getters.hasIssues"
            @handleUpdateIssues="getIssues"
            :requestData="initialList"
          />
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import IssuesSearchBlock from '@/components/molecules/Issues/IssuesSearchBlock.vue';
import IssuesListView from '@/components/molecules/Issues/IssuesListView.vue';

export default {
  name: 'IssuesView',
  components: {
    PrivateTemplate,
    TitleView,
    IssuesSearchBlock,
    IssuesListView,
  },
  props: {
    requestData: Object,
  },
  computed: {
    initialList() {
      return this.$refs.issuesSearchBlock.getRequestData(0);
    },
  },
  methods: {
    getIssues(page) {
      this.$refs.issuesSearchBlock.handleSearch(page);
    },
  },
};
</script>

export default class Project {
  constructor(projectData) {
    this.uuid = projectData.uuid;
    this.name = projectData.name;
    this.company = projectData.company;
    this.startDateExpected = projectData.startDateExpected;
    this.endDateExpected = projectData.endDateExpected;
    this.countOffices = projectData.countOffices;
    this.countSuppliers = projectData.countSuppliers;
    this.client = projectData.client;
    this.clients = projectData.clients;
    this.calendarId = projectData.calendarId;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getCompany() {
    return this.company;
  }

  get getStartDateExpected() {
    return this.startDateExpected;
  }

  get getEndDateExpected() {
    return this.endDateExpected;
  }

  get getCountOffices() {
    return this.countOffices;
  }

  get getCountSuppliers() {
    return this.countSuppliers;
  }

  get getClient() {
    return this.client;
  }
}

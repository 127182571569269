<template>
  <TableContainer title="Incidencias">
    <div class="OfficesSearchView table__container">
      <b-row>
        <b-col class="col-12 col-lg-3">
          <SelectorProject />
        </b-col>
        <b-col class="col-12 col-lg-3">
          <SelectorForm
            selectLabel="Proveedor"
            :selectFormOptions="supplierOptions"
            @handlerSelect="setSupplier"
            :selectedOption="this.selectedSupplier"
            :disabled="this.$store.getters.isSupplier"
          />
        </b-col>
        <b-col class="col-12 col-lg-2">
          <b-form-group label="Buscar">
            <b-button
              variant="secondary"
              type="button"
              @click.prevent="handleSearch"
              block
            >
              <img
                :src="require('@/assets/search.svg')"
              >
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="Separator__vertical__big"/>
      <IssueStats
        :supplierUuid="this.selectedSupplier || null"
        ref="issueStats"
      />
    </div>
  </TableContainer>
</template>

<script>
import { mapState } from 'vuex';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import IssueStats from './IssueStats.vue';

export default {
  name: 'IssuesSearchBlock',
  components: {
    TableContainer,
    SelectorForm,
    SelectorProject,
    IssueStats,
  },
  data() {
    return {
      selectedSupplier: null,
      supplierOptions: [
        { value: null, text: 'Seleccionar proveedor' },
      ],
    };
  },
  created() {
    this.initSuppliers();
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    initSuppliers() {
      if (this.$store.getters.hasSuppliers) {
        this.addSuppliers(this.$store.state.suppliers);

        this.unBlockScreen();
      } else {
        this.blockScreen();
        this.$store.dispatch('getAllSuppliers', {
          projectId: this.$store.getters.getActiveProject,
        })
          .then((res) => {
            this.addSuppliers(res.suppliers);

            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    getRequestData(page) {
      const currentPage = typeof page === 'number' ? page : 1;
      const itemsPerPage = 10;
      const companyId = this.$store.getters.getActiveCompany;
      const projectId = this.$store.getters.getActiveProject;
      const supplierId = this.selectedSupplier || null;

      return {
        currentPage,
        itemsPerPage,
        companyId,
        projectId,
        supplierId,
      };
    },
    handleSearch(page) {
      if (this.$store.getters.hasActiveCompany && this.$store.getters.hasActiveProject) {
        this.blockScreen();
        if (this.$store.getters.isSupplier && !this.$store.getters.getUserSupplier) {
          this.$store.dispatch('getUser', this.$store.state.user.uuid)
            .then(() => {
              this.selectedSupplier = this.$store.getters.getUserSupplier;
              this.$refs.issueStats.getStats();
              this.$store.dispatch('getAllIssues', this.getRequestData(page))
                .then(() => {
                  this.unBlockScreen();
                })
                .catch(() => {
                  this.unBlockScreen();
                });
            });
        } else {
          this.$refs.issueStats.getStats();
          this.$store.dispatch('getAllIssues', this.getRequestData(page))
            .then(() => {
              this.unBlockScreen();
            })
            .catch(() => {
              this.unBlockScreen();
            });
        }
      } else {
        this.$store.commit('emptyIssuesList');
        this.$refs.issueStats.cleanStats();
      }
    },
    addSupplierOption(option) {
      this.supplierOptions.push(option);
    },
    addSuppliers(suppliers) {
      suppliers.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addSupplierOption(option);
      });
    },
    setSupplier(data) {
      // store.commit()
      this.selectedSupplier = data;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
    getSelectedSupplier() {
      return this.selectedSupplier;
    },
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleSearch();
      }
    },
  },
};
</script>

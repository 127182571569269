<template>
  <TableSimpleBlock
    title="Resumen incidencias por proveedor"
    :items="issueSupplierReportData"
    :fields="fields"
    :isLoading="isIssueSupplierReportLoading"
  />
</template>

<script>
import { mapState } from 'vuex';
import TableSimpleBlock from '@/components/molecules/Tables/TableSimpleBlock/TableSimpleBlock.vue';

export default {
  name: 'IssueSupplierReport',
  components: {
    TableSimpleBlock,
  },
  data() {
    return {
      issueSupplierReportData: [],
      isIssueSupplierReportLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'officesCount', label: 'Oficinas Totales', sortable: true },
        { key: 'issuesCount', label: 'Incidencias', sortable: true },
        { key: 'solvedIssuesCount', label: 'Inc resueltas', sortable: true },
        { key: 'solvedIssuesOfficesCount', label: 'Of Inc resueltas', sortable: true },
        { key: 'notSolvedIssuesCount', label: 'Pendientes', sortable: true },
      ],
    };
  },
  created() {
    this.getIssueSupplierReport();
  },
  methods: {
    getIssueSupplierReport() {
      this.setIssueSupplierReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getIssueSupplierReport', data)
        .then((res) => {
          this.issueSupplierReportData = res;
          this.setIssueSupplierReportNotLoading();
        })
        .catch(() => {
          this.setIssueSupplierReportNotLoading();
        });
    },
    setIssueSupplierReportLoading() {
      this.isIssueSupplierReportLoading = true;
    },
    setIssueSupplierReportNotLoading() {
      this.isIssueSupplierReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getIssueSupplierReport();
      }
    },
  },
};
</script>

export default class BudgetPhase {
  constructor(phaseData) {
    this.uuid = phaseData.uuid;
    this.name = phaseData.name;
    this.status = phaseData.status;
    this.documents = phaseData.documents;
    this.performanceDate = phaseData.performanceDate;
    this.revisedDate = phaseData.revisedDate;
    this.approvedDate = phaseData.approvedDate;
    this.amount = phaseData.amount;
    this.orderNumber = phaseData.orderNumber;
    this.comments = phaseData.comments;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getStatus() {
    return this.status;
  }

  get getDocuments() {
    return this.documents;
  }

  get getReceivedDate() {
    return this.performanceDate;
  }

  get getRevisedDate() {
    return this.revisedDate;
  }

  get getApprovedDate() {
    return this.approvedDate;
  }

  get getAmount() {
    return this.amount;
  }

  get getOrderNumber() {
    return this.orderNumber;
  }

  get getComments() {
    return this.comments;
  }
}

export default class Issue {
  constructor(issueData) {
    this.uuid = issueData.uuid;
    this.name = issueData.name;
    this.slug = issueData.slug;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getSlug() {
    return this.slug;
  }
}

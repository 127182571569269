import ProjectFactory from '../domain/services/ProjectFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const PROJECTS = '/projects';

class ProjectAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllProjects(companyUuid) {
    const url = `${PROJECTS}?companyId=${companyUuid}`;

    return this.client
      .get(url)
      .then((res) => ProjectFactory.getAllProject(res.data?.collection))
      .catch((error) => Promise.reject(error));
  }

  getProject(uuid) {
    const url = `${PROJECTS}/${uuid}`;

    return this.client
      .get(url)
      .then((res) => ProjectFactory.getProject(res.data?.resource))
      .catch((error) => Promise.reject(error));
  }

  addProject(data) {
    return this.client
      .post(PROJECTS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateProject(data) {
    const url = `${PROJECTS}/${data.uuid}`;

    return this.client
      .put(url, data.projectData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteProject(uuid) {
    const url = `${PROJECTS}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getProjectReport(data) {
    const url = `${PROJECTS}/report`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';
    const supplierId = data.supplierId !== '' ? `&supplierId=${data.supplierId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}${supplierId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => ProjectFactory.getProjectReport(res.data?.data))
      .catch((error) => Promise.reject(error));
  }
}

export default ProjectAPI;

<template>
  <div class="ActionButtons">
    <b-button
      class="mr-1"
      variant="secondary"
      type="button"
      @click.prevent="handleAction"
    >
      <span>{{ okText }}</span>
    </b-button>
    <div class="Separator__st" />
    <b-button
      variant="outline-dark"
      type="button"
      @click.prevent="cancel"
    >
      <span>{{ cancelText }}</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',
  props: {
    okText: null,
    cancelText: null,
  },
  methods: {
    cancel() {
      this.$emit('handleCancel');
    },
    handleAction() {
      this.$emit('handleAction');
    },
  },
};
</script>

<style lang="sass">
@import './ActionButtons.sass'
</style>

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addCompany"
      @cancel="cancelAction"
    >
      <FormCompanyContent
        @handlecompanyData="setCompanyData"
        :companyData="this.companyData"
        :companyDataValidation="this.companyDataValidation"
        cols="2"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormCompanyContent from '@/components/molecules/Companies/FormCompanyContent.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';

export default {
  name: 'AddCompanyForm',
  components: {
    Modal,
    FormCard,
    FormCompanyContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateCompanies: {},
  },
  data: () => ({
    title: 'Nueva Compañia',
    companyData: {},
    companyDataValidation: {
      name: null,
      contactInfo: null,
    },
  }),
  methods: {
    setCompanyData(companyData) {
      this.companyData = companyData;
    },
    addCompany() {
      if (this.isValidData()) {
        this.blockScreen();

        this.$store.dispatch('addCompany', this.companyData)
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateCompanies();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.companyDataValidation = {
          name: getValidationState(this.companyData?.name),
          contactInfo: getValidationState(this.companyData?.contactInfo),
        };
      }
    },
    isValidData() {
      return hasData(this.companyData?.name)
        && hasData(this.companyData?.contactInfo);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

<template>
  <form>
    <SimpleInput
      inputLabel="Proveedor"
      inputId="newSupplier"
      inputType="text"
      inputPlaceholder="Proveedor"
      :inputValue="this.supplierData.name"
      @handlerText="getName"
      :isValid="this.supplierDataValidation.name"
    ></SimpleInput>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';

export default {
  name: 'FormSupplierContent',
  components: { SimpleInput },
  props: {
    handleSupplierData: {},
    supplierData: Object,
    supplierDataValidation: Object,
  },
  methods: {
    getName(data) {
      this.supplierData.name = data;
      this.$emit('handleSupplierData', this.supplierData);
    },
  },
};
</script>

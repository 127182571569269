export default class OfficeStatusReport {
  constructor(officeStatusReportData) {
    this.measurement = officeStatusReportData.measurement;
    this.pic = officeStatusReportData.pic;
    this.budget = officeStatusReportData.budget;
    this.certification = officeStatusReportData.certification;
  }

  get getMeasurement() {
    return this.measurement;
  }

  get getPic() {
    return this.pic;
  }

  get getBudget() {
    return this.budget;
  }

  get getCertification() {
    return this.certification;
  }
}

<template>
  <div class="CustomCalendar">
    <calendar-view
      :items="items"
      :show-date="showDate"
      :startingDayOfWeek="1"
      class="theme-default holiday-us-traditional holiday-us-official">
      <template #header="{ headerProps }">
        <calendar-view-header
          :header-props="headerProps"
          @input="setShowDate" />
      </template>
    </calendar-view>
  </div>

</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';

export default {
  name: 'CustomCalendar',
  components: {
    CalendarView,
    CalendarViewHeader,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDate: new Date(),
    };
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
  },
};
</script>

<style lang="sass">
@import './CustomCalendar.sass'
</style>

<template>
  <Modal v-show="true">
    <FormCard
      title="Añadir Logo"
      @accept="addLogo"
      @cancel="cancelAction"
    >
      <LogoUploader
        ref="logoUploader"
        :companyUuid="companyUuid"
        @handleClose="cancelAction"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import LogoUploader from '@/components/atoms/LogoUploader/LogoUploader.vue';

export default {
  name: 'AddLogoForm',
  components: {
    Modal,
    FormCard,
    LogoUploader,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    companyUuid: {
      type: String,
      default: '',
    },
  },
  methods: {
    addLogo() {
      this.$refs.logoUploader.uploadFile();
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

<template>
  <div
    class="Modal"
    v-show="showModal"
  >
    <div class="Modal__background"/>
    <div class="Modal__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showModal: Boolean,
  },
};
</script>

<style lang="sass">
  @import './Modal.sass'
</style>

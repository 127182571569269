<template>
  <button
    class="CollapseButton"
    @click="changeShowFullMenuBar()"
  >
    <img src="@/assets/navigation-menu-1.svg" />
  </button>
</template>

<script>

export default {
  name: 'CollapseButton',
  methods: {
    changeShowFullMenuBar() {
      this.$store.commit('changeShowFullMenuBar');
    },
  },
};
</script>

<style lang="sass">
@import './CollapseButton.sass'
</style>

<docs>
  ### Examples
  ```jsx
  <CollapseButton />
  ```
</docs>

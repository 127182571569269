import RevisionApi from '../../api/RevisionApi';

export function addRevision({ dispatch }, data) {
  const revisionApi = new RevisionApi();
  const revision = revisionApi.addRevision(data);

  return revision
    .then(() => {
      const officeData = {
        uuid: data.officeUuid,
      };
      dispatch('getPhaseByOffice', officeData);
    })
    .catch((error) => Promise.reject(error));
}

export function updateRevision(state, { officeUuid, data }) {
  const revisionApi = new RevisionApi();
  const revision = revisionApi.updateRevision(officeUuid, data);

  return revision
    .then()
    .catch((error) => Promise.reject(error));
}

export function deleteRevision(state, { officeUuid, revisionUuid }) {
  const revisionApi = new RevisionApi();
  const revision = revisionApi.deleteRevision(officeUuid, revisionUuid);

  return revision
    .then()
    .catch((error) => Promise.reject(error));
}

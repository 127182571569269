<template>
  <div class="OfficeCard">
    <LogoImageView
      :companyUuid="company.uuid"
    />
    <div class="table__container">
      <p class="phase-name"><b>Oficina:</b> {{ code }}</p>
      <div class="Separator__vertical__st" />
      <p class="phase-name"><b>Empresa:</b> {{ company.name }}</p>
      <div class="Separator__vertical__st" />
      <p class="phase-name"><b>Dirección:</b> {{ address }}</p>
      <div class="Separator__vertical__st" />
      <p class="phase-name"><b>Proyecto:</b> {{ project }}</p>
      <div class="Separator__vertical__st" />
      <p class="phase-name"><b>Proveedor:</b> {{ supplier }}</p>
      <div class="Separator__vertical__st" />
      <div><b class="phase-name">Estado:</b> <StatusBadget :status="active" /></div>
    </div>
  </div>
</template>

<script>
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';
import LogoImageView from '@/components/molecules/LogoImageView/LogoImageView.vue';

export default {
  name: 'OfficeCard',
  components: {
    StatusBadget,
    LogoImageView,
  },
  props: {
    code: String,
    company: Object,
    address: String,
    project: String,
    supplier: String,
    active: Boolean,
  },
};
</script>

<style lang="sass">
  @import './OfficeCard.sass'
</style>

<template>
  <b-form-select
    v-model="selectedCompany"
    :options="options"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'CompanySelect',
  created() {
    this.blockScreen();

    if (this.$store.getters.hasCompanies) {
      this.addCompanies(this.$store.state.companies);

      this.unBlockScreen();
    } else {
      this.$store.dispatch('getAllCompanies')
        .then((res) => {
          this.addCompanies(res);

          this.$store.commit('setCompanies', res);

          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    }
  },
  data() {
    return {
      options: [
        { value: null, text: 'Seleccionar compañia' },
      ],
    };
  },
  methods: {
    ...mapMutations([
      'setActiveCompany',
      'removeActiveCompany',
    ]),
    handlerRemoveActiveCompany() {
      this.removeActiveCompany();
    },
    addCompanyOption(option) {
      this.options.push(option);
    },
    addCompanies(companies) {
      companies.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addCompanyOption(option);
      });
    },
  },
  computed: {
    ...mapState([
      'companies',
    ]),
    selectedCompany: {
      get() {
        return this.$store.state.activeCompany;
      },
      set(newSelectedCompany) {
        if (newSelectedCompany !== null) {
          this.setActiveCompany(newSelectedCompany);
        } else {
          this.handlerRemoveActiveCompany();
        }
      },
    },
  },
  watch: {
    companies(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.options = [
          { value: null, text: 'Seleccionar compañia' },
        ];
        this.addCompanies(this.$store.state.companies);
      }
    },
  },
};
</script>

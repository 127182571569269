<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualizar Presupuesto"
      @accept="updateBudget"
      @cancel="cancelAction"
    >
      <FormBudgetContent
        :budgetData="this.budgetData"
        :budgetDataValidation="this.validationStatus"
        @onChangeStatus="onChangeStatus"
        @onChangePerformanceDate="onChangePerformanceDate"
        @onChangeRevisedDate="onChangeRevisedDate"
        @onChangeApprovedDate="onChangeApprovedDate"
        @onChangeAmount="onChangeAmount"
        @onChangeOrderNumber="onChangeOrderNumber"
        @onChangeComments="onChangeComments"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import { PHASE } from '@/helpers/PhaseHelper';
import { getFormattedFloatNumbersToBackEnd } from '@/helpers/MathHelper';
import FormBudgetContent from './FormBudgetContent.vue';

export default {
  name: 'UpdateBudgetForm',
  components: {
    Modal,
    FormCard,
    FormBudgetContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    budgetData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateOffice: {},
  },
  data: () => ({
    budgetDataValidation: {
      status: null,
      performanceDate: null,
      revisedDate: null,
      approvedDate: null,
      amount: null,
      orderNumber: null,
    },
    validationStatus: {
      status: null,
      amount: null,
      orderNumber: null,
    },
  }),
  mounted() {
    if (Object.keys(this.budgetData).length > 0) {
      this.budgetDataValidation.status = this.budgetData.status;
      this.budgetDataValidation.performanceDate = this.budgetData.performanceDate;
      this.budgetDataValidation.revisedDate = this.budgetData.revisedDate;
      this.budgetDataValidation.approvedDate = this.budgetData.approvedDate;
      this.budgetDataValidation.amount = this.budgetData.amount;
      this.budgetDataValidation.orderNumber = this.budgetData.orderNumber;
      this.budgetDataValidation.comments = this.budgetData.comments;
    }
  },
  methods: {
    updateBudget() {
      if (this.isValidData()) {
        const { amount } = this.budgetDataValidation;
        const phaseData = {
          status: this.budgetDataValidation.status,
          performanceDate: this.budgetDataValidation.performanceDate,
          revisedDate: this.budgetDataValidation.revisedDate,
          approvedDate: this.budgetDataValidation.approvedDate,
          amount: amount ? getFormattedFloatNumbersToBackEnd(amount) : null,
          orderNumber: this.budgetDataValidation.orderNumber,
          comments: this.budgetDataValidation.comments,
        };

        const budgetData = {
          officeUuid: this.officeUuid,
          phase: PHASE.BUDGET.value,
          phaseData,
        };

        this.blockScreen();

        this.$store.dispatch('updateBudgetPhase', {
          data: budgetData,
        })
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });

        this.closeForm();
      } else {
        this.validationStatus = {
          status: getValidationState(this.budgetDataValidation?.status),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.budgetDataValidation?.status);
    },
    onChangeStatus(status) {
      this.budgetDataValidation.status = status;
    },
    onChangePerformanceDate(performanceDate) {
      this.budgetDataValidation.performanceDate = performanceDate;
    },
    onChangeRevisedDate(revisedDate) {
      this.budgetDataValidation.revisedDate = revisedDate;
    },
    onChangeApprovedDate(approvedDate) {
      this.budgetDataValidation.approvedDate = approvedDate;
    },
    onChangeAmount(amount) {
      this.budgetDataValidation.amount = amount;
    },
    onChangeOrderNumber(orderNumber) {
      this.budgetDataValidation.orderNumber = orderNumber;
    },
    onChangeComments(comments) {
      this.budgetDataValidation.comments = comments;
    },
  },
};
</script>

<template>
  <div class="container__simple Importer">
    <b-row>
      <b-col md="6" sm="12" class="mb-3">
        <div>
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Selecciona o arrastra un archivo."
            drop-placeholder="Arrastra el archivo aquí..."
          />
        </div>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
          variant="secondary"
          type="button"
          @click.prevent="uploadFile"
          :disabled="this.file === null"
        >
          Subir archivo
        </b-button>
      </b-col>
      <b-col class="text-right">
        <b-badge :variant="elementsImported > 0 ? 'success' : 'secondary'">
          {{ `Elementos importados: ${elementsImported}`}}
        </b-badge>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Importer',
  props: {
    elementsImported: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    uploadFile() {
      this.$emit('handleUploadFile', this.file);
      this.file = null;
    },
  },
};
</script>
<style scoped lang="sass">
  @import './Importer.sass'
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Datepickers"},[_c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',[_c('label',{staticClass:"Datepickers__label",attrs:{"for":"startDate"}},[_vm._v(" "+_vm._s(_vm.startLabel)+" ")]),_c('b-form-datepicker',{attrs:{"id":"startDate","placeholder":this.startPlaceholder,"date-format-options":{
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },"state":_vm.isValidStartDate,"start-weekday":"1"},on:{"context":_vm.onSelectDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('div',{staticClass:"Separator__vertical__st__mobile"})],1),_c('b-col',[_c('label',{staticClass:"Datepickers__label",attrs:{"for":"endDate"}},[_vm._v(" "+_vm._s(_vm.endLabel)+" ")]),_c('b-form-datepicker',{attrs:{"id":"endDate","placeholder":_vm.endPlaceholder,"date-format-options":{
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },"state":_vm.isValidEndDate,"start-weekday":"1"},on:{"context":_vm.onSelectDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
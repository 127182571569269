<template>
  <div class="container__simple">
    <b-row>
      <b-col class="col col-lg-7">
        <CustomCalendar
          :items="this.items"
        />
      </b-col>
      <b-col class="col col-lg-5">
        <ForecastListView
          :handleShowAddForecastForm="showAddForecastForm"
          @handleUpdateForecasts="getForecasts"
          :items="this.items"
        />
      </b-col>
    </b-row>
    <AddForecastForm
      v-if="isShowedAddForecastForm"
      :showModal="true"
      @cancel="closeAddForecastForm"
      :handleClose="closeAddForecastForm"
      :handleUpdateForecasts="getForecasts"
    />
  </div>

</template>

<script>
import { mapState } from 'vuex';
import AddForecastForm from '@/components/molecules/Forecast/AddForecastForm.vue';
import ForecastListView from '@/components/molecules/Forecast/ForecastListView/ForecastListView.vue';
import CustomCalendar from '@/components/molecules/CustomCalendar/CustomCalendar.vue';

export default {
  name: 'Forecast',
  components: {
    AddForecastForm,
    ForecastListView,
    CustomCalendar,
  },
  data() {
    return {
      isShowedAddForecastForm: false,
      items: [],
    };
  },
  created() {
    this.getForecasts();
  },
  methods: {
    getForecasts() {
      this.blockScreen();
      const activeCompany = this.$store.getters.getActiveCompany;
      const activeProject = this.$store.getters.getActiveProject;

      if (this.$store.getters.isSupplier && !this.$store.getters.getUserSupplier) {
        this.$store.dispatch('getUser', this.$store.state.user.uuid)
          .then(() => {
            const supplierId = this.$store.getters.getUserSupplier;
            const data = {
              companyId: activeCompany === 'null' ? null : activeCompany,
              projectId: activeProject === 'null' ? null : activeProject,
              supplierId: supplierId === 'null' ? null : supplierId,

            };

            this.$store.dispatch('getAllForecasts', data)
              .then((res) => {
                this.items = res.forecasts;
                this.unBlockScreen();
              })
              .catch(() => {
                this.unBlockScreen();
              });
          });
      } else {
        const supplierId = this.$store.getters.getUserSupplier;
        const data = {
          companyId: activeCompany === 'null' ? null : activeCompany,
          projectId: activeProject === 'null' ? null : activeProject,
          supplierId: supplierId === 'null' ? null : supplierId,

        };

        this.$store.dispatch('getAllForecasts', data)
          .then((res) => {
            this.items = res.forecasts;
            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    showAddForecastForm() {
      this.isShowedAddForecastForm = true;
    },
    closeAddForecastForm() {
      this.isShowedAddForecastForm = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getForecasts();
      }
    },
  },
};
</script>

<template>
    <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Importador Oficinas"/>
        <div class="viewContainer__content">
          <div class="container__simple Templates">
            <b-form-group
              label="Tipo de plantilla"
            >
              <b-form-checkbox
                v-model="excel"
                name="templatesType"
                switch
                size="lg"
              >
                {{ excel ? 'Excel' : 'CSV' }}
              </b-form-checkbox>
            </b-form-group>
            <div>
              <b-link
                :href="getUrl(
                  `csv/offices/ImportOfficeValid`, excel
                  )"
              >
                Descargar plantilla Oficinas
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/phases/measurement/ImportMeasurementPhaseTestValid`, excel
                  )"
              >
                Descargar plantilla fase Medición
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/phases/budget/ImportBudgetPhasesValid`, excel
                  )"
              >
                Descargar plantilla fase Presupuesto
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/phases/pic/ImportPICPhaseTestValid`, excel
                  )"
              >
                Descargar plantilla fase PIC
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/phases/certification/ImportCertificationPhasesValid`, excel
                  )"
              >
                Descargar plantilla fase Certificación
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/phases/installation/ImportInstallationPhaseTestValid`, excel
                  )"
              >
                Descargar plantilla fase Instalación
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
            <div>
              <b-link
                :href="getUrl(
                  `csv/forecasts/ImportForecastsValid`, excel
                  )"
              >
                Descargar plantilla Eventos
              </b-link>
            </div>
            <div class="Separator__vertical__st"></div>
          </div>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TitleView from '@/components/atoms/TitleView/TitleView.vue';

export default {
  name: 'Templates',
  components: {
    PrivateTemplate,
    TitleView,
  },
  data: () => ({
    excel: false,
  }),
  methods: {
    getUrl(path, excel) {
      const ext = excel ? 'xlsx' : 'csv';
      // To avoid cache problems when excels templates are updated
      const date = new Date().getTime();
      return `${process.env.VUE_APP_API_URL}/${path}.${ext}?v=${date}`;
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './Templates.sass'
</style>

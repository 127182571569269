<template>
  <form>
    <b-row>
      <b-col>
        <b-form-group
          label="Fecha"
        >
          <b-form-datepicker
            id="noteDate"
            v-model="date"
            placeholder="Fecha"
            @context="getDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.noteDataValidation.date"
            start-weekday="1"
          />
        </b-form-group>
        <b-form-group
          label="Observaciones"
        >
          <b-form-textarea
            id="addNoteTextarea"
            v-model="body"
            placeholder="Observaciones..."
            rows="3"
            max-rows="6"
            @change="getBody"
            :state="this.noteDataValidation.body"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal id="note-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </form>
</template>

<script>

export default {
  name: 'FormNoteContent',
  props: {
    handleNoteData: {},
    noteData: {},
    noteDataValidation: Object,
  },
  data() {
    return {
      body: this.noteData.body,
      date: this.noteData.date,
    };
  },
  created() {
  },
  methods: {
    getBody(data) {
      this.noteData.body = data;
      this.$emit('handleNoteData', this.noteData);
    },
    getDate(data) {
      if (data.selectedDate !== null) {
        this.noteData.date = data.activeYMD;
        this.$emit('handleNoteData', this.noteData);
      }
    },
  },
};
</script>

import Phases from '../models/Phase/Phases';
import MeasurementPhase from '../models/Phase/MeasurementPhase';
import PicPhase from '../models/Phase/PicPhase';
import StandardPhase from '../models/Phase/StandardPhase';
import PhaseRevision from '../models/Phase/PhaseRevision';
import InstallationPhase from '../models/Phase/InstallationPhase';
import { PHASE, getPhaseLabel } from '../../helpers/PhaseHelper';
import Documents from '../models/Phase/Documents';
import BudgetPhase from '../models/Phase/BudgetPhase';
import { getFormattedFloatNumbersFromBackEnd } from '../../helpers/MathHelper';

class PhaseFactory {
  constructor() {
    this.phases = {};
  }

  // eslint-disable-next-line class-methods-use-this
  getDocument(document) {
    return new Documents({
      uuid: document.id,
      phaseUuid: document.id,
      originalName: document.originalName,
      fileName: document.fileName,
      uploadedAt: document.uploadedAt,
    });
  }

  getPhases(phases, office) {
    const measurementPhase = new MeasurementPhase({
      uuid: phases.measurementPhase.uuid,
      name: getPhaseLabel(PHASE.MEASUREMENT.value),
      status: phases.measurementPhase.status,
      documents: phases.measurementPhase.documents.map((document) => this.getDocument(document)),
      performanceDate: phases.measurementPhase.performanceDate,
    });

    const revisions = phases.picPhase.revisions.map((revision) => new PhaseRevision({
      uuid: revision.uuid,
      receivedDate: revision.receivedDate,
      revisedDate: revision.revisedDate,
      approvedDate: revision.approvedDate,
    }));

    const picPhase = new PicPhase({
      uuid: phases.picPhase.uuid,
      name: getPhaseLabel(PHASE.PIC.value),
      status: phases.picPhase.status,
      receivedDate: phases.picPhase.receivedDate,
      revisedDate: phases.picPhase.revisedDate,
      approvedDate: phases.picPhase.approvedDate,
      documents: phases.picPhase.documents.map((document) => this.getDocument(document)),
      revisions,
    });

    const budgetPhase = new BudgetPhase({
      uuid: phases.budgetPhase.uuid,
      name: getPhaseLabel(PHASE.BUDGET.value),
      status: phases.budgetPhase.status,
      documents: phases.budgetPhase.documents.map((document) => this.getDocument(document)),
      performanceDate: phases.budgetPhase.performanceDate,
      revisedDate: phases.budgetPhase.revisedDate,
      approvedDate: phases.budgetPhase.approvedDate,
      amount: getFormattedFloatNumbersFromBackEnd(office.amount),
      orderNumber: phases.budgetPhase.orderNumber,
      comments: phases.budgetPhase.comments,
    });

    const installationPhase = new InstallationPhase({
      uuid: phases.installationPhase.uuid,
      name: getPhaseLabel(PHASE.INSTALLATION.value),
      status: phases.installationPhase.status,
      documents: phases.installationPhase.documents.map((document) => this.getDocument(document)),
      startDate: phases.installationPhase.startDate,
      endDate: phases.installationPhase.endDate,
      stopPermitDate: phases.installationPhase.stopPermitDate,
      comments: phases.installationPhase.comments,
    });

    const certificationPhase = new StandardPhase({
      uuid: phases.certificationPhase.uuid,
      name: getPhaseLabel(PHASE.CERTIFICATION.value),
      status: phases.certificationPhase.status,
      documents: phases.certificationPhase.documents.map((document) => this.getDocument(document)),
      receivedDate: phases.certificationPhase.receivedDate,
      revisedDate: phases.certificationPhase.revisedDate,
      approvedDate: phases.certificationPhase.approvedDate,
      comments: phases.certificationPhase.comments,
    });

    this.phases = new Phases({
      measurementPhase,
      picPhase,
      budgetPhase,
      installationPhase,
      certificationPhase,

    });

    return this.phases;
  }
}

export default new PhaseFactory();

export default class PhaseRevision {
  constructor(revisionData) {
    this.uuid = revisionData.uuid;
    this.receivedDate = revisionData.receivedDate;
    this.revisedDate = revisionData.revisedDate;
    this.approvedDate = revisionData.approvedDate;
  }

  get getUuid() {
    return this.uuid;
  }

  get getReceivedDate() {
    return this.receivedDate;
  }

  get getRevisedDate() {
    return this.revisedDate;
  }

  get getApprovedDate() {
    return this.approvedDate;
  }
}

<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Proyectos - Consulta"/>
        <div class="viewContainer__content">
          <TableContainer
            title="Selecciona un proyecto"
            subtitle="Selecciona un proyecto para seleccionar datos individualizados al proyecto."
          >
            <div class="table__container">
              <b-row>
                <b-col class="col-12 col-lg-6">
                  <SelectorProject />
                </b-col>
              </b-row>
            </div>
          </TableContainer>
          <SingleProjectView v-if="showProjectView" />
          <div v-if="!$store.getters.hasActiveProject">
            <div class="container__simple">
              ¡Vaya! Tienes que seleccionar un proyecto para consultarlo.
            </div>
          </div>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import SingleProjectView from '@/components/molecules/Project/SingleProjectView.vue';

export default {
  name: 'Consultation',
  components: {
    PrivateTemplate,
    TitleView,
    TableContainer,
    SelectorProject,
    SingleProjectView,
  },
  computed: {
    showProjectView() {
      return this.$store.getters.hasProjects && this.$store.getters.hasActiveProject;
    },
  },
};
</script>

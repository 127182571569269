<template>
  <TableContainer :title="title">
    <b-container
      v-if="isLoading"
      fluid
      class="d-flex justify-content-center m-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </b-container>
    <div
      v-if="!isLoading"
      class="TableSimpleBlock__content"
    >
      <TableSimple
        :items="items"
        :fields="fields"
      />
    </div>
  </TableContainer>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import TableSimple from '@/components/atoms/Tables/TableSimple/TableSimple.vue';

export default {
  name: 'TableSimpleBlock',
  components: {
    TableContainer,
    TableSimple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },
};
</script>

<style lang="sass">
  @import './TableSimpleBlock.sass'
</style>

export default class Company {
  constructor(companyData) {
    this.uuid = companyData.uuid;
    this.name = companyData.name;
    this.contactInfo = companyData.contactInfo;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getContactInfo() {
    return this.contactInfo;
  }
}

<template>
  <div class="OfficeView" v-if="this.hasOffice">
    <b-row>
      <b-col class="col-12 col-lg-4">
        <OfficeCard
          :code="this.office.code"
          :company="this.office.project.company"
          :address="this.office.address"
          :project="this.office.project.name"
          :supplier="this.office.supplier.name"
          :active="this.office.active"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <OfficePhaseList
          :phases="this.office.getPhases()"
          :issuesCount="this.issuesCount"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <Map
          v-if="this.showMap"
          :locationUrl="this.office.getAddressSrc()"
        />
      </b-col>
    </b-row>
    <div class="Separator__vertical__big" />
    <b-row>
      <b-col>
        <OfficeTabView
          :officeUuid="this.office.uuid"
          :measurementPhaseData="this.office.measurementPhase"
          :budgetPhaseData="this.office.budgetPhase"
          :picPhaseData="this.office.picPhase"
          :certificationPhaseData="this.office.certificationPhase"
          :installationPhaseData="this.office.installationPhase"
        />
      </b-col>
    </b-row>
    <b-modal id="office-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </div>
</template>

<script>
import OfficeCard from '@/components/molecules/Offices/OfficeCard/OfficeCard.vue';
import OfficePhaseList from '@/components/molecules/Offices/OfficePhaseList/OfficePhaseList.vue';
import Map from '@/components/atoms/Map/Map.vue';
import OfficeTabView from '@/components/molecules/Offices/OfficeTabView/OfficeTabView.vue';

export default {
  name: 'OfficeView',
  components: {
    OfficeCard,
    OfficePhaseList,
    Map,
    OfficeTabView,
  },
  props: {
    officeUuid: String,
  },
  data() {
    return {
      issuesCount: 0,
    };
  },
  created() {
    this.getOffice(this.officeUuid);
  },
  methods: {
    getOffice(officeUuid) {
      if (officeUuid !== null) {
        this.blockScreen();

        const uuid = this.officeUuid;

        this.$store.dispatch('getOffice', { uuid })
          .then(() => {
            this.$store.dispatch('countIssuesByOffice', {
              officeId: uuid,
            })
              .then((res) => {
                this.issuesCount = Number(res.data.issuesCount);
                this.unBlockScreen();
              })
              .catch(() => {
                this.unBlockScreen();
                this.$bvModal.show('office-error-modal');
              });
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('office-error-modal');
          });
      }
    },
  },
  computed: {
    showMap() {
      return this.address !== 'undefined';
    },
    office() {
      return this.$store.state.office;
    },
    hasOffice() {
      return 'uuid' in this.$store.state.office;
    },
  },
};
</script>

<style lang="sass">
  @import './OfficeView.sass'
</style>

<template>
  <div class="SelectorProject">
    <div v-if="!$store.getters.hasActiveCompany">
      <div class="SelectorProject__alertMessage">
        ¡Vaya! Tienes que seleccionar una compañia para visualizar la vista.
      </div>
    </div>
    <div v-if="$store.getters.hasActiveCompany && !hasProjects">
      <div class="SelectorProject__alertMessage">
        No hay proyectos asociados a esta compañia.
      </div>
    </div>
    <b-form-group
      v-if="$store.getters.hasActiveCompany && hasProjects"
      :label="label">
      <b-form-select
        v-model="selectedProject"
        :options="options"
        :state="isValid"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'SelectorProject',
  created() {
    if (this.$store.getters.hasProjects) {
      this.addProjects(this.$store.state.projects);
    } else {
      this.getProjects();
    }
  },
  props: {
    label: {
      type: String,
      default: 'Selecciona un proyecto',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      options: [
        { value: null, text: 'Selecciona un proyecto' },
      ],
    };
  },
  computed: {
    ...mapState([
      'activeProject',
      'activeCompany',
    ]),
    selectedProject: {
      get() {
        return this.$store.state.activeProject;
      },
      set(newSelectedProject) {
        if (newSelectedProject !== null || newSelectedProject !== '') {
          let project = null;

          for (let i = 0; i < this.options.length; i += 1) {
            if (this.options[i].value === newSelectedProject) {
              project = {
                uuid: this.options[i].value,
                name: this.options[i].text,
              };

              break;
            }
          }

          this.setActiveProject(project);

          if (project.uuid !== null) {
            const data = {
              companyId: this.$store.getters.getActiveCompany,
              projectId: project.uuid,
            };

            this.$store.dispatch('getOfficeTypes', data);
            this.$store.dispatch('getAllSuppliers', data);
            this.$emit('change');
          } else {
            this.setActiveOfficeType(null);
            this.emptyOfficeTypes();
          }
        } else {
          this.handlerRemoveActiveProject();
        }
      },
    },
    hasProjects() {
      return this.options.length > 1;
    },
  },
  methods: {
    ...mapMutations([
      'setActiveProject',
      'removeActiveProject',
      'setActiveOfficeType',
      'emptyOfficeTypes',
    ]),
    getProjects() {
      if (this.$store.getters.hasActiveCompany) {
        this.blockScreen();

        this.$store.dispatch('getAllProjects')
          .then((res) => {
            this.addProjects(res);

            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    handlerRemoveActiveProject() {
      this.removeActiveProject();
      this.emptyOfficeTypes();
    },
    addProjectOption(option) {
      this.options.push(option);
    },
    addProjects(projects) {
      this.options = [
        { value: null, text: 'Selecciona un proyecto' },
      ];

      projects.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addProjectOption(option);
      });
    },
  },
  watch: {
    activeProject(newValue) {
      if (newValue === null) {
        this.selectedProject = null;
      }
    },
    activeCompany(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getProjects();

        this.setActiveProject(null);
        this.selectedProject = null;
      }
    },
  },
};
</script>

<style lang="sass">
@import './SelectorProject.sass'
</style>

<template>
  <div>
    <Modal :showModal="showLoader">
      <b-spinner variant="light" label="Spinning"></b-spinner>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';

export default {
  name: 'FullLoader',
  components: {
    Modal,
  },
  props: {
    showLoader: Boolean,
  },
};
</script>

<template>
  <TableSimpleBlock
    title="Informe control revisiones PIC"
    :items="picRevisionReportData"
    :fields="fields"
    :isLoading="isPicRevisionReportLoading"
  />
</template>

<script>
import { mapState } from 'vuex';
import TableSimpleBlock from '@/components/molecules/Tables/TableSimpleBlock/TableSimpleBlock.vue';

export default {
  name: 'PicRevisionReport',
  components: {
    TableSimpleBlock,
  },
  data() {
    return {
      picRevisionReportData: [],
      isPicRevisionReportLoading: false,
      fields: [
        { key: 'name', label: 'Proveedor', sortable: true },
        { key: 'activeOfficesCount', label: 'Oficinas adjudicadas', sortable: true },
        { key: 'receivedCount', label: 'PICs Recibido', sortable: true },
        { key: 'revisedCount', label: 'PICs Revisado', sortable: true },
        { key: 'revisionCount', label: 'Nº Revisiones sobre PICs recibidos', sortable: true },
      ],
    };
  },
  created() {
    this.getPicRevisionReport();
  },
  methods: {
    getPicRevisionReport() {
      this.setPicRevisionReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getPicRevisionReport', data)
        .then((res) => {
          this.picRevisionReportData = res;
          this.setPicRevisionReportNotLoading();
        })
        .catch(() => {
          this.setPicRevisionReportNotLoading();
        });
    },
    setPicRevisionReportLoading() {
      this.isPicRevisionReportLoading = true;
    },
    setPicRevisionReportNotLoading() {
      this.isPicRevisionReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getPicRevisionReport();
      }
    },
  },
};
</script>

import OfficeApi from '../../api/OfficeApi';

export function getAllOffices({ commit }, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getAllOffices(data);

  return offices
    .then((res) => {
      commit('setOfficesList', res);
    })
    .catch((error) => Promise.reject(error));
}

export function getOffice({ commit }, { uuid }) {
  const officeApi = new OfficeApi();
  const office = officeApi.getOffice(uuid);

  return office
    .then((res) => {
      commit('setOffice', res);
    })
    .catch((error) => Promise.reject(error));
}

export function addOffice({ commit }, data) {
  const officeApi = new OfficeApi();
  const office = officeApi.addOffice(data);

  return office
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateOffice({ commit }, data) {
  const officeApi = new OfficeApi();
  const office = officeApi.updateOffice(data);

  return office
    .then(() => {
      commit('updateOfficeList', data);
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function deleteOffice({ commit }, { uuid }) {
  const officeApi = new OfficeApi();
  const office = officeApi.deleteOffice(uuid);

  return office
    .then(() => {
      commit('deleteOffice', uuid);
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getOfficeReport(state, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getOfficeReport(data);

  return offices
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function getOfficeInstallationReport(state, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getOfficeInstallationReport(data);

  return offices
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function getOfficeCertificationReport(state, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getOfficeCertificationReport(data);

  return offices
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function getOfficeStatusReport(state, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getOfficeStatusReport(data);

  return offices
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function manageErrors(commit, error) {
  if (error.response.status === 401) {
    // this.$router.push({ name: 'Login' });
  } else {
    commit('setErrors', error);
  }
}

function upload(commit, offices) {
  return offices
    .then((res) => {
      if (res.errors.length > 0) {
        commit('setInportErrors', res.errors);
      }

      return res;
    })
    .catch((error) => {
      manageErrors(commit, error);
      return Promise.reject(error);
    });
}

export function uploadOffice({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadOffice(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadMeasurement({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadMeasurement(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadBudget({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadBudget(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadPic({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadPic(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadCertification({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadCertification(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadInstallation({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadInstallation(projectUuid, formData);

  return upload(commit, offices);
}

export function uploadForecast({ commit }, { projectUuid, formData }) {
  const officeApi = new OfficeApi();
  const offices = officeApi.uploadForecast(projectUuid, formData);

  return upload(commit, offices);
}

export function exportStatusOffices({ commit }, requestData) {
  const officeApi = new OfficeApi();
  const offices = officeApi.exportStatusOffices(requestData);

  return offices
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function exportPhaseOffices({ commit }, requestData) {
  const officeApi = new OfficeApi();
  const offices = officeApi.exportPhaseOffices(requestData);

  return offices
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getPicRevisionReport({ commit }, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getPicRevisionReport(data);

  return offices
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getOfficeTypes({ commit }, data) {
  const officeApi = new OfficeApi();
  const offices = officeApi.getOfficeTypes(data);

  return offices
    .then((res) => {
      commit('setOfficeTypes', res);
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function downloadOfficeDocuments({ commit }, data) {
  const officeApi = new OfficeApi();
  const office = officeApi.downloadAllDocuments(data);
  return office
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getLotsFromCompanyAndOffice({ commit }, data) {
  const officeApi = new OfficeApi();
  const lot = officeApi.getLots(data);
  return lot
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

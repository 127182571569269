import IssueApi from '../../api/IssueApi';

export function getAllIssues({ commit }, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.getAllIssues(data);

  return issues
    .then((res) => {
      commit('setIssuesList', res);
      return res;
    })
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function exportAllIssues(_, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.exportAllIssues(data);

  return issues
    .then((res) => res)
    .catch((error) => error);
}

export function getIssue(state, { uuid }) {
  const issueApi = new IssueApi();
  const issues = issueApi.getIssue(uuid);

  return issues
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function addIssue({ commit }, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.addIssue(data);

  return issues
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateIssue({ commit }, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.updateIssue(data);

  return issues
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function deleteIssue({ commit }, { uuid }) {
  const issueApi = new IssueApi();
  const issues = issueApi.deleteIssue(uuid);

  return issues
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getIssueReasonReport({ commit }, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.getIssueReasonReport(data);

  return issues
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getIssueSupplierReport(state, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.getIssueSupplierReport(data);

  return issues
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function getIssueReasons({ commit }) {
  const issueApi = new IssueApi();
  const issues = issueApi.getIssueReasons();

  return issues
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getIssueStats({ commit }, data) {
  const issueApi = new IssueApi();
  const issues = issueApi.getIssueStats(data);

  return issues
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}
export function uploadIssueDocument({ commit }, { requestData, formData }) {
  const issueApi = new IssueApi();
  const issue = issueApi.uploadIssueDocument(requestData, formData);

  return issue
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function downloadIssueDocument({ commit }, requestData) {
  const issueApi = new IssueApi();
  const issue = issueApi.downloadIssueDocument(requestData);

  return issue
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}
export function countIssuesByOffice({ commit }, requestData) {
  const issueApi = new IssueApi();
  const issue = issueApi.countIssuesByOffice(requestData);
  return issue
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export default class OfficeStatusReportTotals {
  constructor(officeStatusReportTotalsData) {
    this.totalReceived = officeStatusReportTotalsData.totalReceived;
    this.totalPendingReceive = officeStatusReportTotalsData.totalPendingReceive;
    this.totalRevised = officeStatusReportTotalsData.totalRevised;
    this.totalPendingLogopost = officeStatusReportTotalsData.totalPendingLogopost;
    this.totalPendingSupplier = officeStatusReportTotalsData.totalPendingSupplier;
    this.totalOK = officeStatusReportTotalsData.totalOK;
  }

  get getTotalReceived() {
    return this.totalReceived;
  }

  get getTotalPendingReceive() {
    return this.totalPendingReceive;
  }

  get getTotalRevised() {
    return this.totalRevised;
  }

  get getTotalPendingLogopost() {
    return this.totalPendingLogopost;
  }

  get getTotalPendingSupplier() {
    return this.totalPendingSupplier;
  }

  get getTotalOK() {
    return this.totalOK;
  }
}

<!-- eslint-disable max-len -->
<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView :title="title"/>
        <div class="viewContainer__content">
          <b-row>
            <b-col>
              <TableContainer
                title="Selecciona un proyecto"
                subtitle="Selecciona un proyecto para seleccionar datos individualizados al proyecto."
              >
                <div class="table__container">
                  <b-row>
                    <b-col class="col-12 col-lg-6">
                      <SelectorProject />
                    </b-col>
                  </b-row>
                </div>
              </TableContainer>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="!$store.getters.hasActiveProject">
                <div class="container__simple">
                  ¡Vaya! Tienes que seleccionar un proyecto para consultarlo.
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="$store.getters.hasActiveProject">
            <b-col>
              <slot></slot>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';

export default {
  name: 'ReportView',
  components: {
    PrivateTemplate,
    TitleView,
    TableContainer,
    SelectorProject,
  },
  props: {
    title: String,
  },
};
</script>

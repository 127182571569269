<template>
  <ReportView title="Resumen fase certificación">
    <OfficeCertificationReport/>
  </ReportView>
</template>

<script>
import OfficeCertificationReport from '@/components/molecules/Dashboard/OfficeCertificationReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'OfficeCertificationReportView',
  components: {
    OfficeCertificationReport,
    ReportView,
  },
};
</script>

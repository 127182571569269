<template>
  <private-template>
    <template v-slot:private-template>
      <div class="Offices viewContainer">
        <TitleView title="Oficinas"/>
        <ActionCrumbButton
          v-show="showOfficeView"
          text="< Volver"
          @handleClick="handleCloseOfficeView"
        />
        <div class="viewContainer__content">
          <div v-show="!showOfficeView">
            <OfficesSearchView
              @handleShowAddOfficeForm="showAddOfficeForm"
              @search="onSearch"
              ref="officesSearchView"
            />
            <OfficesListView
              v-if="$store.getters.hasOffices && $store.getters.hasActiveCompany"
              @handleShowOfficeView="handleShowOfficeView"
              @handlePaginationChange="updatePagination"
              :filters="requestData"
            />
            <!--              @handleReloadOffices="reloadOffices"-->
            <div v-if="!$store.getters.hasOffices">
              <div class="container__simple">
                No hay resultados asociados a los criterios de búsqueda.
              </div>
            </div>
          </div>
          <OfficeView
            v-if="showOfficeView"
            :officeUuid="officeUuid"
          />
        </div>
        <AddOfficeForm
          v-if="isShowedAddOfficeForm"
          :showModal="true"
          @cancel="closeAddOfficeForm"
          :handleClose="closeAddOfficeForm"
          :handleCreated="reloadOffices"
        />
      </div>
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import OfficesSearchView from '@/components/molecules/Offices/OfficesSearchView/OfficesSearchView.vue';
import OfficesListView from '@/components/molecules/Offices/OfficesListView/OfficesListView.vue';
import OfficeView from '@/components/molecules/Offices/OfficeView/OfficeView.vue';
import ActionCrumbButton from '@/components/atoms/ActionCrumbButton/ActionCrumbButton.vue';
import AddOfficeForm from '@/components/molecules/Offices/FormOffices/AddOfficeForm.vue';

export default {
  name: 'Offices',
  components: {
    PrivateTemplate,
    TitleView,
    OfficesSearchView,
    OfficesListView,
    OfficeView,
    ActionCrumbButton,
    AddOfficeForm,
  },
  data: () => ({
    showOfficeView: false,
    officeUuid: null,
    isShowedAddOfficeForm: false,
    pagination: 1,
    requestData: {},
  }),
  methods: {
    handleShowOfficeView(officeUuid) {
      this.officeUuid = officeUuid;
      this.showOfficeView = true;
    },
    handleCloseOfficeView() {
      this.$store.commit('emptyOffice');
      this.showOfficeView = false;
    },
    showAddOfficeForm() {
      this.isShowedAddOfficeForm = true;
    },
    closeAddOfficeForm() {
      this.isShowedAddOfficeForm = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
      this.reloadOffices();
    },
    reloadOffices() {
      this.$refs.officesSearchView.handleSearch(this.pagination);
    },
    onSearch(requestData) {
      this.requestData = requestData;
    },
  },
};
</script>

<style lang="sass">
  @import './Offices.sass'
</style>

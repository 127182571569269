<template>
  <div>
    <Header :isPrivate="false" />
    <div class="AuthView">
      <Login />
    </div>
  </div>
</template>

<script>
import Header from '@/components/molecules/Header/Header.vue';
import Login from '@/components/molecules/Login/Login.vue';

export default {
  name: 'LoginView',
  components: {
    Header,
    Login,
  },
};
</script>

<style lang="sass" scoped>
  @import '../AuthView/AuthView.sass'
</style>

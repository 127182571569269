<template>
  <div
    v-if="$store.getters.hasActiveProject && $store.getters.hasProjects"
    class="ActiveProject"
  >
    <div class="ActiveProject__titleBlock">
      <p>PROYECTO SELECCIONADO</p>
      <button
        class="ActiveProject__closeButton"
        @click="handlerRemoveActiveProject()"
      >
        <img src="@/assets/close.svg" />
      </button>
    </div>
    <div class="Separator__vertical__st" />
    <div class="ActiveProject__infoBlock">
      <div class="ActiveProject__icon"/>
      <p>{{ this.getActiveProject() }}</p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ActiveProject',
  methods: {
    ...mapMutations([
      'removeActiveProject',
    ]),
    handlerRemoveActiveProject() {
      this.removeActiveProject();
    },
    getActiveProject() {
      if (this.$store.getters.hasProjects) {
        return this.$store.state.activeProjectName;
      }

      return null;
    },
  },
};
</script>

<style lang="sass">
@import './ActiveProject.sass'
</style>

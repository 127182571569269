<template>
  <div class="Pic">
    <b-row>
      <b-col class="col-lg-16">
        <div class="phaseHeader">
          <div class="text-left phase-name w-100">
            <p>PIC</p>
          </div>
          <div
            v-if="$store.getters.hasAdminPermission"
            class="text-right w-100"
          >
            <b-button
              variant="primary"
              type="button"
              @click.prevent="showAddForm"
            >
              Añadir Revisión
            </b-button>
          </div>
          <div class="Separator__vertical__st"/>
          <div class="text-right">
          </div>
        </div>
        <div class="Separator__vertical__big"/>
        <b-table
          :items="this.getPhaseData"
          :fields="fields"
        >
          <template #cell(status)="data">
            <button
              @click="showUpdateForm"
              class="simpleButton"
            >
              <StatusBadget
                :text="data.item.status"
              />
            </button>
           </template>
          <template #cell(receivedDate)="data">
            {{ data.item.receivedDate | formatDate }}
          </template>
          <template #cell(revisedDate)="data">
            {{ data.item.revisedDate | formatDate }}
          </template>
          <template #cell(approvedDate)="data">
            {{ data.item.approvedDate | formatDate }}
          </template>
          <template  #cell(totalRevisions)="data">
            {{data.item.totalRevisions}}
          </template>
        </b-table>
        <div class="Separator__vertical__big"/>
        <Revisions :officeUuid="officeUuid" :phaseData="phaseData"/>
      </b-col>
    </b-row>
    <UpdatePicForm
      v-if="isShowedUpdateForm"
      :showModal="true"
      @cancel="closeUpdateForm"
      :officeUuid="officeUuid"
      :picData="phaseData"
      :handleClose="closeUpdateForm"
    />
    <AddRevisionForm
      v-if="isShowedAddForm"
      :showModal="true"
      @cancel="closeAddForm"
      :handleClose="closeAddForm"
      :officeUuid="officeUuid"
      @updateRevisions="getRevisions"/>
  </div>
</template>

<script>
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';
import UpdatePicForm from './UpdatePicForm.vue';
import Revisions from '@/components/molecules/Offices/Phases/Pic/Revision/Revisions.vue';
import AddRevisionForm from '@/components/molecules/Offices/Phases/Pic/Revision/AddRevisionForm.vue';

export default {
  name: 'Pic',
  components: {
    AddRevisionForm,
    Revisions,
    StatusBadget,
    UpdatePicForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    phaseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowedUpdateForm: false,
      isShowedAddForm: false,
      fields: [
        { key: 'status', label: 'Estado', class: 'status-header' },
        { key: 'receivedDate', label: 'Fecha Realización', class: 'dates-header' },
        { key: 'revisedDate', label: 'Fecha Revisión', class: 'dates-header' },
        { key: 'approvedDate', label: 'Fecha Aprobación', class: 'dates-header' },
        { key: 'totalRevisions', label: 'Número de revisiones' },
      ],
    };
  },
  methods: {
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
    showAddForm() {
      this.isShowedAddForm = true;
    },
    closeAddForm() {
      this.isShowedAddForm = false;
    },
    getRevisions() {
      const uuid = this.officeUuid;

      const officeData = this.$store.getters.getOffices.find((office) => office.uuid === uuid);

      this.$store.dispatch('getPhaseByOffice', officeData)
        .then(() => {
        })
        .catch(() => {
        });
    },
  },
  computed: {
    getPhaseData() {
      if (typeof this.phaseData === 'undefined') {
        return [];
      }

      return [
        {
          name: this.phaseData.name,
          status: this.phaseData.status,
          receivedDate: this.phaseData.receivedDate,
          revisedDate: this.phaseData.revisedDate,
          approvedDate: this.phaseData.approvedDate,
          totalRevisions: this.phaseData.revisions.length,
        },
      ];
    },

    getTotalRevisions() {
      if (typeof this.phaseData === 'undefined') {
        return [];
      }
      return this.phaseData.revisions.length;
    },
  },
};
</script>
<style scoped lang="sass">
  ::v-deep
    .status-header
      width: 140px

    .dates-header
      width: 15%
</style>

<template>
  <TableContainer title="Proveedores">
    <div class="TableContainer__header">
      <b-button
        variant="primary"
        @click.prevent="showAddSupplierForm"
      >
        Añadir proveedor
      </b-button>
    </div>
    <div class="table__container">
      <div>
        <b-table
          v-if="hasItems()"
          :items="items"
          :fields="fields"
          striped
          hover
          bordered
        >
          <template #cell(acciones)="data">
            <div class="actionGroup">
              <ActionIconButton
                icon="edit.svg"
                type="edit"
                :handler="editSupplier"
                :objectData="data.item"
              />
              <ActionIconButton
                icon="delete.svg"
                type="delete"
                :handler="deleteSupplier"
                :objectData="data.item"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div
      v-if="!hasItems()"
      class="container__simple"
    >
      ¡Vaya! No hay proveedores.
    </div>
    <AddSupplierForm
      v-if="isShowedAddSupplierForm"
      :showModal="true"
      @cancel="closeAddSupllierForm"
      :handleClose="closeAddSupllierForm"
      :handleUpdateSupliers="getSuppliers"
    />
    <UpdateSupplierForm
      v-if="isShowedUpdateSupplierForm"
      :showModal="true"
      @cancel="closeUpdateSupplierForm"
      :supplierData="currentSupplier"
      :handleClose="closeUpdateSupplierForm"
      :handleUpdateSuppliers="getSuppliers"
    />
    <b-modal id="supplier-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </TableContainer>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddSupplierForm from '@/components/molecules/Supplier/AddSupplierForm.vue';
import UpdateSupplierForm from '@/components/molecules/Supplier/UpdateSupplierForm.vue';

export default {
  name: 'SupplierBlock',
  components: {
    TableContainer,
    ActionIconButton,
    AddSupplierForm,
    UpdateSupplierForm,
  },
  created() {
    this.getSuppliers();
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'acciones' },
      ],
      items: [],
      isShowedAddSupplierForm: false,
      isShowedUpdateSupplierForm: false,
      currentSupplier: {
        uuid: '',
        name: '',
        companyName: '',
      },
    };
  },
  methods: {
    getSuppliers() {
      this.blockScreen();
      this.$store.dispatch('getAllSuppliers', {})
        .then((res) => {
          this.items = res.suppliers;

          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    showAddSupplierForm() {
      this.isShowedAddSupplierForm = true;
    },
    closeAddSupllierForm() {
      this.isShowedAddSupplierForm = false;
    },
    showUpdateSupplierForm() {
      this.isShowedUpdateSupplierForm = true;
    },
    closeUpdateSupplierForm() {
      this.isShowedUpdateSupplierForm = false;
    },
    editSupplier(objectData) {
      this.currentSupplier = {
        uuid: objectData.uuid,
        name: objectData.name,
        companyName: objectData.companyName,
      };

      this.showUpdateSupplierForm();
    },
    deleteSupplier(objectData) {
      this.blockScreen();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteSupplier', { uuid })
          .then(() => {
            this.getSuppliers();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('supplier-error-modal');
          });
      }
    },
    hasItems() {
      return this.items.length > 0;
    },
  },
};
</script>

<template>
  <TableContainer title="Proyectos">
    <div
      v-show="$store.getters.hasAdminPermission"
      class="TableContainer__header"
    >
      <b-button
        variant="primary"
        @click.prevent="showAddProyectForm"
      >
        Añadir proyecto
      </b-button>
    </div>
    <div class="table__container">
      <div
        v-if="isShowedLoader"
        class="d-flex justify-content-center mb-3">
        <b-spinner label="Spinning" variant="secondary"></b-spinner>
      </div>
      <div v-if="hasProjects && !isShowedLoader">
        <b-table
          :fields="fields"
          :items="items"
          bordered
          hover
          striped
        >
          <template #cell(startDateExpected)="data">
            {{ data.item.startDateExpected | formatDate }}
          </template>
          <template #cell(endDateExpected)="data">
            {{ data.item.endDateExpected | formatDate }}
          </template>
          <template #cell(client)="data">
            <ul class="client-list">
              <li v-for="client in data.item.clients" :key="client.uuid">
                {{ client.email }}
              </li>
            </ul>
          </template>
          <template #cell(acciones)="data">
            <div
              class="actionGroup"
            >
              <ActionIconButton
                v-show="$store.getters.hasAdminPermission"
                :handler="editProject"
                :objectData="data.item"
                icon="edit.svg"
                type="edit"
              />
              <ActionIconButton
                v-show="$store.getters.hasAdminPermission"
                :handler="handleShowDeleteProject"
                :objectData="data.item"
                icon="delete.svg"
                type="delete"
              />
              <ActionIconButton
                v-show="$store.getters.hasClientPermission"
                :handler="handleShowCalendar"
                :objectData="data.item"
                icon="eye_white.svg"
                type="action2"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div
      v-if="!hasProjects && !isShowedLoader"
      class="container__simple"
    >
      No hay proyectos asociados a esta compañia.
    </div>
    <AddProjectForm
      v-if="isShowedAddProjectForm"
      :handleClose="closeAddProjectForm"
      :handleUpdateProjects="getProjects"
      :showModal="true"
      @cancel="closeAddProjectForm"
    />
    <UpdateProjectForm
      v-if="isShowedUpdateProjectForm"
      :handleClose="closeUpdateProjectForm"
      :handleUpdateProjects="getProjects"
      :projectData="currentProject"
      :showModal="true"
      @cancel="closeUpdateProjectForm"
    />
    <b-modal id="projects-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
    <b-modal id="delete-confirm-modal" centered ok-only title="Cuidado">
      <!-- eslint-disable-next-line max-len -->
      <p class="my-4">¿Seguro que deseas borrar el proyecto? Recuerda que esto borrar todas sus
        oficinas.</p>
      <template #modal-footer="">
        <b-button
          size="md"
          variant="light"
          @click="closeDeleteModal"
        >
          Cancelar
        </b-button>
        <b-button
          size="md"
          variant="danger"
          @click="deleteProject"
        >
          Borrar
        </b-button>
      </template>
    </b-modal>
  </TableContainer>
</template>

<script>
import { mapState } from 'vuex';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddProjectForm from '@/components/molecules/Project/AddProjectForm.vue';
import UpdateProjectForm from '@/components/molecules/Project/UpdateProjectForm.vue';
import { getRoleString } from '@/helpers/UserHelper';

export default {
  name: 'ProjectsAdminBlock',
  components: {
    TableContainer,
    ActionIconButton,
    AddProjectForm,
    UpdateProjectForm,
  },
  created() {
    this.getProjects();
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'startDateExpected', label: 'Fecha inicio prevista', sortable: true },
        { key: 'endDateExpected', label: 'Fecha fin prevista', sortable: true },
        { key: 'client', label: 'Clientes', sortable: true },
        { key: 'acciones' },
      ],
      items: [],
      isShowedAddProjectForm: false,
      isShowedUpdateProjectForm: false,
      currentProject: {
        uuid: '',
        name: '',
        startDateExpected: '',
        endDateExpected: '',
        clientUuid: null,
        clients: null,
      },
      isLoadingProjects: false,
    };
  },
  methods: {
    getProjects() {
      this.showLoader();

      this.$store.dispatch('getAllProjects')
        .then((res) => {
          this.items = res;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    showAddProyectForm() {
      this.isShowedAddProjectForm = true;
    },
    closeAddProjectForm() {
      this.isShowedAddProjectForm = false;
    },
    showUpdateProjectForm() {
      this.isShowedUpdateProjectForm = true;
    },
    closeUpdateProjectForm() {
      this.isShowedUpdateProjectForm = false;
    },
    showLoader() {
      this.isLoadingProjects = true;
    },
    hideLoader() {
      this.isLoadingProjects = false;
    },
    editProject(objectData) {
      this.setCurrentProject(objectData);

      this.showUpdateProjectForm();
    },
    setCurrentProject(objectData) {
      const clients = objectData.clients.map((client) => (
        {
          text: client.email,
          value: client.uuid,
        }));
      this.currentProject = {
        uuid: objectData.uuid,
        name: objectData.name,
        startDateExpected: objectData.startDateExpected,
        endDateExpected: objectData.endDateExpected,
        clientUuid: objectData.client?.uuid,
        clients,
      };
    },
    deleteProject() {
      this.$bvModal.hide('delete-confirm-modal');

      this.blockScreen();

      const { uuid } = this.currentProject;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteProject', { uuid })
          .then(() => {
            this.unBlockScreen();
            this.getProjects();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('projects-error-modal');
          });
      }
    },
    getRoleText(data) {
      return getRoleString(data);
    },
    closeDeleteModal() {
      this.$bvModal.hide('delete-confirm-modal');
    },
    handleShowDeleteProject(objectData) {
      this.setCurrentProject(objectData);

      this.$bvModal.show('delete-confirm-modal');
    },
    handleShowCalendar(objectData) {
      window.open(`https://calendar.google.com/calendar/embed?src=${objectData.calendarId}`, '_blank');
    },
  },
  computed: {
    ...mapState([
      'activeCompany',
    ]),
    hasProjects() {
      return this.items.length > 0;
    },
    isShowedLoader() {
      return this.isLoadingProjects;
    },
  },
  watch: {
    activeCompany(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getProjects();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/style/responsive.sass"
.client-list
  list-style-type: none
  padding-left: 0
</style>

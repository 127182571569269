import CommentApi from '../../api/CommentApi';

export function addComment({ dispatch }, data) {
  const commentApi = new CommentApi();
  const comment = commentApi.addComment(data);

  return comment
    .then(() => {
      const officeData = {
        uuid: data.officeUuid,
      };
      dispatch('getPhaseByOffice', officeData);
    })
    .catch((error) => Promise.reject(error));
}

export function updateComment({ dispatch }, data) {
  const commentApi = new CommentApi();
  const comment = commentApi.updateComment(data);
  return comment
    .then(() => {
      const officeData = {
        uuid: data.officeUuid,
      };
      dispatch('getPhaseByOffice', officeData);
    })
    .catch((error) => Promise.reject(error));
}

export function deleteComment({ dispatch }, data) {
  const commentApi = new CommentApi();
  const comment = commentApi.deleteComment(data);
  return comment
    .then(() => {
      const officeData = {
        uuid: data.officeUuid,
      };
      dispatch('getPhaseByOffice', officeData);
    })
    .catch((error) => Promise.reject(error));
}

<template>
  <div class="Budget">
    <b-row>
      <b-col class="col-lg-12">
        <div class="Separator__vertical__st"/>
        <div class="phaseHeader">
          <div class="text-left phase-name">
            <p>Presupuesto</p>
          </div>
          <div class="text-right">
            <b-button
              type="button"
              variant="secondary"
              @click.prevent="showCommentForm">
              Añadir comentario
            </b-button>
          </div>
        </div>
        <div class="Separator__vertical__big"/>
        <b-table
          :fields="fields"
          :items="this.getPhaseData"
          fixed
        >
          <template #cell(status)="data">
            <button
              class="simpleButton"
              @click="showUpdateForm"
            >
              <StatusBadget
                :text="data.item.status"
              />
            </button>
          </template>
          <template #cell(performanceDate)="data">
            {{ data.item.performanceDate | formatDate }}
          </template>
          <template #cell(revisedDate)="data">
            {{ data.item.revisedDate | formatDate }}
          </template>
          <template #cell(approvedDate)="data">
            {{ data.item.approvedDate | formatDate }}
          </template>
          <template #cell(comments)="data">
            <b-table
              :fields="fieldsCommentTable"
              :items="data.item.comments">
              <template #cell(insertDate)="data">
                {{ data.item.insertDate | formatDate }}
              </template>
              <template #cell(comment)="data">
                <p class="comment-text">
                  {{ data.item.text }}
                </p>
              </template>
              <template #cell()="data">
                <div
                  v-if="$store.getters.hasAdminPermission"
                  class="actionGroup"
                >
                  <ActionIconButton
                    :handler="editComment"
                    :objectData="data.item"
                    icon="edit.svg"
                    type="edit"
                  />
                  <ActionIconButton
                    :handler="deleteComment"
                    :objectData="data.item"
                    icon="delete.svg"
                    type="delete"/>
                </div>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <UpdateBudgetForm
      v-if="isShowedUpdateForm"
      :budgetData="phaseData"
      :handleClose="closeUpdateForm"
      :officeUuid="officeUuid"
      :showModal="true"
      @cancel="closeUpdateForm"
    />
    <AddCommentForm
      v-if="isShowedCommentForm"
      :handleClose="closeCommentForm"
      :officeUuid="officeUuid"
      :phaseUuid="phaseData.uuid"
      :show-modal="true"
      @cancel="closeCommentForm"/>
    <UpdateCommentForm
      v-if="isShowedUpdateCommentForm"
      :commentData="currentComment"
      :handleClose="closeUpdateCommentForm"
      :officeUuid="officeUuid"
      :phaseUuid="phaseData.uuid"
      :show-modal="true"
      @cancel="closeUpdateCommentForm"/>
  </div>
</template>

<script>
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';
import UpdateBudgetForm from './UpdateBudgetForm.vue';
import AddCommentForm from '@/components/molecules/Comments/AddComment/AddCommentForm.vue';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import UpdateCommentForm from '@/components/molecules/Comments/UpdateComment/UpdateCommentForm.vue';

export default {
  name: 'Budget',
  components: {
    UpdateCommentForm,
    ActionIconButton,
    AddCommentForm,
    StatusBadget,
    UpdateBudgetForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    phaseData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isShowedUpdateForm: false,
      isShowedCommentForm: false,
      isShowedUpdateCommentForm: false,
      currentComment: null,
      fields: [
        { key: 'status', label: 'Estado', class: 'status-header' },
        { key: 'performanceDate', label: 'Fecha Realización', class: 'dates-header' },
        { key: 'revisedDate', label: 'Fecha Revisión', class: 'dates-header' },
        { key: 'approvedDate', label: 'Fecha Aprobación', class: 'dates-header' },
        { key: 'comments', label: 'Comentarios', class: 'comment-header' },
      ],
      fieldsCommentTable: [
        { key: 'insertDate', label: 'Fecha de Comentario', thStyle: { display: 'none' } },
        { key: 'comment', label: 'Comentario', thStyle: { display: 'none' } },
        { key: 'actions', label: 'Actions', thStyle: { display: 'none' } },
      ],
    };
  },
  methods: {
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
    showCommentForm() {
      this.isShowedCommentForm = true;
    },
    closeCommentForm() {
      this.isShowedCommentForm = false;
    },
    editComment(objectData) {
      const data = {
        comment: objectData.text,
        insertDate: objectData.insertDate,
        uuid: objectData.uuid,
      };
      this.currentComment = data;
      this.isShowedUpdateCommentForm = true;
    },
    closeUpdateCommentForm() {
      this.isShowedUpdateCommentForm = false;
    },
    deleteComment(objectData) {
      const data = {
        phaseUuid: this.phaseData.uuid,
        officeUuid: this.officeUuid,
        commentUuid: objectData.uuid,
      };
      this.$store.dispatch('deleteComment', data);
    },
  },
  computed: {
    getPhaseData() {
      if (typeof this.phaseData === 'undefined') {
        return [];
      }

      const phaseDataList = [
        {
          name: this.phaseData.name,
          status: this.phaseData.status,
          performanceDate: this.phaseData.performanceDate,
          revisedDate: this.phaseData.revisedDate,
          approvedDate: this.phaseData.approvedDate,
          insertCommentDate: this.phaseData.insertCommentDate,
          comments: this.phaseData.comments,
        },
      ];

      return phaseDataList;
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep
  .status-header
    width: 140px

  .dates-header
    width: 15%

  .comment-text
    max-width: 6.5em
</style>

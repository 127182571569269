import Project from '../models/Project';
import Company from '../models/Company';
import ProjectReport from '../models/ProjectReport';
import User from '../models/User';

class ProjectFactory {
  constructor() {
    this.projects = [];
    this.project = {};
    this.newCompany = {};
    this.projectReport = {};
  }

  getCompany(company) {
    this.newCompany = new Company({
      uuid: company.uuid,
      name: company.name,
      contactInfo: company.contactInfo,
    });

    return this.newCompany;
  }

  getClient(client) {
    if (!client) {
      return null;
    }

    this.newClient = new User({
      uuid: client.uuid,
      email: client.email,
      role: client.roles[0],
    });

    return this.newClient;
  }

  getClients(clients) {
    if (!clients) {
      return null;
    }
    this.newClients = clients.map((client) => this.getClient(client));

    return this.newClients;
  }

  getAllProject(projectData) {
    this.projects = projectData.map((project) => {
      const company = new Company({
        uuid: project.company.uuid,
        name: project.company.name,
        contactInfo: project.company.contactInfo,
      });

      const newProject = new Project({
        uuid: project.uuid,
        name: project.name,
        company,
        startDateExpected: project.startDateExpected,
        endDateExpected: project.endDateExpected,
        client: this.getClient(project.client),
        clients: this.getClients(project.clients),
        calendarId: project.calendarId,
      });
      return newProject;
    });
    return this.projects;
  }

  getProject(projectData) {
    this.project = new Project({
      uuid: projectData.uuid,
      name: projectData.name,
      company: this.getCompany(projectData.company),
      startDateExpected: projectData.startDateExpected,
      endDateExpected: projectData.endDateExpected,
      countOffices: projectData.count_offices,
      countSuppliers: projectData.count_suppliers,
      client: this.getClient(projectData.client),
      calendarId: projectData.calendarId,
    });

    return this.project;
  }

  getProjectReport(projectReportData) {
    this.projectReport = new ProjectReport({
      amount: projectReportData.amount,
      officeNumber: projectReportData.officeNumber,
      averageAmount: projectReportData.averageAmount,
      activeOfficeNumber: projectReportData.activeOfficeNumber,
    });

    return this.projectReport;
  }
}

export default new ProjectFactory();

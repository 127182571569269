<template>
  <div>
    <b-form-group
      label="Subir logo (8:3, Ej: 400px x 150px)"
    >
      <b-form-file
        v-model="logoImage"
        :state="Boolean(logoImage)"
        placeholder="Selecciona o arrastra una imagen."
        drop-placeholder="Arrastra la imagen aquí..."
      ></b-form-file>
    </b-form-group>
    <!-- <div class="Separator__vertical__st"/>
    <div id="logo-croppie"></div> -->
  </div>
</template>

<script>
import Croppie from 'croppie';
import Logo from '@/assets/logo_bn.png';

export default {
  name: 'LogoUploader',
  props: {
    companyUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logoImage: null,
      croppie: null,
    };
  },
  mounted() {
    // this.setUpCroppie();
  },
  methods: {
    setUpCroppie() {
      // Disabled at the moment.
      const el = document.getElementById('logo-croppie');
      const sizes = el.getBoundingClientRect();
      this.croppie = new Croppie(el, {
        viewport: { width: sizes.width - 20, height: 140 },
        boundary: { width: sizes.width, height: sizes.width / 2 },
        showZoomer: true,
        enableOrientation: false,
      });
      this.croppie.bind({
        url: Logo,
      });
    },
    uploadFile() {
      const formData = new FormData();

      formData.append('file', this.logoImage);

      const requestData = {
        companyUuid: this.companyUuid,
      };

      this.blockScreen();

      this.$store.dispatch('uploadCompanyLogo', { requestData, formData })
        .then(() => {
          this.unBlockScreen();
          this.$emit('handleClose');
        })
        .catch(() => {
          this.unBlockScreen();
          this.$emit('handleClose');
        });
    },
  },
  watch: {
    logoImage() {
      // Disabled at the moment.
      // this.croppie.bind({
      //   url: URL.createObjectURL(this.logoImage),
      // });
    },
  },
};
</script>

import Pagination from '../models/Pagination';
import User from '../models/User';

class UserFactory {
  constructor() {
    this.users = [];
    this.pagination = {};
  }

  getUsersRequest(data) {
    const usersRequest = {
      users: this.getUsers(data?.collection),
      pagination: this.getPagination(data?.pagination),
    };

    return usersRequest;
  }

  getUsersClientRequest(data) {
    const usersRequest = {
      users: this.getUsersClient(data?.collection),
      pagination: this.getPagination(data?.pagination),
    };

    return usersRequest;
  }

  getUsers(userData) {
    this.users = userData.map((user) => {
      const newUser = new User({
        uuid: user.uuid,
        email: user.email,
        role: JSON.parse(user.roles)[0],
        avatarOriginalName: user.avatarOriginalName,
        name: user.name,
        surnames: user.surnames,
        invited: user.invited,
        companyName: user.companyName,
      });

      return newUser;
    });

    return this.users;
  }

  getUsersClient(userData) {
    this.users = userData.map((user) => {
      const newUser = new User({
        uuid: user.uuid,
        email: user.email,
        role: user.roles[0],
        avatarOriginalName: user.avatarOriginalName,
        name: user.name,
        surnames: user.surnames,
        invited: user.invited,
        companyName: user.companyName,
      });

      return newUser;
    });

    return this.users;
  }

  getPagination(paginationData) {
    this.pagination = new Pagination({
      items: paginationData.items,
      totalItems: paginationData.totalItems,
      offset: paginationData.offset,
    });

    return this.pagination;
  }

  getUser(userData) {
    this.user = new User({
      uuid: userData.uuid,
      email: userData.email,
      role: userData.roles[0],
      avatarOriginalName: userData.avatarOriginalName,
      name: userData.name,
      surnames: userData.surnames,
      supplier: userData.supplier,
    });

    return this.user;
  }
}

export default new UserFactory();

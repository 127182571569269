<template>
  <ReportView title="Incidencias por proveedor">
    <IssueSupplierReport/>
  </ReportView>
</template>

<script>
import IssueSupplierReport from '@/components/molecules/Dashboard/IssueSupplierReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'IssueSupplierReportView',
  components: {
    IssueSupplierReport,
    ReportView,
  },
};
</script>

<template>
  <div>
    <router-link
      :to="{ name: url }"
      class="link__standard"
      @click.native="menuSelected(url)"
    >
      <div class="MenuRow">
        <div class="MenuRow__icon">
          <MenuRowIcon
            :icon="icon"
            :isSelected="isCurrentPath"
          />
        </div>
        <div
          v-show="showFullMenuBar"
          class="MenuRow__collapseBlock"
        >
          <div class="MenuRow__label">
          <span :class="
            isCurrentPath ?
            'MenuRow__route--active' :
            'MenuRow__route'"
          >
            {{ label }}
          </span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import MenuRowIcon from '@/components/atoms/MenuRow/MenuRowIcon.vue';

export default {
  name: 'MenuRawSimple',
  components: {
    MenuRowIcon,
  },
  props: {
    icon: String,
    label: String,
    url: String,
    path: String,
  },
  computed: {
    ...mapState([
      'showFullMenuBar',
    ]),
    isCurrentPath() {
      return this.$route.path === this.path;
    },
  },
  methods: {
    ...mapMutations([
      'setMenuSelected',
    ]),
    menuSelected(url) {
      const data = {
        url,
        screenWidth: this.$screen.width,
      };

      this.setMenuSelected(data);
    },
  },
};
</script>

<style lang="sass">
@import './MenuRow.sass'
</style>

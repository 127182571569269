<template>
  <private-template>
    <template v-slot:private-template>
      <div class="Users viewContainer">
        <TitleView title="Usuarios"/>
        <div class="viewContainer__content">
          <UsersTable />
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import UsersTable from '@/components/molecules/Tables/UsersTable/UsersTable.vue';

export default {
  name: 'Users',
  components: {
    PrivateTemplate,
    TitleView,
    UsersTable,
  },
};
</script>

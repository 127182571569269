<template>
  <div>
    <Header :isPrivate="false" />
    <div class="AuthView">
      <ResetPassword />
    </div>
  </div>
</template>

<script>
import Header from '@/components/molecules/Header/Header.vue';
import ResetPassword from '@/components/molecules/ResetPassword/ResetPassword.vue';

export default {
  name: 'ResetPasswordView',
  components: {
    Header,
    ResetPassword,
  },
};
</script>

<style lang="sass" scoped>
  @import '../AuthView/AuthView.sass'
</style>

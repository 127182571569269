<template>
  <div class="Card">
    <b-container fluid
      v-if="isLoading"
      class="d-flex justify-content-center">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </b-container>
    <b-row v-if="!isLoading">
      <b-col class="Card__iconBlock col-2">
        <img
          :src="require('@/assets/cards/' + icon)"
        >
      </b-col>
      <b-col class="col-10">
        <div>
          <slot></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    icon: String,
    isLoading: Boolean,

  },
};
</script>

<style lang="sass">
@import './Card.sass'
</style>

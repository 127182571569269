<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="updateIssue"
      @cancel="cancelAction"
    >
      <FormIssueContent
        @handleIssueData="setIssueData"
        :issueData="this.issueData"
        :issueDataValidation="this.issueDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import FormIssueContent from './FormIssueContent.vue';

export default {
  name: 'UpdateIssueForm',
  components: {
    Modal,
    FormCard,
    FormIssueContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    officeUuid: {
      type: String,
      default: '',
    },
    issueData: {
      uuid: '',
      openingDate: null,
      resolutionDate: null,
      reportedBy: null,
      reasonUuid: null,
      status: null,
      observations: null,
      name: null,
    },
  },
  data: () => ({
    title: 'Actualizar Incidencia',
    issueDataValidation: {
      openingDate: null,
      resolutionDate: null,
      reportedBy: null,
      reasonUuid: null,
      status: null,
      observations: null,
      name: null,
    },
  }),
  methods: {
    setIssueData(issueData) {
      this.issueData = issueData;
    },
    updateIssue() {
      if (this.isValidData()) {
        this.blockScreen();

        const requestData = {
          uuid: this.issueData.uuid,
          issueData: this.issueData,
        };

        requestData.issueData.officeUuid = this.officeUuid;
        delete requestData.issueData.uuid;

        this.$store.dispatch('updateIssue', requestData)
          .then(() => {
            this.unBlockScreen();
            this.$emit('handleUpdateIssue');
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.issueDataValidation = {
          openingDate: getValidationState(this.issueData?.openingDate),
          reportedBy: getValidationState(this.issueData?.reportedBy),
          reasonUuid: getValidationState(this.issueData?.reasonUuid),
          status: getValidationState(this.issueData?.status),
          observations: getValidationState(this.issueData?.observations),
        };
      }
    },
    isValidData() {
      return hasData(this.issueData?.openingDate)
        && hasData(this.issueData?.reportedBy)
        && hasData(this.issueData?.reasonUuid)
        && hasData(this.issueData?.status)
        && hasData(this.issueData?.observations);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

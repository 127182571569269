<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="updateUser"
      @cancel="cancelAction"
    >
      <FormUserContent
        @handleUserData="setUserData"
        :userData="this.userData"
        cols="2"
        :userDataValidation="this.userDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormUserContent from '@/components/molecules/Users/FormUserContent.vue';
import {
  hasData, isValidEmail, getValidationState, getEmailValidationState,
} from '@/helpers/ValidationHelper';

export default {
  name: 'FormUpdateUser',
  components: {
    Modal,
    FormCard,
    FormUserContent,
  },
  props: {
    showModal: Boolean,
    userData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateUsers: {},
  },
  data: () => ({
    title: 'Actualizar usuario',
    userDataValidation: {
      email: null,
      role: null,
      name: null,
      surnames: null,
    },
  }),
  methods: {
    setUserData(userData) {
      this.userData = userData;
    },
    updateUser() {
      if (this.isValidData()) {
        const userdata = {
          email: this.userData.email,
          role: this.userData.role,
          name: this.userData.name,
          surnames: this.userData.surnames,
        };

        this.blockScreen();

        this.$store.dispatch('updateUser', {
          uuid: this.userData.uuid,
          userData: userdata,
        })
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateUsers();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.userDataValidation = {
          email: getEmailValidationState(this.userData?.email),
          role: getValidationState(this.userData?.role),
          name: getValidationState(this.userData?.name),
          surnames: getValidationState(this.userData?.surnames),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return isValidEmail(this.userData?.email)
        && hasData(this.userData?.role)
        && hasData(this.userData?.name)
        && hasData(this.userData?.surnames);
    },
  },
};
</script>

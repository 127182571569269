import ForecastFactory from '../domain/services/ForecastFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const FORECASTS = '/forecasts';

class forecastAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllForecasts(data) {
    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = data.projectId ? `&projectId=${data.projectId}` : '';
    const supplierId = data.supplierId ? `&supplierId=${data.supplierId}` : '';

    return this.client
      .get(`${FORECASTS}${companyId}${projectId}${supplierId}`)
      .then((res) => ForecastFactory.getForecastsRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  addForecast(data) {
    return this.client
      .post(FORECASTS, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateForecast(data) {
    const url = `${FORECASTS}/${data.uuid}`;

    return this.client
      .put(url, data.forecastData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteForecast(uuid) {
    const url = `${FORECASTS}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getForecastStatusReport(data) {
    const url = `${FORECASTS}/report/status`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';
    const from = `${companyId}${projectId}` === '' ? `?from=${data.from}` : `&from=${data.from}`;
    const to = `&to=${data.to}`;

    const urlWithParams = `${url}${companyId}${projectId}${from}${to}`;

    return this.client
      .get(urlWithParams)
      .then((res) => ForecastFactory.getForecastStatusReports(res.data?.data.forecastStatusStats))
      .catch((error) => Promise.reject(error));
  }
}

export default forecastAPI;

import Forecast from '../models/Forecast';
import { getPhaseLabel } from '../../helpers/PhaseHelper';
import ForecastStatusReport from '../models/ForecastStatusReport';

class ForecastFactory {
  constructor() {
    this.forecasts = [];
  }

  getForecastsRequest(data) {
    const forecastsRequest = {
      forecasts: this.getForecasts(data?.collection),
    };

    return forecastsRequest;
  }

  getForecasts(forecastData) {
    this.forecasts = forecastData.map((forecast) => {
      const newForecast = new Forecast({
        id: forecast.uuid,
        title: `${getPhaseLabel(forecast.phase)} - ${forecast.lot}`,
        startDate: forecast.startDate,
        endDate: forecast.endDate,
        phase: forecast.phase,
        lot: forecast.lot,
        color: forecast.color,
        style: `background-color: ${forecast.color};`,
      });

      return newForecast;
    });

    return this.forecasts;
  }

  getForecastStatusReport(forecastStatusReportData) {
    const { previewedOfficesCount } = forecastStatusReportData;
    const { receivedPhaseInRangeCount } = forecastStatusReportData;
    this.forecastStatusReport = new ForecastStatusReport({
      phase: forecastStatusReportData.phase,
      previewedOfficesCount,
      receivedPhaseInRangeCount,
      percentCompletion: `${((parseFloat(receivedPhaseInRangeCount) / parseFloat(previewedOfficesCount)) * 100).toFixed(2)}%`,
    });

    return this.forecastStatusReport;
  }

  getForecastStatusReports(forecastStatusReportData) {
    this.forecastStatusReports = forecastStatusReportData.map(
      (forecastStatusData) => this.getForecastStatusReport(forecastStatusData),
    );

    return this.forecastStatusReports;
  }
}

export default new ForecastFactory();

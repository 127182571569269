<template>
  <Modal v-show="showModal">
    <FormCard
        title="Cambiar Comentario"
        @accept="updateComment"
        @cancel="cancelAction"
    >
      <FormCommentContent
          :commentData="this.commentData"
          :commentDataValidation="this.commentDataValidation"
          @handleCommentData="setCommentData"
      />
    </FormCard>
  </Modal>
</template>
<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormCommentContent from '@/components/molecules/Comments/AddComment/FormCommentContent.vue';
import { hasData } from '@/helpers/ValidationHelper';

export default {
  name: 'UpdateCommentForm',
  components: { FormCommentContent, FormCard, Modal },
  props: {
    showModal: Boolean,
    commentData: Object,
    officeUuid: String,
    phaseUuid: String,
    handleClose: {},
  },
  methods: {
    setCommentData(commentData) {
      this.commentData = commentData;
    },
    cancelAction() {
      this.$emit('cancel');
    },
    isValidData() {
      return hasData(this.commentData?.comment);
    },
    updateComment() {
      const data = {
        phaseUuid: this.phaseUuid,
        officeUuid: this.officeUuid,
        commentUuid: this.commentData.uuid,
        text: this.commentData.comment,
      };
      this.$store.dispatch('updateComment', data)
        .then(() => {
          this.handleClose();
        });
    },
  },
  data() {
    return {
      commentDataValidation: {
        comment: null,
      },
      newCommentData: {
        comment: null,
      },
    };
  },
};
</script>
<style lang="sass" scoped>

</style>

<template>
  <div id="app">
    <router-view />
    <FullLoader
      :showLoader="$store.state.isShowedLoadderScreen"
    />
    <ErrorModal
      :showModal="$store.getters.hasErrors"
    />
  </div>
</template>

<script>
import FullLoader from '@/components/molecules/Loader/FullLoader/FullLoader.vue';
import ErrorModal from '@/components/molecules/Errors/ErrorModal.vue';

export default {
  name: 'Home',
  components: {
    FullLoader,
    ErrorModal,
  },
};
</script>

<style lang="sass">
  @import './style/App.sass'
  @import './style/text.sass'
  @import '@/style/utilities.sass'
</style>

<template>
  <inline-svg
    :src="require('@/assets/' + icon)"
    :class="
      isSelected ?
      'MenuRow__icon--active' :
      'MenuRow__icon--unactive'"
  />
</template>

<script>
export default {
  name: 'MenuRowIcon',
  props: {
    icon: String,
    isSelected: Boolean,
  },
};
</script>

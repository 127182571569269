<template>
  <form>
    <SimpleInput
      inputLabel="Nombre"
      inputId="userDataFormName"
      inputType="text"
      inputPlaceholder="Nombre"
      :inputValue="this.userData.name"
      @handlerText="getName"
      :isValid="this.userDataValidation.name"
    ></SimpleInput>
    <SimpleInput
      inputLabel="Apellidos"
      inputId="userDataFormSurnames"
      inputType="text"
      inputPlaceholder="apellidos"
      :inputValue="this.userData.surnames"
      @handlerText="getSurnames"
      :isValid="this.userDataValidation.surnames"
    ></SimpleInput>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';

export default {
  name: 'FormUserDataContent',
  components: { SimpleInput },
  props: {
    handleUserData: {},
    userData: Object,
    userDataValidation: Object,
  },
  methods: {
    getName(data) {
      this.userData.name = data;
      this.$emit('handleUserData', this.userData);
    },
    getSurnames(data) {
      this.userData.surnames = data;
      this.$emit('handleUserData', this.userData);
    },
  },
};
</script>

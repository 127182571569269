var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('private-template',{scopedSlots:_vm._u([{key:"private-template",fn:function(){return [_c('div',{staticClass:"viewContainer"},[_c('TitleView',{attrs:{"title":"Importador Oficinas"}}),_c('div',{staticClass:"viewContainer__content"},[_c('div',{staticClass:"container__simple Templates"},[_c('b-form-group',{attrs:{"label":"Tipo de plantilla"}},[_c('b-form-checkbox',{attrs:{"name":"templatesType","switch":"","size":"lg"},model:{value:(_vm.excel),callback:function ($$v) {_vm.excel=$$v},expression:"excel"}},[_vm._v(" "+_vm._s(_vm.excel ? 'Excel' : 'CSV')+" ")])],1),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/offices/ImportOfficeValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla Oficinas ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/phases/measurement/ImportMeasurementPhaseTestValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla fase Medición ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/phases/budget/ImportBudgetPhasesValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla fase Presupuesto ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/phases/pic/ImportPICPhaseTestValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla fase PIC ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/phases/certification/ImportCertificationPhasesValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla fase Certificación ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/phases/installation/ImportInstallationPhaseTestValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla fase Instalación ")])],1),_c('div',{staticClass:"Separator__vertical__st"}),_c('div',[_c('b-link',{attrs:{"href":_vm.getUrl(
                "csv/forecasts/ImportForecastsValid", _vm.excel
                )}},[_vm._v(" Descargar plantilla Eventos ")])],1),_c('div',{staticClass:"Separator__vertical__st"})],1)])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import Auth from '../models/Auth';

class AuthFactory {
  constructor() {
    this.auth = {};
  }

  getAuth(authData) {
    this.auth = {
      token: authData.token,
      refreshToken: authData.refresh_token,
    };

    return new Auth(this.auth);
  }
}

export default new AuthFactory();

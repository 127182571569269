<template>
  <ReportView title="Incidencias por tipología">
    <IssueReasonReport/>
  </ReportView>
</template>

<script>
import IssueReasonReport from '@/components/molecules/Dashboard/IssueReasonReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'IssueReasonReportView',
  components: {
    IssueReasonReport,
    ReportView,
  },
};
</script>

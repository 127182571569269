export default class Event {
  constructor(eventData) {
    this.id = eventData.id;
    this.projectId = eventData.projectId;
    this.name = eventData.name;
    this.consumed = eventData.consumed;
    this.body = eventData.body;
    this.officeCode = eventData.officeCode;
    this.occurredOn = eventData.occurredOn;
    this.phaseName = eventData.phaseName;
  }

  get getId() {
    return this.id;
  }

  get getProjectId() {
    return this.projectId;
  }

  get getName() {
    return this.name;
  }

  get getConsumed() {
    return this.consumed;
  }

  get getBody() {
    return this.body;
  }

  get getOfficeCode() {
    return this.officeCode;
  }
}

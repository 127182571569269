import StorageManager from '../helpers/StorageManager';

export const getDefaultState = () => ({
  showFullMenuBar: true,
  menuSelected: 'Dashboard',
  auth: {
    token: null,
    refreshToken: null,
  },
  user: {
    role: '',
    uuid: '',
    avatarOriginalName: null,
    email: null,
    name: null,
    surnames: null,
    avatar: null,
    supplier: '',
  },
  isShowedLoadderScreen: false,
  activeCompany: null,
  activeProject: null,
  activeProjectName: null,
  companies: [],
  projects: [],
  offices: [],
  office: {},
  suppliers: [],
  issues: [],
  isShowedNavMenu: false,
  issueReasons: [],
  errors: [],
  showErrors: false,
  clients: [],
  alerts: [],
});

export default {
  showFullMenuBar: true,
  menuSelected: 'Dashboard',
  auth: {
    token: StorageManager.getToken() || null,
    refreshToken: StorageManager.getRefreshToken() || null,
  },
  user: {
    role: '',
    uuid: '',
    avatarOriginalName: null,
    email: null,
    name: null,
    surnames: null,
    supplier: '',
  },
  isShowedLoadderScreen: false,
  activeCompany: StorageManager.getCompany() || null,
  activeProject: StorageManager.getProject() || null,
  activeProjectName: StorageManager.getProjectName() || null,
  companies: [],
  projects: [],
  offices: [],
  office: {},
  suppliers: [],
  issues: [],
  isShowedNavMenu: false,
  issueReasons: [],
  errors: [],
  showErrors: false,
  clients: [],
  officeTypes: StorageManager.getOfficeTypes() || [],
  activeOfficeType: null,
  alerts: [],
};

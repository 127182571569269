import EventsApi from '@/api/EventsApi';

export function getEvents({ commit }, data) {
  const eventApi = new EventsApi();
  const events = eventApi.getEvents(data);
  return events
    .then((res) => commit('setAlerts', res))
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateConsumedEvents({ commit }, data) {
  const eventsApi = new EventsApi();
  const events = eventsApi.updateConsumeEvents(data);
  return events.then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

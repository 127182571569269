<template>
  <TableSimpleBlock
    title="Resumen instalaciones por proveedor"
    :items="officeInstallationReportData"
    :fields="fields"
    :isLoading="isOfficeInstallationReportLoading"
  />
</template>

<script>
import { mapState } from 'vuex';
import TableSimpleBlock from '@/components/molecules/Tables/TableSimpleBlock/TableSimpleBlock.vue';

export default {
  name: 'OfficeInstallationReport',
  components: {
    TableSimpleBlock,
  },
  data() {
    return {
      officeInstallationReportData: [],
      isOfficeInstallationReportLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'activeOfficesCount', label: 'Oficinas activas', sortable: true },
        { key: 'finishedInstallationCount', label: 'Instalaciones finalizadas', sortable: true },
        { key: 'inProgressInstallationCount', label: 'Instalaciones en progreso', sortable: true },
        { key: 'pendingInstallationCount', label: 'Instalaciones pendientes', sortable: true },
        { key: 'issueCount', label: 'Incidencias', sortable: true },
        { key: 'stoppedCount', label: 'Paradas', sortable: true },
      ],
    };
  },
  created() {
    this.getOfficeInstallationReport();
  },
  methods: {
    getOfficeInstallationReport() {
      this.setOfficeInstallationReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getOfficeInstallationReport', data)
        .then((res) => {
          this.officeInstallationReportData = res;
          this.setOfficeInstallationReportNotLoading();
        })
        .catch(() => {
          this.setOfficeInstallationReportNotLoading();
        });
    },
    setOfficeInstallationReportLoading() {
      this.isOfficeInstallationReportLoading = true;
    },
    setOfficeInstallationReportNotLoading() {
      this.isOfficeInstallationReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getOfficeInstallationReport();
      }
    },
  },
};
</script>

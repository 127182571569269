<!-- eslint-disable max-len -->
<template>
  <Card
    :isLoading="isSupplierReportLoading"
    icon="Proveedores.svg"
  >
    <SimpleCardContent
      title="Total proveedores"
      :titleData="supplierReportData.suppliers"
    >
      <div class="SupplierReport__list">
        <div
          v-for="assignation in supplierReportData.assignations"
          :key="assignation.uuid"
        >
          <div>
            <p>{{ assignation.name }}</p>
            <p>{{ assignation.totalOffices }}</p>
          </div>
        </div>
      </div>
    </SimpleCardContent>
  </Card>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/atoms/Cards/Card.vue';
import SimpleCardContent from '@/components/atoms/Cards/SimpleCardContent/SimpleCardContent.vue';

export default {
  name: 'SupplierReport',
  components: {
    Card,
    SimpleCardContent,
  },
  data() {
    return {
      supplierReportData: {
        supplier: '',
        assignations: [],
      },
      isSupplierReportLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'totalOffices', label: 'Total oficinas', sortable: true },
      ],
    };
  },
  created() {
    this.getSupplierReport();
  },
  methods: {
    getSupplierReport() {
      this.setSupplierReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getSupplierReport', data)
        .then((res) => {
          this.supplierReportData = res;
          this.setSupplierReportNotLoading();
        })
        .catch(() => {
          this.setSupplierReportNotLoading();
        });
    },
    setSupplierReportLoading() {
      this.isSupplierReportLoading = true;
    },
    setSupplierReportNotLoading() {
      this.isSupplierReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSupplierReport();
      }
    },
  },
};
</script>

<style lang="sass">
  @import './SupplierReport.sass'
</style>

<template>
  <div class="d-flex flex-lg-row-reverse">
    <div class="Separator__vertical__st" />
    <b-button
      variant="secondary"
      type="button"
      @click.prevent="downloadFile">
      Descargar todo
    </b-button>
    <div class="Separator__vertical__st" />
  </div>

</template>

<script>
import exportFile from '@/helpers/FileHelper';

export default {
  name: 'DownloadAllDocumentsButton',
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
  },
  methods: {
    downloadFile() {
      this.blockScreen();
      const requestData = {
        officeId: this.officeUuid,
      };
      this.$store.dispatch('downloadOfficeDocuments', requestData)
        .then((res) => {
          exportFile(`Documentos oficina - ${this.officeUuid} - ${this.$store.getters.getActiveProjectName}.zip`, res.data);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
  },
};
</script>

<style scoped>

</style>

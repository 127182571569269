<template>
  <TableContainer title="Usuarios">
    <div class="TableContainer__header">
      <b-button
        variant="primary"
        @click.prevent="showAddUserForm"
      >
        Añadir usuario
      </b-button>
    </div>
    <div class="table__container">
      <div class="UsersTable">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          striped
          hover
          bordered
        >
          <template #cell(role)="data">
            {{ getRoleText(data.item.role) }}
          </template>
          <template #cell(invited)="data">
            <b-badge :variant="data.item.invited === true ? 'success' : 'secondary'">
              {{ data.item.invited === '1' ? 'Enviada' : 'No enviada' }}
            </b-badge>
          </template>
          <template #cell(acciones)="data">
            <div class="actionGroup">
              <ActionIconButton
                icon="edit.svg"
                type="edit"
                :handler="editUser"
                :objectData="data.item"
              />
              <ActionIconButton
                v-if="!isSuperAdmin(data)"
                icon="delete.svg"
                type="delete"
                :handler="deleteUser"
                :objectData="data.item"
              />
              <ActionIconButton
                icon="email.svg"
                type="action2"
                :handler="invite"
                :objectData="data.item"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          align="end"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @input="getNewUsers()"
        ></b-pagination>
      </div>
    </div>
    <FormNewUser
      v-if="isShowedAddUserForm"
      :showModal="true"
      @cancel="closeAddUserForm"
      :handleClose="closeAddUserForm"
      :handleUpdateUsers="getUsers"
    />
    <FormUpdateUser
      v-if="isShowedUpdateUserForm"
      :showModal="true"
      @cancel="closeUpdateUserForm"
      :userData="currentUser"
      :handleClose="closeUpdateUserForm"
      :handleUpdateUsers="getUsers"
    />
    <b-modal id="users-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </TableContainer>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import FormNewUser from '@/components/molecules/Users/FormNewUser.vue';
import FormUpdateUser from '@/components/molecules/Users/FormUpdateUser.vue';
import { getRoleString, ROLE } from '@/helpers/UserHelper';

export default {
  name: 'UsersTable',
  components: {
    TableContainer,
    ActionIconButton,
    FormNewUser,
    FormUpdateUser,
  },
  created() {
    this.getUsers();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      fields: [
        { key: 'email', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'surnames', label: 'Apellidos', sortable: true },
        { key: 'role', label: 'Rol', sortable: true },
        { key: 'invited', label: 'Invitación', sortable: true },
        { key: 'acciones' },
      ],
      items: [],
      isShowedAddUserForm: false,
      isShowedUpdateUserForm: false,
      currentUser: {
        uuid: '',
        email: '',
        role: '',
        name: '',
        surnames: '',
      },
    };
  },
  computed: {
    getCurrentPage() {
      return this.currentPage;
    },
    getItemsPerPage() {
      return this.perPage;
    },
  },
  methods: {
    getUsers() {
      this.blockScreen();
      const currentPage = this.getCurrentPage;
      const itemsPerPage = this.getItemsPerPage;
      this.$store.dispatch('getUsers', { currentPage, itemsPerPage })
        .then((res) => {
          this.rows = res.pagination.totalItems;
          this.items = res.users;
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    getNewUsers() {
      this.getUsers();
    },
    showAddUserForm() {
      this.isShowedAddUserForm = true;
    },
    closeAddUserForm() {
      this.isShowedAddUserForm = false;
    },
    showUpdateUserForm() {
      this.isShowedUpdateUserForm = true;
    },
    closeUpdateUserForm() {
      this.isShowedUpdateUserForm = false;
    },
    editUser(objectData) {
      this.currentUser = {
        uuid: objectData.uuid,
        email: objectData.email,
        role: objectData.role,
        name: objectData.name,
        surnames: objectData.surnames,
        companyName: objectData.companyName,
      };

      this.showUpdateUserForm();
    },
    deleteUser(objectData) {
      this.blockScreen();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteUser', { uuid })
          .then(() => {
            this.unBlockScreen();
            this.getUsers();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('users-error-modal');
          });
      }
    },
    invite(objectData) {
      this.blockScreen();

      const userData = {
        uuid: objectData.uuid,
        email: objectData.email,
      };

      if (objectData.email !== 'undefined') {
        this.$store.dispatch('inviteUser', userData)
          .then(() => {
            this.unBlockScreen();
            this.getUsers();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    getRoleText(data) {
      return getRoleString(data);
    },
    isSuperAdmin(data) {
      return data.item.role === ROLE.SUPER_ADMIN;
    },
  },
};
</script>

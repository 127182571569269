export default class Office {
  constructor(officeData) {
    this.uuid = officeData.uuid;
    this.active = officeData.active;
    this.code = officeData.code;
    this.code2 = officeData.code2;
    this.type = officeData.type;
    this.lot = officeData.lot;
    this.dtTerritorial = officeData.dtTerritorial;
    this.address = officeData.address;
    this.city = officeData.city;
    this.province = officeData.province;
    this.autonomousCommunity = officeData.autonomousCommunity;
    this.postalCode = officeData.postalCode;
    this.name = officeData.name;
    this.posterType = officeData.posterType;
    this.posterSize = officeData.posterSize;
    this.bannerNumber = officeData.bannerNumber;
    this.amount = officeData.amount;
    this.amountWithVAT = officeData.amountWithVAT;
    this.measurementPhase = officeData.measurementPhase;
    this.picPhase = officeData.picPhase;
    this.budgetPhase = officeData.budgetPhase;
    this.installationPhase = officeData.installationPhase;
    this.certificationPhase = officeData.certificationPhase;
    this.invoiced = officeData.invoiced;
    this.supplier = officeData.supplier;
    this.project = officeData.project;
  }

  get getUuid() {
    return this.uuid;
  }

  get getActive() {
    return this.active;
  }

  get getCode() {
    return this.code;
  }

  get getCode2() {
    return this.code2;
  }

  get getType() {
    return this.type;
  }

  get getLot() {
    return this.lot;
  }

  get getDtTerritorial() {
    return this.dtTerritorial;
  }

  get getAddress() {
    return this.address;
  }

  get getCity() {
    return this.city;
  }

  get getProvince() {
    return this.province;
  }

  get getAutonomousCommunity() {
    return this.autonomousCommunity;
  }

  get getPostalCode() {
    return this.postalCode;
  }

  get getName() {
    return this.name;
  }

  get getPosterType() {
    return this.posterType;
  }

  get getPosterSize() {
    return this.posterSize;
  }

  get getBannerNumber() {
    return this.bannerNumber;
  }

  get getAmount() {
    return this.amount;
  }

  get getAmountWithVAT() {
    return this.amountWithVAT;
  }

  get getMeasurementPhase() {
    return this.measurementPhase;
  }

  get getPicPhase() {
    return this.picPhase;
  }

  get getBudgetPhase() {
    return this.budgetPhase;
  }

  get getInstallationPhase() {
    return this.installationPhase;
  }

  get getCertificationPhase() {
    return this.certificationPhase;
  }

  get getInvoiced() {
    return this.invoiced;
  }

  get getSupplier() {
    return this.supplier;
  }

  get getProject() {
    return this.project;
  }

  getPhases() {
    const phases = [];
    if (this.measurementPhase !== null) {
      phases.push(this.measurementPhase);
    }

    if (this.budgetPhase !== null) {
      phases.push(this.budgetPhase);
    }

    if (this.picPhase !== null) {
      phases.push(this.picPhase);
    }

    if (this.installationPhase !== null) {
      phases.push(this.installationPhase);
    }

    if (this.certificationPhase !== null) {
      phases.push(this.certificationPhase);
    }

    return phases;
  }

  getAddressSrc() {
    const address = `${this.address || ''}, ${this.city || ''}, ${this.postalCode || ''}, ${this.province || ''}`;

    const encodedAddress = encodeURI(address);

    const src = `https://www.google.com/maps?q=${encodedAddress}&output=embed`;

    return src;
  }

  getFormattedAmount() {
    return this.amount.toString().replace('.', ',');
  }
}

<template>
  <TableSimpleBlock
    title="Incidencias según tipología"
    :items="issueReasonReportData"
    :fields="fields"
    :isLoading="isIssueReasonReportLoading"
  />
</template>

<script>
import { mapState } from 'vuex';
import TableSimpleBlock from '@/components/molecules/Tables/TableSimpleBlock/TableSimpleBlock.vue';

export default {
  name: 'IssueReasonReport',
  components: {
    TableSimpleBlock,
  },
  data() {
    return {
      issueReasonReportData: [],
      isIssueReasonReportLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'countersByTypology.banderolas', label: 'Banderolas', sortable: true },
        { key: 'countersByTypology.mostradorDeCaja', label: 'Mostrador de caja', sortable: true },
        { key: 'countersByTypology.frontCaja', label: 'Front-caja', sortable: true },
        { key: 'countersByTypology.elementosInteriores', label: 'Elementos interiores', sortable: true },
        { key: 'countersByTypology.elementosExteriores', label: 'Elementos Exteriores', sortable: true },
        { key: 'countersByTypology.rotulosCorporeos', label: 'Rótulos corpóreos', sortable: true },
        { key: 'countersByTypology.rotulosDeLona', label: 'Rótulos de lona', sortable: true },
        { key: 'countersByTypology.cajeros', label: 'Cajeros', sortable: true },
        { key: 'countersByTypology.otros', label: 'Otros', sortable: true },
      ],
    };
  },
  created() {
    this.getIssueReasonReport();
  },
  methods: {
    getIssueReasonReport() {
      this.setIssueReasonReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getIssueReasonReport', data)
        .then((res) => {
          this.issueReasonReportData = res;
          this.setIssueReasonReportNotLoading();
        })
        .catch(() => {
          this.setIssueReasonReportNotLoading();
        });
    },
    setIssueReasonReportLoading() {
      this.isIssueReasonReportLoading = true;
    },
    setIssueReasonReportNotLoading() {
      this.isIssueReasonReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getIssueReasonReport();
      }
    },
  },
};
</script>

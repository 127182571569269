export default class User {
  constructor(userData) {
    this.uuid = userData.uuid;
    this.email = userData.email;
    this.role = userData.role;
    this.avatarOriginalName = userData.avatarOriginalName;
    this.name = userData.name;
    this.surnames = userData.surnames;
    this.invited = userData.invited;
    this.supplier = userData.supplier;
    this.companyName = userData.companyName;
  }

  get getUuid() {
    return this.uuid;
  }

  get getEmail() {
    return this.email;
  }

  get getRole() {
    return this.role;
  }

  get getAvatarOriginalName() {
    return this.avatarOriginalName;
  }

  get getName() {
    return this.name;
  }

  get getSurnames() {
    return this.surnames;
  }

  get getInvited() {
    return this.invited;
  }

  get getSupplier() {
    return this.supplier;
  }

  get getCompanyName() {
    return this.companyName;
  }
}

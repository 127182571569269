<template>
  <div
    v-if="this.issueList"
    class="container__simple"
  >
    <b-row>
      <b-col>
        <div class="text-right">
          <b-button
            variant="primary"
            type="button"
            v-b-modal.exportation-format-modal
          >
            Exportar incidencias
          </b-button>
          <b-modal id="exportation-format-modal" centered ok-only
            title="Elige el formato de exportación"
            @ok="exportIssues"
            @close="() => { this.exportationFormat = 'excel'; }"
          >
            <b-form-group>
              <b-form-radio v-model="exportationFormat" name="excel-radio" value="excel">
                Excel
              </b-form-radio>
              <b-form-radio v-model="exportationFormat" name="csv-radio" value="csv">
                CSV
              </b-form-radio>
            </b-form-group>
          </b-modal>
        </div>
        <div class="Separator__vertical__st" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          class="table__container__paginated"
          v-if="this.$store.getters.hasIssues"
        >
          <b-table
            :items="$store.getters.getIssues"
            :fields="fields"
            striped
            hover
            bordered
          >
            <template #cell(openingDate)="data">
              {{ data.item.openingDate | formatDate }}
            </template>
            <template #cell(resolutionDate)="data">
              {{ data.item.resolutionDate | formatDate }}
            </template>
            <template #cell(reportedBy)="data">
              {{ data.item.reportedBy === 'client' ? 'Cliente' : 'Proveedor' }}
            </template>
            <template #cell(reason)="data">
              {{ data.item.reason.name || '' }}
            </template>
            <template #cell(status)="data">
              {{ getStatus(data.item.status) }}
            </template>
            <template #cell(owner)="data">
              {{ data.item.owner ? data.item.owner.email : '' }}
            </template>
            <template #cell(acciones)="data">
              <div class="actionGroup">
                <ActionIconButton
                  v-if="canManage(data.item)"
                  icon="edit.svg"
                  type="edit"
                  :handler="editIssue"
                  :objectData="data.item"
                />
                <ActionIconButton
                  v-if="$store.getters.hasAdminPermission"
                  icon="delete.svg"
                  type="delete"
                  :handler="deleteIssue"
                  :objectData="data.item"
                />
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          align="end"
          v-model="currentPage"
          :total-rows="this.issueList.pagination.totalItems"
          :per-page="perPage"
          @input="handleUpdateIssues()"
        />
      </b-col>
    </b-row>
    <UpdateIssueForm
      v-if="isShowedUpdateForm"
      :showModal="true"
      @handleUpdateIssue="handleUpdateIssues"
      @cancel="closeUpdateForm"
      :issueData="currentIssue"
      :handleClose="closeUpdateForm"
      :officeUuid="currentIssue.officeUuid"
    />
  </div>
</template>

<script>
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import { getStatusLabel } from '@/helpers/status/IssueStatusHelper';
import exportFile from '@/helpers/FileHelper';
import UpdateIssueForm from './UpdateIssueForm.vue';

export default {
  name: 'IssuesListView',
  components: {
    ActionIconButton,
    UpdateIssueForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    requestData: Object,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: 'openingDate', label: 'Fecha Apertura', sortable: true },
        { key: 'resolutionDate', label: 'Fecha Resolución', sortable: true },
        { key: 'office.code', label: 'Código Oficina', sortable: true },
        { key: 'office.supplier.name', label: 'Proveedor', sortable: true },
        { key: 'office.province', label: 'Provincia', sortable: true },
        { key: 'reportedBy', label: 'Reportado', sortable: true },
        { key: 'reason', label: 'Motivo', sortable: true },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'observations', label: 'Observaciones', sortable: true },
        { key: 'owner', label: 'Propietario', sortable: true },
        { key: 'acciones' },
      ],
      isShowedUpdateForm: false,
      currentIssue: {
        uuid: '',
        officeUuid: '',
        openingDate: '',
        resolutionDate: '',
        reportedBy: '',
        reasonUuid: '',
        status: '',
        observations: '',
      },
      exportationFormat: 'excel',
    };
  },
  methods: {
    exportIssues() {
      const data = { ...this.requestData, format: this.exportationFormat };
      this.$store.dispatch('exportAllIssues', data)
        .then((res) => {
          exportFile(
            `incidencias.${this.exportationFormat === 'excel' ? 'xlsx' : 'csv'}`,
            res.data,
          );
        });
    },
    editIssue(objectData) {
      this.currentIssue = {
        uuid: objectData.uuid,
        officeUuid: objectData.office.uuid,
        openingDate: objectData.openingDate,
        resolutionDate: objectData.resolutionDate,
        reportedBy: objectData.reportedBy,
        reasonUuid: objectData.reason.uuid,
        status: objectData.status,
        observations: objectData.observations,
      };

      this.showUpdateForm();
    },
    deleteIssue(objectData) {
      this.blockScreen();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteIssue', { uuid })
          .then(() => {
            this.$emit('handleUpdateIssues');
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    handleUpdateIssues() {
      this.$emit('handleUpdateIssues', this.currentPage);
    },
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
    isOwner(data) {
      return data?.owner?.uuid === this.$store.getters.getUser.uuid;
    },
    canManage(data) {
      return this.$store.getters.hasAdminPermission || this.isOwner(data);
    },
    getStatus(status) {
      return getStatusLabel(status);
    },
  },
  computed: {
    hasIssues() {
      return this.$store.getters.hasIssues;
    },
    issueList() {
      return this.$store.state.issues;
    },
    getCurrentPage() {
      return this.currentPage;
    },
    getItemsPerPage() {
      return this.perPage;
    },
  },
};
</script>

<template>
  <Modal v-show="showModal">
    <FormCard
      title="Elegir incidencia"
      @accept="uploadFile"
      @cancel="closeUploadForm">
      <SelectorForm
        selectLabel="Incidencia"
        :selectFormOptions="options"
        @handlerSelect="setSelected"
        class="documentSelector"/>
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';

export default {
  name: 'UploadIssueDocumentForm',
  components: { SelectorForm, FormCard, Modal },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    officeUuid: {
      type: String,
      default: '',
    },
    file: null,
    options: null,
  },
  data() {
    return {
      selectedIssuedId: null,
    };
  },
  methods: {
    showUploadForm() {
      this.isShowedUploadForm = true;
    },
    closeUploadForm() {
      this.$emit('cancel');
    },
    setSelected(option) {
      this.selectedIssuedId = option;
    },
    uploadFile() {
      this.blockScreen();

      const formData = new FormData();

      this.$props.file.map((file) => {
        formData.append('file[]', file, file.name);
        return file;
      });
      const requestData = {
        officeUuid: this.officeUuid,
        issueId: this.selectedIssuedId,
      };
      this.$store
        .dispatch('uploadIssueDocument', { requestData, formData })
        .then(() => {
          this.file = null;
          this.$emit('manageDocumentChanges');
          this.unBlockScreen();
          this.closeUploadForm();
        })
        .catch(() => {
          this.unBlockScreen();
          this.closeUploadForm();
        });
    },
  },
};
</script>

<style scoped>

</style>

import Vue from 'vue';

import {
  ButtonPlugin,
  FormPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  TablePlugin,
  LayoutPlugin,
  SpinnerPlugin,
  ModalPlugin,
  DropdownPlugin,
  PaginationPlugin,
  FormGroupPlugin,
  FormDatepickerPlugin,
  BadgePlugin,
  TabsPlugin,
  CardPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  FormFilePlugin,
  ProgressPlugin,
  LinkPlugin,
  PopoverPlugin,
  FormRadioPlugin,
  CollapsePlugin,
  CarouselPlugin,
  ImagePlugin,
  ListGroupPlugin,
  VBToggle,
} from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(ButtonPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(TablePlugin);
Vue.use(LayoutPlugin);
Vue.use(SpinnerPlugin);
Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(PaginationPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(BadgePlugin);
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormFilePlugin);
Vue.use(ProgressPlugin);
Vue.use(LinkPlugin);
Vue.use(PopoverPlugin);
Vue.use(FormRadioPlugin);
Vue.use(CollapsePlugin);
Vue.use(ImagePlugin);
Vue.use(ListGroupPlugin);
Vue.use(CarouselPlugin);
Vue.directive('b-toggle', VBToggle);

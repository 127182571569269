<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Proyectos - Administrador"/>
        <div
          class="viewContainer__content"
        >
          <ProjectsAdminBlock v-if="$store.getters.hasActiveCompany"/>
          <div v-if="!$store.getters.hasActiveCompany">
            <div class="container__simple">
              ¡Vaya! Tienes que seleccionar una compañia para visualizar la vista.
            </div>
          </div>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import ProjectsAdminBlock from '../../molecules/Project/ProjectsAdminBlock.vue';

export default {
  name: 'ProjectsAdmin',
  components: {
    PrivateTemplate,
    TitleView,
    ProjectsAdminBlock,
  },
};
</script>

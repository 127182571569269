export default class Documents {
  constructor(documentData) {
    this.uuid = documentData.uuid;
    this.phaseUuid = documentData.phaseUuid;
    this.originalName = documentData.originalName;
    this.fileName = documentData.fileName;
    this.uploadedAt = documentData.uploadedAt;
  }

  get getUuid() {
    return this.uuid;
  }

  get getPhaseUuid() {
    return this.phaseUuid;
  }

  get getOriginalName() {
    return this.originalName;
  }

  get getFileName() {
    return this.fileName;
  }

  get getUploadedAte() {
    return this.uploadedAt;
  }
}

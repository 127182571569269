export const ISSUE_STATUS = {
  PENDING_ACTION: {
    value: 'pending action',
    name: 'Pendiente actuación',
  },
  IN_PROGRESS: {
    value: 'in progress',
    name: 'En progreso',
  },
  PENDING_DATE: {
    value: 'pending date',
    name: 'Pendiente fecha',
  },
  RESOLVED: {
    value: 'resolved',
    name: 'Resuelta',
  },
};

export function getIssueStatusSelectorOptions() {
  const options = [
    { value: null, text: 'Seleccionar estado' },
  ];

  Object.entries(ISSUE_STATUS).forEach(([key, value]) => {
    const option = {
      value: ISSUE_STATUS[key].value,
      text: value.name,
    };

    options.push(option);
  });

  return options;
}

export function getStatusLabel(status) {
  switch (status) {
    case ISSUE_STATUS.PENDING_ACTION.value:
      return ISSUE_STATUS.PENDING_ACTION.name;
    case ISSUE_STATUS.IN_PROGRESS.value:
      return ISSUE_STATUS.IN_PROGRESS.name;
    case ISSUE_STATUS.PENDING_DATE.value:
      return ISSUE_STATUS.PENDING_DATE.name;
    case ISSUE_STATUS.RESOLVED.value:
      return ISSUE_STATUS.RESOLVED.name;
    default:
      return '';
  }
}

<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualizar Instalación"
      @accept="updateInstallation"
      @cancel="cancelAction"
    >
      <FormInstallationContent
        :installationData="this.installationData"
        :installationDataValidation="this.validationStatus"
        @onChangeStatus="onChangeStatus"
        @onChangeStartDate="onChangeStartDate"
        @onChangeEndDate="onChangeEndDate"
        @onChangeStopPermitDate="onChangeStopPermitDate"
        @onChangeComments="onChangeComments"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import { PHASE } from '@/helpers/PhaseHelper';
import FormInstallationContent from './FormInstallationContent.vue';

export default {
  name: 'UpdateInstallationForm',
  components: {
    Modal,
    FormCard,
    FormInstallationContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    installationData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateOffice: {},
  },
  data: () => ({
    installationDataValidation: {
      status: null,
      startDate: null,
      endDate: null,
      stopPermitDate: null,
      planningDate: null,
      comments: null,
    },
    validationStatus: {
      status: null,
    },
  }),
  mounted() {
    if (Object.keys(this.installationData).length > 0) {
      this.installationDataValidation.status = this.installationData.status;
      this.installationDataValidation.startDate = this.installationData.startDate;
      this.installationDataValidation.endDate = this.installationData.endDate;
      this.installationDataValidation.stopPermitDate = this.installationData.stopPermitDate;
      this.installationDataValidation.comments = this.installationData.comments;
    }
  },
  methods: {
    updateInstallation() {
      if (this.isValidData()) {
        const phaseData = {
          status: this.installationDataValidation.status,
          startDate: this.installationDataValidation.startDate,
          endDate: this.installationDataValidation.endDate,
          stopPermitDate: this.installationDataValidation.stopPermitDate,
          comments: this.installationDataValidation.comments,
        };

        const installationData = {
          officeUuid: this.officeUuid,
          phase: PHASE.INSTALLATION.value,
          phaseData,
        };

        this.blockScreen();

        this.$store.dispatch('updateInstallationPhase', {
          data: installationData,
        })
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });

        this.closeForm();
      } else {
        this.validationStatus = {
          status: getValidationState(this.installationDataValidation?.status),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.installationDataValidation?.status);
    },
    onChangeStatus(status) {
      this.installationDataValidation.status = status;
    },
    onChangeStartDate(startDate) {
      this.installationDataValidation.startDate = startDate;
    },
    onChangeEndDate(endDate) {
      this.installationDataValidation.endDate = endDate;
    },
    onChangeStopPermitDate(stopPermitDate) {
      this.installationDataValidation.stopPermitDate = stopPermitDate;
    },
    onChangeComments(comments) {
      this.installationDataValidation.comments = comments;
    },
  },
};
</script>

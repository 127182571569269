export default class SupplierReport {
  constructor(supplierReportData) {
    this.suppliers = supplierReportData.suppliers;
    this.assignations = supplierReportData.assignations;
  }

  get getSuppliers() {
    return this.suppliers;
  }

  get getAssignations() {
    return this.assignations;
  }
}

<template>
  <div class="container__simple">
    <b-row cols="1" cols-lg="4">
      <b-col>
        <TextGroup
          label="Fecha inicio previsto"
          :text="this.project.startDateExpected | formatDate"
        />
      </b-col>
      <b-col>
        <TextGroup
          label="Fecha fin previsto"
          :text="this.project.endDateExpected | formatDate"
        />
      </b-col>
      <b-col>
        <TextGroup
          label="Nº oficinas"
          :text="this.project.countOffices | toString"
        />
      </b-col>
      <b-col>
        <TextGroup
          label="Total proveedores"
          :text="this.project.countSuppliers | toString"
        />
      </b-col>
    </b-row>
    <b-modal id="project-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextGroup from '@/components/atoms/TextGroup/TextGroup.vue';

export default {
  name: 'SingleProjectView',
  components: {
    TextGroup,
  },
  data() {
    return {
      project: {},
    };
  },
  created() {
    this.getProject();
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  methods: {
    getProject() {
      this.blockScreen();

      const uuid = this.$store.getters.getActiveProject;

      if (uuid !== 'undefined') {
        this.$store.dispatch('getProject', { uuid })
          .then((res) => {
            this.project = res;
            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('project-error-modal');
          });
      }
    },
  },
  watch: {
    activeProject(newValue) {
      if (newValue !== null) {
        this.getProject();
      }
    },
  },
};
</script>

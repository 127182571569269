<template>
  <form>
    <b-row>
      <b-col>
        <Datepickers
          startLabel="Fecha Apertura"
          endLabel="Fecha Resolución"
          startPlaceholder="Fecha Apertura"
          endPlaceholder="Fecha Resolución"
          :isValidStartDate="this.issueDataValidation.openingDate"
          :isValidEndDate="this.issueDataValidation.resolutionDate"
          @handleStartDate="getOpeningDate"
          @handleEndDate="getResolutionDate"
          :startDateValue="this.issueData.openingDate"
          :endDateValue="this.issueData.resolutionDate"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-lg-6">
        <SelectorForm
          selectLabel="Estado"
          :selectFormOptions="selectIssueStatusFormOptions"
          @handlerSelect="getStatus"
          :selectedOption="this.issueData.status"
          :isValid="this.issueDataValidation.status"
        />
      </b-col>
      <b-col class="col-12 col-lg-6">
        <b-container
          v-if="isReasonsLoading"
          fluid
          class="d-flex justify-content-center m-3"
        >
          <b-spinner
            variant="secondary"
            label="Spinning"
          />
        </b-container>
        <SelectorForm
          v-if="!isReasonsLoading"
          selectLabel="Razones"
          :selectFormOptions="this.$store.getters.getIssueReasons"
          @handlerSelect="getReason"
          :selectedOption="this.issueData.reasonUuid"
          :isValid="this.issueDataValidation.reasonUuid"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-lg-6">
        <SelectorForm
          v-if="$store.getters.hasAdminPermission"
          selectLabel="Reportado"
          :selectFormOptions="selectUserTypeFormOptions"
          @handlerSelect="getReportedBy"
          :selectedOption="this.issueData.reportedBy"
          :isValid="this.issueDataValidation.reportedBy"
        />
      </b-col>
      <b-col class="col-12 col-lg-6">
        <SimpleInput
          inputLabel="Nombre"
          inputPlaceholder="Introduzca un nombre"
          @handlerText="getName"
          :isValid="this.issueDataValidation.name"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Observaciones"
        >
          <b-form-textarea
            id="addIssueTextarea"
            v-model="observations"
            placeholder="Observaciones..."
            rows="3"
            max-rows="6"
            @change="getObservations"
            :state="this.issueDataValidation.observations"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal id="issue-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </form>
</template>

<script>
import Datepickers from '@/components/molecules/Datepickers/Datepickers.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import { getUserTypeSelectorOptions } from '@/helpers/SelectorHelper';
import { getIssueStatusSelectorOptions } from '@/helpers/status/IssueStatusHelper';
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';

export default {
  name: 'FormIssueContent',
  components: {
    SimpleInput,
    Datepickers,
    SelectorForm,
  },
  props: {
    handleIssueData: {},
    issueData: {},
    issueDataValidation: Object,
  },
  data() {
    return {
      selectUserTypeFormOptions: getUserTypeSelectorOptions(),
      selectIssueStatusFormOptions: getIssueStatusSelectorOptions(),
      observations: this.issueData.observations,
      isReasonsLoading: false,
    };
  },
  created() {
    this.getIssueReasons();
  },
  methods: {
    setIssueOptions(res) {
      const options = [
        { value: null, text: 'Seleccionar razón' },
      ];

      res.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        options.push(option);
      });

      this.$store.commit('setIssueReasons', options);
    },
    getOpeningDate(data) {
      this.issueData.openingDate = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getResolutionDate(data) {
      this.issueData.resolutionDate = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getReportedBy(data) {
      this.issueData.reportedBy = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getStatus(data) {
      this.issueData.status = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getObservations(data) {
      this.issueData.observations = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getReason(data) {
      this.issueData.reasonUuid = data;
      this.$emit('handleIssueData', this.issueData);
    },
    getName(data) {
      this.issueData.name = data;
      this.$emit('handleIssueData', this.issueData);
    },
    showReasonsLoading() {
      this.isReasonsLoading = true;
    },
    hideReasonsLoading() {
      this.isReasonsLoading = false;
    },
    getIssueReasons() {
      if (!this.$store.getters.hasIssueReasons) {
        this.showReasonsLoading();

        this.$store.dispatch('getIssueReasons')
          .then((res) => {
            this.setIssueOptions(res);
            this.hideReasonsLoading();
          })
          .catch(() => {
            this.hideReasonsLoading();
          });
      }
    },
  },
};
</script>

export default class MeasurementPhase {
  constructor(measurementPhaseData) {
    this.uuid = measurementPhaseData.uuid;
    this.name = measurementPhaseData.name;
    this.status = measurementPhaseData.status;
    this.documents = measurementPhaseData.documents;
    this.performanceDate = measurementPhaseData.performanceDate;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getStatus() {
    return this.status;
  }

  get getDocuments() {
    return this.documents;
  }

  get getReceivedDate() {
    return this.performanceDate;
  }
}

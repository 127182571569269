import ProjectApi from '../../api/ProjectApi';

export function getAllProjects({ commit, getters }) {
  const companyUuid = getters.getActiveCompany;
  const projectApi = new ProjectApi();
  const projects = projectApi.getAllProjects(companyUuid);

  return projects
    .then((res) => {
      commit('setProjects', res);
      return res;
    })
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function getProject({ commit }, { uuid }) {
  const projectApi = new ProjectApi();
  const projects = projectApi.getProject(uuid);

  return projects
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function addProject({ commit, getters }, companyUuid) {
  const projectApi = new ProjectApi();
  const projects = projectApi.addProject(companyUuid);

  return projects
    .then(() => {
      getAllProjects({ commit, getters });
    })
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function updateProject({ commit, getters }, data) {
  const projectApi = new ProjectApi();
  const projects = projectApi.updateProject(data);

  return projects
    .then(() => {
      getAllProjects({ commit, getters });
    })
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function deleteProject({ commit, getters }, { uuid }) {
  const projectApi = new ProjectApi();
  const projects = projectApi.deleteProject(uuid);

  return projects
    .then(() => {
      getAllProjects({ commit, getters });
    })
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

export function getProjectReport({ commit }, data) {
  const projectApi = new ProjectApi();
  const projects = projectApi.getProjectReport(data);

  return projects
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return error;
    });
}

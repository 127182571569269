<template>
  <img :src="require(
    isOpen ?
    '@/assets/arrows/keyboard-arrow-top.svg' :
    '@/assets/arrows/keyboard-arrow-bottom.svg')"
  >
</template>

<script>
export default {
  name: 'CollapseIcon',
  props: {
    isOpen: Boolean,
  },
};
</script>

<docs>
  ### Examples
  ```jsx
  <CollapseIcon />
  ```
</docs>

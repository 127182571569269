import CompanyFactory from '../domain/services/CompanyFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const COMPANIES = '/companies';

class CompanyAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllCompanies() {
    return this.client
      .get(COMPANIES)
      .then((res) => CompanyFactory.getCompany(res.data?.collection))
      .catch((error) => Promise.reject(error));
  }

  addCompany(companyData) {
    return this.client
      .post(COMPANIES, companyData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateCompany(data) {
    const url = `${COMPANIES}/${data.uuid}`;

    return this.client
      .put(url, data.companyData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteCompany(uuid) {
    const url = `${COMPANIES}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  uploadCompanyLogo(requestData, formData) {
    const url = `${COMPANIES}/${requestData.companyUuid}/logo`;

    return this.client
      .post(url, formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      .then()
      .catch((error) => Promise.reject(error));
  }

  getCompanyLogo(requestData) {
    const url = `${COMPANIES}/${requestData.companyUuid}/logo`;

    return this.client
      .get(url,
        {
          responseType: 'blob',
        })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }
}

export default CompanyAPI;

export const ERROR_MESSAGE = {
  USER_FORBIDDEN: {
    key: 'exception.operation_forbidden_for_user',
    value: 'Esta operación no está permitida para este usuario',
  },
  ONE_REVISION: {
    key: 'exception.supplier_has_one_revision',
    value: 'Este proveedor ya tiene una revisión en esta oficina',
  },
  OFFICE_HAS_ISSUES: {
    key: 'exception.office_has_issues',
    value: 'No se pueden borrar oficinas con incidencias abiertas',
  },
  OFFICE_HAS_NOT_DOCUMENTS: {
    key: 'exception.files_not_found',
    value: 'Esta oficina no tiene documentos para descargar',
  },
};

export function getErrorMessage(key) {
  switch (key) {
    case ERROR_MESSAGE.ONE_REVISION.key:
      return ERROR_MESSAGE.ONE_REVISION.value;
    case ERROR_MESSAGE.USER_FORBIDDEN.key:
      return ERROR_MESSAGE.USER_FORBIDDEN.value;
    case ERROR_MESSAGE.OFFICE_HAS_ISSUES.key:
      return ERROR_MESSAGE.OFFICE_HAS_ISSUES.value;
    case ERROR_MESSAGE.OFFICE_HAS_NOT_DOCUMENTS.key:
      return ERROR_MESSAGE.OFFICE_HAS_NOT_DOCUMENTS.value;
    default:
      return '';
  }
}

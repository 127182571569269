<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualiza tu usuario"
      @accept="updateUser"
      @cancel="cancelAction"
    >
      <FormUserDataContent
        @handleUserData="setUserData"
        :userData="this.userData"
        :userDataValidation="this.userDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import FormUserDataContent from './FormUserDataContent.vue';

export default {
  name: 'UpdateUserDataForm',
  components: {
    Modal,
    FormCard,
    FormUserDataContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateUsers: {},
  },
  data: () => ({
    userData: {
      type: Object,
      default: () => {},
    },
    userDataValidation: {
      name: null,
      surnames: null,
    },
  }),
  created() {
    this.userData = {
      uuid: this.$store.getters.getUser.uuid,
      name: this.$store.getters.getUser.name,
      surnames: this.$store.getters.getUser.surnames,
      email: this.$store.getters.getUser.email,
      role: this.$store.getters.getUser.role,
    };
  },
  methods: {
    setUserData(userData) {
      this.userData = userData;
    },
    updateUser() {
      if (this.isValidData()) {
        const userdata = {
          name: this.userData.name,
          surnames: this.userData.surnames,
          email: this.userData.email,
          role: this.userData.role,
        };

        this.blockScreen();

        this.$store.dispatch('updateUser', {
          uuid: this.userData.uuid,
          userData: userdata,
        })
          .then(() => {
            this.unBlockScreen();
            this.$emit('handleUpdateUserData');
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.userDataValidation = {
          name: getValidationState(this.userData?.name),
          surnames: getValidationState(this.userData?.surnames),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.userData?.name)
        && hasData(this.userData?.surnames);
    },
  },
};
</script>

<template>
  <Card
    :isLoading="isProjectReportLoading"
    icon="presupuesto.svg"
  >
    <SimpleCardContent
      title="Presupuesto actual cerrado"
      :titleData="getFormattedAmount"
      optionalTitle="Total oficinas activas"
      :optionalDataTitle="projectReportData.activeOfficeNumber"
    >
      <b-row>
        <b-col>
          <p class="Text__sd">Núm oficinas</p>
          <p class="Text__small">{{ projectReportData.officeNumber  }}</p>
        </b-col>
        <b-col>
          <p class="Text__sd">Precio medio oficina</p>
          <p class="Text__small">{{ getFormattedAverageAmount }}</p>
        </b-col>
      </b-row>
    </SimpleCardContent>
  </Card>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/atoms/Cards/Card.vue';
import SimpleCardContent from '@/components/atoms/Cards/SimpleCardContent/SimpleCardContent.vue';

export default {
  name: 'ProjectReport',
  components: {
    Card,
    SimpleCardContent,
  },
  data() {
    return {
      projectReportData: {
        amount: '',
        officeNumber: '',
        averageAmount: '',
      },
      isProjectReportLoading: false,
    };
  },
  created() {
    this.getProjectReport();
  },
  methods: {
    getProjectReport() {
      this.setProjectReportLoading();
      if (this.$store.getters.isSupplier && !this.$store.getters.getUserSupplier) {
        this.$store.dispatch('getUser', this.$store.state.user.uuid)
          .then(() => {
            const data = {
              companyId: this.$store.getters.getActiveCompany,
              projectId: this.$store.getters.getActiveProject,
              supplierId: this.$store.getters.getUserSupplier,
            };

            this.$store.dispatch('getProjectReport', data)
              .then((res) => {
                this.projectReportData = res;
                this.setProjectReportNotLoading();
              })
              .catch(() => {
                this.setProjectReportNotLoading();
              });
          });
      } else {
        const data = {
          companyId: this.$store.getters.getActiveCompany,
          projectId: this.$store.getters.getActiveProject,
          supplierId: this.$store.getters.getUserSupplier,
        };

        this.$store.dispatch('getProjectReport', data)
          .then((res) => {
            this.projectReportData = res;
            this.setProjectReportNotLoading();
          })
          .catch(() => {
            this.setProjectReportNotLoading();
          });
      }
    },
    setProjectReportLoading() {
      this.isProjectReportLoading = true;
    },
    setProjectReportNotLoading() {
      this.isProjectReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
    getFormattedAmount() {
      return this.projectReportData.amount !== '' ? `${this.projectReportData.getFormattedAmount()}€` : '';
    },
    getFormattedAverageAmount() {
      return this.projectReportData.averageAmount !== '' ? `${this.projectReportData.getFormattedAverage()}€` : '';
    },
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getProjectReport();
      }
    },
  },
};
</script>

import AuthAPI from '../../api/AuthApi';

export default function login({ commit }, credentials) {
  const authApi = new AuthAPI();
  const jwtAuthApi = authApi.getJWTToken(credentials);

  return jwtAuthApi
    .then((res) => {
      commit('login', res);
      return res;
    })
    .catch((error) => Promise.reject(error));
}

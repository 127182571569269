<template>
  <form>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <SelectorForm
          selectLabel="Fase"
          :selectFormOptions="selectFormOptions"
          @handlerSelect="getPhase"
          :selectedOption="this.forecastData.phase"
          :isValid="this.forecastDataValidation.phase"
        />
      </b-col>
      <b-col>
        <SimpleInput
          inputLabel="Lote"
          inputId="forecastLot"
          inputType="text"
          inputPlaceholder="Lote"
          :inputValue="this.forecastData.lot"
          @handlerText="getLot"
          :isValid="this.forecastDataValidation.lot"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Datepickers
          startPlaceholder="Fecha Inicio"
          endPlaceholder="Fecha Fin"
          :isValidStartDate="this.forecastDataValidation.startDate"
          :isValidEndDate="this.forecastDataValidation.endDate"
          @handleStartDate="getStartDate"
          @handleEndDate="getEndDate"
          :startDateValue="this.forecastData.startDate"
          :endDateValue="this.forecastData.endDate"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <SimpleInput
          inputLabel="Color"
          inputId="forecastColor"
          inputType="text"
          inputPlaceholder="Color (hexadecimal)"
          :inputValue="this.forecastData.color"
          @handlerText="getColor"
          :isValid="this.forecastDataValidation.color"
        />
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';
import Datepickers from '@/components/molecules/Datepickers/Datepickers.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import { getPhaseSelectorOptions } from '@/helpers/PhaseHelper';

export default {
  name: 'ForecastFormContent',
  components: {
    SimpleInput,
    Datepickers,
    SelectorForm,
  },
  props: {
    handleForecastData: {},
    forecastData: {
      type: Object,
      default: () => {},
    },
    forecastDataValidation: Object,
  },
  data() {
    return {
      selectFormOptions: getPhaseSelectorOptions(),
    };
  },
  methods: {
    getStartDate(data) {
      this.forecastData.startDate = data;
      this.$emit('handleForecastData', this.forecastData);
    },
    getEndDate(data) {
      this.forecastData.endDate = data;
      this.$emit('handleForecastData', this.forecastData);
    },
    getPhase(data) {
      this.forecastData.phase = data;
      this.$emit('handleForecastData', this.forecastData);
      this.$emit('handleUpdatePhase', data);
    },
    getLot(data) {
      this.forecastData.lot = data;
      this.$emit('handleForecastData', this.forecastData);
    },
    getColor(data) {
      this.forecastData.color = data;
      this.$emit('handleForecastData', this.forecastData);
    },
  },
};
</script>

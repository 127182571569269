import SupplierApi from '../../api/SupplierApi';

export function getAllSuppliers({ commit }, { projectId }) {
  const supplierApi = new SupplierApi();
  const suppliers = supplierApi.getAllSuppliers(projectId);

  return suppliers
    .then((res) => {
      commit('setSuppliers', res.suppliers);
      return res;
    })
    .catch((error) => Promise.reject(error));
}

export function addSupplier(state, data) {
  const supplierApi = new SupplierApi();
  const suppliers = supplierApi.addSupplier(data);

  return suppliers
    .then()
    .catch((error) => Promise.reject(error));
}

export function updateSupplier(state, data) {
  const supplierApi = new SupplierApi();
  const suppliers = supplierApi.updateSupplier(data);

  return suppliers
    .then()
    .catch((error) => Promise.reject(error));
}

export function deleteSupplier(state, { uuid }) {
  const supplierApi = new SupplierApi();
  const suppliers = supplierApi.deleteSupplier(uuid);

  return suppliers
    .then()
    .catch((error) => Promise.reject(error));
}

export function getSupplierReport(state, data) {
  const supplierApi = new SupplierApi();
  const suppliers = supplierApi.getSupplierReport(data);

  return suppliers
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

<template>
  <div class="Auth">
    <b-form @submit="onSubmit">
      <label for="input-email">Email</label>
      <b-form-input
        id="input-email"
        type="email"
        v-model="credentials.username"
        placeholder="Email"
        required
      ></b-form-input>
      <div class="Separator__vertical__big" />
      <label for="input-password">Password</label>
      <b-form-input
        id="input-password"
        type="password"
        v-model="credentials.password"
        placeholder="Password"
        required
      ></b-form-input>
      <div class="Separator__vertical__big" />
      <b-button type="submit" variant="primary">Login</b-button>
      <div class="Separator__vertical__st" />
      <div class="text-center">
        <router-link
          class="Text__small"
          :to="{ name: getResetPasswordURL() }"
          @click.native="getResetPasswordURL()"
        >
          ¿Olvidaste tu contraseña?
        </router-link>
      </div>
    </b-form>
    <b-modal id="error-modal" centered ok-only title="Error al iniciar sesión">
      <p class="my-4">El password o la contraseña no son correctos.</p>
    </b-modal>
  </div>
</template>

<script>

const RESET_URL = 'ResetPassword';

export default {
  name: 'Login',
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.blockScreen();
      this.$store.dispatch('login', this.credentials)
        .then(() => {
          this.unBlockScreen();
          this.$router.push({ name: 'Dashboard' });
        })
        .catch((error) => {
          this.unBlockScreen();

          if (error.response.data.code === 401) {
            this.$bvModal.show('error-modal');
          }
        });
    },
    getResetPasswordURL() {
      return RESET_URL;
    },
  },
};
</script>

<style lang="sass">
  @import '../Auth/Auth.sass'
</style>

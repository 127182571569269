<template>
  <Modal v-show="showModal">
    <FormCard       title="Elegir fase"
                    @accept="uploadFile"
                    @cancel="closeUploadForm">
          <SelectorForm
            selectLabel = "Fase"
            :selectFormOptions="options"
            @handlerSelect="setSelected"
            class="documentSelector"
          />

    </FormCard>
  </Modal>

</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';

export default {
  name: 'UploadDocumentForm',
  components: { FormCard, SelectorForm, Modal },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    officeUuid: {
      type: String,
      default: '',
    },
    measurementPhaseData: {
      type: Object,
      default: () => {},
    },
    budgetPhaseData: {
      type: Object,
      default: () => {},
    },
    picPhaseData: {
      type: Object,
      default: () => {},
    },
    installationPhaseData: {
      type: Object,
      default: () => {},
    },
    certificationPhaseData: {
      type: Object,
      default: () => {},
    },
    file: null,
  },
  data() {
    return {
      options: [
        { value: null, text: 'Sin selección' },
        { value: this.$props.measurementPhaseData.uuid, text: 'Medición' },
        { value: this.$props.budgetPhaseData.uuid, text: 'Presupuesto' },
        { value: this.$props.picPhaseData.uuid, text: 'PIC' },
        { value: this.$props.installationPhaseData.uuid, text: 'Instalación' },
        { value: this.$props.certificationPhaseData.uuid, text: 'Certificación' },

      ],
      selectedPhaseUuid: null,
    };
  },
  methods: {
    setSelected(option) {
      this.selectedPhaseUuid = option;
    },
    showUploadForm() {
      this.isShowedUploadForm = true;
    },
    closeUploadForm() {
      this.$emit('cancel');
    },
    uploadFile() {
      this.blockScreen();
      const formData = new FormData();

      this.$props.file.map((file) => {
        formData.append('file[]', file, file.name);
        return file;
      });

      const requestData = {
        officeUuid: this.officeUuid,
        phaseUuid: this.selectedPhaseUuid,
      };

      this.$store
        .dispatch('uploadPhaseDocument', { requestData, formData })
        .then(() => {
          this.file = null;
          this.$emit('manageDocumentChanges');
          this.unBlockScreen();
          this.closeUploadForm();
        })
        .catch(() => {
          this.unBlockScreen();
          this.closeUploadForm();
        });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <TableContainer title="Informe estado según planificación">
    <div class="TableSimpleBlock__content">
      <b-row>
        <b-col class="col-12 col-lg-6">
          <Datepickers
            startPlaceholder="Fecha Inicio Periodo"
            endPlaceholder="Fecha Fin Periodo"
            :isValidStartDate="this.fromDateValidation"
            :isValidEndDate="this.toDateValidation"
            @handleStartDate="getStartDate"
            @handleEndDate="getEndDate"
            :startDateValue="this.fromDate"
            :endDateValue="this.toDate"
          />
        </b-col>
        <b-col class="col-12">
          <b-button
            variant="primary"
            type="button"
            @click.prevent="getPlanningReport"
          >
            Filtrar Informe
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="table__container">
      <b-table
        :items="planningReportData"
        :fields="fields"
        striped
        hover
        bordered
      >
<!--        <template #cell(phase)="data">-->
<!--          {{ data.item.phase | getPhaseLabel }}-->
<!--        </template>-->
<!--        <template #cell(date)="data">-->
<!--          {{ data.item.startDate | formatDate }} - {{ data.item.endDate | formatDate }}-->
<!--        </template>-->
<!--        <template #cell(percent)="data">-->
<!--          <b-progress max="100">-->
<!--            <b-progress-bar-->
<!--            :value="getPercent(data.item.officesCount, data.item.finishedOfficeCount)"-->
<!--            :label="`${getPercent(data.item.officesCount, data.item.finishedOfficeCount)}%`"-->
<!--          />-->
<!--          </b-progress>-->
<!--        </template>-->
      </b-table>
    </div>
  </TableContainer>
</template>

<script>
import { mapState } from 'vuex';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import Datepickers from '@/components/molecules/Datepickers/Datepickers.vue';
import { getDecimalNumber } from '@/helpers/MathHelper';
import {
  getValidationState,
  hasData,
  getDateValidationRange,
  isValidDateRange,
} from '@/helpers/ValidationHelper';
import { getPhaseLabel } from '@/helpers/PhaseHelper';

export default {
  name: 'PlanningReport',
  components: {
    TableContainer,
    Datepickers,
  },
  data() {
    return {
      planningReportData: [],
      fields: [
        { key: 'phase', label: 'Fase', sortable: true },
        { key: 'previewedOfficesCount', label: 'Previsto', sortable: true },
        { key: 'receivedPhaseInRangeCount', label: 'Real', sortable: true },
        { key: 'percentCompletion', label: '%', sortable: true },
      ],
      fromDate: '',
      toDate: '',
      fromDateValidation: null,
      toDateValidation: null,
    };
  },
  // created() {
  //   this.getPlanningReport();
  // },
  methods: {
    getPlanningReport() {
      if (this.isValidData()) {
        this.blockScreen();

        const data = {
          companyId: this.$store.getters.getActiveCompany,
          projectId: this.$store.getters.getActiveProject,
          from: this.fromDate,
          to: this.toDate,
        };

        this.$store.dispatch('getForecastStatusReport', data)
          .then((res) => {
            this.planningReportData = res.map((element) => ({
              phase: getPhaseLabel(element.phase),
              previewedOfficesCount: element.previewedOfficesCount,
              receivedPhaseInRangeCount: element.receivedPhaseInRangeCount,
              percentCompletion: (
                element.receivedPhaseInRangeCount / element.previewedOfficesCount
              ) * 100,
            }));
            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      } else {
        this.fromDateValidation = getValidationState(this.fromDate);
        this.toDateValidation = getValidationState(this.toDate);
        // eslint-disable-next-line no-multi-assign
        this.fromDateValidation = this.toDateValidation = getDateValidationRange(
          this.fromDate,
          this.toDate,
        );
      }
    },
    getStartDate(data) {
      this.fromDate = data;
    },
    getEndDate(data) {
      this.toDate = data;
    },
    getPercent(total, totalFinished) {
      const percent = (totalFinished / total) * 100;

      return getDecimalNumber(percent);
    },
    isValidData() {
      return hasData(this.fromDate)
        && hasData(this.toDate)
        && isValidDateRange(this.fromDate, this.toDate);
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getPlanningReport();
      }
    },
  },
};
</script>

export default class OfficeCertificationReport {
  constructor(officeCertificationReportData) {
    this.uuid = officeCertificationReportData.uuid;
    this.name = officeCertificationReportData.name;
    this.activeOfficesCount = officeCertificationReportData.activeOfficesCount;
    this.approvedCertificationCount = officeCertificationReportData.approvedCertificationCount;
    this.finishedInstallationCount = officeCertificationReportData.finishedInstallationCount;
    this.issuedOfficesCount = officeCertificationReportData.issuedOfficesCount;
    // eslint-disable-next-line max-len
    this.pendingReceiveCertificationCount = officeCertificationReportData.pendingReceiveCertificationCount;
    // eslint-disable-next-line max-len
    this.pendingResolveCertificationCount = officeCertificationReportData.pendingResolveCertificationCount;
    this.receivedCertificationCount = officeCertificationReportData.receivedCertificationCount;
    this.revisedCertificationCount = officeCertificationReportData.revisedCertificationCount;
    this.solvedIssuedOfficesCount = officeCertificationReportData.solvedIssuedOfficesCount;
    this.percentIncidences = officeCertificationReportData.percentIncidences;
    this.percentSolvedIncidences = officeCertificationReportData.percentSolvedIncidences;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getActiveOfficesCount() {
    return this.activeOfficesCount;
  }

  get getFinishedInstallationCount() {
    return this.finishedInstallationCount;
  }

  get getApprovedCertificationCount() {
    return this.approvedCertificationCount;
  }

  get getIssuedOfficesCount() {
    return this.issuedOfficesCount;
  }

  get getPendingReceiveCertificationCount() {
    return this.pendingReceiveCertificationCount;
  }

  get getPendingResolveCertificationCount() {
    return this.pendingResolveCertificationCount;
  }

  get getReceivedCertificationCount() {
    return this.receivedCertificationCount;
  }

  get getRevisedCertificationCount() {
    return this.revisedCertificationCount;
  }

  get getSolvedIssuedOfficesCount() {
    return this.solvedIssuedOfficesCount;
  }

  get getPercentIncidences() {
    return this.percentIncidences;
  }

  get getPercentSolvedIncidences() {
    return this.percentSolvedIncidences;
  }
}

<template>
  <div class="Notes">
    <b-row>
      <b-col>
        <div
          v-if="$store.getters.hasProviderPermission"
          class="text-right"
        >
          <b-button
            variant="primary"
            type="button"
            @click.prevent="showAddForm"
          >
            Añadir Nota
          </b-button>
        </div>
        <div class="Separator__vertical__big"/>
        <div v-if="!hasNotes && !isLoading" class="container__simple">
          No existen notas.
        </div>
        <b-container
          v-if="isLoading"
          fluid
          class="d-flex justify-content-center m-3">
          <b-spinner variant="secondary" label="Spinning"></b-spinner>
        </b-container>
        <div
          class="table__container"
          v-if="hasNotes && !isLoading"
        >
          <b-table
            :items="items"
            :fields="fields"
            striped
            hover
            bordered
          >
            <template #cell(date)="data">
              {{ data.item.date | formatDate }}
            </template>
            <template #cell(acciones)="data">
              <div
                v-if="$store.getters.hasAdminPermission"
                class="actionGroup"
              >
                <ActionIconButton
                  icon="edit.svg"
                  type="edit"
                  :handler="editNote"
                  :objectData="data.item"
                />
                <ActionIconButton
                  icon="delete.svg"
                  type="delete"
                  :handler="deleteNote"
                  :objectData="data.item"
                />
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <AddNoteForm
      v-if="isShowedAddForm"
      :showModal="true"
      @cancel="closeAddForm"
      :handleClose="closeAddForm"
      :officeUuid="officeUuid"
      @updateNotes="getNotes"
    />
    <UploadNoteForm
      v-if="isShowedUpdateNoteForm"
      :showModal="true"
      @cancel="closeUpdateNoteForm"
      :noteData="currentNote"
      :handleClose="closeUpdateNoteForm"
      :officeUuid="officeUuid"
      @updateNotes="getNotes"
    />
  </div>
</template>

<script>
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddNoteForm from './AddNoteForm.vue';
import UploadNoteForm from './UploadNoteForm.vue';

export default {
  name: 'Notes',
  components: {
    ActionIconButton,
    AddNoteForm,
    UploadNoteForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'body', label: 'Nota', sortable: true },
        { key: 'date', label: 'Fecha', sortable: true },
        { key: 'acciones' },
      ],
      isShowedAddForm: false,
      isShowedUpdateNoteForm: false,
      currentNote: {
        uuid: '',
        officeUuid: '',
        body: '',
        date: null,
      },
      isLoading: false,
    };
  },
  created() {
    this.getNotes();
  },
  methods: {
    editNote(objectData) {
      this.currentNote = {
        uuid: objectData.uuid,
        officeUuid: this.officeUuid,
        body: objectData.body,
        date: objectData.date,
      };

      this.showUpdateNoteForm();
    },
    deleteNote(objectData) {
      this.showLoader();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteNote', { uuid })
          .then(() => {
            this.deleteNoteResult(uuid);
            this.hideLoader();
          })
          .catch(() => {
            this.hideLoader();
          });
      }
    },
    showAddForm() {
      this.isShowedAddForm = true;
    },
    closeAddForm() {
      this.isShowedAddForm = false;
    },
    showUpdateNoteForm() {
      this.isShowedUpdateNoteForm = true;
    },
    closeUpdateNoteForm() {
      this.isShowedUpdateNoteForm = false;
    },
    getNotes() {
      this.showLoader();

      if (typeof this.officeUuid !== 'undefined' && this.officeUuid) {
        const uuid = this.officeUuid;

        this.$store.dispatch('getAllNotes', { uuid })
          .then((res) => {
            this.items = res;

            this.hideLoader();
          })
          .catch(() => {
            this.hideLoader();
          });
      }
    },
    deleteNoteResult(uuid) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].uuid === uuid) {
          this.items.splice(i, 1);
          break;
        }
      }

      this.hideLoader();
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
  },
  computed: {
    hasNotes() {
      return this.items.length > 0;
    },
  },
};
</script>

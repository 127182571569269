<template>
  <ReportView title="Planificación">
    <PlanningReport/>
  </ReportView>
</template>

<script>
import PlanningReport from '@/components/molecules/Reports/PlanningReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'PlanningReportView',
  components: {
    PlanningReport,
    ReportView,
  },
};
</script>

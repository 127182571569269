import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';
import { ROLE } from '@/helpers/UserHelper';

Vue.use(Vuex);

export function userIsClient() {
  return state.user.role === ROLE.CLIENT;
}

export function hasSuperAdminPermission() {
  return state.user.role === ROLE.SUPER_ADMIN;
}

export function hasAdminPermission() {
  return state.user.role === ROLE.SUPER_ADMIN
    || state.user.role === ROLE.ADMIN;
}

export function hasClientPermission() {
  return state.user.role === ROLE.SUPER_ADMIN
    || state.user.role === ROLE.ADMIN
    || state.user.role === ROLE.CLIENT;
}

export function hasProviderPermission() {
  return state.user.role === ROLE.SUPER_ADMIN
    || state.user.role === ROLE.ADMIN
    || state.user.role === ROLE.PROVIDER;
}

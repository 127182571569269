import login from './login';
import {
  addProject,
  deleteProject,
  getAllProjects,
  getProject,
  getProjectReport,
  updateProject,
} from './project';
import {
  addUser,
  deleteUser,
  getAvatar,
  getClients,
  getUser,
  getUsers,
  inviteUser,
  resetPassword,
  updatePassword,
  updateUser,
  uploadAvatar,
} from './user';
import {
  addCompany,
  deleteCompany,
  getAllCompanies,
  getCompanyLogo,
  updateCompany,
  uploadCompanyLogo,
} from './company';
import {
  addSupplier,
  deleteSupplier,
  getAllSuppliers,
  getSupplierReport,
  updateSupplier,
} from './supplier';
import {
  addForecast,
  deleteForecast,
  getAllForecasts,
  getForecastStatusReport,
  updateForecast,
} from './forecast';
import {
  addOffice,
  deleteOffice,
  downloadOfficeDocuments,
  exportPhaseOffices,
  exportStatusOffices,
  getAllOffices,
  getLotsFromCompanyAndOffice,
  getOffice,
  getOfficeCertificationReport,
  getOfficeInstallationReport,
  getOfficeReport,
  getOfficeStatusReport,
  getOfficeTypes,
  getPicRevisionReport,
  updateOffice,
  uploadBudget,
  uploadCertification,
  uploadForecast,
  uploadInstallation,
  uploadMeasurement,
  uploadOffice,
  uploadPic,
} from './office';
import {
  addIssue,
  countIssuesByOffice,
  deleteIssue,
  downloadIssueDocument,
  exportAllIssues,
  getAllIssues,
  getIssueReasonReport,
  getIssueReasons,
  getIssueStats,
  getIssueSupplierReport,
  updateIssue,
  uploadIssueDocument,
} from './issues';
import {
  deletePhaseDocument,
  downloadPhaseDocument,
  getPhaseByOffice,
  phaseRevision,
  updateBudgetPhase,
  updateCertificationPhase,
  updateInstallationPhase,
  updateMeasurementPhase,
  updatePicPhase,
  uploadPhaseDocument,
} from './phase';
import {
  addNote, deleteNote, getAllNotes, getNote, updateNote,
} from './notes';
import { addRevision, deleteRevision, updateRevision } from './revision';

import { addComment, deleteComment, updateComment } from './comment';
import { getEvents, updateConsumedEvents } from '@/store/actions/events';

export default {
  login,
  getUsers,
  getUser,
  addUser,
  updateUser,
  deleteUser,
  getClients,
  uploadAvatar,
  getAvatar,
  updatePassword,
  inviteUser,
  getAllProjects,
  getProject,
  addProject,
  updateProject,
  deleteProject,
  getProjectReport,
  getAllCompanies,
  addCompany,
  updateCompany,
  deleteCompany,
  uploadCompanyLogo,
  getCompanyLogo,
  getAllSuppliers,
  addSupplier,
  updateSupplier,
  deleteSupplier,
  getSupplierReport,
  getAllForecasts,
  addForecast,
  updateForecast,
  deleteForecast,
  getForecastStatusReport,
  getAllOffices,
  getOffice,
  addOffice,
  updateOffice,
  deleteOffice,
  getOfficeReport,
  uploadOffice,
  uploadMeasurement,
  uploadBudget,
  uploadPic,
  uploadCertification,
  uploadInstallation,
  uploadForecast,
  exportStatusOffices,
  exportPhaseOffices,
  getPicRevisionReport,
  getOfficeTypes,
  getOfficeInstallationReport,
  getOfficeCertificationReport,
  getOfficeStatusReport,
  updateMeasurementPhase,
  updateBudgetPhase,
  updatePicPhase,
  updateCertificationPhase,
  updateInstallationPhase,
  phaseRevision,
  uploadPhaseDocument,
  getAllIssues,
  exportAllIssues,
  addIssue,
  updateIssue,
  deleteIssue,
  getIssueReasonReport,
  getIssueSupplierReport,
  getIssueReasons,
  getIssueStats,
  countIssuesByOffice,
  uploadIssueDocument,
  downloadIssueDocument,
  getAllNotes,
  getNote,
  addNote,
  updateNote,
  deleteNote,
  downloadPhaseDocument,
  downloadOfficeDocuments,
  getPhaseByOffice,
  deletePhaseDocument,
  addRevision,
  updateRevision,
  deleteRevision,
  resetPassword,
  addComment,
  updateComment,
  deleteComment,
  getLotsFromCompanyAndOffice,
  getEvents,
  updateConsumedEvents,
};

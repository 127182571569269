import Issue from '../models/Issue';
import IssueReasonReport from '../models/IssueReasonReport';
import IssueReasonReportCounters from '../models/IssueReasonReportCounters';
import IssueSupplierReport from '../models/IssueSupplierReport';
import IssueReason from '../models/IssueReason';
import Pagination from '../models/Pagination';
import User from '../models/User';
import IssueStats from '../models/IssueStats';

const PERCENT_FOOTER = 'percents';
const TOTAL_FOOTER = 'totals';
const PERCENT_FOOTER_TRANSLATION = '% INCIDENCIA';
const TOTAL_FOOTER_TRANSLATION = 'Total general';

class IssueFactory {
  constructor() {
    this.issues = [];
  }

  getOwner(owner) {
    if (!owner) {
      return null;
    }

    this.newOwner = new User({
      uuid: owner.uuid,
      email: owner.email,
      role: owner.roles[0],
    });

    return this.newOwner;
  }

  getAllIssuesRequest(data) {
    const usersRequest = {
      data: this.getAllIssues(data?.collection),
      pagination: this.getPagination(data?.pagination),
    };

    return usersRequest;
  }

  getAllIssues(issueData) {
    this.issues = issueData.map((issue) => {
      const newIssue = new Issue({
        uuid: issue.uuid,
        office: issue.office,
        status: issue.status,
        name: issue.name,
        openingDate: issue.openingDate,
        resolutionDate: issue.resolutionDate,
        reason: issue.reason,
        reportedBy: issue.reportedBy,
        countOffices: issue.countOffices,
        observations: issue.observations,
        owner: this.getOwner(issue.owner),
        documents: issue.documents,
      });

      return newIssue;
    });

    return this.issues;
  }

  getIssueReasonReport(issueReasonReportData) {
    const banderolas = issueReasonReportData.countersByTypology.Banderolas;
    const mostradorDeCaja = issueReasonReportData.countersByTypology.Mostrador_de_caja;
    const frontCaja = issueReasonReportData.countersByTypology.Front_caja;
    const elementosInteriores = issueReasonReportData.countersByTypology.Elementos_interiores;
    const elementosExteriores = issueReasonReportData.countersByTypology.Elementos_exteriores;
    const rotulosCorporeos = issueReasonReportData.countersByTypology.Rotulos_corporeos;
    const rotulosDeLona = issueReasonReportData.countersByTypology.Rotulos_de_lona;
    const cajeros = issueReasonReportData.countersByTypology.Cajeros;
    const otros = issueReasonReportData.countersByTypology.Otros;
    let issueReasonReportCounters;
    if (issueReasonReportData.name === PERCENT_FOOTER) {
      issueReasonReportCounters = new IssueReasonReportCounters({
        banderolas: `${parseFloat(banderolas).toFixed(2)}%`,
        mostradorDeCaja: `${parseFloat(mostradorDeCaja).toFixed(2)}%`,
        frontCaja: `${parseFloat(frontCaja).toFixed(2)}%`,
        elementosInteriores: `${parseFloat(elementosInteriores).toFixed(2)}%`,
        elementosExteriores: `${parseFloat(elementosExteriores).toFixed(2)}%`,
        rotulosCorporeos: `${parseFloat(rotulosCorporeos).toFixed(2)}%`,
        rotulosDeLona: `${parseFloat(rotulosDeLona).toFixed(2)}%`,
        cajeros: `${parseFloat(cajeros).toFixed(2)}%`,
        otros: `${parseFloat(otros).toFixed(2)}%`,
      });
    } else {
      issueReasonReportCounters = new IssueReasonReportCounters({
        banderolas,
        mostradorDeCaja,
        frontCaja,
        elementosInteriores,
        elementosExteriores,
        rotulosCorporeos,
        rotulosDeLona,
        cajeros,
        otros,
      });
    }

    let { name } = issueReasonReportData;
    switch (issueReasonReportData.name) {
      case PERCENT_FOOTER:
        name = PERCENT_FOOTER_TRANSLATION;
        break;
      case TOTAL_FOOTER:
        name = TOTAL_FOOTER_TRANSLATION;
        break;
      default:
    }

    this.issueReasonReport = new IssueReasonReport({
      uuid: issueReasonReportData.uuid,
      name,
      countersByTypology: issueReasonReportCounters,
    });

    return this.issueReasonReport;
  }

  getIssueReasonReports(issueReasonReportData) {
    this.issueReasonReports = issueReasonReportData.map(
      (issueReasonReport) => this.getIssueReasonReport(issueReasonReport),
    );

    return this.issueReasonReports;
  }

  getIssueSupplierReport(issueSupplierReportData) {
    this.issueSupplierReport = new IssueSupplierReport({
      uuid: issueSupplierReportData.uuid,
      name: issueSupplierReportData.name,
      issuesCount: issueSupplierReportData.issuesCount,
      officesCount: issueSupplierReportData.officesCount,
      solvedIssuesCount: issueSupplierReportData.solvedIssuesCount,
      solvedIssuesOfficesCount: issueSupplierReportData.solvedIssuesOfficesCount,
      notSolvedIssuesCount: issueSupplierReportData.notSolvedIssuesCount,
    });

    return this.issueSupplierReport;
  }

  getIssueSupplierReports(issueSupplierReportData) {
    this.issueSupplierReports = issueSupplierReportData.map(
      (issueSupplierReport) => this.getIssueSupplierReport(issueSupplierReport),
    );

    return this.issueSupplierReports;
  }

  getIssueReason(issueReasonData) {
    this.issueReason = new IssueReason({
      uuid: issueReasonData.uuid,
      name: issueReasonData.name,
      slug: issueReasonData.slug,
    });

    return this.issueReason;
  }

  getIssueReasons(issueReasonData) {
    this.issueReasons = issueReasonData.map(
      (issueReason) => this.getIssueReason(issueReason),
    );

    return this.issueReasons;
  }

  getPagination(paginationData) {
    this.pagination = new Pagination({
      items: paginationData.items,
      totalItems: paginationData.totalItems,
      offset: paginationData.offset,
    });

    return this.pagination;
  }

  getIssueStats(issueReasonData) {
    this.issueStats = new IssueStats({
      pendingAction: issueReasonData.pendingAction,
      inProgress: issueReasonData.inProgress,
      pendingDate: issueReasonData.pendingDate,
      resolved: issueReasonData.resolved,
    });

    return this.issueStats;
  }
}

export default new IssueFactory();

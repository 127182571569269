<template>
  <div>
    <ImporterWithProjectSelector
      @handleUploadFile="uploadFile"
      :elementsImported="elementsImported"
    />
    <b-modal id="importer-modal" centered ok-only title="Error">
      <p class="my-4">Ok.</p>
    </b-modal>
  </div>
</template>

<script>
import ImporterWithProjectSelector from './ImporterWithProjectSelector.vue';

export default {
  name: 'ImporterManager',
  components: {
    ImporterWithProjectSelector,
  },
  props: {
    action: String,
  },
  data() {
    return {
      elementsImported: 0,
    };
  },
  methods: {
    uploadFile(file) {
      this.blockScreen();
      const formData = new FormData();

      formData.append('file', file);

      const projectUuid = this.$store.getters.getActiveProject;

      this.$store.dispatch(this.action, { projectUuid, formData })
        .then((res) => {
          this.elementsImported = res.elementsImported;
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
  },
};
</script>

<template>
  <form>
    <b-row>
      <b-col>
        <SimpleInput
          inputLabel="Nombre"
          inputId="nameProject"
          inputType="text"
          inputPlaceholder="Nombre"
          :inputValue="this.projectData.name"
          @handlerText="getName"
          :isValid="this.projectDataValidation.name"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Datepickers
          startPlaceholder="Fecha Inicio"
          endPlaceholder="Fecha Fin"
          :isValidStartDate="this.projectDataValidation.startDateExpected"
          :isValidEndDate="this.projectDataValidation.endDateExpected"
          @handleStartDate="getStartDate"
          @handleEndDate="getEndDate"
          :startDateValue="this.projectData.startDateExpected"
          :endDateValue="this.projectData.endDateExpected"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ClientSelector
          @handleChangeClients="getClients"
          :client="this.projectData.clientUuid"
          :clients="this.projectData.clients"
        />
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';
import Datepickers from '@/components/molecules/Datepickers/Datepickers.vue';
import ClientSelector from '@/components/atoms/Selectors/ClientSelector.vue';

export default {
  name: 'FormProjectContent',
  components: {
    SimpleInput,
    Datepickers,
    ClientSelector,
  },
  props: {
    handleProjectData: {},
    projectData: Object,
    projectDataValidation: Object,
  },
  methods: {
    getName(data) {
      this.projectData.name = data;
      this.$emit('handleProjectData', this.projectData);
    },
    getStartDate(data) {
      this.projectData.startDateExpected = data;
      this.$emit('handleProjectData', this.projectData);
    },
    getEndDate(data) {
      this.projectData.endDateExpected = data;
      this.$emit('handleProjectData', this.projectData);
    },
    getClients(data) {
      this.projectData.clientsUuid = data;
      this.$emit('handleProjectData', this.projectData);
    },
  },
};
</script>

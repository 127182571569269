export default class InstallationPhase {
  constructor(installationPhaseData) {
    this.uuid = installationPhaseData.uuid;
    this.name = installationPhaseData.name;
    this.status = installationPhaseData.status;
    this.documents = installationPhaseData.documents;
    this.startDate = installationPhaseData.startDate;
    this.endDate = installationPhaseData.endDate;
    this.stopPermitDate = installationPhaseData.stopPermitDate;
    this.planningDate = installationPhaseData.planningDate;
    this.comments = installationPhaseData.comments;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getStatus() {
    return this.status;
  }

  get getDocuments() {
    return this.documents;
  }

  get getStartDate() {
    return this.startDate;
  }

  get getEndDate() {
    return this.endDate;
  }

  get getStopPermitDate() {
    return this.stopPermitDate;
  }

  get getComments() {
    return this.comments;
  }
}

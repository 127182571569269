import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const OFFICES = '/offices';

class commentApi {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  addComment(data) {
    const url = `${OFFICES}/${data.officeUuid}/phase/${data.phaseUuid}/comment`;
    return this.client
      .post(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateComment(data) {
    const url = `${OFFICES}/${data.officeUuid}/phase/${data.phaseUuid}/comment/${data.commentUuid}`;
    return this.client
      .put(url, { text: data.text })
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteComment(data) {
    const url = `${OFFICES}/${data.officeUuid}/phase/${data.phaseUuid}/comment/${data.commentUuid}`;
    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default commentApi;

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addProject"
      @cancel="cancelAction"
    >
      <FormProjectContent
        :projectData="this.projectData"
        :projectDataValidation="this.projectDataValidation"
        @handleProjectData="setProjectData"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormProjectContent from '@/components/molecules/Project/FormProjectContent.vue';
import { getValidationState, hasData } from '@/helpers/ValidationHelper';

export default {
  name: 'AddProjectForm',
  components: {
    Modal,
    FormCard,
    FormProjectContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateProjects: {},
  },
  data: () => ({
    title: 'Nuevo proyecto',
    projectData: {
      name: null,
      startDateExpected: null,
      endDateExpected: null,
      clientsUuid: null,
    },
    projectDataValidation: {
      name: null,
      startDateExpected: null,
      endDateExpected: null,
    },
  }),
  methods: {
    setProjectData(projectData) {
      this.projectData = projectData;
    },
    addProject() {
      if (this.isValidData()) {
        this.blockScreen();

        const requestData = this.projectData;
        requestData.companyUuid = this.$store.getters.getActiveCompany;
        requestData.email = this.$store.getters.getUser.email;

        this.$store.dispatch('addProject', requestData)
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateProjects();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.projectDataValidation = {
          name: getValidationState(this.projectData?.name),
          startDateExpected: getValidationState(this.projectData?.startDateExpected),
          endDateExpected: getValidationState(this.projectData?.endDateExpected),
        };
      }
    },
    isValidData() {
      return hasData(this.projectData?.name)
        && hasData(this.projectData?.startDateExpected)
        && hasData(this.projectData?.endDateExpected);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

export function calculateOffset(page, itemsPerPage) {
  const offset = (page - 1) * itemsPerPage;

  return offset;
}

export function getOffsetUrl(route, offset) {
  const urlWithParam = `${route}?offset=${offset}`;

  return urlWithParam;
}

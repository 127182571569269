<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addNote"
      @cancel="cancelAction"
    >
      <FormNoteContent
        @handleNoteData="setNoteData"
        :noteData="this.noteData"
        :noteDataValidation="this.noteDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import FormNoteContent from './FormNoteContent.vue';

export default {
  name: 'UpdateNoteForm',
  components: {
    Modal,
    FormCard,
    FormNoteContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    officeUuid: {
      type: String,
      default: '',
    },
    noteData: {
      uuid: null,
      body: null,
      officeUuid: null,
      date: null,
    },
  },
  data: () => ({
    title: 'Actualizar Nota',
    noteDataValidation: {
      body: null,
      date: null,
    },
  }),
  created() {
    if (typeof this.officeUuid !== 'undefined' && this.officeUuid !== '') {
      this.noteData.officeUuid = this.officeUuid;
    }
  },
  methods: {
    setNoteData(noteData) {
      this.noteData = noteData;
    },
    addNote() {
      if (this.isValidData()) {
        this.blockScreen();

        this.$store.dispatch('updateNote', this.noteData)
          .then(() => {
            this.unBlockScreen();
            this.$emit('updateNotes');
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.noteDataValidation = {
          body: getValidationState(this.noteData?.body),
          date: getValidationState(this.noteData?.date),
        };
      }
    },
    isValidData() {
      return hasData(this.noteData?.body)
        && hasData(this.noteData?.date);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

export default class Supplier {
  constructor(supplierData) {
    this.uuid = supplierData.uuid;
    this.name = supplierData.name;
    this.companyName = supplierData.companyName;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getCompanyName() {
    return this.companyName;
  }
}

import Supplier from '../models/Supplier';
import SupplierReport from '../models/SupplierReport';
import Assignation from '../models/Assignation';

class SupplierFactory {
  constructor() {
    this.suppliers = [];
    this.supplierReport = {};
  }

  getSuppliersRequest(data) {
    const suppliersRequest = {
      suppliers: this.getSuppliers(data?.collection),
    };

    return suppliersRequest;
  }

  getSuppliers(supplierData) {
    this.suppliers = supplierData.map((supplier) => {
      const newSupplier = new Supplier({
        uuid: supplier.uuid,
        name: supplier.name,
        companyName: supplier.companyName,
      });

      return newSupplier;
    });

    return this.suppliers;
  }

  getSupplierReport(supplierReportData) {
    this.supplierReport = new SupplierReport({
      suppliers: supplierReportData.suppliers,
      assignations: supplierReportData.assignations.map((assignation) => {
        const newAssignation = new Assignation({
          uuid: assignation.uuid,
          name: assignation.name,
          totalOffices: assignation.totalOffices,
        });

        return newAssignation;
      }),
    });

    return this.supplierReport;
  }
}

export default new SupplierFactory();

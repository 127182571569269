<template>
  <div class="Revisions">
    <p>Revisiones</p>
    <div class="Separator__vertical__st"/>
    <p v-if="!hasRevisions">No hay revisiones asociadas.</p>
    <b-table
      v-if="!isShowedLoader && hasRevisions"
      :items="this.getRevisionsData"
      :fields="revisionFields"
    >
      <template #cell(receivedDate)="data">
        {{ data.item.receivedDate | formatDate }}
      </template>
      <template #cell(revisedDate)="data">
        {{ data.item.revisedDate | formatDate }}
      </template>
      <template #cell(approvedDate)="data">
        {{ data.item.approvedDate | formatDate }}
      </template>
      <template #cell(acciones)="data">
        <div
          v-if="$store.getters.hasAdminPermission"
          class="actionGroup"
        >
          <ActionIconButton
            icon="edit.svg"
            type="edit"
            :handler="editRevision"
            :objectData="data.item"
          />
          <ActionIconButton
            icon="delete.svg"
            type="delete"
            :handler="deleteRevision"
            :objectData="data.item"
          />
        </div>
      </template>
    </b-table>
    <AddRevisionForm
      v-if="isShowedAddForm"
      :showModal="true"
      @cancel="closeAddForm"
      :handleClose="closeAddForm"
      :officeUuid="officeUuid"
      @updateRevisions="getRevisions"
    />
    <UpdateRevisionForm
      v-if="isShowedUpdateForm"
      :showModal="true"
      @cancel="closeUpdateForm"
      :handleClose="closeUpdateForm"
      :officeUuid="officeUuid"
      @updateRevisions="getRevisions"
      :revisionData="currentRevision"
    />
    <div
      v-if="isShowedLoader"
      class="d-flex justify-content-center mb-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import { getRevisionListData } from '@/helpers/PhaseHelper';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddRevisionForm from './AddRevisionForm.vue';
import UpdateRevisionForm from './UpdateRevisionForm.vue';

export default {
  name: 'Revisions',
  components: {
    AddRevisionForm,
    UpdateRevisionForm,
    ActionIconButton,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    phaseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoadingRevisions: false,
      revisionFields: [
        { key: ' ', class: 'status-header' },
        { key: 'receivedDate', label: 'Fecha Recepción', class: 'dates-header' },
        { key: 'revisedDate', label: 'Fecha Revisión', class: 'dates-header' },
        { key: 'approvedDate', label: 'Fecha Aprobación', class: 'dates-header' },
        { key: 'acciones' },
      ],
      isShowedAddForm: false,
      isShowedUpdateForm: false,
      currentRevision: {
        uuid: null,
        receivedDate: null,
        revisedDate: null,
        approvedDate: null,
      },
    };
  },
  methods: {
    showAddForm() {
      this.isShowedAddForm = true;
    },
    closeAddForm() {
      this.isShowedAddForm = false;
    },
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
    showLoader() {
      this.isLoadingRevisions = true;
    },
    hideLoader() {
      this.isLoadingRevisions = false;
    },
    setCurrentRevision(objectData) {
      this.currentRevision = {
        uuid: objectData.uuid,
        receivedDate: objectData.receivedDate,
        revisedDate: objectData.revisedDate,
        approvedDate: objectData.approvedDate,
      };
    },
    editRevision(objectData) {
      this.setCurrentRevision(objectData);

      this.showUpdateForm();
    },
    deleteRevision(objectData) {
      this.blockScreen();

      const { officeUuid } = this;
      const revisionUuid = objectData?.uuid;

      if (revisionUuid !== 'undefined') {
        this.$store.dispatch('deleteRevision', { officeUuid, revisionUuid })
          .then(() => {
            this.unBlockScreen();
            this.getRevisions();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    getRevisions() {
      const uuid = this.officeUuid;

      this.showLoader();

      const officeData = this.$store.getters.getOffices.find((office) => office.uuid === uuid);

      this.$store.dispatch('getPhaseByOffice', officeData)
        .then(() => {
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
  },
  computed: {
    isShowedLoader() {
      return this.isLoadingRevisions;
    },
    getRevisionsData() {
      return getRevisionListData(this.phaseData);
    },
    hasRevisions() {
      return getRevisionListData(this.phaseData).length > 0;
    },
  },
};
</script>

<style scoped lang="sass">
  ::v-deep
    .status-header
      width: 140px

    .dates-header
      width: 15%
</style>

import axios from '../services/index';
import AuthFactory from '../domain/services/AuthFactory';

const AUTH = '/auth';

class AuthAPI {
  constructor() {
    this.client = axios;
  }

  getJWTToken(credentials) {
    return this.client
      .post(AUTH, credentials)
      .then((res) => AuthFactory.getAuth(res.data))
      .catch((error) => Promise.reject(error));
  }
}

export default AuthAPI;

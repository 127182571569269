export default class PicRevisionReport {
  constructor(picRevisionData) {
    this.uuid = picRevisionData.uuid;
    this.name = picRevisionData.name;
    this.activeOfficesCount = picRevisionData.activeOfficesCount;
    this.receivedCount = picRevisionData.receivedCount;
    this.revisedCount = picRevisionData.revisedCount;
    this.revisionCount = picRevisionData.revisionCount;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getActiveOfficesCount() {
    return this.activeOfficesCount;
  }

  get getReceivedCount() {
    return this.receivedCount;
  }

  get getRevisedCount() {
    return this.revisedCount;
  }

  get getRevisionCount() {
    return this.revisionCount;
  }
}

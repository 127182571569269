import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../components/organisms/Dashboard/Dashboard.vue';
import ProjectsAdmin from '../components/organisms/Projects/ProjectsAdmin.vue';
import Consultation from '../components/organisms/Projects/Consultation.vue';
import Planning from '../components/organisms/Projects/Planning.vue';
import Users from '../components/organisms/Users/Users.vue';
import Companies from '../components/organisms/Companies/Companies.vue';
import Supplier from '../components/organisms/Supplier/Supplier.vue';
import Offices from '../components/organisms/Offices/Offices.vue';
import IssuesView from '../components/organisms/Issues/IssuesView.vue';
import LoginView from '../components/organisms/LoginView/LoginView.vue';
import ResetPasswordView from '../components/organisms/ResetPasswordView/ResetPasswordView.vue';
import OfficeStatusReportView from '../components/organisms/Reports/OfficeStatusReportView.vue';
import IssueSupplierReportView from '../components/organisms/Reports/IssueSupplierReportView.vue';
import IssueReasonReportView from '../components/organisms/Reports/IssueReasonReportView.vue';
import OfficeInstallationReportView
from '../components/organisms/Reports/OfficeInstallationReportView.vue';
import OfficeCertificationReportView
from '../components/organisms/Reports/OfficeCertificationReportView.vue';
import PlanningReportView from '../components/organisms/Reports/PlanningReportView.vue';
import PicRevisionReportView from '../components/organisms/Reports/PicRevisionReportView.vue';
import OfficeImporterView from '../components/organisms/Importers/OfficeImporterView.vue';
import MeasurementImporterView from '../components/organisms/Importers/MeasurementImporterView.vue';
import BudgetImporterView from '../components/organisms/Importers/BudgetImporterView.vue';
import PicImporterView from '../components/organisms/Importers/PicImporterView.vue';
import CertificationImporterView
from '../components/organisms/Importers/CertificationImporterView.vue';
import InstallationImporterView
from '../components/organisms/Importers/InstallationImporterView.vue';
import ForecastImporterView from '../components/organisms/Importers/ForecastImporterView.vue';
import Templates from '../components/organisms/Templates/Templates.vue';
import store from '../store/index';
import Events from '@/components/organisms/Events/Events.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/projects-admin',
    name: 'ProjectsAdmin',
    component: ProjectsAdmin,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/consultation',
    name: 'Consultation',
    component: Consultation,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/planning',
    name: 'Planning',
    component: Planning,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/supplier',
    name: 'Supplier',
    component: Supplier,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/offices',
    name: 'Offices',
    component: Offices,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/issues',
    name: 'IssuesView',
    component: IssuesView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/office-status-report',
    name: 'OfficeStatusReportView',
    component: OfficeStatusReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/issue-supplier-report',
    name: 'IssueSupplierReportView',
    component: IssueSupplierReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/issue-reason-report',
    name: 'IssueReasonReportView',
    component: IssueReasonReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/office-installation-report',
    name: 'OfficeInstallationReportView',
    component: OfficeInstallationReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/office-certification-report',
    name: 'OfficeCertificationReportView',
    component: OfficeCertificationReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/planning-report',
    name: 'PlanningReportView',
    component: PlanningReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/office-importer',
    name: 'OfficeImporterView',
    component: OfficeImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/measurement-importer',
    name: 'MeasurementImporterView',
    component: MeasurementImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/budget-importer',
    name: 'BudgetImporterView',
    component: BudgetImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/pic-importer',
    name: 'PicImporterView',
    component: PicImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/certification-importer',
    name: 'CertificationImporterView',
    component: CertificationImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/installation-importer',
    name: 'InstallationImporterView',
    component: InstallationImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/forecast-importer',
    name: 'ForecastImporterView',
    component: ForecastImporterView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/pic-revision-report',
    name: 'PicRevisionReportView',
    component: PicRevisionReportView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      isPublic: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const routeIsPublic = to.matched.some((record) => record.meta.isPublic);
  if (routeIsPublic) {
    next();
    return;
  }

  if (store.getters.isUserLogged) {
    store.commit('loadUser');
    next();
    return;
  }

  next({ name: 'Login' });
});

export default router;

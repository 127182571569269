<template>
  <div class="Header">
    <button
      v-if="isPrivate"
      class="Header__hamburger"
      @click="this.changeShowNavMenu"
    >
      <img src="@/assets/navigation-menu-1.svg" />
    </button>
    <img class="Header__logo" src="@/assets/logo.png">
    <UserMenuAction v-if="isPrivate" />
  </div>
</template>

<script>
import UserMenuAction from '@/components/atoms/User/UserMenuAction.vue';

export default {
  name: 'Header',
  props: {
    isPrivate: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UserMenuAction,
  },
  methods: {
    changeShowNavMenu() {
      this.$store.commit('changeShowNavMenu');
    },
  },
};
</script>

<style lang="sass">
  @import './Header.sass'
</style>

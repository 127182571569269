export default class IssueSupplierReport {
  constructor(issueSupplierReportData) {
    this.uuid = issueSupplierReportData.uuid;
    this.name = issueSupplierReportData.name;
    this.issuesCount = issueSupplierReportData.issuesCount;
    this.officesCount = issueSupplierReportData.officesCount;
    this.solvedIssuesCount = issueSupplierReportData.solvedIssuesCount;
    this.solvedIssuesOfficesCount = issueSupplierReportData.solvedIssuesOfficesCount;
    this.notSolvedIssuesCount = issueSupplierReportData.notSolvedIssuesCount;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getIssuesCount() {
    return this.issuesCount;
  }

  get getOfficesCount() {
    return this.officesCount;
  }

  get getSolvedIssuesCount() {
    return this.solvedIssuesCount;
  }

  get getSolvedIssuesOfficesCount() {
    return this.solvedIssuesOfficesCount;
  }

  get getNotSolvedIssuesCount() {
    return this.notSolvedIssuesCount;
  }
}

export default function toQuerystring(obj) {
  const params = [];

  Object.keys(obj).forEach((p) => {
    if (obj[p] === null) {
      return;
    }
    const key = encodeURIComponent(p);
    const value = encodeURIComponent(obj[p]);
    params.push(`${key}=${value}`);
  });

  return params.join('&');
}

export function slugify(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

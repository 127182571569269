<template>
  <form>
    <b-row>
      <b-col class="col-12">
        <b-form-group
          label="Fecha Recepción"
        >
          <b-form-datepicker
            id="receivedDate"
            v-model="receivedDate"
            placeholder="Fecha Recepción"
            @context="onChangeReceivedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.revisionDataValidation.receivedDate"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="Separator__vertical__st"/>
    <b-row>
      <b-col class="col-12">
        <b-form-group
          label="Fecha Revisión"
          :hidden="!this.$store.getters.hasAdminPermission"
        >
          <b-form-datepicker
            id="revisedDate"
            v-model="revisedDate"
            placeholder="Fecha Revisión"
            @context="onChangeRevisedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.revisionDataValidation.revisedDate"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="Separator__vertical__st"/>
    <b-row>
      <b-col class="col-12">
        <b-form-group
          label="Fecha Aprobación"
          :hidden="!this.$store.getters.hasAdminPermission"
        >
          <b-form-datepicker
            id="approvedDate"
            v-model="approvedDate"
            placeholder="Fecha Aprobación"
            @context="onChangeAprovedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.revisionDataValidation.approvedDate"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>

<script>

export default {
  name: 'FormRevisionContent',
  components: { },
  props: {
    revisionData: {
      type: Object,
      default: () => {},
    },
    revisionDataValidation: Object,
  },
  data() {
    return {
      receivedDate: this.revisionData.receivedDate,
      revisedDate: this.revisionData.revisedDate,
      approvedDate: this.revisionData.approvedDate,
    };
  },
  methods: {
    onChangeReceivedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeReceivedDate', data.activeYMD);
      }
    },
    onChangeRevisedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeRevisedDate', data.activeYMD);
      }
    },
    onChangeAprovedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeAprovedDate', data.activeYMD);
      }
    },
  },
};
</script>

import { getFormattedNumbers } from '../../helpers/MathHelper';

export default class ProjectReport {
  constructor(projectReportData) {
    this.amount = projectReportData.amount;
    this.officeNumber = projectReportData.officeNumber;
    this.averageAmount = projectReportData.averageAmount;
    this.activeOfficeNumber = projectReportData.activeOfficeNumber;
  }

  get getAmount() {
    return this.amount;
  }

  get getOfficeNumber() {
    return this.officeNumber;
  }

  get getAverageAmount() {
    return this.averageAmount;
  }

  getFormattedAmount() {
    return getFormattedNumbers(this.amount);
  }

  getFormattedAverage() {
    return getFormattedNumbers(this.averageAmount);
  }

  get getActiveOfficeNumber() {
    return this.activeOfficeNumber;
  }
}

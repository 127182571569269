<!-- eslint-disable max-len -->
<template>
  <private-template>
    <template v-slot:private-template>
      <div class="Dashboard viewContainer">
        <TitleView title="Home"/>
        <div class="viewContainer__content">
          <b-row>
            <b-col>
              <TableContainer
                title="Selecciona un proyecto"
                subtitle="Selecciona un proyecto para seleccionar datos individualizados al proyecto."
              >
                <div class="table__container">
                  <b-row>
                    <b-col class="col-12 col-lg-6">
                      <SelectorProject />
                    </b-col>
                  </b-row>
                </div>
              </TableContainer>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="!$store.getters.hasActiveProject">
                <div class="container__simple">
                  ¡Vaya! Tienes que seleccionar un proyecto para consultarlo.
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="$store.getters.hasActiveCompany && $store.getters.hasActiveProject">
            <b-col class="col-12 col-lg-6">
              <ProjectReport />
              <SupplierReport v-if="!$store.getters.isSupplier" />
              <OfficeInstallationReport v-if="!$store.getters.isSupplier" />
            </b-col>
            <b-col class="col-12 col-lg-6">
              <OfficeStatusReport/>
              <IssueSupplierReport  v-if="!$store.getters.isSupplier" />
            </b-col>
          </b-row>
          <b-row v-if="$store.getters.hasActiveCompany && $store.getters.hasActiveProject">
            <b-col>
              <OfficeCertificationReport  v-if="!$store.getters.isSupplier"/>
              <IssueReasonReport  v-if="!$store.getters.isSupplier" />
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import ProjectReport from '@/components/molecules/Dashboard/ProjectReport.vue';
import SupplierReport from '@/components/molecules/Dashboard/SupplierReport/SupplierReport.vue';
import OfficeInstallationReport from '@/components/molecules/Dashboard/OfficeInstallationReport.vue';
import OfficeCertificationReport from '@/components/molecules/Dashboard/OfficeCertificationReport.vue';
import IssueSupplierReport from '@/components/molecules/Dashboard/IssueSupplierReport.vue';
import IssueReasonReport from '@/components/molecules/Dashboard/IssueReasonReport.vue';
import OfficeStatusReport from '@/components/molecules/Dashboard/OfficeStatusReport.vue';

export default {
  name: 'Dashboard',
  components: {
    PrivateTemplate,
    TitleView,
    TableContainer,
    SelectorProject,
    ProjectReport,
    SupplierReport,
    OfficeInstallationReport,
    OfficeCertificationReport,
    IssueSupplierReport,
    IssueReasonReport,
    OfficeStatusReport,
  },
  data() {
    return {
      supplierReportData: {
        supplier: '',
        assignations: [],
      },
    };
  },
};
</script>

<style lang="sass">
  @import './Dashboard.sass'
</style>

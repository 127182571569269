/* eslint-disable max-len */
import Vue from 'vue';
import { getDefaultState } from '@/store/state';
import { getFormattedFloatNumbersFromBackEnd } from '@/helpers/MathHelper';
import StorageManager from '../helpers/StorageManager';
import { getUserRole, getUserUuid } from '../helpers/UserHelper';
import { breakpoint } from '../helpers/SizeHelper';
import OfficeFactory from '../domain/services/OfficeFactory';
import { getErrorMessage } from '@/helpers/ErrorHelper';

function updatePhase(state) {
  for (let i = 0; i < state.offices.offices.length; i += 1) {
    if (state.offices.offices[i].uuid === state.office.uuid) {
      Vue.set(state.offices.offices, i, state.office);
      break;
    }
  }
}

function getOfficeIndex(state, officeUuid) {
  for (let i = 0; i < state.offices.offices.length; i += 1) {
    if (state.offices.offices[i].uuid === officeUuid) {
      return i;
    }
  }

  return null;
}

function logoutActions(state) {
  StorageManager.removeLocalData();
  Object.assign(state, getDefaultState());
  window.location.href = '/';
}

export default {
  showFullMenuBar(state) {
    state.showFullMenuBar = true;
  },
  changeShowFullMenuBar(state) {
    state.showFullMenuBar = !state.showFullMenuBar;
  },
  setMenuSelected(state, data) {
    if (data.screenWidth < breakpoint) {
      state.isShowedNavMenu = false;
    }

    state.menuSelected = data.url;
  },
  login(state, auth) {
    const accessToken = auth.token;
    const { refreshToken } = auth;

    state.auth = auth;

    StorageManager.saveToken(accessToken);
    StorageManager.saveRefreshToken(refreshToken);
  },
  showLoadderScreen(state) {
    state.isShowedLoadderScreen = true;
  },
  hideLoadderScreen(state) {
    state.isShowedLoadderScreen = false;
  },
  loadUser(state) {
    const role = getUserRole(state.auth.token);
    const uuid = getUserUuid(state.auth.token);

    state.user.role = role;
    state.user.uuid = uuid;
  },
  setActiveCompany(state, company) {
    state.activeCompany = company;
    StorageManager.saveCompany(company);
  },
  setActiveProject(state, project) {
    state.activeProject = project?.uuid;
    state.activeProjectName = project?.name;
    StorageManager.saveProject(project?.uuid);
    StorageManager.saveProjectName(project?.name);
  },
  setActiveOfficeType(state, officeType) {
    state.activeOfficeType = officeType;
  },
  removeActiveCompany(state) {
    state.activeCompany = null;
    StorageManager.removeCompany();
  },
  removeActiveProject(state) {
    state.activeProject = null;
    StorageManager.removeProject();
  },
  emptyOfficeTypes(state) {
    state.officeTypes = [];
  },
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setOfficesList(state, officesList) {
    state.offices = officesList;
  },
  emptyOfficesList(state) {
    state.offices = [];
  },
  setOffice(state, office) {
    state.office = office;
  },
  setLotsList(state, lotsList) {
    state.lotList = lotsList;
  },
  updateMeasurementOffice(state, measurementPhaseData) {
    if (Object.keys(state.office).length !== 0) {
      state.office.measurementPhase.status = measurementPhaseData.res.status;
      state.office.measurementPhase.performanceDate = measurementPhaseData.res.performanceDate;

      updatePhase(state);
    } else {
      const officeIndex = getOfficeIndex(state, measurementPhaseData.officeUuid);

      if (officeIndex !== null) {
        state.offices.offices[officeIndex].measurementPhase.status = measurementPhaseData.res.status;
        state.offices.offices[officeIndex].measurementPhase.performanceDate = measurementPhaseData.res.performanceDate;
      }
    }
  },
  updateBudgetOffice(state, budgetPhaseData) {
    if (Object.keys(state.office).length !== 0) {
      state.office.budgetPhase.status = budgetPhaseData.res.status;
      state.office.budgetPhase.performanceDate = budgetPhaseData.res.performanceDate;
      state.office.budgetPhase.revisedDate = budgetPhaseData.res.revisedDate;
      state.office.budgetPhase.approvedDate = budgetPhaseData.res.approvedDate;
      state.office.budgetPhase.amount = getFormattedFloatNumbersFromBackEnd(budgetPhaseData.res.amount);
      state.office.amount = getFormattedFloatNumbersFromBackEnd(budgetPhaseData.res.amount);
      state.office.budgetPhase.orderNumber = budgetPhaseData.res.orderNumber;
      state.office.budgetPhase.comments = budgetPhaseData.res.comments;

      updatePhase(state);
    } else {
      const officeIndex = getOfficeIndex(state, budgetPhaseData.officeUuid);

      state.offices.offices[officeIndex].budgetPhase.status = budgetPhaseData.res.status;
      state.offices.offices[officeIndex].budgetPhase.performanceDate = budgetPhaseData.res.performanceDate;
      state.offices.offices[officeIndex].budgetPhase.revisedDate = budgetPhaseData.res.revisedDate;
      state.offices.offices[officeIndex].budgetPhase.approvedDate = budgetPhaseData.res.approvedDate;
      state.offices.offices[officeIndex].budgetPhase.amount = getFormattedFloatNumbersFromBackEnd(budgetPhaseData.res.amount);
      state.offices.offices[officeIndex].amount = getFormattedFloatNumbersFromBackEnd(budgetPhaseData.res.amount);
      state.offices.offices[officeIndex].budgetPhase.orderNumber = budgetPhaseData.res.orderNumber;
      state.offices.offices[officeIndex].budgetPhase.comments = budgetPhaseData.res.comments;
      // if (Object.keys(state.office).length !== 0) {
      //   state.office = Object.assign(state.office, state.offices.offices[officeIndex]);
      // }
    }
  },
  updatePicOffice(state, picPhaseData) {
    if (Object.keys(state.office).length !== 0) {
      state.office.picPhase.status = picPhaseData.res.status;
      state.office.picPhase.receivedDate = picPhaseData.res.receivedDate;
      state.office.picPhase.revisedDate = picPhaseData.res.revisedDate;
      state.office.picPhase.approvedDate = picPhaseData.res.approvedDate;

      updatePhase(state);
    } else {
      const officeIndex = getOfficeIndex(state, picPhaseData.officeUuid);

      state.offices.offices[officeIndex].picPhase.status = picPhaseData.res.status;
      state.offices.offices[officeIndex].picPhase.receivedDate = picPhaseData.res.receivedDate;
      state.offices.offices[officeIndex].picPhase.revisedDate = picPhaseData.res.revisedDate;
      state.offices.offices[officeIndex].picPhase.approvedDate = picPhaseData.res.approvedDate;
    }
  },
  updateCertificationOffice(state, certificationPhaseData) {
    if (Object.keys(state.office).length !== 0) {
      state.office.certificationPhase.status = certificationPhaseData.res.status;
      state.office.certificationPhase.receivedDate = certificationPhaseData.res.receivedDate;
      state.office.certificationPhase.revisedDate = certificationPhaseData.res.revisedDate;
      state.office.certificationPhase.approvedDate = certificationPhaseData.res.approvedDate;
      state.office.certificationPhase.comments = certificationPhaseData.res.comments;

      updatePhase(state);
    } else {
      const officeIndex = getOfficeIndex(state, certificationPhaseData.officeUuid);

      state.offices.offices[officeIndex].certificationPhase.status = certificationPhaseData.res.status;
      state.offices.offices[officeIndex].certificationPhase.receivedDate = certificationPhaseData.res.receivedDate;
      state.offices.offices[officeIndex].certificationPhase.revisedDate = certificationPhaseData.res.revisedDate;
      state.offices.offices[officeIndex].certificationPhase.approvedDate = certificationPhaseData.res.approvedDate;
      state.offices.offices[officeIndex].certificationPhase.comments = certificationPhaseData.res.comments;
    }
  },
  updateInstallationOffice(state, installationPhaseData) {
    if (Object.keys(state.office).length !== 0) {
      state.office.installationPhase.status = installationPhaseData.res.status;
      state.office.installationPhase.startDate = installationPhaseData.res.startDate;
      state.office.installationPhase.endDate = installationPhaseData.res.endDate;
      state.office.installationPhase.stopPermitDate = installationPhaseData.res.stopPermitDate;
      state.office.installationPhase.comments = installationPhaseData.res.comments;

      updatePhase(state);
    } else {
      const officeIndex = getOfficeIndex(state, installationPhaseData.officeUuid);

      state.offices.offices[officeIndex].installationPhase.status = installationPhaseData.res.status;
      state.offices.offices[officeIndex].installationPhase.startDate = installationPhaseData.res.startDate;
      state.offices.offices[officeIndex].installationPhase.endDate = installationPhaseData.res.endDate;
      state.offices.offices[officeIndex].installationPhase.stopPermitDate = installationPhaseData.res.stopPermitDate;
      state.offices.offices[officeIndex].installationPhase.comments = installationPhaseData.res.comments;
    }
  },
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers;
  },
  setOfficeTypes(state, types) {
    const officeTypesAsOptions = [
      { value: null, text: 'Tipo de oficina' },
      ...types.map((type) => ({
        value: type,
        text: type,
      })),
    ];

    state.officeTypes = officeTypesAsOptions;
    StorageManager.saveOfficeTypes(officeTypesAsOptions);
  },
  updateOfficeList(state, office) {
    for (let i = 0; i < state.offices.offices.length; i += 1) {
      if (state.offices.offices[i].uuid === office.uuid) {
        const newOffice = office.officeData;
        newOffice.uuid = office.uuid;
        newOffice.project = office.officeData.projectObject;
        newOffice.supplier = office.officeData.supplierObject;

        const newOffice2 = OfficeFactory.getOffice(newOffice);

        Vue.set(state.offices.offices, i, newOffice2);
        break;
      }
    }
  },
  deleteOffice(state, uuid) {
    for (let i = 0; i < state.offices.offices.length; i += 1) {
      if (state.offices.offices[i].uuid === uuid) {
        state.offices.offices.splice(i, 1);
        break;
      }
    }
  },
  changeShowNavMenu(state) {
    state.isShowedNavMenu = !state.isShowedNavMenu;
  },
  showNavMenu(state) {
    state.isShowedNavMenu = true;
  },
  hideShowNavMenu(state) {
    state.isShowedNavMenu = false;
  },
  updatePhases(state, phases) {
    state.office.measurementPhase = phases.measurementPhase;
    state.office.budgetPhase = phases.budgetPhase;
    state.office.picPhase = phases.picPhase;
    state.office.installationPhase = phases.installationPhase;
    state.office.certificationPhase = phases.certificationPhase;
  },
  setIssueReasons(state, reasons) {
    state.issueReasons = reasons;
  },
  logout(state) {
    logoutActions(state);
  },
  setErrors(state, error) {
    if (error.response.status === 401) {
      logoutActions(state);
      return;
    }

    if (error.response.status === 500
      || error.response.status === 403
      || error.response.status === 404
    ) {
      state.errors = [{
        field: 'Error',
        message: error.response.status === 403
          ? getErrorMessage(error.response.data.errors)
          : error.response.statusText,
      }];

      state.showErrors = true;
      return;
    }

    const errors = error.response?.data?.errors;

    if (errors) {
      if (typeof errors === 'string') {
        state.errors = [{
          field: 'Error',
          message: errors,
        }];
      } else {
        state.errors = errors;
      }

      state.showErrors = true;
    }
  },
  setInportErrors(state, errors) {
    const newErrors = errors.map((error) => {
      if (typeof error?.errors === 'string') {
        const newError = {
          field: error.message,
          message: error.errors,
        };

        return newError;
      }

      const newError = {
        field: error.message,
        message: error.errors[0]?.message,
      };

      return newError;
    });

    state.errors = newErrors;
    state.showErrors = true;
  },
  resetErrors(state) {
    state.errors = [];
    state.showErrors = false;
  },
  setIssuesList(state, issuesList) {
    state.issues = issuesList;
  },
  emptyIssuesList(state) {
    state.issues = [];
  },
  setIssuesCount(state, issuesCount) {
    state.issuesCount = issuesCount;
  },
  setNotesCount(state, notesCount) {
    state.notesCount = notesCount;
  },
  setClients(state, clients) {
    state.clients = clients;
  },
  emptyOffice(state) {
    state.office = {};
  },
  setUserData(state, userData) {
    state.user.email = userData.email;
    state.user.name = userData.name;
    state.user.surnames = userData.surnames;
    state.user.supplier = userData.supplier.uuid;
  },

  setAlerts(state, eventsData) {
    state.alerts = eventsData;
  },
};

import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';

Vue.use(Vuex);

export function isUserLogged() {
  return state.auth.token !== null;
}

export function getUser() {
  return state.user;
}

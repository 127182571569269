<template>
  <ReportView title="Informe control revisiones PIC">
    <PicRevisionReport/>
  </ReportView>
</template>

<script>
import PicRevisionReport from '@/components/molecules/Reports/PicRevisionReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'PicRevisionReportView',
  components: {
    PicRevisionReport,
    ReportView,
  },
};
</script>

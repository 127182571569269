<template>
  <div class="TextGroup">
    <p class="TextGroup__label">{{ label }}</p>
    <p class="TextGroup__text">{{ text }}</p>
  </div>
</template>

<script>

export default {
  name: 'TextGroup',
  props: {
    label: String,
    text: String,
  },
};
</script>

<style lang="sass">
  @import './TextGroup.sass'
</style>

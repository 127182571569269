import { getFormattedFloatNumbersFromBackEnd } from '@/helpers/MathHelper';
import Office from '../models/Office';
import MeasurementPhase from '../models/Phase/MeasurementPhase';
import PicPhase from '../models/Phase/PicPhase';
import StandardPhase from '../models/Phase/StandardPhase';
import BudgetPhase from '../models/Phase/BudgetPhase';
import Supplier from '../models/Supplier';
import Project from '../models/Project';
import Company from '../models/Company';
import Pagination from '../models/Pagination';
import PhaseRevision from '../models/Phase/PhaseRevision';
import InstallationPhase from '../models/Phase/InstallationPhase';
import { PHASE, getPhaseLabel } from '../../helpers/PhaseHelper';
import Documents from '../models/Phase/Documents';
import OfficeReport from '../models/OfficeReport';
import OfficeInstallationReport from '../models/OfficeInstallationReport';
import OfficeCertificationReport from '../models/OfficeCertificationReport';
import OfficeStatusReport from '../models/OfficeStatusReport';
import OfficeStatusReportTotals from '../models/OfficeStatusReportTotals';
import ImportResponse from '../models/Importers/ImportResponse';
import PicRevisionReport from '../models/PicRevisionReport';

const TOTAL_FOOTER = 'totals';
const TOTAL_FOOTER_TRANSLATION = 'Total general';

class OfficeFactory {
  constructor() {
    this.offices = [];
    this.pagination = [];
    this.office = {};
    this.officeReport = {};
    this.officeInstallationReport = {};
    this.officeInstallationReports = {};
    this.officeStatusReport = {};
  }

  getAllOfficesRequest(data) {
    const usersRequest = {
      offices: this.getOffices(data?.collection),
      pagination: this.getPagination(data?.pagination),
    };

    return usersRequest;
  }

  // eslint-disable-next-line class-methods-use-this
  getDocument(document) {
    return new Documents({
      uuid: document.id,
      phaseUuid: document.id,
      originalName: document.originalName,
      fileName: document.fileName,
      uploadedAt: document.uploadedAt,
    });
  }

  getOffice(office) {
    const measurementPhase = new MeasurementPhase({
      uuid: office.measurementPhase.uuid,
      name: getPhaseLabel(PHASE.MEASUREMENT.value),
      status: office.measurementPhase.status,
      documents: office.measurementPhase.documents.map((document) => this.getDocument(document)),
      performanceDate: office.measurementPhase.performanceDate,
    });

    const revisions = office.picPhase.revisions.map((revision) => new PhaseRevision({
      uuid: revision.uuid,
      receivedDate: revision.receivedDate,
      revisedDate: revision.revisedDate,
      approvedDate: revision.approvedDate,
    }));

    const picPhase = new PicPhase({
      uuid: office.picPhase.uuid,
      name: getPhaseLabel(PHASE.PIC.value),
      status: office.picPhase.status,
      receivedDate: office.picPhase.receivedDate,
      revisedDate: office.picPhase.revisedDate,
      approvedDate: office.picPhase.approvedDate,
      documents: office.picPhase.documents.map((document) => this.getDocument(document)),
      revisions,
    });

    const budgetPhase = new BudgetPhase({
      uuid: office.budgetPhase.uuid,
      name: getPhaseLabel(PHASE.BUDGET.value),
      status: office.budgetPhase.status,
      documents: office.budgetPhase.documents.map((document) => this.getDocument(document)),
      performanceDate: office.budgetPhase.performanceDate,
      revisedDate: office.budgetPhase.revisedDate,
      approvedDate: office.budgetPhase.approvedDate,
      amount: getFormattedFloatNumbersFromBackEnd(office.amount),
      orderNumber: office.budgetPhase.orderNumber,
      comments: office.budgetPhase.comments,
      insertCommentDate: office.budgetPhase.insertCommentDate,
    });

    const installationPhase = new InstallationPhase({
      uuid: office.installationPhase.uuid,
      name: getPhaseLabel(PHASE.INSTALLATION.value),
      status: office.installationPhase.status,
      documents: office.installationPhase.documents.map((document) => this.getDocument(document)),
      startDate: office.installationPhase.startDate,
      endDate: office.installationPhase.endDate,
      stopPermitDate: office.installationPhase.stopPermitDate,
      comments: office.installationPhase.comments,
      insertCommentDate: office.installationPhase.insertCommentDate,
    });

    const certificationPhase = new StandardPhase({
      uuid: office.certificationPhase.uuid,
      name: getPhaseLabel(PHASE.CERTIFICATION.value),
      status: office.certificationPhase.status,
      documents: office.certificationPhase.documents.map((document) => this.getDocument(document)),
      receivedDate: office.certificationPhase.receivedDate,
      revisedDate: office.certificationPhase.revisedDate,
      approvedDate: office.certificationPhase.approvedDate,
      comments: office.certificationPhase.comments,
      insertCommentDate: office.certificationPhase.insertCommentDate,
    });

    const supplier = new Supplier({
      uuid: office.supplier.uuid,
      name: office.supplier.name,
    });

    const company = new Company({
      uuid: office.project.company.uuid,
      name: office.project.company.name,
      contactInfo: office.project.company.contactInfo,
    });

    const project = new Project({
      uuid: office.project.uuid,
      name: office.project.name,
      company,
      lot: office.project.lot,
      startDateExpected: office.project.startDateExpected,
      endDateExpected: office.project.endDateExpected,
      totalAmount: getFormattedFloatNumbersFromBackEnd(office.project.totalAmount),
    });

    this.office = new Office({
      uuid: office.uuid,
      active: office.active,
      code: office.code,
      code2: office.code2,
      type: office.type,
      lot: office.lot,
      dtTerritorial: office.dtTerritorial,
      address: office.address,
      city: office.city,
      province: office.province,
      autonomousCommunity: office.autonomousCommunity,
      postalCode: office.postalCode,
      name: office.name,
      posterType: office.posterType,
      posterSize: office.posterSize,
      bannerNumber: office.bannerNumber,
      amount: getFormattedFloatNumbersFromBackEnd(office.amount),
      amountWithVAT: getFormattedFloatNumbersFromBackEnd(office.amountWithVAT),
      measurementPhase,
      picPhase,
      budgetPhase,
      installationPhase,
      certificationPhase,
      invoiced: office.invoiced,
      supplier,
      project,
    });

    return this.office;
  }

  getOffices(officeData) {
    this.offices = officeData.map((office) => this.getOffice(office));

    return this.offices;
  }

  getPagination(paginationData) {
    this.pagination = new Pagination({
      items: paginationData.items,
      totalItems: paginationData.totalItems,
      offset: paginationData.offset,
    });

    return this.pagination;
  }

  getOfficeReport(officeReportData) {
    this.officeReport = new OfficeReport({
      totalOffices: officeReportData.totalOffices,
      totalActiveOffices: officeReportData.totalActiveOffices,
    });

    return this.officeReport;
  }

  getOfficeInstallationReport(officeInstallationReportData) {
    this.officeInstallationReport = new OfficeInstallationReport({
      uuid: officeInstallationReportData.uuid,
      name: officeInstallationReportData.name,
      activeOfficesCount: officeInstallationReportData.activeOfficesCount,
      finishedInstallationCount: officeInstallationReportData.finishedInstallationCount,
      inProgressInstallationCount: officeInstallationReportData.inProgressInstallationCount,
      pendingInstallationCount: officeInstallationReportData.pendingInstallationCount,
      issueCount: officeInstallationReportData.issueCount,
      stoppedCount: officeInstallationReportData.stoppedCount,
    });

    return this.officeInstallationReport;
  }

  getOfficeInstallationReports(officeInstallationReportData) {
    this.officeInstallationReports = officeInstallationReportData.map(
      (officeInstallationReport) => this.getOfficeInstallationReport(officeInstallationReport),
    );

    return this.officeInstallationReports;
  }

  getOfficeCertificationReport(officeCertificationReportData) {
    const {
      activeOfficesCount,
      issuedOfficesCount,
      solvedIssuedOfficesCount,
    } = officeCertificationReportData;
    const name = officeCertificationReportData.name === TOTAL_FOOTER
      ? TOTAL_FOOTER_TRANSLATION
      : officeCertificationReportData.name;
    this.officeCertificationReport = new OfficeCertificationReport({
      uuid: officeCertificationReportData.uuid,
      name,
      activeOfficesCount: parseInt(activeOfficesCount, 10),
      approvedCertificationCount:
        parseInt(officeCertificationReportData.approvedCertificationCount, 10),
      finishedInstallationCount:
        parseInt(officeCertificationReportData.finishedInstallationCount, 10),
      issuedOfficesCount: parseInt(issuedOfficesCount, 10),
      pendingReceiveCertificationCount:
        parseInt(officeCertificationReportData.pendingReceiveCertificationCount, 10),
      pendingResolveCertificationCount:
        parseInt(officeCertificationReportData.pendingResolveCertificationCount, 10),
      receivedCertificationCount:
        parseInt(officeCertificationReportData.receivedCertificationCount, 10),
      revisedCertificationCount:
        parseInt(officeCertificationReportData.revisedCertificationCount, 10),
      solvedIssuedOfficesCount: parseInt(solvedIssuedOfficesCount, 10),
      percentIncidences: parseInt(activeOfficesCount, 10) === 0
        ? '0.00%'
        : `${((parseFloat(issuedOfficesCount) / parseFloat(activeOfficesCount)) * 100)
          .toFixed(2)}%`,
      percentSolvedIncidences: parseInt(issuedOfficesCount, 10) === 0
        ? '0.00%'
        : `${((parseFloat(solvedIssuedOfficesCount) / parseFloat(issuedOfficesCount)) * 100)
          .toFixed(2)}%`,
    });

    return this.officeCertificationReport;
  }

  getOfficeCertificationReports(officeCertificationReportData) {
    this.officeCertificationReports = officeCertificationReportData.map(
      (officeCertificationReport) => this.getOfficeCertificationReport(officeCertificationReport),
    );

    return this.officeCertificationReports;
  }

  getOfficeStatusReport(officeStatusReportData) {
    const measurement = new OfficeStatusReportTotals({
      totalReceived: officeStatusReportData.measurement.totalReceived,
      totalPendingReceive: officeStatusReportData.measurement.totalPendingReceive,
      totalRevised: officeStatusReportData.measurement.totalRevised,
      totalPendingLogopost: officeStatusReportData.measurement.totalPendingLogopost,
      totalPendingSupplier: officeStatusReportData.measurement.totalPendingSupplier,
      totalOK: officeStatusReportData.measurement.totalOK,
    });

    const pic = new OfficeStatusReportTotals({
      totalReceived: officeStatusReportData.pic.totalReceived,
      totalPendingReceive: officeStatusReportData.pic.totalPendingReceive,
      totalRevised: officeStatusReportData.pic.totalRevised,
      totalPendingLogopost: officeStatusReportData.pic.totalPendingLogopost,
      totalPendingSupplier: officeStatusReportData.pic.totalPendingSupplier,
      totalOK: officeStatusReportData.pic.totalOK,
    });

    const budget = new OfficeStatusReportTotals({
      totalReceived: officeStatusReportData.budget.totalReceived,
      totalPendingReceive: officeStatusReportData.budget.totalPendingReceive,
      totalRevised: officeStatusReportData.budget.totalRevised,
      totalPendingLogopost: officeStatusReportData.budget.totalPendingLogopost,
      totalPendingSupplier: officeStatusReportData.budget.totalPendingSupplier,
      totalOK: officeStatusReportData.budget.totalOK,
    });

    const certification = new OfficeStatusReportTotals({
      totalReceived: officeStatusReportData.certification.totalReceived,
      totalPendingReceive: officeStatusReportData.certification.totalPendingReceive,
      totalRevised: officeStatusReportData.certification.totalRevised,
      totalPendingLogopost: officeStatusReportData.certification.totalPendingLogopost,
      totalPendingSupplier: officeStatusReportData.certification.totalPendingSupplier,
      totalOK: officeStatusReportData.certification.totalOK,
    });

    this.officeStatusReport = new OfficeStatusReport({
      measurement,
      pic,
      budget,
      certification,
    });

    return this.officeStatusReport;
  }

  getImportResponse(uploadedOfficesData) {
    this.uploadedOffices = new ImportResponse({
      elementsImported: uploadedOfficesData.elementsImported,
      errors: uploadedOfficesData.errors,
    });

    return this.uploadedOffices;
  }

  getPicRevisionReport(picRevisionReportData) {
    this.picRevisionReport = new PicRevisionReport({
      uuid: picRevisionReportData.uuid,
      name: picRevisionReportData.name,
      activeOfficesCount: picRevisionReportData.activeOfficesCount,
      receivedCount: picRevisionReportData.receivedCount,
      revisedCount: picRevisionReportData.revisedCount,
      revisionCount: picRevisionReportData.revisionCount,
    });

    return this.picRevisionReport;
  }

  getPicRevisionReports(picRevisionReportData) {
    this.picRevisionReports = picRevisionReportData.map(
      (picRevision) => this.getPicRevisionReport(picRevision),
    );

    return this.picRevisionReports;
  }
}

export default new OfficeFactory();

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addIssue"
      @cancel="cancelAction"
    >
      <FormIssueContent
        @handleIssueData="setIssueData"
        :issueData="this.issueData"
        :issueDataValidation="this.issueDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import FormIssueContent from './FormIssueContent.vue';

export default {
  name: 'AddIssueForm',
  components: {
    Modal,
    FormCard,
    FormIssueContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    officeUuid: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    title: 'Nueva Incidencia',
    issueData: {
      openingDate: null,
      resolutionDate: null,
      reportedBy: null,
      reasonUuid: null,
      status: null,
      observations: null,
    },
    issueDataValidation: {
      openingDate: null,
      resolutionDate: null,
      reportedBy: null,
      reasonUuid: null,
      status: null,
      observations: null,
    },
  }),
  methods: {
    setIssueData(issueData) {
      this.issueData = issueData;
    },
    addIssue() {
      const requestData = this.issueData;
      if (!this.$store.getters.hasAdminPermission) {
        if (this.$store.getters.userIsClient) {
          requestData.reportedBy = 'client';
        } else {
          requestData.reportedBy = 'supplier';
        }
      }

      if (this.isValidData()) {
        this.blockScreen();

        requestData.officeUuid = this.officeUuid;

        this.$store.dispatch('addIssue', requestData)
          .then(() => {
            this.unBlockScreen();
            this.$emit('handleAddIssue');
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.issueDataValidation = {
          openingDate: getValidationState(this.issueData?.openingDate),
          reportedBy: getValidationState(this.issueData?.reportedBy),
          reasonUuid: getValidationState(this.issueData?.reasonUuid),
          status: getValidationState(this.issueData?.status),
          observations: getValidationState(this.issueData?.observations),
          name: getValidationState(this.issueData?.name),
        };
      }
    },
    isValidData() {
      return hasData(this.issueData?.openingDate)
        && hasData(this.issueData?.reportedBy)
        && hasData(this.issueData?.reasonUuid)
        && hasData(this.issueData?.status)
        && hasData(this.issueData?.observations)
        && hasData(this.issueData?.name);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

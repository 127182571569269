<template>
  <ReportView title="EstadoOficinas">
    <OfficeStatusReport/>
  </ReportView>
</template>

<script>
import OfficeStatusReport from '@/components/molecules/Dashboard/OfficeStatusReport.vue';
import ReportView from './ReportView.vue';

export default {
  name: 'OfficeStatusReportView',
  components: {
    OfficeStatusReport,
    ReportView,
  },
};
</script>

<template>
    <b-modal
      v-model="showModal"
      @ok="handleClose"
      @close="handleClose"
      centered
      ok-only
      title="Error"
    >
      <div
        v-for="error in this.$store.getters.getErrors"
        :key="error.field"
      >
        <p class="text-danger">{{ `${error.field}: ${error.message}`}}</p>
      </div>
    </b-modal>
</template>

<script>

export default {
  name: 'ErrorModal',
  props: {
    showModal: Boolean,
  },
  methods: {
    handleClose(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$store.commit('resetErrors');
    },
  },
};
</script>

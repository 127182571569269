<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addForecast"
      @cancel="cancelAction"
    >
      <ForecastFormContent
        @handleForecastData="setForecastData"
        @handleUpdatePhase="setPhaseColor"
        :forecastData="this.forecastData"
        :forecastDataValidation="this.forecastDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import ForecastFormContent from '@/components/molecules/Forecast/ForecastFormContent.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import { getPhaseColor } from '@/helpers/PhaseHelper';

export default {
  name: 'AddForecastForm',
  components: {
    Modal,
    FormCard,
    ForecastFormContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateForecasts: {},
  },
  data: () => ({
    title: 'Añadir nuevo evento al proyecto',
    forecastData: {
      startDate: null,
      endDate: null,
      phase: null,
      lot: null,
      color: null,
    },
    forecastDataValidation: {
      startDate: null,
      endDate: null,
      phase: null,
      lot: null,
      color: null,
    },
  }),
  methods: {
    setForecastData(forecastData) {
      this.forecastData = forecastData;
    },
    addForecast() {
      if (this.isValidData()) {
        this.blockScreen();

        const requestData = this.forecastData;
        requestData.project = this.$store.getters.getActiveProject;

        this.$store.dispatch('addForecast', requestData)
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateForecasts();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.forecastDataValidation = {
          startDate: getValidationState(this.forecastData?.startDate),
          endDate: getValidationState(this.forecastData?.endDate),
          phase: getValidationState(this.forecastData?.phase),
          lot: getValidationState(this.forecastData?.lot),
          color: getValidationState(this.forecastData?.color),
        };
      }
    },
    isValidData() {
      return hasData(this.forecastData?.startDate)
        && hasData(this.forecastData?.endDate)
        && hasData(this.forecastData?.phase)
        && hasData(this.forecastData?.lot)
        && hasData(this.forecastData?.color);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    setPhaseColor(phase) {
      this.forecastData.color = getPhaseColor(phase);
    },
  },
};
</script>

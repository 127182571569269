<template>
  <div class="IssueStats">
    <b-row v-if="!isLoading">
      <b-col class="col-12 col-xl-3">
        <h5>
          <b-badge :variant="getPendingVariant">
            {{ `Pendiente actuación: ${pendingAction}`}}
          </b-badge>
        </h5>
      </b-col>
      <div class="Separator__vertical__big"/>
      <b-col class="col-12 col-xl-3">
        <h5>
          <b-badge :variant="getInProgressVariant">
            {{ `En curso: ${inProgress}`}}
          </b-badge>
        </h5>
      </b-col>
      <div class="Separator__vertical__big"/>
      <b-col class="col-12 col-xl-3">
        <h5>
          <b-badge :variant="getPendingDateVariant">
            {{ `Pendiente de fecha: ${pendingDate}`}}
          </b-badge>
        </h5>
      </b-col>
      <div class="Separator__vertical__big"/>
      <b-col class="col-12 col-xl-3">
        <h5>
          <b-badge :variant="getResolvedVariant">
            {{ `Resueltas: ${resolved}`}}
          </b-badge>
        </h5>
      </b-col>
    </b-row>
    <b-container
      v-if="isLoading"
      fluid
      class="d-flex justify-content-center m-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'IssueStats',
  props: {
    supplierUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pendingAction: 0,
      inProgress: 0,
      pendingDate: 0,
      resolved: 0,
      isLoading: false,
    };
  },
  created() {

  },
  methods: {
    getStats() {
      this.showLoader();
      const companyId = this.$store.getters.getActiveCompany;
      const projectId = this.$store.getters.getActiveProject;
      const supplierId = this.$store.getters.getUserSupplier === ''
        ? this.supplierUuid
        : this.$store.getters.getUserSupplier;

      const requestData = {
        companyId,
        projectId,
        supplierId,
      };

      this.$store.dispatch('getIssueStats', requestData)
        .then((res) => {
          this.pendingAction = res.pendingAction;
          this.inProgress = res.inProgress;
          this.pendingDate = res.pendingDate;
          this.resolved = res.resolved;
          this.hideLoader();
        })
        .catch(() => {
          this.cleanStats();
          this.hideLoader();
        });
    },
    getVariant(stat) {
      return (stat !== null && stat !== 0) ? 'success' : 'secondary';
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    cleanStats() {
      this.pendingAction = 0;
      this.inProgress = 0;
      this.pendingDate = 0;
      this.resolved = 0;
    },
  },
  computed: {
    getPendingVariant() {
      return this.getVariant(this.pendingAction);
    },
    getInProgressVariant() {
      return this.getVariant(this.inProgress);
    },
    getPendingDateVariant() {
      return this.getVariant(this.pendingDate);
    },
    getResolvedVariant() {
      return this.getVariant(this.resolved);
    },
  },
};
</script>

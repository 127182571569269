import NoteFactory from '../domain/services/NoteFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';

const NOTES = '/notes';

class NoteAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllNotes(officeUuid) {
    const url = `${NOTES}?officeId=${officeUuid}`;

    return this.client
      .get(url)
      .then((res) => NoteFactory.getAllNote(res.data?.collection))
      .catch((error) => Promise.reject(error));
  }

  getNote(uuid) {
    const url = `${NOTES}/${uuid}`;

    return this.client
      .get(url)
      .then((res) => NoteFactory.getNote(res.data?.resource))
      .catch((error) => Promise.reject(error));
  }

  addNote(data) {
    return this.client
      .post(NOTES, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateNote(data) {
    const url = `${NOTES}/${data.uuid}`;

    return this.client
      .put(url, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteNote(uuid) {
    const url = `${NOTES}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default NoteAPI;

<template>
  <form>
    <b-row>
      <b-col>
        <b-form-group
          label="Comentarios"
        >
          <b-form-textarea
            id="addCommentTextarea"
            v-model="comment"
            placeholder="comentarios..."
            rows="3"
            max-rows="6"
            @change="getComment"
            :state="this.commentDataValidation.comment"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </form>
</template>

<script>
export default {
  name: 'FormCommentContent',
  props: {
    handleCommentData: {},
    commentData: {},
    commentDataValidation: Object,
  },
  data() {
    return {
      comment: this.commentData.comment,
    };
  },
  methods: {
    getComment(data) {
      this.commentData.comment = data;
      this.$emit('handleCommentData', this.commentData);
    },
  },
};
</script>

<style scoped>

</style>

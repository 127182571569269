export default class AssignationReport {
  constructor(assignationData) {
    this.uuid = assignationData.uuid;
    this.name = assignationData.name;
    this.totalOffices = assignationData.totalOffices;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getTotalOffices() {
    return this.totalOffices;
  }
}

<template>
  <div
    :class="{'NavMenu--collapsed': !$store.state.showFullMenuBar}"
    class="NavMenu"
  >
    <div class="NavMenu__collapseButtonBlock">
      <div
        :class="{'NavMenu__collapseButtonContainer--collapsed': !$store.state.showFullMenuBar}"
        class="NavMenu__collapseButtonContainer"
      >
        <CollapseButton/>
      </div>
    </div>
    <div
      v-show="showFullMenuBar"
      class="NavMenu__selector"
    >
      <CompanySelect/>
    </div>
    <hr>
    <MenuRowSimple
      icon="dashboard.svg"
      label="Home"
      path="/dashboard"
      url="Dashboard"
    />
    <MenuRow
      :submenu="projects"
      icon="project.svg"
      label="Proyectos"
      path="projects"
      url="Projects"
    />
    <MenuRowSimple
      icon="business.svg"
      label="Oficinas"
      path="/offices"
      url="Offices"
    />
    <MenuRow
      :submenu="reports"
      icon="analytics.svg"
      label="Informes"
      path="reports"
      url="Reports"
    />
    <MenuRowSimple
      icon="warning.svg"
      label="Incidencias"
      path="/issues"
      url="IssuesView"
    />
    <MenuRowSimple
      v-show="$store.getters.hasClientPermission"
      icon="alert.svg"
      label="Alertas"
      path="/events"
      url="Events"
    />
    <div class="Separator__vertical__big"/>
    <ActiveProject
      v-show="showFullMenuBar"
    />
    <hr class="Separator__vertical__big">
    <MenuRowSimple
      v-show="$store.getters.hasSuperAdminPermission"
      icon="team.svg"
      label="Usuarios"
      path="/users"
      url="Users"
    />
    <MenuRowSimple
      v-show="$store.getters.hasSuperAdminPermission"
      icon="products-briefcase.svg"
      label="Compañias"
      path="/companies"
      url="Companies"
    />
    <MenuRowSimple
      v-show="$store.getters.hasSuperAdminPermission"
      icon="user.svg"
      label="Proveedor"
      path="/supplier"
      url="Supplier"
    />
    <MenuRow
      v-show="$store.getters.hasSuperAdminPermission"
      :submenu="importers"
      icon="upload.svg"
      label="Importadores"
      path="importers"
      url="Importers"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MenuRowSimple from '@/components/atoms/MenuRow/MenuRowSimple.vue';
import MenuRow from '@/components/atoms/MenuRow/MenuRow.vue';
import CollapseButton from '@/components/atoms/CollapseButton/CollapseButton.vue';
import CompanySelect from '@/components/atoms/CompanySelect/CompanySelect.vue';
import ActiveProject from '@/components/atoms/ActiveProject/ActiveProject.vue';

const PROJECTS = [
  {
    label: 'Administrador',
    url: 'ProjectsAdmin',
    path: '/projects-admin',
  },
  {
    label: 'Consulta',
    url: 'Consultation',
    path: '/consultation',
  },
  {
    label: 'Planning',
    url: 'Planning',
    path: '/planning',
  },
];

const PROJECTS_SUPPLIER = [
  {
    label: 'Consulta',
    url: 'Consultation',
    path: '/consultation',
  },
  {
    label: 'Planning',
    url: 'Planning',
    path: '/planning',
  },
];
const REPORTS = [
  {
    label: 'Estado oficinas',
    url: 'OfficeStatusReportView',
    path: '/office-status-report',
  },
  {
    label: 'Incidencias por proveedor',
    url: 'IssueSupplierReportView',
    path: '/issue-supplier-report',
  },
  {
    label: 'Incidencias por tipología',
    url: 'IssueReasonReportView',
    path: '/issue-reason-report',
  },
  {
    label: 'Resumen fase instalación',
    url: 'OfficeInstallationReportView',
    path: '/office-installation-report',
  },
  {
    label: 'Resumen fase certificación',
    url: 'OfficeCertificationReportView',
    path: '/office-certification-report',
  },
  {
    label: 'Informe estado según planificación',
    url: 'PlanningReportView',
    path: '/planning-report',
  },
  {
    label: 'Informe control revisiones PIC',
    url: 'PicRevisionReportView',
    path: '/pic-revision-report',
  },
];

const IMPORTERS = [
  {
    label: 'Imp. Oficinas',
    url: 'OfficeImporterView',
    path: '/office-importer',
  },
  {
    label: 'Imp. Fase Medición',
    url: 'MeasurementImporterView',
    path: '/measurement-importer',
  },
  {
    label: 'Imp. Fase Presupuesto',
    url: 'BudgetImporterView',
    path: '/budget-importer',
  },
  {
    label: 'Imp. Fase PIC',
    url: 'PicImporterView',
    path: '/pic-importer',
  },
  {
    label: 'Imp. Fase Certificación',
    url: 'CertificationImporterView',
    path: '/certification-importer',
  },
  {
    label: 'Imp. Fase Instalación',
    url: 'InstallationImporterView',
    path: '/installation-importer',
  },
  {
    label: 'Imp. Eventos',
    url: 'ForecastImporterView',
    path: '/forecast-importer',
  },
  {
    label: 'Plantillas',
    url: 'Templates',
    path: '/templates',
  },
];

export default {
  name: 'NavMenu',
  components: {
    MenuRow,
    CollapseButton,
    MenuRowSimple,
    CompanySelect,
    ActiveProject,
  },
  data() {
    return {
      projects: this.$store.getters.isSupplier ? PROJECTS_SUPPLIER : PROJECTS,
      reports: REPORTS,
      importers: IMPORTERS,
    };
  },
  computed: {
    ...mapState([
      'showFullMenuBar',
    ]),
  },
};
</script>

<style lang="sass">
@import './NavMenu.sass'
</style>

export default class OfficeInstallationReport {
  constructor(officeInstallationReportData) {
    this.uuid = officeInstallationReportData.uuid;
    this.name = officeInstallationReportData.name;
    this.activeOfficesCount = officeInstallationReportData.activeOfficesCount;
    this.finishedInstallationCount = officeInstallationReportData.finishedInstallationCount;
    this.inProgressInstallationCount = officeInstallationReportData.inProgressInstallationCount;
    this.pendingInstallationCount = officeInstallationReportData.pendingInstallationCount;
    this.issueCount = officeInstallationReportData.issueCount;
    this.stoppedCount = officeInstallationReportData.stoppedCount;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getActiveOfficesCount() {
    return this.activeOfficesCount;
  }

  get getFinishedInstallationCount() {
    return this.finishedInstallationCount;
  }

  get getInProgressInstallationCount() {
    return this.inProgressInstallationCount;
  }

  get getPendingInstallationCount() {
    return this.pendingInstallationCount;
  }

  get getIssueCount() {
    return this.issueCount;
  }

  get getStoppedCount() {
    return this.stoppedCount;
  }
}

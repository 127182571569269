<template>
  <div class="Issues">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between">
          <div class="text-left w-75 issueDocumentUploader">
            <IssueDocumentUploader
              v-if="!isLoading && hasIssues"
              :officeUuid = "officeUuid"
              :options = "getOptions"
              @manageDocuments="getIssues"
            />
          </div>
          <div class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click.prevent="showAddForm"
            >
              Añadir Incidencia
            </b-button>
          </div>
        </div>
        <div class="Separator__vertical__big"/>
        <b-container
          v-if="isLoading"
          fluid
          class="d-flex justify-content-center m-3">
          <b-spinner variant="secondary" label="Spinning"></b-spinner>
        </b-container>
        <div
          v-if="hasIssues && !isLoading"
          class="table__container"
        >
          <b-table
            :items="items"
            :fields="fields"
            striped
            hover
            bordered
            @row-clicked="showCarousel"
          >
            <template #cell(openingDate)="data">
              {{ data.item.name }}
            </template>
            <template #cell(openingDate)="data">
              {{ data.item.openingDate | formatDate }}
            </template>
            <template #cell(resolutionDate)="data">
              {{ data.item.resolutionDate | formatDate }}
            </template>
            <template #cell(reportedBy)="data">
              {{ data.item.reportedBy === 'client' ? 'Cliente' : 'Proveedor' }}
            </template>
            <template #cell(reason)="data">
              {{ data.item.reason.name || '' }}
            </template>
            <template #cell(status)="data">
              {{ data.item.status | getStatusLabel }}
            </template>
            <template #cell(acciones)="data">
              <div class="actionGroup">
                <ActionIconButton
                  v-if="$store.getters.hasAdminPermission ||
                  ($store.getters.userIsClient
                  && data.item.owner.uuid === $store.getters.getUser.uuid)"
                  icon="edit.svg"
                  type="edit"
                  :handler="editIssue"
                  :objectData="data.item"
                />
                <ActionIconButton
                  v-if="$store.getters.hasAdminPermission"
                  icon="delete.svg"
                  type="delete"
                  :handler="deleteIssue"
                  :objectData="data.item"
                />
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <div class="document-container">
      <div class="carousel-container">
      <b-carousel
        v-if="!isLoadingDocuments &&
          this.carouselImages && this.currentIssue"
        no-animation
        controls
        ref="imageCarousel"
        img-width="600"
        img-height="500"
        :interval="0"
      >
        <b-carousel-slide
          v-for="document in this.carouselImages"
          :key="document.uuid"
          :id="document.uuid"
          :img-alt="getImage(document)"
          :img-src="loadedImages[document.uuid].imgSrc"
        >
        </b-carousel-slide>
      </b-carousel>
      </div>
      <div class="imageList">
        <ImageList
          v-if="!isLoadingDocuments &&
            this.carouselImages && this.currentIssue"
          :listImages="this.loadedImages"
          @setImageCarousel="setImageCarousel"/>
      </div>
      <b-container
        v-if="isLoadingDocuments"
        fluid
        class="d-flex m-3">
        <b-spinner variant="secondary" label="Spinning"></b-spinner>
      </b-container>
    </div>
    <AddIssueForm
      v-if="isShowedAddForm"
      :showModal="true"
      @handleAddIssue="getIssues"
      @cancel="closeAddForm"
      :handleClose="closeAddForm"
      :officeUuid="this.officeUuid"
    />
    <UpdateIssueForm
      v-if="isShowedUpdateForm"
      :showModal="true"
      @handleUpdateIssue="getIssues"
      @cancel="closeUpdateForm"
      :issueData="currentIssue"
      :handleClose="closeUpdateForm"
      :officeUuid="this.officeUuid"
    />
  </div>
</template>

<script>
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddIssueForm from './AddIssueForm.vue';
import UpdateIssueForm from './UpdateIssueForm.vue';
import IssueDocumentUploader from '@/components/atoms/IssueDocumentUploader/IssueDocumentUploader.vue';
import file from '@/assets/file.svg';
import ImageList from '@/components/molecules/Documents/ImageList.vue';

export default {
  name: 'Issues',
  components: {
    ImageList,
    IssueDocumentUploader,
    ActionIconButton,
    AddIssueForm,
    UpdateIssueForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'openingDate', label: 'Fecha Apertura', sortable: true },
        { key: 'resolutionDate', label: 'Fecha Resolución', sortable: true },
        { key: 'reportedBy', label: 'Reportado', sortable: true },
        { key: 'reason', label: 'Motivo', sortable: true },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'observations', label: 'Observaciones', sortable: true },
        { key: 'acciones' },
      ],
      isShowedAddForm: false,
      isShowedUpdateForm: false,
      currentIssue: {
        name: '',
        uuid: '',
        openingDate: '',
        resolutionDate: '',
        reportedBy: '',
        reasonUuid: '',
        status: '',
        observations: '',
      },
      isLoading: false,
      carouselImages: null,
      loadedImages: {},
      isLoadingDocuments: false,
    };
  },
  created() {
    this.getIssues();
  },
  methods: {
    editIssue(objectData) {
      this.currentIssue = {
        name: objectData.name,
        uuid: objectData.uuid,
        openingDate: objectData.openingDate,
        resolutionDate: objectData.resolutionDate,
        reportedBy: objectData.reportedBy,
        reasonUuid: objectData.reason.uuid,
        status: objectData.status,
        observations: objectData.observations,
      };

      this.showUpdateForm();
    },
    deleteIssue(objectData) {
      this.showLoader();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteIssue', { uuid })
          .then(() => {
            this.deleteIssueResult(uuid);
            this.hideLoader();
          })
          .catch(() => {
            this.hideLoader();
          });
      }
    },
    showAddForm() {
      this.isShowedAddForm = true;
    },
    closeAddForm() {
      this.isShowedAddForm = false;
    },
    getIssues() {
      this.showLoader();
      this.showLoaderDocuments();

      if (typeof this.officeUuid !== 'undefined' && this.officeUuid) {
        const data = {
          currentPage: 1,
          itemsPerPage: 1000,
          companyId: this.$store.getters.getActiveCompany,
          projectId: this.$store.getters.getActiveProject,
          officeId: this.officeUuid,
        };

        this.$store.dispatch('getAllIssues', data)
          .then((res) => {
            this.items = res.data;

            this.hideLoader();
            this.hideLoaderDocuments();
          })
          .catch(() => {
            this.hideLoader();
            this.hideLoaderDocuments();
          });
      }
    },
    deleteIssueResult(uuid) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].uuid === uuid) {
          this.items.splice(i, 1);
          break;
        }
      }

      this.hideLoader();
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    showLoaderDocuments() {
      this.isLoadingDocuments = true;
    },
    hideLoaderDocuments() {
      this.isLoadingDocuments = false;
    },
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
    showCarousel(item) {
      this.carouselImages = item.documents;
      this.currentIssue = {
        name: item.name,
        uuid: item.uuid,
        openingDate: item.openingDate,
        resolutionDate: item.resolutionDate,
        reportedBy: item.reportedBy,
        reasonUuid: item.reason.uuid,
        status: item.status,
        observations: item.observations,
      };
      this.loadedImages = {};
    },
    isImage(imageName) {
      const exts = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
      const ext = imageName.split('.').reverse()[0];
      return exts.indexOf(ext) > -1;
    },
    getImage(data) {
      if (this.loadedImages[data.uuid]) {
        return;
      }
      this.showLoaderDocuments();
      if (!this.isImage(data.fileName)) {
        this.$set(this.loadedImages, data.uuid,
          {
            imgSrc: file,
            name: data.originalName,
          });
        this.hideLoader();
      } else {
        const requestData = {
          officeUuid: this.officeUuid,
          issueId: this.currentIssue.uuid,
          documentUuid: data.uuid,
        };

        this.$store.dispatch('downloadIssueDocument', requestData)
          .then((res) => {
            this.$set(this.loadedImages, data.uuid,
              {
                imgSrc: window.URL.createObjectURL(res.data),
                name: data.originalName,
              });
            if (this.document === null) { // Initialize the first image of the carousel
              this.document = {
                uuid: data.uuid,
                originalName: data.originalName,
                fileName: data.fileName,
                uploadedAt: data.uploadedAt,
              };
            }
            this.hideLoaderDocuments();
          });
      }
    },
    setImageCarousel(uuid) {
      const slide = this.$refs.imageCarousel.slides.find((element) => element.id === uuid);
      this.$refs.imageCarousel.index = this.$refs.imageCarousel.slides.indexOf(slide);
      this.document = this.getDocumentsData[this.$refs.imageCarousel.index];
    },
  },
  computed: {
    hasIssues() {
      return this.items && this.items.length > 0;
    },
    getOptions() {
      return this.items.map((option) => ({
        text: option.name,
        value: option.uuid,
      }));
    },
  },
};
</script>
<style scoped lang="sass">
 .issueDocumentUploader
   height: 25px
</style>

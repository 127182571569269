<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="updateProject"
      @cancel="cancelAction"
    >
      <FormProjectContent
        @handleProjectData="setProjectData"
        :projectData="this.projectData"
        :projectDataValidation="this.projectDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormProjectContent from '@/components/molecules/Project/FormProjectContent.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';

export default {
  name: 'UpdateProjectForm',
  components: {
    Modal,
    FormCard,
    FormProjectContent,
  },
  props: {
    showModal: Boolean,
    projectData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateProjects: {},
  },
  data: () => ({
    title: 'Actualizar proyecto',
    projectDataValidation: {
      name: null,
      startDateExpected: null,
      endDateExpected: null,
      clientUuid: null,
      clients: null,
    },
  }),
  methods: {
    setProjectData(projectData) {
      this.projectData = projectData;
    },
    updateProject() {
      if (this.isValidData()) {
        this.blockScreen();
        const requestData = {
          companyUuid: this.$store.getters.getActiveCompany,
          name: this.projectData.name,
          startDateExpected: this.projectData.startDateExpected,
          endDateExpected: this.projectData.endDateExpected,
          clientUuid: this.projectData.clientUuid ? this.projectData.clientUuid : null,
          clientsUuid: this.projectData.clientsUuid,
        };

        this.$store.dispatch('updateProject', {
          uuid: this.projectData.uuid,
          projectData: requestData,
        })
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateProjects();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.projectDataValidation = {
          name: getValidationState(this.projectData?.name),
          startDateExpected: getValidationState(this.projectData?.startDateExpected),
          endDateExpected: getValidationState(this.projectData?.endDateExpected),
        };
      }
    },
    isValidData() {
      return hasData(this.projectData?.name)
        && hasData(this.projectData?.startDateExpected)
        && hasData(this.projectData?.endDateExpected);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

<template>
  <form>
    <SimpleInput
      inputLabel="Contraseña actual"
      inputId="userPasswordFormOldPass"
      inputType="password"
      inputPlaceholder="Contraseña actual"
      @handlerText="getOldPass"
      :isValid="this.userDataValidation.oldPassword"
    ></SimpleInput>
    <SimpleInput
      inputLabel="Nueva contraseña"
      inputId="userPasswordFormNewPass"
      inputType="password"
      inputPlaceholder="Nueva contraseña"
      @handlerText="getNewPass"
      :isValid="this.userDataValidation.newPassword"
    ></SimpleInput>
    <SimpleInput
      inputLabel="Repetir nueva contraseña"
      inputId="userPasswordFormRepeatNewPass"
      inputType="password"
      inputPlaceholder="Repetir nueva contraseña"
      @handlerText="getRepeatedNewPass"
      :isValid="this.userDataValidation.newPasswordRepeat"
    ></SimpleInput>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';

export default {
  name: 'FormUserPasswordContent',
  components: { SimpleInput },
  props: {
    handleUserData: {},
    userData: Object,
    userDataValidation: Object,
  },
  methods: {
    getOldPass(data) {
      this.userData.oldPassword = data;
      this.$emit('handleUserData', this.userData);
    },
    getNewPass(data) {
      this.userData.newPassword = data;
      this.$emit('handleUserData', this.userData);
    },
    getRepeatedNewPass(data) {
      this.userData.newPasswordRepeat = data;
      this.$emit('handleUserData', this.userData);
    },
  },
};
</script>

<template>
  <div class="Datepickers">
    <b-row cols="1" cols-lg="2">
      <b-col>
        <label
          for="startDate"
          class="Datepickers__label"
        >
          {{ startLabel }}
        </label>
        <b-form-datepicker
          id="startDate"
          v-model="startDate"
          :placeholder="this.startPlaceholder"
          @context="onSelectDate"
          :date-format-options="{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }"
          :state="isValidStartDate"
          start-weekday="1"
        />
        <div class="Separator__vertical__st__mobile"></div>
      </b-col>
      <b-col>
        <label
          for="endDate" class="Datepickers__label"
        >
          {{ endLabel }}
        </label>
        <b-form-datepicker
          id="endDate"
          v-model="endDate"
          :placeholder="endPlaceholder"
          @context="onSelectDate"
          :date-format-options="{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }"
          :state="isValidEndDate"
          start-weekday="1"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Datepickers',
  props: {
    startLabel: {
      type: String,
      default: 'Selecciona fecha inicio',
    },
    endLabel: {
      type: String,
      default: 'Selecciona fecha fin',
    },
    startPlaceholder: String,
    endPlaceholder: String,
    isValidStartDate: {
      type: Boolean,
      default: null,
    },
    isValidEndDate: {
      type: Boolean,
      default: null,
    },
    startDateValue: String,
    endDateValue: String,
  },
  data() {
    return {
      startDate: this.startDateValue,
      endDate: this.endDateValue,
      context: null,
    };
  },
  methods: {
    onSelectDate() {
      this.$emit('handleStartDate', this.startDate);
      this.$emit('handleEndDate', this.endDate);
    },
  },
};
</script>

<style lang="sass">
@import './Datepickers.sass'
</style>

<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualizar Pic"
      @accept="updatePic"
      @cancel="cancelAction"
    >
      <FormPicContent
        @handlePicData="setPicData"
        :picData="this.picData"
        :picDataValidation="this.validationStatus"
        @onChangeStatus="onChangeStatus"
        @onChangeReceivedDate="onChangeReceivedDate"
        @onChangeRevisedDate="onChangeRevisedDate"
        @onChangeApprovedDate="onChangeApprovedDate"
      />
      <!--        @onChangeReceivedDate="onChangeReceivedDate"-->
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import FormPicContent from './FormPicContent.vue';

export default {
  name: 'UpdatePicForm',
  components: {
    Modal,
    FormCard,
    FormPicContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    picData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateOffice: {},
  },
  data: () => ({
    picDataValidation: {
      status: null,
      receivedDate: null,
      revisedDate: null,
      approvedDate: null,
      // receivedDate: null,
    },
    validationStatus: {
      status: null,
      receivedDate: null,
    },
  }),
  mounted() {
    if (this.picData?.status) {
      this.picDataValidation.status = this.picData.status;
      this.picDataValidation.receivedDate = this.picData.receivedDate;
      this.picDataValidation.revisedDate = this.picData.revisedDate;
      this.picDataValidation.approvedDate = this.picData.approvedDate;
    }
  },
  methods: {
    setPicData(picData) {
      this.picData = picData;
    },
    updatePic() {
      if (this.isValidData()) {
        const phaseData = {
          status: this.picDataValidation.status,
          receivedDate: this.picDataValidation.receivedDate,
          revisedDate: this.picDataValidation.revisedDate,
          approvedDate: this.picDataValidation.approvedDate,
          // receivedDate: this.picDataValidation.receivedDate,
        };

        const picData = {
          officeUuid: this.officeUuid,
          phase: 'pic',
          phaseData,
        };

        this.blockScreen();

        this.$store.dispatch('updatePicPhase', {
          data: picData,
        })
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });

        this.closeForm();
      } else {
        this.validationStatus = {
          status: getValidationState(this.picDataValidation?.status),
          receivedDate: getValidationState(this.picDataValidation?.receivedDate),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.picDataValidation?.status)
        && hasData(this.picDataValidation?.receivedDate);
    },
    onChangeStatus(status) {
      this.picDataValidation.status = status;
    },
    onChangeReceivedDate(receivedDate) {
      this.picDataValidation.receivedDate = receivedDate;
    },
    onChangeRevisedDate(revisedDate) {
      this.picDataValidation.revisedDate = revisedDate;
    },
    onChangeApprovedDate(approvedDate) {
      this.picDataValidation.approvedDate = approvedDate;
    },
    // onChangeReceivedDate(receivedDate) {
    //   this.picDataValidation.receivedDate = receivedDate;
    // },
  },
};
</script>

<template>
  <Modal
    class="OfficeForm"
    v-show="showModal"
  >
    <FormCard
      :title="title"
      @accept="updateOffice"
      @cancel="cancelAction"
    >
      <FormOfficeContent
        @handleOfficeData="setOfficeData"
        :officeData="this.officeData"
        :officeDataValidation="this.officeDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import { getFormattedFloatNumbersToBackEnd } from '@/helpers/MathHelper';
import FormOfficeContent from './FormOfficeContent.vue';

export default {
  name: 'AddOfficeForm',
  components: {
    Modal,
    FormCard,
    FormOfficeContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    officeData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
  },
  data: () => ({
    title: 'Editar oficina',
    officeDataValidation: {
      code: null,
      type: null,
      lot: null,
      city: null,
      project: null,
      supplier: null,
    },
  }),
  methods: {
    setOfficeData(officeData) {
      this.officeData = officeData;
    },
    updateOffice() {
      if (this.isValidData()) {
        this.blockScreen();

        const { uuid } = this.officeData;
        const requestData = this.officeData;
        const { amount } = requestData;
        const { amountWithVAT } = requestData;

        requestData.amount = amount
          ? getFormattedFloatNumbersToBackEnd(amount)
          : null;
        requestData.amountWithVAT = amountWithVAT
          ? getFormattedFloatNumbersToBackEnd(amountWithVAT)
          : null;
        delete requestData.uuid;

        this.$store.dispatch('updateOffice', {
          uuid,
          officeData: requestData,
        })
          .then(() => {
            this.$store.dispatch('getOfficeTypes', {
              companyId: this.$store.getters.getActiveCompany,
              projectId: this.$store.getters.getActiveProject,
            });

            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.officeDataValidation = {
          code: getValidationState(this.officeData?.code),
          type: getValidationState(this.officeData?.type),
          lot: getValidationState(this.officeData?.lot),
          city: getValidationState(this.officeData?.city),
          project: getValidationState(this.$store.getters.getActiveProject),
          supplier: getValidationState(this.officeData?.supplier),
        };
      }
    },
    isValidData() {
      return hasData(this.officeData?.code)
        && hasData(this.officeData?.type)
        && hasData(this.officeData?.lot)
        && hasData(this.officeData?.city)
        && hasData(this.$store.getters.getActiveProject)
        && hasData(this.officeData?.supplier);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

<style lang="sass">
  @import './OfficeForm.sass'
</style>

<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Proyectos - Planning"/>
        <div class="viewContainer__content">
          <TableContainer
            title="Selecciona un proyecto"
            subtitle="Selecciona un proyecto para seleccionar datos individualizados al proyecto."
          >
            <b-row>
              <b-col class="col-12 col-lg-6">
                <div class="table__container">
                  <SelectorProject />
                </div>
              </b-col>
            </b-row>
          </TableContainer>
          <div v-if="$store.getters.hasActiveProject">
            <Forecast />
          </div>
          <div v-if="!$store.getters.hasActiveProject">
            <div class="container__simple">
              ¡Vaya! Tienes que seleccionar un proyecto para visualizarlo.
            </div>
          </div>
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import Forecast from '@/components/molecules/Forecast/Forecast.vue';

export default {
  name: 'Planning',
  components: {
    PrivateTemplate,
    TitleView,
    TableContainer,
    SelectorProject,
    Forecast,
  },
};
</script>

<template>
  <div class="TableContainer">
    <div class="TableContainer__titleBlock">
      <h2 class="TableContainer__title">{{ title }}</h2>
      <p class="Text__small" v-show="subtitle">{{ subtitle }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TableContainer',
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="sass">
  @import './TableContainer.sass'
</style>

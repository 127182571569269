<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualizar Medición"
      @accept="updateMeasurement"
      @cancel="cancelAction"
    >
      <FormMeasurementContent
        @handleMeasurementData="setMeasurementData"
        :measurementData="this.measurementData"
        :measurementDataValidation="this.validationStatus"
        @onChangeStatus="onChangeStatus"
        @onChangePerformanceDate="onChangePerformanceDate"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState,
} from '@/helpers/ValidationHelper';
import { PHASE } from '@/helpers/PhaseHelper';
import FormMeasurementContent from './FormMeasurementContent.vue';

export default {
  name: 'UpdateMeasurementForm',
  components: {
    Modal,
    FormCard,
    FormMeasurementContent,
  },
  props: {
    showModal: Boolean,
    officeUuid: {
      type: String,
      default: '',
    },
    measurementData: {
      type: Object,
      default: () => {},
    },
    handleClose: {},
    handleUpdateOffice: {},
  },
  data: () => ({
    measurementDataValidation: {
      status: null,
      performanceDate: null,
    },
    validationStatus: {
      status: null,
    },
  }),
  mounted() {
    if (Object.keys(this.measurementData).length > 0) {
      this.measurementDataValidation.status = this.measurementData.status;
      this.measurementDataValidation.performance = this.measurementData.performanceDate;
    }
    if (this.measurementData?.status) {
      this.measurementDataValidation.status = this.measurementData.status;
    }
  },
  methods: {
    setMeasurementData(measurementData) {
      this.measurementData = measurementData;
    },
    updateMeasurement() {
      if (this.isValidData()) {
        const phaseData = {
          status: this.measurementDataValidation.status,
          performanceDate: this.measurementDataValidation.performanceDate,
        };

        const measurementData = {
          officeUuid: this.officeUuid,
          phase: PHASE.MEASUREMENT.value,
          phaseData,
        };

        this.blockScreen();

        this.$store.dispatch('updateMeasurementPhase', {
          data: measurementData,
        })
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.validationStatus = {
          status: getValidationState(this.measurementDataValidation?.status),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.measurementDataValidation?.status);
    },
    onChangeStatus(status) {
      this.measurementDataValidation.status = status;
    },
    onChangePerformanceDate(performanceDate) {
      this.measurementDataValidation.performanceDate = performanceDate;
    },
  },
};
</script>

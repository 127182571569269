<template>
  <div class="FormCard">
    <div class="FormCard__header">
      <h2 class="FormCard__header__title">{{ title }}</h2>
      <p>{{ subtitle }}</p>
    </div>
    <div class="FormCard__content">
      <slot></slot>
    </div>
    <div class="FormCard__footer">
      <div class="Separator__vertical__st"></div>
      <ActionButtons
        :okText="saveText"
        :cancelText="cancelText"
        @handleAction="acceptAction"
        @handleCancel="cancelAction"
      />
    </div>
  </div>
</template>
<script>
import ActionButtons from '@/components/atoms/ActionButtons/ActionButtons.vue';

export default {
  name: 'FormCard',
  components: { ActionButtons },
  props: {
    title: String,
    subtitle: String,
  },
  computed: {
    saveText() {
      return 'Guardar';
    },
    cancelText() {
      return 'Cancelar';
    },
  },
  methods: {
    acceptAction() {
      this.$emit('accept');
    },
    cancelAction() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="sass">
@import './FormCard.sass'
</style>

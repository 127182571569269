<template>
  <private-template>
    <template v-slot:private-template>
      <div class="Users viewContainer">
        <TitleView title="Alertas"/>
        <div class="viewContainer__content">
          <TableContainer
            subtitle="Selecciona un proyecto para seleccionar datos individualizados al proyecto."
            title="Selecciona un proyecto"
          >
            <div class="table__container">
              <b-row>
                <b-col class="col-12 col-lg-6">
                  <SelectorProject/>
                </b-col>
              </b-row>
            </div>
          </TableContainer>
          <EventsTable/>
        </div>
      </div>
    </template>

  </private-template>

</template>

<script>
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import EventsTable from '@/components/molecules/Tables/EventsTable/EventsTable.vue';

export default {
  name: 'Events',
  components: {
    EventsTable,
    SelectorProject,
    TableContainer,
    TitleView,
    PrivateTemplate,
  },
};
</script>

<style lang="sass" scoped>

</style>

export default class PicPhase {
  constructor(picPhaseData) {
    this.uuid = picPhaseData.uuid;
    this.name = picPhaseData.name;
    this.status = picPhaseData.status;
    this.receivedDate = picPhaseData.receivedDate;
    this.revisedDate = picPhaseData.revisedDate;
    this.approvedDate = picPhaseData.approvedDate;
    this.documents = picPhaseData.documents;
    this.revisions = picPhaseData.revisions;
  }

  get getUuid() {
    return this.uuid;
  }

  get getName() {
    return this.name;
  }

  get getStatus() {
    return this.status;
  }

  get getDocuments() {
    return this.documents;
  }

  get getRevisions() {
    return this.revisions;
  }

  get getReceivedDate() {
    return this.receivedDate;
  }

  get getRevisedDate() {
    return this.revisedDate;
  }

  get getApprovedDate() {
    return this.approvedDate;
  }
}

export default class ImportResponse {
  constructor(importData) {
    this.elementsImported = importData.elementsImported;
    this.errors = importData.errors;
  }

  get getElementsImported() {
    return this.elementsImported;
  }

  get getErrors() {
    return this.errors;
  }
}

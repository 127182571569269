export const VALIDATION_STATE = {
  VALID: null,
  INVALID: false,
};

export function hasData(data) {
  return data !== ''
    && typeof data !== 'undefined'
    && data !== null;
}

export function isValidEmail(email) {
  if (!hasData(email)) {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function isValidDateRange(from, to) {
  return (new Date(from)) < (new Date(to));
}

export function getValidationState(data) {
  return hasData(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function getEmailValidationState(data) {
  return isValidEmail(data) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function getDateValidationRange(from, to) {
  return isValidDateRange(from, to) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export function isEqual(value1, value2) {
  return value1 === value2;
}

export function getEqualValidationState(value1, value2) {
  return hasData(value1)
    && hasData(value2)
    && isEqual(value1, value2) ? VALIDATION_STATE.VALID : VALIDATION_STATE.INVALID;
}

export default class Issue {
  constructor(issueData) {
    this.uuid = issueData.uuid;
    this.office = issueData.office;
    this.status = issueData.status;
    this.name = issueData.name;
    this.openingDate = issueData.openingDate;
    this.resolutionDate = issueData.resolutionDate;
    this.reason = issueData.reason;
    this.reportedBy = issueData.reportedBy;
    this.countOffices = issueData.countOffices;
    this.observations = issueData.observations;
    this.owner = issueData.owner;
    this.documents = issueData.documents;
  }

  get getUuid() {
    return this.uuid;
  }

  get getOffice() {
    return this.office;
  }

  get getStatus() {
    return this.status;
  }

  get getOpeningDate() {
    return this.openingDate;
  }

  get getResolutionDate() {
    return this.resolutionDate;
  }

  get getReason() {
    return this.reason;
  }

  get getReportedBy() {
    return this.reportedBy;
  }

  get getCountOffices() {
    return this.countOffices;
  }

  get getObservations() {
    return this.observations;
  }

  get getOwner() {
    return this.owner;
  }

  get getName() {
    return this.name;
  }

  get getDocuments() {
    return this.documents;
  }
}

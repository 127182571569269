import { render, staticRenderFns } from "./MenuRowIcon.vue?vue&type=template&id=332047c4&"
import script from "./MenuRowIcon.vue?vue&type=script&lang=js&"
export * from "./MenuRowIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
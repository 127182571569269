<template>
  <div v-if="$store.getters.hasClientPermission
  || $store.getters.hasProviderPermission" class="Documents">
    <div class="w-100" v-if="!isShowedLoader && hasDocuments">
      <div class="document-header d-flex justify-content-between align-items-center">
        <div class="d-flex w-50 align-items-center justify-content-between">
          <div>{{document ? document.originalName : null}}</div>
          <div>{{document ? document.uploadedAt : null}}</div>
        </div>
        <div class="button-container">
          <ActionIconButton
            icon="download.svg"
            type="view"
            :handler="downloadFile"
            :objectData="document"
          />
          <ActionIconButton
            v-if="$store.getters.hasAdminPermission"
            icon="delete.svg"
            type="delete"
            :handler="deleteFile"
            :objectData="document"
          />
        </div>
      </div>

    <div class="Separator__vertical__big"/>
      <div class="document-container">
        <div class="carousel-container"
        :style="{'display': !this.getDocumentIsPdf ? 'block' : 'none'}">
          <b-carousel
            v-if="!isShowedLoader && hasDocuments"
            no-animation
            controls
            ref="imageCarousel"
            img-width="600"
            img-height="500"
            :interval="interval"
            @sliding-end="next"
          >
            <b-carousel-slide
              v-for="document in this.getDocumentsData"
              :key="document.uuid"
              :id="document.uuid"
              :img-alt="getImage(document)"
              :img-src="loadedImages[document.uuid] && loadedImages[document.uuid].imgSrc"
            >
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="pdf-container" v-if="!isShowedLoader && hasDocuments && !showCarousel">
          <a href="#" role="button" class="carousel-control-prev pdf-controls">
            <span aria-hidden="true"
            v-on:click="setImageCarousel(getPreviousUuid())"
            class="carousel-control-prev-icon">
            </span>
            <span class="sr-only">Previous slide</span>
          </a>
          <div v-on:click="downloadFile(document, true)">
            <vue-pdf-embed
              :key="this.document.uuid"
              :id="this.document.uuid"
              :page=1
              :source="loadedImages[this.document.uuid].pdfSrc"
              />
          </div>
          <a href="#" role="button" class="carousel-control-next pdf-controls"
          style="left: 50% !important;">
            <span aria-hidden="true"
            v-on:click="setImageCarousel(getNextUuid())"
            class="carousel-control-next-icon">
            </span>
            <span class="sr-only">Next slide</span>
          </a>
        </div>
        <div class="imageList">
          <ImageList
            :listImages="this.loadedImages"
            @setImageCarousel="setImageCarousel"/>
        </div>
      </div>
    </div>
    <p v-if="!hasDocuments">No hay documentos asociados.</p>
    <div v-if="isShowedLoader" class="d-flex justify-content-center mb-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import { getPhaseDocumentList } from '@/helpers/PhaseHelper';
import exportFile from '@/helpers/FileHelper';
import ImageList from '@/components/molecules/Documents/ImageList.vue';
import file from '@/assets/file.svg';

export default {
  name: 'Documents',
  components: {
    ImageList,
    ActionIconButton,
    VuePdfEmbed,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    phaseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadedImages: {},
      documentFields: [
        { key: 'originalName', label: 'Nombre' },
        { key: 'uploadedAt', label: 'Fecha' },
        { key: 'image', label: 'Imagen', tdClass: 'text-center' },
      ],
      isLoadingDocuments: false,
      document: null,
      interval: 0,
      showCarousel: true,
    };
  },
  methods: {
    isPdf(pdfName) {
      const ext = pdfName.split('.').reverse()[0].toLowerCase();
      return ext === 'pdf';
    },
    isImage(imageName) {
      const exts = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
      const ext = imageName.split('.').reverse()[0].toLowerCase();
      return exts.indexOf(ext) > -1;
    },
    getImage(data) {
      if (this.loadedImages[data.uuid]) {
        return;
      }
      this.showLoader();
      if (this.document === null) { // Initialize the first image of the carousel
        this.document = {
          uuid: data.uuid,
          originalName: data.originalName,
          fileName: data.fileName,
          uploadedAt: data.uploadedAt,
        };
        this.showCarousel = !this.isPdf(data.fileName);
      }
      if (!this.isImage(data.fileName) && !this.isPdf(data.fileName)) {
        this.$set(this.loadedImages, data.uuid,
          {
            imgSrc: file,
            name: data.originalName,
            docId: data.fileName.replace('.', '_'),
            pdfSrc: '',
          });

        this.hideLoader();
      } else {
        const requestData = {
          officeUuid: this.officeUuid,
          phaseUuid: this.phaseData.uuid,
          documentUuid: data.uuid,
        };
        this.$set(this.loadedImages, data.uuid, {});
        if (this.isPdf(data.fileName)) {
          this.$store.dispatch('downloadPhaseDocument', requestData)
            .then((res) => {
              this.$set(this.loadedImages, data.uuid,
                {
                  imgSrc: file,
                  name: data.originalName,
                  docId: data.fileName.replace('.', '_'),
                  pdfSrc: window.URL.createObjectURL(res.data),
                });
              this.hideLoader();
            });
        } else {
          this.$store.dispatch('downloadPhaseDocument', requestData)
            .then((res) => {
              this.$set(this.loadedImages, data.uuid,
                {
                  imgSrc: window.URL.createObjectURL(res.data),
                  name: data.originalName,
                  docId: data.fileName.replace('.', '_'),
                  pdfSrc: '',
                });
              this.hideLoader();
            });
        }
      }
    },
    downloadFile(data, open = false) {
      this.blockScreen();

      const requestData = {
        officeUuid: this.officeUuid,
        phaseUuid: this.phaseData.uuid,
        documentUuid: data.uuid,
      };

      this.$store
        .dispatch('downloadPhaseDocument', requestData)
        .then((res) => {
          exportFile(data.fileName, res.data, open);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    deleteFile(data) {
      this.showLoader();

      const requestData = {
        officeUuid: this.officeUuid,
        phaseUuid: this.phaseData.uuid,
        documentUuid: data.uuid,
      };
      this.$delete(this.loadedImages, data.uuid);

      // update document object
      const uuids = Object.keys(this.loadedImages);
      this.document = null;
      if (uuids.length > 0) {
        this.document = {
          uuid: uuids[0],
          originalName: this.loadedImages[uuids[0]].name,
          fileName: '',
          uploadedAt: '',
        };
        this.showCarousel = !this.isPdf(this.loadedImages[uuids[0]].name);
      }
      this.$store
        .dispatch('deletePhaseDocument', requestData)
        .then(() => {
          this.hideLoader();
          this.manageDocumentChanges();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    manageDocumentChanges() {
      const uuid = this.officeUuid;

      this.showLoader();

      const officeData = this.$store.getters.getOffices.find((office) => office.uuid === uuid);

      this.$store
        .dispatch('getPhaseByOffice', officeData)
        .then(() => {
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    showLoader() {
      this.isLoadingDocuments = true;
    },
    hideLoader() {
      this.isLoadingDocuments = false;
    },
    next(slide) {
      this.document = this.getDocumentsData[slide];
      this.showCarousel = !this.isPdf(this.document.originalName);
    },
    getPreviousUuid() {
      const { uuid } = this.document;
      const slide = this.$refs.imageCarousel.slides.find((element) => element.id === uuid);
      const position = this.$refs.imageCarousel.slides.indexOf(slide);
      return this.getDocumentsData[position - 1]
        ?. uuid
        || this.getDocumentsData[this.getDocumentsData.length - 1].uuid;
    },
    getNextUuid() {
      const { uuid } = this.document;
      const slide = this.$refs.imageCarousel.slides.find((element) => element.id === uuid);
      const position = this.$refs.imageCarousel.slides.indexOf(slide);
      return this.getDocumentsData[position + 1]
        ?. uuid
        || this.getDocumentsData[0].uuid;
    },
    setImageCarousel(uuid) {
      const slide = this.$refs.imageCarousel.slides.find((element) => element.id === uuid);
      this.$refs.imageCarousel.index = this.$refs.imageCarousel.slides.indexOf(slide);
      this.showCarousel = !this.isPdf(
        this.getDocumentsData[this.$refs.imageCarousel.index].originalName,
      );
      this.document = this.getDocumentsData[this.$refs.imageCarousel.index];
      if (!this.showCarousel) {
        this.showLoader();
      }
      if (!this.showCarousel) {
        // PDF component rendering not working fine when change between differents pdfs
        window.setTimeout(() => (this.hideLoader()), 500);
      }
    },
  },
  computed: {
    isShowedLoader() {
      return this.isLoadingDocuments;
    },
    getDocumentIsPdf() {
      return this.document ? this.isPdf(this.document.originalName) : false;
    },
    getDocumentsData() {
      return getPhaseDocumentList(this.phaseData);
    },
    hasDocuments() {
      return getPhaseDocumentList(this.phaseData).length > 0;
    },
  },
};
</script>

<style lang="sass">
img.imagePreview
  object-fit: contain
  height: 34px

.Documents
  display: flex

.imageList
  display: flex
  flex-wrap: wrap
  height: 400px
  overflow-x: hidden
  overflow-y: auto
  align-items: flex-start

.carousel-caption
  background-color: #fff
  p
    color: black
  right: auto !important
  left: 20% !important
  bottom: 25px !important

.carousel-item
  img
    height: auto
    object-fit: contain
    max-height: 400px

.carousel-container
  width: 85%
  margin-right: 2em

.pdf-container
  width: 50%
  margin-left: 17.5%
  margin-right: 17.5%

.vue-pdf-embed
  canvas
    width: 70% !important
    height: 70% !important

.document-container
  display: flex
  align-items: flex-start

.pdf-controls
  top: 30% !important

.pdf-control-prev-icon
  background-image:  url("~@/assets/prev.svg") !important
  background-repeat: no-repeat !important

.pdf-control-next-icon
  background-image: url("~@/assets/next.svg") !important
  background-repeat: no-repeat !important

.carousel-control-prev-icon,
.carousel-control-next-icon
  width: 75% !important
  height: 20% !important
  background-position: top !important
  opacity: 2 !important
  border-radius: 100%

.carousel-control-prev-icon
  background-image:  url("~@/assets/prev.svg") !important
  background-repeat: no-repeat !important

.carousel-control-next-icon
  background-image: url("~@/assets/next.svg") !important
  background-repeat: no-repeat !important

.document-header
  width: 60%
</style>

<template>
  <form>
    <b-row cols="1" :cols-lg="this.cols">
      <b-col>
        <SimpleInput
          inputLabel="Email"
          inputId="newUserEmail"
          inputType="email"
          inputPlaceholder="Email"
          :inputValue="this.userData.email"
          @handlerText="getEmail"
          :isValid="this.userDataValidation.email"
        ></SimpleInput>
      </b-col>
      <b-col v-show="this.showPassword">
        <SimpleInput
          inputLabel="Password"
          inputId="newUserPassword"
          inputType="password"
          inputPlaceholder="Password"
          :inputValue="this.userData.password"
          @handlerText="getPass"
          :isValid="this.userDataValidation.password"
        ></SimpleInput>
      </b-col>
      <b-col>
        <SelectorForm
          selectLabel="Rol"
          :selectFormOptions="selectFormOptions"
          @handlerSelect="getRol"
          :selectedOption="this.userData.role"
          :isValid="this.userDataValidation.role"
        />
      </b-col>
    </b-row>
    <b-row cols="1" :cols-lg="this.cols">
      <b-col>
        <SimpleInput
          inputLabel="Nombre"
          inputId="newUserName"
          inputType="text"
          inputPlaceholder="Nombre"
          :inputValue="this.userData.name"
          @handlerText="getName"
          :isValid="this.userDataValidation.name"
        ></SimpleInput>
      </b-col>
      <b-col>
        <SimpleInput
          inputLabel="Apellidos"
          inputId="newUserSurames"
          inputType="text"
          inputPlaceholder="Apellidos"
          :inputValue="this.userData.surnames"
          @handlerText="getSurnames"
          :isValid="this.userDataValidation.surnames"
        ></SimpleInput>
      </b-col>
      <b-col v-if="isSupplierRoleSelected">
        <SelectorForm
          select-label="Proveedor"
          :selectFormOptions="supplierSelectFormOptions"
          @handlerSelect="getSupplier"
        ></SelectorForm>
      </b-col>
      <b-col v-if="this.userData.role === 'ROLE_PROVIDER'">
        <SimpleInput
          inputLabel="Compañía"
          inputId="company"
          inputType="text"
          inputPlaceholder="Apellidos"
          :inputValue="this.userData.companyName"
          :disabled="true"
        ></SimpleInput>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import { getSelectRolOptions } from '@/helpers/UserHelper';

export default {
  name: 'FormUserContent',
  created() {
    this.getSuppliers();
  },
  components: { SimpleInput, SelectorForm },
  props: {
    handleUserData: {},
    cols: String,
    showPassword: Boolean,
    userData: Object,
    userDataValidation: Object,
  },
  data() {
    return {
      selectFormOptions: getSelectRolOptions(),
      supplierSelectFormOptions: {
        type: Array,
        default: null,
      },
      isSupplierRoleSelected: false,
    };
  },
  methods: {
    getEmail(data) {
      this.userData.email = data;
      this.$emit('handleUserData', this.userData);
    },
    getPass(data) {
      this.userData.password = data;
      this.$emit('handleUserData', this.userData);
    },
    getRol(data) {
      this.userData.role = data;
      this.isSupplierRoleSelected = data === 'ROLE_PROVIDER';
      this.$emit('handleUserData', this.userData);
    },
    getName(data) {
      this.userData.name = data;
      this.$emit('handleUserData', this.userData);
    },
    getSurnames(data) {
      this.userData.surnames = data;
      this.$emit('handleUserData', this.userData);
    },
    getSupplier(data) {
      this.userData.supplier = data;
      this.$emit('handleUserData', this.userData);
    },

    getSuppliers() {
      if (this.$store.getters.hasSuppliers) {
        this.addSuppliers(this.$store.getters.getSuppliers);
      } else {
        this.blockScreen();

        this.$store.dispatch('getAllSuppliers', {})
          .then((res) => {
            this.addSuppliers(res.suppliers);

            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
          });
      }
    },
    addSupplierOption(option) {
      this.supplierSelectFormOptions.push(option);
    },
    addSuppliers(suppliers) {
      this.supplierSelectFormOptions = [
        { value: null, text: 'Sin proveedor' },
      ];
      suppliers.forEach((supplier) => {
        const option = {
          value: supplier.uuid,
          text: supplier.name,
        };

        this.addSupplierOption(option);
      });
    },
  },
};
</script>

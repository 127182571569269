export default class Forecast {
  constructor(forecastData) {
    this.id = forecastData.id;
    this.title = forecastData.title;
    this.project = forecastData.project;
    this.phase = forecastData.phase;
    this.lot = forecastData.lot;
    this.startDate = forecastData.startDate;
    this.endDate = forecastData.endDate;
    this.color = forecastData.color;
    this.style = forecastData.style;
  }

  get getId() {
    return this.id;
  }

  get getTitle() {
    return this.title;
  }

  get getProject() {
    return this.project;
  }

  get getPhase() {
    return this.phase;
  }

  get getLot() {
    return this.lot;
  }

  get getStartDate() {
    return this.startDate;
  }

  get getEndDate() {
    return this.endDate;
  }

  get getColor() {
    return this.color;
  }

  get getStyle() {
    return this.style;
  }
}

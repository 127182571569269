<template>
  <div>
    <div>
      <label>{{ selectLabel }}</label>
      <Multiselect  @input="changeSelection"
                      :options="selectFormOptions" v-model="selected" :multiple="true"
                      :close-on-select="false" :clear-on-select="false"
                      :preserve-search="true" placeholder="Escoge uno o varios clientes"
                      :value="selectedClients"
                      label="text"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="Seleccionado"
                      :limit="3"
                      :limitText="showMoreText"
                      track-by="text" :preselect-first="true">
      </Multiselect>
    </div>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'SelectorMultiple',
  components: { Multiselect },
  props: {
    selectLabel: String,
    selectFormOptions: Array,
    selectedClients: Array || null,
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selected: this.selectedClients,
    };
  },
  watch: {
    selectedOption(newValue) {
      this.selected = newValue;
    },
  },
  methods: {
    changeSelection(clientsSelected) {
      if (clientsSelected[clientsSelected.length - 1].text === 'Sin cliente') {
        this.selected = [];
      } else {
        this.selected = clientsSelected;
      }
      const clientsUuid = [];
      Object.keys(this.selected).forEach((key) => {
        if (this.selected[key].value) {
          clientsUuid.push(this.selected[key].value);
        }
      });
      this.$emit('handlerSelect', clientsUuid);
    },
    showMoreText(count) {
      return `y ${count} más`;
    },
  },
  mounted() {
    if (this.selectedClients) {
      this.selected = this.selectedClients;
      const clientsUuid = [];
      Object.keys(this.selected).forEach((key) => {
        if (this.selected[key].value) {
          clientsUuid.push(this.selected[key].value);
        }
      });
      this.$emit('handlerSelect', clientsUuid);
    }
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>

<template>
  <div class="LogoImageView">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center pt-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </div>
    <img
      v-if="!isLoading"
      :src="logo"
    />
  </div>
</template>

<script>

export default {
  name: 'LogoImageView',
  props: {
    companyUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      logo: null,
      isLoading: false,
    };
  },
  created() {
    this.getLogo();
  },
  methods: {
    getLogo() {
      const requestData = {
        companyUuid: this.companyUuid,
      };

      this.showLoader();

      this.$store.dispatch('getCompanyLogo', requestData)
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          this.logo = fileURL;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
  },
};
</script>

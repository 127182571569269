<template>
  <div class="Measurement">
    <b-row>
      <b-col class="col-lg-12">
        <div class="Separator__vertical__st"/>
        <div class="phaseHeader">
          <div class="text-left phase-name">
            <p>Medición</p>
          </div>
          <div class="text-right">
          </div>
        </div>
        <div class="Separator__vertical__big"/>
        <b-table
          :items="this.getPhaseData"
          :fields="fields"
          fixed
        >
          <template #cell(status)="data">
            <button
              @click="showUpdateForm"
              class="simpleButton"
            >
            <StatusBadget
              :text="data.item.status"
            />
            </button>

          </template>
          <template #cell(performanceDate)="data">
            {{ data.item.performanceDate | formatDate }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <UpdateMeasurementForm
      v-if="isShowedUpdateForm"
      :showModal="true"
      @cancel="closeUpdateForm"
      :officeUuid="officeUuid"
      :measurementData="phaseData"
      :handleClose="closeUpdateForm"
    />
  </div>
</template>

<script>
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';
import UpdateMeasurementForm from './UpdateMeasurementForm.vue';

export default {
  name: 'Measurement',
  components: {
    StatusBadget,
    UpdateMeasurementForm,
  },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    phaseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowedUpdateForm: false,
      fields: [
        { key: 'status', label: 'Estado', class: 'status-header' },
        { key: 'performanceDate', label: 'Fecha Realización' },
      ],
    };
  },
  methods: {
    showUpdateForm() {
      this.isShowedUpdateForm = true;
    },
    closeUpdateForm() {
      this.isShowedUpdateForm = false;
    },
  },
  computed: {
    getPhaseData() {
      if (typeof this.phaseData === 'undefined') {
        return [];
      }

      const phaseDataList = [
        {
          name: this.phaseData.name,
          status: this.phaseData.status,
          performanceDate: this.phaseData.performanceDate,
        },
      ];

      return phaseDataList;
    },
  },
};
</script>

<style scoped lang="sass">
  ::v-deep
    .status-header
      width: 140px
    .dates-header
      width: 15%
</style>

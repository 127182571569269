<template>
  <div class="Auth">
    <b-form @submit="onSubmit">
      <label for="input-email">Introduzca el email:</label>
      <b-form-input
        id="input-email"
        type="email"
        v-model="resetData.email"
        placeholder="Email"
        required
      ></b-form-input>
      <div class="Separator__vertical__big" />
      <b-button type="submit" variant="primary">Reset Password</b-button>
      <div class="Separator__vertical__st" />
      <div class="text-center">
        <router-link
          class="Text__small"
          :to="{ name: getLoginURL() }"
          @click.native="getLoginURL()"
        >
          Volver al login
        </router-link>
      </div>
    </b-form>
    <b-modal
      id="password-reset-modal"
      centered
      ok-only
      @ok="goToLogin()"
      @close="goToLogin()"
    >
      <p class="my-4">Compruebe su bandeja de entrada.</p>
    </b-modal>
  </div>
</template>

<script>
const LOGIN_URL = 'Login';

export default {
  name: 'ResetPassword',
  data() {
    return {
      resetData: {
        email: '',
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.blockScreen();
      this.$store.dispatch('resetPassword', this.resetData)
        .then(() => {
          this.unBlockScreen();
          this.$bvModal.show('password-reset-modal');
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    getLoginURL() {
      return LOGIN_URL;
    },
  },
};
</script>

<style lang="sass">
  @import '../Auth/Auth.sass'
</style>

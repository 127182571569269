<template>
  <form>
    <b-row>
      <b-col class="col-12 col-lg-6">
        <SelectorProject
          label="Proyecto *"
          :isValid="this.officeDataValidation.project"
        />
      </b-col>
      <b-col class="col-12 col-lg-6">
        <SelectorForm
          selectLabel="Proveedor *"
          :selectFormOptions="supplierOptions"
          @handlerSelect="getSupplier"
          :selectedOption="this.getSelectedSupplier"
          :isValid="this.officeDataValidation.supplier"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Cod Oficina *"
          inputId="officeCode"
          inputType="text"
          inputPlaceholder="Cod Oficina"
          :inputValue="this.officeData.code"
          @handlerText="getCode"
          :isValid="this.officeDataValidation.code"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Cod2 Oficina"
          inputId="officeCode2"
          inputType="text"
          inputPlaceholder="Cod2 Oficina"
          :inputValue="this.officeData.code2"
          @handlerText="getCode2"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Tipo *"
          inputId="officeType"
          inputType="text"
          inputPlaceholder="Tipo"
          :inputValue="this.officeData.type"
          @handlerText="getType"
          :isValid="this.officeDataValidation.type"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Nombre"
          inputId="officeName"
          inputType="text"
          inputPlaceholder="Nombre"
          :inputValue="this.officeData.name"
          @handlerText="getName"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <b-form-group
          label="Estado"
        >
          <b-form-checkbox
            v-model="active"
            name="officeState"
            switch
            @change="getState"
            size="lg"
          >
            {{ active ? 'Activo' : 'Inactivo' }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Lote *"
          inputId="officeLot"
          inputType="text"
          inputPlaceholder="Lote"
          :inputValue="this.officeData.lot"
          @handlerText="getLot"
          :isValid="this.officeDataValidation.lot"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="DT - Territorial"
          inputId="officeDtTerritorial"
          inputType="text"
          inputPlaceholder="DT - Territorial"
          :inputValue="this.officeData.dtTerritorial"
          @handlerText="getDtTerritorial"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="col-12 col-lg-8">
        <SimpleInput
          inputLabel="Dirección"
          inputId="officeAddress"
          inputType="text"
          inputPlaceholder="Dirección"
          :inputValue="this.officeData.address"
          @handlerText="getAddress"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Ciudad *"
          inputId="officeCity"
          inputType="text"
          inputPlaceholder="Ciudad"
          :inputValue="this.officeData.city"
          @handlerText="getCity"
          :isValid="this.officeDataValidation.city"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="CP"
          inputId="officePostalCode"
          inputType="text"
          inputPlaceholder="CP"
          :inputValue="this.officeData.postalCode"
          @handlerText="getPostalCode"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Provincia"
          inputId="officeProvince"
          inputType="text"
          inputPlaceholder="Provincia"
          :inputValue="this.officeData.province"
          @handlerText="getProvince"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Comunidad Autónoma"
          inputId="officeAutonomousCommunity"
          inputType="text"
          inputPlaceholder="Comunidad Autónoma"
          :inputValue="this.officeData.autonomousCommunity"
          @handlerText="getAutonomousCommunity"
        />
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Tipo de rótulo"
          inputId="officePosterType"
          inputType="text"
          inputPlaceholder="Tipo de rótulo"
          :inputValue="this.officeData.posterType"
          @handlerText="getPosterType"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Ml rótulo"
          inputId="officePosterSize"
          inputType="text"
          inputPlaceholder="Ml rótulo"
          :inputValue="this.officeData.posterSize"
          @handlerText="getPosterSize"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Uds. Banderola"
          inputId="officeBannerNumber"
          inputType="number"
          inputPlaceholder="Uds. Banderola"
          :inputValue="this.officeData.bannerNumber"
          @handlerText="getBannerNumber"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Importe (con decimales)"
          inputId="officeAmount"
          inputType="text"
          inputPlaceholder="Importe"
          :inputValue="this.officeData.amount"
          @handlerText="getAmount"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Importe con IVA (con decimales)"
          inputId="officeAmountWithVAT"
          inputType="text"
          inputPlaceholder="Importe con IVA"
          :inputValue="this.officeData.amountWithVAT"
          @handlerText="getAmountWithVAT"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <SimpleInput
          inputLabel="Facturado"
          inputId="officeInvoiced"
          inputType="text"
          inputPlaceholder="Facturado"
          :inputValue="this.officeData.invoiced"
          @handlerText="getInvoiced"
        />
      </b-col>
    </b-row>
    <b-modal id="office-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </form>
</template>

<script>
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import getObjectByUuid from '@/helpers/ObjectHelper';

export default {
  name: 'FormOfficeContent',
  components: {
    SimpleInput,
    SelectorForm,
    SelectorProject,
  },
  props: {
    handleOfficeData: {},
    officeData: {},
    officeDataValidation: Object,
  },
  data() {
    return {
      active: this.officeData.active,
      projectOptions: [
        { value: null, text: 'Selecciona un proyecto' },
      ],
      supplierOptions: [
        { value: null, text: 'Selecciona un proveedor' },
      ],
    };
  },
  created() {
    this.initSuppliers();
  },
  methods: {
    initSuppliers() {
      this.blockScreen();
      const data = {
      //  projectId: this.$store.getters.getActiveProject,
      };
      this.$store.dispatch('getAllSuppliers', data)
        .then((res) => {
          this.addSuppliers(res.suppliers);

          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
          this.$bvModal.show('addOffice-error-modal');
        });
    },
    getCode(data) {
      this.officeData.code = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getCode2(data) {
      this.officeData.code2 = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getType(data) {
      this.officeData.type = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getLot(data) {
      this.officeData.lot = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getDtTerritorial(data) {
      this.officeData.dtTerritorial = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getAddress(data) {
      this.officeData.address = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getCity(data) {
      this.officeData.city = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getProvince(data) {
      this.officeData.province = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getAutonomousCommunity(data) {
      this.officeData.autonomousCommunity = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getPostalCode(data) {
      this.officeData.postalCode = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getName(data) {
      this.officeData.name = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getPosterType(data) {
      this.officeData.posterType = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getPosterSize(data) {
      this.officeData.posterSize = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getBannerNumber(data) {
      this.officeData.bannerNumber = parseInt(data, 10);
      this.$emit('handleOfficeData', this.officeData);
    },
    getAmount(data) {
      this.officeData.amount = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getAmountWithVAT(data) {
      this.officeData.amountWithVAT = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getState(data) {
      this.officeData.active = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getInvoiced(data) {
      this.officeData.invoiced = data;
      this.$emit('handleOfficeData', this.officeData);
    },
    getSupplier(data) {
      this.officeData.supplier = data;
      this.officeData.supplierObject = getObjectByUuid(this.supplierOptions, data);
      this.$emit('handleOfficeData', this.officeData);
    },
    addSupplierOption(option) {
      this.supplierOptions.push(option);
    },
    addSuppliers(suppliers) {
      suppliers.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addSupplierOption(option);
      });
    },
  },
  computed: {
    getSelectedSupplier() {
      return this.officeData.supplier?.uuid || this.officeData.supplier;
    },
  },
};
</script>

<template>
  <TableSimpleBlock
    title="Fase certificación"
    :items="officeCertificationReportData"
    :fields="fields"
    :isLoading="isOfficeCertificationReportLoading"
  />
</template>

<script>
import { mapState } from 'vuex';
import TableSimpleBlock from '@/components/molecules/Tables/TableSimpleBlock/TableSimpleBlock.vue';

export default {
  name: 'OfficeCertificationReport',
  components: {
    TableSimpleBlock,
  },
  data() {
    return {
      officeCertificationReportData: [],
      isOfficeCertificationReportLoading: false,
      fields: [
        { key: 'name', label: 'Proveedor', sortable: true },
        { key: 'finishedInstallationCount', label: 'Oficinas Instaladas', sortable: true },
        { key: 'receivedCertificationCount', label: 'Certificaciones recibidas', sortable: true },
        { key: 'revisedCertificationCount', label: 'Certificaciones revisadas', sortable: true },
        { key: 'issuedOfficesCount', label: 'Oficinas con Incidencia', sortable: true },
        { key: 'solvedIssuedOfficesCount', label: 'Of. con Incidencia Solucionada', sortable: true },
        { key: 'approvedCertificationCount', label: 'Certificación OK', sortable: true },
        { key: 'percentIncidences', label: '% Incidencias', sortable: true },
        { key: 'percentSolvedIncidences', label: '% Incidencia Solucionada', sortable: true },
        { key: 'pendingReceiveCertificationCount', label: 'Cert. pendientes de recibir', sortable: true },
        { key: 'pendingResolveCertificationCount', label: 'Cert. pendientes resolver Incidencias', sortable: true },
      ],
    };
  },
  created() {
    this.getOfficeCertificationReport();
  },
  methods: {
    getOfficeCertificationReport() {
      this.setOfficeCertificationReportLoading();

      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };

      this.$store.dispatch('getOfficeCertificationReport', data)
        .then((res) => {
          this.officeCertificationReportData = res;
          this.setOfficeCertificationReportNotLoading();
        })
        .catch(() => {
          this.setOfficeCertificationReportNotLoading();
        });
    },
    setOfficeCertificationReportLoading() {
      this.isOfficeCertificationReportLoading = true;
    },
    setOfficeCertificationReportNotLoading() {
      this.isOfficeCertificationReportLoading = false;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getOfficeCertificationReport();
      }
    },
  },
};
</script>

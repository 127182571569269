<template>
  <span class="StatusBadget phase-name">
    <b-badge :variant="getVariant()">{{ getText() }}</b-badge>
  </span>
</template>

<script>
import { getStatusLabel } from '@/helpers/StatusHelper';

export default {
  name: 'StatusBadget',
  props: {
    text: String,
    status: Boolean,
  },
  methods: {
    getText() {
      if (typeof this.text === 'undefined') {
        return this.status === true ? 'Activo' : 'Inactivo';
      }

      return getStatusLabel(this.text);
    },
    getVariant() {
      if (typeof this.text === 'undefined') {
        return this.status === true ? 'success' : 'secondary';
      }

      return this.text;
    },
  },
};
</script>

<style lang="sass">
  @import 'StatusBadget.sass'
</style>

export const REPORT_STATUS = {
  CLIENT: {
    value: 'client',
    name: 'Cliente',
  },
  SUPPLIER: {
    value: 'supplier',
    name: 'Proveedor',
  },
};

export const REASONS = {
  INTERIOR_CORPOREOS: {
    slug: 'interior_corporeos',
    name: '',
  },
  COUNTER: {
    slug: 'counter',
    name: '',
  },
  OTHER: {
    slug: 'other',
    name: 'Otros',
  },
  BANDEROLE: {
    slug: 'banderole',
    name: '',
  },
  CASHIERS: {
    slug: 'cashiers',
    name: '',
  },
  EXTERIOR_VINYLS: {
    slug: 'exterior_vinyls',
    name: '',
  },
  INTERIOR_ELEMENTS: {
    slug: 'interior_elements',
    name: '',
  },
  CORPOREAL: {
    slug: 'corporeal',
    name: '',
  },
  EXTERIOR_ELEMENTS: {
    slug: 'exterior_elements',
    name: '',
  },
  SIGN: {
    slug: 'sign',
    name: '',
  },
};

export function getUserTypeSelectorOptions() {
  const options = [
    { value: null, text: 'Seleccionar reportado' },
  ];

  Object.entries(REPORT_STATUS).forEach(([key, value]) => {
    const option = {
      value: REPORT_STATUS[key].value,
      text: value.name,
    };

    options.push(option);
  });

  return options;
}

export function getStatusLabel(status) {
  switch (status) {
    case REPORT_STATUS.CLIENT.value:
      return REPORT_STATUS.CLIENT.name;
    case REPORT_STATUS.SUPPLIER.value:
      return REPORT_STATUS.SUPPLIER.name;
    default:
      return '';
  }
}

export function getReasonsLabel(reason) {
  switch (reason) {
    case REASONS.INTERIOR_CORPOREOS.slug:
      return REASONS.INTERIOR_CORPOREOS.name;
    case REASONS.COUNTER.slug:
      return REASONS.COUNTER.name;
    case REASONS.OTHER.slug:
      return REASONS.OTHER.name;
    case REASONS.BANDEROLE.slug:
      return REASONS.BANDEROLE.name;
    case REASONS.CASHIERS.slug:
      return REASONS.CASHIERS.name;
    case REASONS.EXTERIOR_VINYLS.slug:
      return REASONS.EXTERIOR_VINYLS.name;
    case REASONS.INTERIOR_ELEMENTS.slug:
      return REASONS.INTERIOR_ELEMENTS.name;
    case REASONS.CORPOREAL.slug:
      return REASONS.CORPOREAL.name;
    case REASONS.EXTERIOR_ELEMENTS.slug:
      return REASONS.EXTERIOR_ELEMENTS.name;
    case REASONS.SIGN.slug:
      return REASONS.SIGN.name;
    default:
      return '';
  }
}

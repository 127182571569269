<template>
  <div v-if="this.$store.getters.hasProviderPermission || this.$store.getters.hasClientPermission">
    <UploadIssueDocumentForm
      v-if="isShowedUploadForm"
      :officeUuid = "officeUuid"
      :showModal="true"
      :options="options"
      :file="file"
      @cancel="cancelAction"
      @manageDocumentChanges="manageDocumentChanges"
    />
    <div class="d-flex justify-content-between h-25 align-items-center">
      <b-form-file
        v-model="file"
        multiple
        :state="Boolean(file)"
        placeholder="Selecciona o arrastra un archivo."
        drop-placeholder="Arrastra el archivo aquí..."
        class="documentUploader"
      ></b-form-file>
      <b-button
        variant="secondary"
        type="button"
        @click.prevent="uploadFile"
        :disabled="this.file === null"
        class="issue-upload-button"
      >
        Subir archivo
      </b-button>
    </div>
  </div>
</template>

<script>
import UploadIssueDocumentForm
from '@/components/atoms/IssueDocumentUploader/UploadIssueDocumentForm.vue';

export default {
  name: 'IssueDocumentUploader',
  components: { UploadIssueDocumentForm },
  props: {
    officeUuid: {
      type: String,
      default: '',
    },
    options: null,
  },
  data() {
    return {
      file: null,
      isShowedUploadForm: false,
    };
  },
  methods: {
    showUploadForm() {
      this.isShowedUploadForm = true;
    },
    cancelAction() {
      this.isShowedUploadForm = false;
    },
    uploadFile() {
      this.isShowedUploadForm = true;
    },
    manageDocumentChanges() {
      this.file = null;
      this.$emit('manageDocuments');
    },
  },
};
</script>

<style scoped lang="sass">
    .issue-upload-button
      width: 150px
      margin-left: 20px
</style>

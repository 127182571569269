export const PHASE = {
  MEASUREMENT: {
    value: 'measurement',
    name: 'Medición',
  },
  BUDGET: {
    value: 'budget',
    name: 'Presupuesto',
  },
  PIC: {
    value: 'PIC',
    name: 'PIC',
  },
  INSTALLATION: {
    value: 'installation',
    name: 'Instalación',
  },
  CERTIFICATION: {
    value: 'certification',
    name: 'Certificación',
  },
};

export function getPhaseSelectorOptions() {
  const options = [
    { value: null, text: 'Seleccionar fase' },
  ];

  Object.entries(PHASE).forEach(([key, value]) => {
    const option = {
      value: PHASE[key].value,
      text: value.name,
    };

    options.push(option);
  });

  return options;
}

export function getPhaseLabel(phase) {
  switch (phase) {
    case PHASE.MEASUREMENT.value:
      return PHASE.MEASUREMENT.name;
    case PHASE.BUDGET.value:
      return PHASE.BUDGET.name;
    case PHASE.PIC.value:
      return PHASE.PIC.name;
    case PHASE.CERTIFICATION.value:
      return PHASE.CERTIFICATION.name;
    case PHASE.INSTALLATION.value:
      return PHASE.INSTALLATION.name;
    default:
      return '';
  }
}

export function getPhaseColor(phase) {
  switch (phase) {
    case PHASE.MEASUREMENT.value:
      return '#007DA5';
    case PHASE.BUDGET.value:
      return '#24B4B0';
    case PHASE.PIC.value:
      return '#9ABCB0';
    case PHASE.CERTIFICATION.value:
      return '#AAC2AC';
    case PHASE.INSTALLATION.value:
      return '#EF7D45';
    default:
      return '';
  }
}

export function getPhaseDocumentList(phaseData) {
  if (typeof phaseData?.documents === 'undefined') {
    return [];
  }

  const documentsDataList = [];

  for (let i = 0; phaseData?.documents.length > i; i += 1) {
    const documentsData = {
      uuid: phaseData?.documents[i].uuid,
      fileName: phaseData?.documents[i].fileName,
      originalName: phaseData?.documents[i].originalName,
      uploadedAt: phaseData?.documents[i].uploadedAt,
    };

    documentsDataList.push(documentsData);
  }

  return documentsDataList;
}

export function getRevisionListData(phaseData) {
  if (typeof phaseData?.revisions === 'undefined') {
    return [];
  }

  const revisionsDataList = [];

  for (let i = 0; phaseData?.revisions.length > i; i += 1) {
    const revisionsData = {
      uuid: phaseData?.revisions[i].uuid,
      receivedDate: phaseData?.revisions[i].receivedDate,
      revisedDate: phaseData?.revisions[i].revisedDate,
      approvedDate: phaseData?.revisions[i].approvedDate,
    };

    revisionsDataList.push(revisionsData);
  }

  return revisionsDataList;
}

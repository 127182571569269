export default class Note {
  constructor(noteData) {
    this.uuid = noteData.uuid;
    this.officeUuid = noteData.officeUuid;
    this.body = noteData.body;
    this.date = noteData.date;
  }

  get getUuid() {
    return this.uuid;
  }

  get getOfficeUuid() {
    return this.officeUuid;
  }

  get getBody() {
    return this.body;
  }

  get getDate() {
    return this.date;
  }
}

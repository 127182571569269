<template>
  <div>
    <TableContainer
      title="Selecciona un proyecto"
      subtitle="Selecciona el proyecto al que importar los datos."
    >
      <div class="table__container">
        <b-row>
          <b-col class="col-12 col-lg-6">
            <SelectorProject />
          </b-col>
        </b-row>
      </div>
    </TableContainer>
    <div v-if="!this.$store.getters.hasActiveProject">
      <div class="container__simple">
        ¡Vaya! Tienes que seleccionar un proyecto para poder subir un fichero.
      </div>
    </div>
    <Importer
      v-if="this.$store.getters.hasActiveProject"
      @handleUploadFile="uploadFile"
      :elementsImported="elementsImported"
    />
  </div>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import Importer from '@/components/atoms/Importer/Importer.vue';

export default {
  name: 'ImporterWithProjectSelector',
  components: {
    TableContainer,
    SelectorProject,
    Importer,
  },
  props: {
    elementsImported: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    uploadFile(file) {
      this.$emit('handleUploadFile', file);
    },
  },
};
</script>

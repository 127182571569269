<template>
  <div class="OfficePhaseList">
    <TableContainer title="Estado actual Fases">
      <div class="table__container">
        <b-table
          :items="getItems"
          :fields="fields"
          striped
          hover
        >
          <template #cell(status)="data" >
            <StatusBadget
              v-if="!Number.isInteger(data.item.status)"
              :text="data.item.status"
            />
            <div v-else>
              {{data.item.status}}
            </div>
          </template>
        </b-table>
      </div>
    </TableContainer>
  </div>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';

export default {
  name: 'OfficePhaseList',
  components: {
    TableContainer,
    StatusBadget,
  },
  props: {
    phases: {
      type: Array,
      default: () => [],
    },
    issuesCount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name', label: 'Fase', sortable: true, tdClass: 'phase-name',
        },
        { key: 'status', label: 'Estado', sortable: true },
      ],
    };
  },
  computed: {
    getItems() {
      const computedItems = this.phases;
      computedItems.push({ name: 'Incidencias', status: this.issuesCount });

      return computedItems;
    },
  },
};
</script>

<style lang="sass">
  @import './OfficePhaseList.sass'
</style>

<template>
  <div>
    <b-container
      v-if="isLoading"
      fluid
      class="d-flex justify-content-center m-3">
      <b-spinner variant="secondary" label="Spinning"></b-spinner>
    </b-container>
    <div
      v-if="!isLoading"
      class="SelectorSimple"
    >
      <SelectorForm
        :selectLabel="label"
        :selectFormOptions="options"
        @handlerSelect="setSelected"
        :selectedOption="selectedOption"
        :disabled="this.$store.getters.isSupplier"
      />
      <span class="Separator__big" />
      <b-form-group label="Buscar">
        <b-button
          variant="primary"
          @click.prevent="handleSearch"
        >
          <img src="@/assets/search.svg" alt="search">
        </b-button>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';

export default {
  name: 'SelectorSimple',
  components: {
    SelectorForm,
  },
  props: {
    label: String,
    selectedOption: String || null,
    options: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    setSelected(data) {
      this.selected = data;
    },
    handleSearch() {
      this.$emit('handleSearch', this.selected);
    },
  },
};
</script>

<style lang="sass">
@import './SelectorSimple.sass'
</style>

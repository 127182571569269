import Event from '@/domain/models/Event';
import Pagination from '@/domain/models/Pagination';

class EventFactory {
  constructor() {
    this.events = [];
    this.pagination = {};
  }

  getEvents(eventsData) {
    this.events = eventsData.collection.map((eventData) => {
      const event = new Event({
        id: eventData.id,
        projectId: eventData.project_id,
        name: eventData.name,
        consumed: eventData.consumed !== '0',
        body: JSON.parse(eventData.body),
        officeCode: JSON.parse(eventData.body).officeCode,
        occurredOn: eventData.occurred_on,
        phaseName: JSON.parse(eventData.body).phaseName,
      });
      return event;
    });

    return this.events;
  }

  getPagination(paginationData) {
    this.pagination = new Pagination({
      items: paginationData.items,
      totalItems: paginationData.totalItems,
      offset: paginationData.offset,
    });

    return this.pagination;
  }

  getEvent(eventData) {
    this.event = new Event({
      id: eventData.id,
      projectId: eventData.projectId,
      name: eventData.name,
      consumed: eventData.consumed,
      body: eventData.body,
      officeCode: eventData.body.officeCode,
      phaseName: eventData.body.phaseName,
    });

    return this.event;
  }
}

export default new EventFactory();

<template>
  <b-form-group :label="selectLabel">
    <b-form-select
      v-model="selected"
      :options="selectFormOptions"
      @change.native="changeSelection"
      :state="isValid"
      :disabled="disabled"
    />
  </b-form-group>
</template>

<script>

export default {
  name: 'SelectorForm',
  props: {
    selectLabel: String,
    selectFormOptions: Array,
    selectedOption: String || null,
    isValid: {
      type: Boolean,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      selected: this.selectedOption,
    };
  },
  methods: {
    changeSelection(e) {
      this.selected = e.target.value !== '' ? e.target.value : null;
      this.$emit('handlerSelect', this.selected);
    },
  },
  watch: {
    selectedOption(newValue) {
      this.selected = newValue;
    },
  },
};
</script>

<style lang="sass">
@import './SelectorForm.sass'
</style>

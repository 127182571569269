<template>
  <TableContainer title="Compañias">
    <div class="table__container">
      <div align="end">
        <b-button
          variant="primary"
          @click.prevent="showAddCompaniesForm"
        >
          Añadir compañia
        </b-button>
      </div>
      <div class="Separator__vertical__big" />
      <div>
        <b-table
          v-if="hasItems()"
          :items="items"
          :fields="fields"
          striped
          hover
          bordered
        >
          <template #cell(acciones)="data">
             <div class="actionGroup">
              <ActionIconButton
                icon="edit.svg"
                type="edit"
                :handler="editCompany"
                :objectData="data.item"
              />
              <ActionIconButton
                icon="delete.svg"
                type="delete"
                :handler="deleteCompany"
                :objectData="data.item"
              />
              <ActionIconButton
                icon="image.svg"
                type="action2"
                :handler="uploadLogo"
                :objectData="data.item"
              />
             </div>
          </template>
        </b-table>
      </div>
    </div>
    <div
      v-if="!hasItems()"
      class="container__simple"
    >
      ¡Vaya! No hay compañias.
    </div>
    <AddCompanyForm
      v-if="isShowedAddCompaniesForm"
      :showModal="true"
      @cancel="closeAddCompanyForm"
      :handleClose="closeAddCompanyForm"
      :handleUpdateCompanies="getCompanies"
    />
    <UpdateCompanyForm
      v-if="isShowedUpdateCompaniesForm"
      :showModal="true"
      @cancel="closeUpdateCompanyForm"
      :companyData="currentCompany"
      :handleClose="closeUpdateCompanyForm"
      :handleUpdateCompanies="getCompanies"
    />
    <AddLogoForm
      v-if="isShowedAddLogoForm"
      @cancel="closeAddLogoForm"
      :handleClose="closeAddLogoForm"
      :companyUuid="currentCompany.uuid"
    />
    <b-modal id="companies-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </TableContainer>
</template>

<script>
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import AddCompanyForm from '@/components/molecules/Companies/AddCompanyForm.vue';
import UpdateCompanyForm from '@/components/molecules/Companies/UpdateCompanyForm.vue';
import AddLogoForm from '@/components/molecules/Companies/AddLogoForm.vue';

export default {
  name: 'CompaniesBlock',
  components: {
    TableContainer,
    ActionIconButton,
    AddCompanyForm,
    UpdateCompanyForm,
    AddLogoForm,
  },
  created() {
    this.getCompanies();
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'contactInfo', label: 'Información de contacto', sortable: true },
        { key: 'acciones' },
      ],
      items: [],
      isShowedAddCompaniesForm: false,
      isShowedUpdateCompaniesForm: false,
      isShowedAddLogoForm: false,
      currentCompany: {
        uuid: '',
        name: '',
        contactInfo: '',
      },
    };
  },
  methods: {
    getCompanies() {
      this.blockScreen();
      this.$store.dispatch('getAllCompanies')
        .then((res) => {
          this.items = res;
          this.$store.commit('setCompanies', res);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    showAddCompaniesForm() {
      this.isShowedAddCompaniesForm = true;
    },
    closeAddCompanyForm() {
      this.isShowedAddCompaniesForm = false;
    },
    showUpdateCompanyForm() {
      this.isShowedUpdateCompaniesForm = true;
    },
    closeUpdateCompanyForm() {
      this.isShowedUpdateCompaniesForm = false;
    },
    setCurrentCompany(objectData) {
      this.currentCompany = {
        uuid: objectData.uuid,
        name: objectData.name,
        contactInfo: objectData.contactInfo,
      };
    },
    editCompany(objectData) {
      this.setCurrentCompany(objectData);

      this.showUpdateCompanyForm();
    },
    deleteCompany(objectData) {
      this.blockScreen();

      const uuid = objectData?.uuid;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteCompany', { uuid })
          .then(() => {
            this.getCompanies();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('companies-error-modal');
          });
      }
    },
    uploadLogo(objectData) {
      this.setCurrentCompany(objectData);

      this.showAddLogoForm();
    },
    showAddLogoForm() {
      this.isShowedAddLogoForm = true;
    },
    closeAddLogoForm() {
      this.isShowedAddLogoForm = false;
    },
    hasItems() {
      return this.items.length > 0;
    },
  },
};
</script>

<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="updateRevision"
      @cancel="cancelAction"
    >
      <FormRevisionContent
        @handleRevisionData="setRevisionData"
        :revisionData="this.revisionData"
        :revisionDataValidation="this.revisionDataValidation"
        @onChangeReceivedDate="onChangeReceivedDate"
        @onChangeRevisedDate="onChangeRevisedDate"
        @onChangeAprovedDate="onChangeAprovedDate"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';
import FormRevisionContent from './FormRevisionContent.vue';

export default {
  name: 'UpdateRevisionForm',
  components: {
    Modal,
    FormCard,
    FormRevisionContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    officeUuid: {
      type: String,
      default: '',
    },
    revisionData: {
      uuid: null,
      receivedDate: null,
      revisedDate: null,
      approvedDate: null,
    },
  },
  data: () => ({
    title: 'Actualizar Revisión',
    revisionDataValidation: {
      receivedDate: null,
      revisedDate: null,
      approvedDate: null,
    },
  }),
  created() {
    if (typeof this.officeUuid !== 'undefined' && this.officeUuid !== '') {
      this.revisionData.officeUuid = this.officeUuid;
    }
  },
  methods: {
    setRevisionData(revisionData) {
      this.revisionData = revisionData;
    },
    updateRevision() {
      if (this.isValidData()) {
        this.blockScreen();

        const { officeUuid } = this;
        const data = this.revisionData;

        this.$store.dispatch('updateRevision', { officeUuid, data })
          .then(() => {
            this.unBlockScreen();
            this.$emit('updateRevisions');
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.revisionDataValidation = {
          receivedDate: getValidationState(this.revisionData?.receivedDate),
        };
      }
    },
    isValidData() {
      return hasData(this.revisionData?.receivedDate);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    onChangeReceivedDate(receivedDate) {
      this.revisionData.receivedDate = receivedDate;
    },
    onChangeRevisedDate(revisedDate) {
      this.revisionData.revisedDate = revisedDate;
    },
    onChangeAprovedDate(approvedDate) {
      this.revisionData.approvedDate = approvedDate;
    },
  },
};
</script>

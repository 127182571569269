<template>
  <div class="TitleView">
    <h1 class="TitleView__title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'TitleView',
  props: {
    title: String,
  },
};
</script>

<style lang="sass">
  @import './TitleView.sass'
</style>

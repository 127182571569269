<template>
  <Modal v-show="showModal">
    <FormCard
      :title="title"
      @accept="addSupplier"
      @cancel="cancelAction"
    >
      <FormSupplierContent
        @handleSupplierData="setSupplierData"
        :supplierData="this.supplierData"
        :supplierDataValidation="this.supplierDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import FormSupplierContent from '@/components/molecules/Supplier/FormSupplierContent.vue';
import { hasData, getValidationState } from '@/helpers/ValidationHelper';

export default {
  name: 'AddSuplierForm',
  components: {
    Modal,
    FormCard,
    FormSupplierContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateSupliers: {},
  },
  data: () => ({
    title: 'Nuevo proveedor',
    supplierData: {},
    supplierDataValidation: {
      name: null,
    },
  }),
  methods: {
    setSupplierData(supplierData) {
      this.supplierData = supplierData;
    },
    addSupplier() {
      if (this.isValidData()) {
        this.blockScreen();

        this.$store.dispatch('addSupplier', this.supplierData)
          .then(() => {
            this.unBlockScreen();
            this.handleUpdateSupliers();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.supplierDataValidation = {
          name: getValidationState(this.supplierData?.name),
        };
      }
    },
    isValidData() {
      return hasData(this.supplierData?.name);
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
  },
};
</script>

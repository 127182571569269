import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';
import {
  hasAdminPermission,
  hasClientPermission,
  hasProviderPermission,
  hasSuperAdminPermission,
  userIsClient,
} from './permissions';
import { getUser, isUserLogged } from './user';
import { ROLE } from '@/helpers/UserHelper';

Vue.use(Vuex);

export default {
  isUserLogged,
  getUser,
  userIsClient,
  hasSuperAdminPermission,
  hasAdminPermission,
  hasClientPermission,
  hasProviderPermission,
  hasCompanies() {
    return typeof state.companies !== 'undefined' && state.companies.length > 0;
  },
  hasProjects() {
    return typeof state.projects !== 'undefined' && state.projects.length > 0;
  },
  hasActiveCompany() {
    return state.activeCompany !== null && typeof state.activeCompany !== 'undefined' && state.activeCompany !== '';
  },
  hasActiveProject() {
    return state.activeProject !== null
      && typeof state.activeProject !== 'undefined'
      && state.activeProject !== 'undefined'
      && state.activeProject !== 'null'
      && state.activeProject !== '';
  },
  getActiveCompany() {
    return state.activeCompany;
  },
  getActiveProject() {
    return state.activeProject;
  },
  getOfficeTypes() {
    return state.officeTypes;
  },
  getActiveProjectName() {
    return state.activeProjectName;
  },
  hasSuppliers() {
    return typeof state.suppliers !== 'undefined' && state.suppliers.length > 0;
  },
  getSuppliers() {
    return state.suppliers;
  },
  isShowedNavMenu() {
    return state.isShowedNavMenu;
  },
  hasOffices() {
    return state.offices && state.offices?.offices?.length > 0;
  },
  getIssueReasons() {
    return state.issueReasons;
  },
  hasIssueReasons() {
    return state.issueReasons && state.issueReasons.length > 0;
  },
  getErrors() {
    return state.errors;
  },
  hasErrors() {
    return state.showErrors;
  },
  getIssues() {
    return state.issues.data;
  },
  hasIssues() {
    return state.issues.data?.length > 0;
  },
  getClients() {
    return state.clients;
  },
  hasClients() {
    return state.clients.length > 0;
  },
  hasUserData() {
    return state.user.email !== null
      && state.user.name !== null
      && state.user.surnames !== null;
  },
  getOffices() {
    return state.offices.offices;
  },
  getUserSupplier() {
    return state.user.supplier;
  },
  isSupplier() {
    return state.user.role === ROLE.PROVIDER;
  },
  getEvents() {
    return state.alerts;
  },
  hasEvents() {
    return state.alerts.length > 0;
  },
};

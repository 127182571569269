<template>
  <div class="container__simple">
    <div
      v-if="this.officesList"
      class="table__container__paginated"
    >
      <div class="text-right">
        <b-button
          variant="primary"
          type="button"
          v-b-modal.exportation-format-modal
        >
          Exportar oficinas
        </b-button>
        <b-modal id="exportation-format-modal" centered ok-only
          title="Elige el formato de exportación"
          @ok="exportPhaseOffices"
          @close="() => { this.exportationFormat = 'excel'; }"
        >
          <b-form-group>
            <b-form-radio v-model="exportationFormat" name="excel-radio" value="excel">
              Excel
            </b-form-radio>
            <b-form-radio v-model="exportationFormat" name="csv-radio" value="csv">
              CSV
            </b-form-radio>
          </b-form-group>
        </b-modal>
      </div>
      <div class="Separator__vertical__st" />
      <b-table
        :items="this.officesList.offices"
        :fields="fields"
        :current-page="currentPage"
        striped
        hover
        bordered
      >
        <template #cell(supplier)="data">
          {{ data.item.supplier.name }}
        </template>
        <template #cell(measurementPhase)="data">
          <button
            @click="editMeasurementPhase(data.item)"
            class="simpleButton"
          >
            <StatusBadget
              :text="data.item.measurementPhase.status"
            />
          </button>
        </template>
        <template #cell(budgetPhase)="data">
          <button
            @click="editBudgetPhase(data.item)"
            class="simpleButton"
          >
            <StatusBadget
              :text="data.item.budgetPhase.status"
            />
          </button>
        </template>
        <template #cell(picPhase)="data">
          <button
            @click="editPicPhase(data.item)"
            class="simpleButton"
          >
            <StatusBadget
              :text="data.item.picPhase.status"
            />
          </button>
        </template>
        <template #cell(installationPhase)="data">
          <button
            @click="editInstallationPhase(data.item)"
            class="simpleButton"
          >
            <StatusBadget
              :text="data.item.installationPhase.status"
            />
          </button>
        </template>
        <template #cell(certificationPhase)="data">
          <button
            @click="editCertificationPhase(data.item)"
            class="simpleButton"
          >
            <StatusBadget
              :text="data.item.certificationPhase.status"
            />
          </button>
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount  ? `${data.item.getFormattedAmount()}€` : ''}}
        </template>
        <template #cell(acciones)="data">
          <div class="actionGroup">
            <ActionIconButton
              :id="`viewOffice-${slugify(data.item.code)}`"
              icon="eye_white.svg"
              type="view"
              :handler="viewOffice"
              :objectData="data.item"
            />
            <ActionIconButton
              v-if="$store.getters.hasAdminPermission"
              icon="edit.svg"
              type="edit"
              :handler="editOffice"
              :objectData="data.item"
            />
            <ActionIconButton
              v-if="$store.getters.hasAdminPermission"
              icon="delete.svg"
              type="delete"
              :handler="handleShowDeleteOffice"
              :objectData="data.item"
            />
            <ActionIconButton
              icon="error.svg"
              type="action1"
              :handler="showAddIssueForm"
              :objectData="data.item"
            />
            <ActionIconButton
              v-if="$store.getters.hasAdminPermission || $store.getters.isSupplier"
              icon="bookmark.svg"
              type="action2"
              :handler="showAddNoteForm"
              :objectData="data.item"
            />
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination
      align="end"
      v-model="currentPage"
      :total-rows="this.officesList.pagination.totalItems"
      :per-page="perPage"
      @page-click="updatePagination"
    ></b-pagination>
    <!--      @input="getNewOffices()"-->
    <UpdateOfficeForm
      v-if="isShowedUpdateOfficeForm"
      :showModal="true"
      @cancel="closeUpdateOfficeForm"
      :officeData="currentOffice"
      :handleClose="closeUpdateOfficeForm"
    />
    <AddNoteForm
      v-if="isShowedAddNoteForm"
      :showModal="true"
      @cancel="closeAddNoteForm"
      :handleClose="closeAddNoteForm"
      :officeUuid="currentOffice.uuid"
    />
    <AddIssueForm
      v-if="isShowedAddIssueForm"
      :showModal="true"
      @cancel="closeAddIssueForm"
      :handleClose="closeAddIssueForm"
      :officeUuid="currentOffice.uuid"
    />
    <UpdateMeasurementForm
      v-if="isShowedUpdateMeasurementForm"
      :showModal="true"
      @cancel="hideUpdateMeasurementForm"
      :officeUuid="currentOffice.uuid"
      :measurementData="currentOffice.measurementPhase"
      :handleClose="hideUpdateMeasurementForm"
    />
    <UpdateBudgetForm
      v-if="isShowedUpdateBudgetForm"
      :showModal="true"
      @cancel="hideUpdateBudgetForm"
      :officeUuid="currentOffice.uuid"
      :budgetData="currentOffice.budgetPhase"
      :handleClose="hideUpdateBudgetForm"
    />
    <UpdatePicForm
      v-if="isShowedUpdatePicForm"
      :showModal="true"
      @cancel="hideUpdatePicForm"
      :officeUuid="currentOffice.uuid"
      :picData="currentOffice.picPhase"
      :handleClose="hideUpdatePicForm"
    />
    <UpdateCertificationForm
      v-if="isShowedUpdateCertificationForm"
      :showModal="true"
      @cancel="hideUpdateCertificationForm"
      :officeUuid="currentOffice.uuid"
      :certificationData="currentOffice.certificationPhase"
      :handleClose="hideUpdateCertificationForm"
    />
    <UpdateInstallationForm
      v-if="isShowedUpdateInstallationForm"
      :showModal="true"
      @cancel="hideUpdateInstallationForm"
      :officeUuid="currentOffice.uuid"
      :installationData="currentOffice.installationPhase"
      :handleClose="hideUpdateInstallationForm"
    />
    <b-modal id="delete-confirm-modal" centered ok-only title="Cuidado">
      <!-- eslint-disable-next-line max-len -->
      <p class="my-4">¿Seguro que deseas borrar una oficina? Recuerda que no debe de haber ninguna incidencia o nota asociada para realizar esta operación.</p>
        <template #modal-footer="">
        <b-button
          size="md"
          variant="light"
          @click="closeDeleteModal"
        >
          Cancelar
        </b-button>
        <b-button
          size="md"
          variant="danger"
          @click="deleteOffice"
        >
          Borrar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import StatusBadget from '@/components/atoms/StatusBadget/StatusBadget.vue';
import UpdateOfficeForm from '@/components/molecules/Offices/FormOffices/UpdateOfficeForm.vue';
import AddNoteForm from '@/components/molecules/Notes/AddNoteForm.vue';
import AddIssueForm from '@/components/molecules/Issues/AddIssueForm.vue';
import UpdateMeasurementForm from '@/components/molecules/Offices/Phases/Measurement/UpdateMeasurementForm.vue';
import UpdateBudgetForm from '@/components/molecules/Offices/Phases/Budget/UpdateBudgetForm.vue';
import UpdatePicForm from '@/components/molecules/Offices/Phases/Pic/UpdatePicForm.vue';
import UpdateCertificationForm from '@/components/molecules/Offices/Phases/Certification/UpdateCertificationForm.vue';
import UpdateInstallationForm from '@/components/molecules/Offices/Phases/Installation/UpdateInstallationForm.vue';
import exportFile from '@/helpers/FileHelper';
import { slugify } from '../../../../helpers/URLHelper';

export default {
  name: 'OfficesListView',
  components: {
    ActionIconButton,
    StatusBadget,
    UpdateOfficeForm,
    AddNoteForm,
    AddIssueForm,
    UpdateMeasurementForm,
    UpdateBudgetForm,
    UpdatePicForm,
    UpdateCertificationForm,
    UpdateInstallationForm,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      fields: [
        { key: 'supplier', label: 'Proveedor', sortable: true },
        { key: 'code', label: 'Código', sortable: true },
        { key: 'lot', label: 'Lote', sortable: true },
        { key: 'type', label: 'Tipo', sortable: true },
        { key: 'province', label: 'Provincia', sortable: true },
        { key: 'measurementPhase', label: 'Medición', sortable: true },
        { key: 'budgetPhase', label: 'Presupuesto', sortable: true },
        { key: 'picPhase', label: 'PIC', sortable: true },
        { key: 'installationPhase', label: 'Instalación', sortable: true },
        { key: 'certificationPhase', label: 'Certificación', sortable: true },
        { key: 'amount', label: 'Importe', sortable: true },
        { key: 'acciones' },
      ],
      items: [],
      isShowedUpdateOfficeForm: false,
      currentOffice: {
        uuid: null,
        code: null,
        code2: null,
        type: null,
        lot: null,
        dtTerritorial: null,
        address: null,
        city: null,
        province: null,
        autonomousCommunity: null,
        postalCode: null,
        name: null,
        posterType: null,
        posterSize: null,
        bannerNumber: null,
        amount: null,
        amountWithVAT: null,
        active: true,
        invoiced: null,
        project: null,
        projectObject: null,
        supplier: null,
        supplierObject: null,
        measurementPhase: null,
        budgetPhase: null,
        picPhase: null,
        installationPhase: null,
        certificationPhase: null,
      },
      isShowedAddIssueForm: false,
      isShowedAddNoteForm: false,
      isShowedUpdateMeasurementForm: false,
      isShowedUpdateBudgetForm: false,
      isShowedUpdatePicForm: false,
      isShowedUpdateCertificationForm: false,
      isShowedUpdateInstallationForm: false,
      exportationFormat: 'excel',
      slugify,
    };
  },
  mounted() {
    const hashObject = {};
    const hashContent = document.location.hash.split('#')[1];
    if (!hashContent) {
      return;
    }

    hashContent.split(',').forEach((param) => {
      const [key, value] = param.split('=');
      hashObject[key] = value;
    });

    const officeCode = decodeURIComponent(hashObject.code);

    this.doSearch(officeCode);
    this.runDelayed(() => {
      const button = document.querySelector(`#viewOffice-${slugify(officeCode)}`);
      if (!button) {
        return;
      }
      button.click();
      this.runDelayed(() => {
        const documentation = document.querySelector('a.documentation');
        if (!documentation) {
          return;
        }
        documentation.click();
      });
    }, 1000);
  },
  computed: {
    getCurrentPage() {
      return this.currentPage;
    },
    getItemsPerPage() {
      return this.perPage;
    },
    officesList() {
      return this.$store.state.offices;
    },
  },
  methods: {
    async runDelayed(callback, time = 500) {
      await new Promise((resolve) => setTimeout(() => {
        callback();
        resolve();
      }, time));
    },
    doSearch(officeCode) {
      this.blockScreen();
      const search = {
        freeFilter: officeCode,
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };
      this.$store.dispatch('getAllOffices', search)
        .then(() => {
          this.$emit('search', search);
        })
        .finally(() => {
          this.unBlockScreen();
        });
    },
    exportPhaseOffices() {
      this.blockScreen();
      const data = {
        companyId: this.filters.companyId || '',
        projectId: this.filters.projectId || '',
        supplierId: this.filters.supplierId || '',
        phase: this.filters.phase || '',
        phaseStatus: this.filters.phaseStatus || '',
        freeFilter: this.filters.freeFilter || '',
        type: this.filters.type || '',
        lot: this.filters.lot || '',
        format: this.exportationFormat,
      };
      this.$store.dispatch('exportPhaseOffices', data)
        .then((res) => {
          exportFile(`oficinas.${this.exportationFormat === 'excel' ? 'xlsx' : 'csv'}`, res.data);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    updatePagination(event, page) {
      this.$emit('handlePaginationChange', page);
    },
    viewOffice(objectData) {
      this.$emit('handleShowOfficeView', objectData.uuid);
    },
    setCurrentOffice(objectData) {
      this.currentOffice = {
        uuid: objectData.uuid,
        code: objectData.code,
        code2: objectData.code2,
        type: objectData.type,
        lot: objectData.lot,
        dtTerritorial: objectData.dtTerritorial,
        address: objectData.address,
        city: objectData.city,
        province: objectData.province,
        autonomousCommunity: objectData.autonomousCommunity,
        postalCode: objectData.postalCode,
        name: objectData.name,
        posterType: objectData.posterType,
        posterSize: objectData.posterSize,
        bannerNumber: objectData.bannerNumber,
        amount: objectData.amount,
        amountWithVAT: objectData.amountWithVAT,
        active: objectData.active,
        invoiced: objectData.invoiced,
        project: objectData.project?.uuid,
        projectObject: objectData.project,
        supplier: objectData.supplier?.uuid,
        supplierObject: objectData.supplier,
        measurementPhase: objectData.measurementPhase,
        budgetPhase: objectData.budgetPhase,
        picPhase: objectData.picPhase,
        installationPhase: objectData.installationPhase,
        certificationPhase: objectData.certificationPhase,
      };
    },
    editOffice(objectData) {
      this.setCurrentOffice(objectData);

      this.showUpdateOfficeForm();
    },
    handleShowDeleteOffice(objectData) {
      this.setCurrentOffice(objectData);

      this.$bvModal.show('delete-confirm-modal');
    },
    deleteOffice() {
      this.$bvModal.hide('delete-confirm-modal');

      this.blockScreen();

      const { uuid } = this.currentOffice;

      if (uuid !== 'undefined') {
        this.$store.dispatch('deleteOffice', { uuid })
          .then(() => {
            this.currentDeleteOfficeUuid = null;
            this.getProjects();
          })
          .catch(() => {
            this.currentDeleteOfficeUuid = null;
            this.unBlockScreen();
          });
      }
    },
    showUpdateOfficeForm() {
      this.isShowedUpdateOfficeForm = true;
    },
    closeUpdateOfficeForm() {
      this.isShowedUpdateOfficeForm = false;
    },
    closeDeleteModal() {
      this.$bvModal.hide('delete-confirm-modal');
    },
    showAddNoteForm(objectData) {
      this.setCurrentOffice(objectData);

      this.isShowedAddNoteForm = true;
    },
    closeAddNoteForm() {
      this.isShowedAddNoteForm = false;
    },
    showAddIssueForm(objectData) {
      this.setCurrentOffice(objectData);

      this.isShowedAddIssueForm = true;
    },
    closeAddIssueForm() {
      this.isShowedAddIssueForm = false;
    },
    editMeasurementPhase(objectData) {
      if (this.$store.getters.hasAdminPermission) {
        this.setCurrentOffice(objectData);

        this.showUpdateMeasurementForm();
      }
    },
    showUpdateMeasurementForm() {
      this.isShowedUpdateMeasurementForm = true;
    },
    hideUpdateMeasurementForm() {
      this.isShowedUpdateMeasurementForm = false;
    },
    editBudgetPhase(objectData) {
      if (this.$store.getters.hasAdminPermission) {
        this.setCurrentOffice(objectData);

        this.showUpdateBudgetForm();
      }
    },
    showUpdateBudgetForm() {
      this.isShowedUpdateBudgetForm = true;
    },
    hideUpdateBudgetForm() {
      this.isShowedUpdateBudgetForm = false;
    },
    editPicPhase(objectData) {
      if (this.$store.getters.hasAdminPermission) {
        this.setCurrentOffice(objectData);

        this.showUpdatePicForm();
      }
    },
    showUpdatePicForm() {
      this.isShowedUpdatePicForm = true;
    },
    hideUpdatePicForm() {
      this.isShowedUpdatePicForm = false;
    },
    editCertificationPhase(objectData) {
      if (this.$store.getters.hasAdminPermission) {
        this.setCurrentOffice(objectData);

        this.showUpdateCertificationForm();
      }
    },
    showUpdateCertificationForm() {
      this.isShowedUpdateCertificationForm = true;
    },
    hideUpdateCertificationForm() {
      this.isShowedUpdateCertificationForm = false;
    },
    editInstallationPhase(objectData) {
      if (this.$store.getters.hasAdminPermission) {
        this.setCurrentOffice(objectData);

        this.showUpdateInstallationForm();
      }
    },
    showUpdateInstallationForm() {
      this.isShowedUpdateInstallationForm = true;
    },
    hideUpdateInstallationForm() {
      this.isShowedUpdateInstallationForm = false;
    },
  },
};
</script>

export default class Pagination {
  constructor(paginationData) {
    this.items = paginationData.items;
    this.totalItems = paginationData.totalItems;
    this.offset = paginationData.offset;
  }

  get getItems() {
    return this.items;
  }

  get getTotalItems() {
    return this.totalItems;
  }

  get getOffset() {
    return this.offset;
  }
}

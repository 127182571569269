<template>
  <div>
    <b-form-group label="Tipo de oficina">
      <b-form-select
        v-model="selectedOfficeType"
        :options="options"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'OfficeTypeSelector',
  methods: {
    ...mapMutations([
      'setActiveOfficeType',
      'emptyOfficeTypes',
    ]),
    resetOfficeType() {
      this.selectedOfficeType = null;
    },
  },
  computed: {
    selectedOfficeType: {
      get() {
        return this.$store.state.activeOfficeType;
      },
      set(newSelectedOfficeType) {
        this.setActiveOfficeType(newSelectedOfficeType);
        this.$emit('setOfficeType', newSelectedOfficeType);
      },
    },
    options: {
      get() {
        return this.$store.getters.getOfficeTypes;
      },
    },
  },
  watch: {
    activeProject() {
      this.$emit('setOfficeType', null);
      this.selectedOfficeType = null;
    },
  },
};
</script>

import IssueFactory from '../domain/services/IssueFactory';
import getAxiosWithAuthHeader from '../helpers/AuthHelper';
import { calculateOffset, getOffsetUrl } from '../helpers/RouterHelper';

const ISSUES = '/issues';
const OFFICES = '/offices';

class IssueAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getAllIssues(data) {
    const offset = calculateOffset(data.currentPage, data.itemsPerPage);
    const url = getOffsetUrl(ISSUES, offset);

    const companyId = typeof data.companyId !== 'undefined' ? `&companyId=${data.companyId}` : '&companyId=';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '&projectId=';
    const officeId = typeof data.officeId !== 'undefined' ? `&officeId=${data.officeId}` : '';
    const supplierId = (typeof data.supplierId !== 'undefined' && data.supplierId !== null) ? `&supplierId=${data.supplierId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}${officeId}${supplierId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => IssueFactory.getAllIssuesRequest(res.data))
      .catch((error) => Promise.reject(error));
  }

  exportAllIssues(data) {
    const url = `${ISSUES}/report?`;

    const companyId = typeof data.companyId !== 'undefined' ? `&companyId=${data.companyId}` : '&companyId=';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '&projectId=';
    const officeId = typeof data.officeId !== 'undefined' ? `&officeId=${data.officeId}` : '';
    const supplierId = (typeof data.supplierId !== 'undefined' && data.supplierId !== null) ? `&supplierId=${data.supplierId}` : '';
    const format = `&format=${data.format}`;

    const urlWithParams = `${url}${companyId}${projectId}${officeId}${supplierId}${format}`;

    return this.client
      .get(urlWithParams, {
        responseType: 'blob',
      })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  getIssue(uuid) {
    const url = `${ISSUES}/${uuid}`;

    return this.client
      .get(url)
      .then((res) => IssueFactory.getIssue(res.data?.resource))
      .catch((error) => Promise.reject(error));
  }

  addIssue(data) {
    return this.client
      .post(ISSUES, data)
      .then()
      .catch((error) => Promise.reject(error));
  }

  updateIssue(data) {
    const url = `${ISSUES}/${data.uuid}`;

    return this.client
      .put(url, data.issueData)
      .then()
      .catch((error) => Promise.reject(error));
  }

  deleteIssue(uuid) {
    const url = `${ISSUES}/${uuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }

  getIssueReasonReport(data) {
    const url = `${ISSUES}/report/reason`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => IssueFactory.getIssueReasonReports(res.data?.data.typologyCountersBySupplier))
      .catch((error) => Promise.reject(error));
  }

  getIssueSupplierReport(data) {
    const url = `${ISSUES}/report/supplier`;

    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '';

    const urlWithParams = `${url}${companyId}${projectId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => IssueFactory.getIssueSupplierReports(res.data?.data.countersBySupplier))
      .catch((error) => Promise.reject(error));
  }

  getIssueReasons() {
    const url = `${ISSUES}/reasons`;

    return this.client
      .get(url)
      .then((res) => IssueFactory.getIssueReasons(res.data?.collection))
      .catch((error) => Promise.reject(error));
  }

  getIssueStats(data) {
    const companyId = typeof data.companyId !== 'undefined' ? `?companyId=${data.companyId}` : '?companyId=';
    const projectId = typeof data.projectId !== 'undefined' ? `&projectId=${data.projectId}` : '&projectId=';
    const supplierId = (typeof data.supplierId !== 'undefined' && data.supplierId !== null) ? `&supplierId=${data.supplierId}` : '';

    const urlWithParams = `${ISSUES}/stats${companyId}${projectId}${supplierId}`;

    return this.client
      .get(urlWithParams)
      .then((res) => IssueFactory.getIssueStats(res.data?.data))
      .catch((error) => Promise.reject(error));
  }

  uploadIssueDocument(requestData, formData) {
    const url = `${OFFICES}/${requestData.officeUuid}/issue/${requestData.issueId}/document`;

    return this.client
      .post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      .then()
      .catch((error) => Promise.reject(error));
  }

  downloadIssueDocument(requestData) {
    const url = `${OFFICES}/${requestData.officeUuid}/issue/document/${requestData.documentUuid}`;

    return this.client
      .get(url,
        {
          responseType: 'blob',
        })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  countIssuesByOffice(requestData) {
    const url = `${ISSUES}/office/${requestData.officeId}`;

    return this.client
      .get(url)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }
}

export default IssueAPI;

<template>
  <private-template>
    <template v-slot:private-template>
      <div class="viewContainer">
        <TitleView title="Importador Fase Certificación"/>
        <div class="viewContainer__content">
          <ImporterManager
            action="uploadCertification"
          />
        </div>
      </div>
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/templates/PrivateTemplate.vue';
import TitleView from '@/components/atoms/TitleView/TitleView.vue';
import ImporterManager from '@/components/molecules/Importers/ImporterManager.vue';

export default {
  name: 'CertificationImporterView',
  components: {
    PrivateTemplate,
    TitleView,
    ImporterManager,
  },
};
</script>

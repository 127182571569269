import Company from '../models/Company';

class CompanyFactory {
  constructor() {
    this.companies = [];
  }

  getCompany(companyData) {
    this.companies = companyData.map((company) => {
      const newCompany = new Company({
        uuid: company.uuid,
        name: company.name,
        contactInfo: company.contactInfo,
      });

      return newCompany;
    });

    return this.companies;
  }
}

export default new CompanyFactory();

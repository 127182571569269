import Vue from 'vue';
import moment from 'moment';
import { getPhaseLabel } from '@/helpers/PhaseHelper';
import { getStatusLabel } from '@/helpers/status/IssueStatusHelper';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins/bootstrap-vue';
import '@/plugins/axios';
import '@/plugins/vue-inline-svg';
import '@/plugins/vue-screen';
import '@/style/custom.sass';
import '@/plugins/croppie';

Vue.config.productionTip = false;

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }

  return value;
});

Vue.filter('toString', (value) => {
  if (typeof value !== 'undefined') {
    return String(value);
  }

  return value;
});

Vue.filter('getPhaseLabel', (value) => {
  if (value) {
    return getPhaseLabel(value);
  }

  return value;
});

Vue.filter('getStatusLabel', (value) => {
  if (value) {
    return getStatusLabel(value);
  }

  return value;
});

Vue.mixin({
  methods: {
    blockScreen() {
      this.$store.commit('showLoadderScreen');
    },
    unBlockScreen() {
      this.$store.commit('hideLoadderScreen');
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

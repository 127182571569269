export default class IssueReasonReportCounters {
  constructor(issueReasonReportCountersData) {
    this.banderolas = issueReasonReportCountersData.banderolas;
    this.mostradorDeCaja = issueReasonReportCountersData.mostradorDeCaja;
    this.frontCaja = issueReasonReportCountersData.frontCaja;
    this.elementosInteriores = issueReasonReportCountersData.elementosInteriores;
    this.elementosExteriores = issueReasonReportCountersData.elementosExteriores;
    this.rotulosCorporeos = issueReasonReportCountersData.rotulosCorporeos;
    this.rotulosDeLona = issueReasonReportCountersData.rotulosDeLona;
    this.cajeros = issueReasonReportCountersData.cajeros;
    this.otros = issueReasonReportCountersData.otros;
  }

  get getBanderolas() {
    return this.banderolas;
  }

  get getMostradorDeCaja() {
    return this.mostradorDeCaja;
  }

  get getFrontCaja() {
    return this.frontCaja;
  }

  get getElementosInteriores() {
    return this.elementosInteriores;
  }

  get getElementosExteriores() {
    return this.elementosExteriores;
  }

  get getRotulosCorporeos() {
    return this.rotulosCorporeos;
  }

  get getRotulosDeLona() {
    return this.rotulosDeLona;
  }

  get getCajeros() {
    return this.cajeros;
  }

  get getOtros() {
    return this.otros;
  }
}

export default function getObjectByUuid(list, uuid) {
  let name = null;

  for (let i = 0; i < list.length; i += 1) {
    if (list[i].value === uuid) {
      name = list[i].text;
    }
  }

  return {
    name,
    uuid,
  };
}

<template>
  <div>
    <b-spinner
      class="mr-3"
      v-if="isLoadingAvatar"
      variant="secondary"
      label="Spinning"
    />
    <div class="UserMenuAction" v-if="!isLoadingAvatar">
      <button
        id="popover-reactive-1"
        ref="button"
        class="UserMenuAction__avatarIconButton mr-3"
      >
        <img
          :src="user.avatar || getDefaultAvatar()"
          class="UserMenuAction__avatarIcon"
        />
      </button>
      <b-popover
        target="popover-reactive-1"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        container="my-container"
        ref="popover"
        @show="onShow"
        @shown="onShown"
        @hidden="onHidden"
      >
        <div class="UserMenuAction__modal">
          <div class="UserMenuAction__closeButtonContainer text-right">
            <button @click.prevent="onClose" class="close" aria-label="Close">
              <span class="d-inline-block" aria-hidden="true">&times;</span>
            </button>
          </div>

          <button
            @click.prevent="showAddAvatarForm"
            class="UserMenuAction__avatarButton"
          >
            <img
              class="UserMenuAction__avatar"
              :src="user.avatar || getDefaultAvatar()"
            />
          </button>
          <div class="Separator__vertical__st" />
          <p>{{ this.$store.getters.getUser.name }}</p>
          <div class="Separator__vertical__sm" />
          <p>{{ this.$store.getters.getUser.surnames }}</p>
          <div class="Separator__vertical__sm" />
          <p>{{ this.$store.getters.getUser.email }}</p>
          <div class="Separator__vertical__st" />
          <b-button
            block
            size="sm"
            variant="light"
            @click="editUserData"
          >
            Edit. nombre y apellidos
          </b-button>
          <b-button
            block
            size="sm"
            variant="light"
            @click="editUserPassword"
          >
            Cambio de contraseña
          </b-button>
          <b-button
            block
            size="sm"
            variant="danger"
            @click.prevent="logout"
          >
            Cerrar sesión
          </b-button>
        </div>
      </b-popover>
      <AddAvatarForm
        v-if="isShowedAddAvatarForm"
        @cancel="closeAddAvatarForm"
        @handleGetAvatar="getAvatar"
        @handleClose="closeAddAvatarForm"
      />
      <UpdateUserDataForm
        v-if="isShowedUpdateUserDataForm"
        :showModal="true"
        @cancel="closeUpdateUserDataForm"
        :handleClose="closeUpdateUserDataForm"
        @handleUpdateUserData="getUserData"
      />
      <UpdateUserPasswordForm
        v-if="isShowedUpdateUserPasswordForm"
        :showModal="true"
        @cancel="closeUpdateUserPasswordForm"
        :handleClose="closeUpdateUserPasswordForm"
      />
    </div>
  </div>
</template>

<script>
import AddAvatarForm from '@/components/molecules/Users/AddAvatarForm.vue';
import UpdateUserDataForm from '@/components/molecules/Users/UpdateUserDataForm.vue';
import UpdateUserPasswordForm from '@/components/molecules/Users/UpdateUserPasswordForm.vue';
import Smiley from '@/assets/smiley.svg';

export default {
  name: 'UserMenuAction',
  components: {
    AddAvatarForm,
    UpdateUserDataForm,
    UpdateUserPasswordForm,
  },
  data() {
    return {
      popoverShow: false,
      isShowedAddAvatarForm: false,
      user: {
        avatar: null,
      },
      isLoadingAvatar: false,
      isShowedUpdateUserDataForm: false,
      isShowedUpdateUserPasswordForm: false,
    };
  },
  created() {
    this.getUserInit();
  },
  methods: {
    getUserInit() {
      const userUuid = this.$store.getters.getUser.uuid;

      if (!this.$store.getters.hasUserData) {
        this.getUser(userUuid);
      }

      this.getUserAvatar();
    },
    getUser(userUuid) {
      this.blockScreen();
      this.$store.dispatch('getUser', userUuid)
        .then(() => {
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
        });
    },
    getUserAvatar() {
      const userUuid = this.$store.getters.getUser.uuid;

      this.showLoader();

      this.$store.dispatch('getAvatar', userUuid)
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          this.user.avatar = fileURL;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
    showAddAvatarForm() {
      this.popoverShow = false;
      this.isShowedAddAvatarForm = true;
    },
    closeAddAvatarForm() {
      this.isShowedAddAvatarForm = false;
    },
    onClose() {
      this.popoverShow = false;
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
    },
    logout() {
      this.$store.commit('logout');
    },
    getDefaultAvatar() {
      return Smiley;
    },
    getAvatar() {
      this.getUserAvatar();
      this.closeAddAvatarForm();
    },
    showLoader() {
      this.isLoadingAvatar = true;
    },
    hideLoader() {
      this.isLoadingAvatar = false;
    },
    showUpdateUserDataForm() {
      this.isShowedUpdateUserDataForm = true;
    },
    closeUpdateUserDataForm() {
      this.isShowedUpdateUserDataForm = false;
    },
    editUserData() {
      this.showUpdateUserDataForm();

      this.popoverShow = false;
    },
    getUserData() {
      const userUuid = this.$store.getters.getUser.uuid;

      this.getUser(userUuid);
      this.closeUpdateUserDataForm();
    },
    showUpdateUserPasswordForm() {
      this.isShowedUpdateUserPasswordForm = true;
    },
    closeUpdateUserPasswordForm() {
      this.isShowedUpdateUserPasswordForm = false;
    },
    editUserPassword() {
      this.showUpdateUserPasswordForm();

      this.popoverShow = false;
    },
  },
};
</script>

<style lang="sass">
  @import './UserMenuAction.sass'
</style>

<template>
  <form>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <SelectorForm
          selectLabel="Estado"
          :selectFormOptions="selectFormOptions"
          @handlerSelect="onChangeStatus"
          :selectedOption="status"
          :isValid="this.certificationDataValidation.status"
          :disabled="!this.$store.getters.hasAdminPermission"
        />
      </b-col>
      <b-col>
        <b-form-group
          label="Fecha Realización"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="receivedDate"
            v-model="receivedDate"
            placeholder="Fecha Realización"
            @context="onChangeReceivedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <b-form-group
          label="Fecha Revisión"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="revisedDate"
            :disabled="this.$store.getters.isSupplier"
            v-model="revisedDate"
            placeholder="Fecha Revisión"
            @context="onChangeRevisedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Fecha Aprobación"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="approvedDate"
            v-model="approvedDate"
            placeholder="Fecha Aprobación"
            :disabled="this.$store.getters.isSupplier"
            @context="onChangeAprovedDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import {
  getStatusSelectorOptionCertification,
} from '@/helpers/StatusHelper';

export default {
  name: 'FormCertificationContent',
  components: { SelectorForm },
  props: {
    certificationData: {
      type: Object,
      default: () => {},
    },
    certificationDataValidation: Object,
  },
  data() {
    return {
      selectFormOptions: getStatusSelectorOptionCertification(),
      status: this.certificationData.status,
      receivedDate: this.certificationData.receivedDate,
      revisedDate: this.certificationData.revisedDate,
      approvedDate: this.certificationData.approvedDate,
      comments: this.certificationData.comments,
    };
  },
  methods: {
    onChangeStatus(data) {
      this.$emit('onChangeStatus', data);
    },
    onChangeReceivedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeReceivedDate', data.activeYMD);
      } else {
        this.$emit('onChangeReceivedDate', null);
      }
    },
    onChangeRevisedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeRevisedDate', data.activeYMD);
      } else {
        this.$emit('onChangeRevisedDate', null);
      }
    },
    onChangeAprovedDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeAprovedDate', data.activeYMD);
      } else {
        this.$emit('onChangeAprovedDate', null);
      }
    },
    onChangeComments(data) {
      if (data.comments !== null) {
        this.$emit('onChangeComments', data);
      }
    },
  },
};
</script>

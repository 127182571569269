<template>
  <TableContainer
    title="Oficinas"
    subtitle="Consulta de todas las oficinas"
  >
    <div class="OfficesSearchView table__container">
      <b-row>
        <b-col class="col-12 col-lg-3">
          <SelectorProject
            @change="changeProject()"
          />
        </b-col>
        <b-col class="col-12 col-lg-3">
          <b-form-group label="Selecciona una fase">
            <b-form-select
              v-model="selectedPhase"
              :options="phaseOptions"
              @change.native="changePhase"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-lg-2">
          <b-form-group label="Selecciona un estado">
            <b-form-select
              v-model="selectedStatus"
              :options="statusOptions"
              @change.native="changeStatus"
              :disabled="isStatusActive"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-lg-1">
          <b-form-group label="Buscar">
            <b-button
              variant="secondary"
              type="button"
              @click.prevent="handleSearch"
              block
            >
              <img
                :src="require('@/assets/search.svg')"
                alt="search"
              >
            </b-button>
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-lg-1">
          <b-form-group label="Limpiar">
            <b-button
              variant="secondary"
              type="button"
              @click.prevent="handleClean"
              block
            >
              <img
                :src="require('@/assets/cancel.svg')"
                alt="cancel"
              >
            </b-button>
          </b-form-group>
        </b-col>
        <b-col
          class="col-12 col-lg-2"
          v-if="$store.getters.hasAdminPermission"
        >
          <b-form-group label="Alta nueva oficina">
            <b-button
              variant="primary"
              type="button"
              @click.prevent="handleAddOffice"
              block
            >
              <img
                :src="require('@/assets/add.svg')"
                alt="add"
              >
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col class="col-12 col-lg-3">
          <OfficeTypeSelector
            ref="officeTypeSelector"
            @setOfficeType="setOfficeType"
          />
        </b-col>
        <b-col class="col-12 col-lg-3">
          <SimpleInput
            inputLabel="Campo libre"
            inputId="searchOfficeFreeParam"
            inputType="text"
            inputPlaceholder="Campo libre"
            :inputValue="selectedOfficeFreeParam"
            @handlerText="setOfficeFreeParam"
          />
        </b-col>
        <b-col class="col-12 col-lg-3">
          <SelectorForm
            selectLabel="Proveedor"
            :selectFormOptions="supplierOptions"
            @handlerSelect="setSupplier"
            :selectedOption="this.selectedSupplier"
            :disabled="this.$store.getters.isSupplier"
          />
        </b-col>
        <b-col class="col-12 col-lg-2">
          <SelectorForm
            selectLabel="Lote"
            :selectFormOptions="lotOptions"
            @handlerSelect="setLot"
            :selectedOption="this.selectedLot"
          />
        </b-col>
        <b-col class="col-12 col-lg-1">
          <SimpleCheckbox
            selectLabel="Solo inactivas"
            @handlerSelect="setOnlyInactiveOffices"
          />
        </b-col>
        <b-col class="col-12 col-lg-2">
          <b-badge variant="success">
            {{ `Total reg: ${getTotalItems}`}}
          </b-badge>
        </b-col>
      </b-row>
    </div>
  </TableContainer>
</template>

<script>
import { mapState } from 'vuex';
import SimpleInput from '@/components/atoms/Forms/SimpleInput/SimpleInput.vue';
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import SimpleCheckbox from '@/components/atoms/Forms/SimpleCheckbox/SimpleCheckbox.vue';
import SelectorProject from '@/components/atoms/Project/SelectorProject.vue';
import TableContainer from '@/components/atoms/Tables/TableContainer/TableContainer.vue';
import OfficeTypeSelector from '@/components/molecules/Offices/OfficeTypeSelector/OfficeTypeSelector.vue';
import { getPhaseSelectorOptions } from '@/helpers/PhaseHelper';
import { getStatusSelectorOptions, getStatusSelectorOptionsByPhase } from '@/helpers/StatusHelper';

export default {
  name: 'OfficesSearchView',
  components: {
    TableContainer,
    SimpleInput,
    SelectorForm,
    SelectorProject,
    OfficeTypeSelector,
    SimpleCheckbox,
  },
  data() {
    return {
      selectedPhase: null,
      selectedStatus: null,
      selectedOfficeFreeParam: null,
      selectedSupplier: null,
      selectedOfficeType: null,
      selectedLot: null,
      onlyInactive: false,
      phaseOptions: getPhaseSelectorOptions(),
      statusOptions: getStatusSelectorOptions(),
      supplierOptions: [
        { value: null, text: 'Seleccionar proveedor' },
      ],
      lotOptions: [
        { value: null, text: 'Seleccionar lote' },
      ],
    };
  },
  mounted() {
    const {
      companyId, projectId, phase, status, supplierId,
    } = this.$route.query;
    if (companyId) {
      this.$store.commit('setActiveCompany', companyId);
    }
    if (projectId) {
      this.$store.commit('setActiveProject', projectId);
    }
    if (phase) {
      this.selectedPhase = phase;
      if (status) {
        this.selectedStatus = status;
      }
    }
    if (supplierId) {
      this.selectedSupplier = supplierId;
    }

    this.initSuppliers();
    this.initLots();
    this.handleSearch();
  },
  methods: {
    changeProject() {
      this.initSuppliers(true);
      this.initLots();
      this.handleClean();
    },
    initSuppliers(force) {
      if (this.$store.getters.hasSuppliers && !force) {
        this.addSuppliers(this.$store.state.suppliers);

        this.unBlockScreen();
      } else {
        this.blockScreen();
        this.supplierOptions = [
          { value: null, text: 'Seleccionar proveedor' },
        ];
        const data = {
          projectId: this.$store.getters.getActiveProject,
        };
        this.$store.dispatch('getAllSuppliers', data)
          .then((res) => {
            this.addSuppliers(res.suppliers);

            this.unBlockScreen();
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('addOffice-error-modal');
          });
      }
    },
    initLots() {
      this.blockScreen();
      this.lotOptions = [
        { value: null, text: 'Seleccionar lote' },
      ];
      const data = {
        companyId: this.$store.getters.getActiveCompany,
        projectId: this.$store.getters.getActiveProject,
      };
      this.$store.dispatch('getLotsFromCompanyAndOffice', data)
        .then((res) => {
          this.addLots(res);
          this.unBlockScreen();
        })
        .catch(() => {
          this.unBlockScreen();
          this.$bvModal.show('addOffice.error-modal');
        });
    },
    handleSearch(page) {
      this.$store.commit('emptyOffice');
      if (
        this.$store.getters.hasActiveCompany && this.$store.getters.hasActiveProject
        // this.$store.getters.getActiveCompany
        // && this.$store.getters.getActiveProject
        // && this.$store.getters.getActiveCompany !== 'null'
        // && this.$store.getters.getActiveProject !== 'null'
      ) {
        this.blockScreen();
        const currentPage = typeof page === 'number' ? page : 1;
        const itemsPerPage = 10;
        const companyId = this.$store.getters.getActiveCompany;
        const projectId = this.$store.getters.getActiveProject;
        const supplierId = this.selectedSupplier || null;
        const phase = this.selectedPhase;
        const phaseStatus = this.selectedStatus;
        const type = this.selectedOfficeType;
        const freeFilter = this.selectedOfficeFreeParam;
        const lot = this.selectedLot;
        const setOnlyInactive = this.onlyInactive;
        if (this.$store.getters.isSupplier && !this.$store.getters.getUserSupplier) {
          this.$store.dispatch('getUser', this.$store.state.user.uuid)
            .then(() => {
              const userSupplier = this.$store.getters.getUserSupplier;
              this.selectedSupplier = userSupplier;
              const requestData = {
                currentPage,
                itemsPerPage,
                companyId,
                projectId,
                supplierId,
                phase,
                phaseStatus,
                type,
                freeFilter,
                userSupplier,
                lot,
                onlyInactive: setOnlyInactive,
              };

              this.$store.dispatch('getAllOffices', requestData)
                .then(() => {
                  this.unBlockScreen();
                  this.$emit('search', requestData);
                })
                .catch(() => {
                  this.unBlockScreen();
                });
            });
        } else {
          const userSupplier = this.$store.getters.getUserSupplier
            ? this.$store.getters.getUserSupplier
            : this.selectedSupplier;
          this.selectedSupplier = userSupplier;
          const requestData = {
            currentPage,
            itemsPerPage,
            companyId,
            projectId,
            supplierId,
            phase,
            phaseStatus,
            type,
            freeFilter,
            userSupplier,
            lot,
            onlyInactive: setOnlyInactive,
          };

          this.$store.dispatch('getAllOffices', requestData)
            .then(() => {
              this.unBlockScreen();
              this.$emit('search', requestData);
            })
            .catch(() => {
              this.unBlockScreen();
            });
        }
      } else {
        this.$store.commit('emptyOfficesList');
      }
    },
    handleAddOffice() {
      this.$emit('handleShowAddOfficeForm');
    },
    addSupplierOption(option) {
      this.supplierOptions.push(option);
    },
    addSuppliers(suppliers) {
      suppliers.forEach((element) => {
        const option = {
          value: element.uuid,
          text: element.name,
        };

        this.addSupplierOption(option);
      });
    },
    addLotOption(option) {
      this.lotOptions.push(option);
    },
    addLots(offices) {
      offices.forEach((office) => {
        const option = {
          value: office.lot,
          text: office.lot,
        };
        this.addLotOption(option);
      });
    },
    changePhase(e) {
      this.selectedPhase = e.target.value;
      this.statusOptions = getStatusSelectorOptionsByPhase(this.selectedPhase);
    },
    changeStatus(e) {
      this.selectedStatus = e.target.value;
    },
    setOfficeFreeParam(data) {
      this.selectedOfficeFreeParam = data;
    },
    setSupplier(data) {
      this.selectedSupplier = data;
    },
    handleClean() {
      this.selectedPhase = null;
      this.selectedStatus = null;
      this.selectedOfficeFreeParam = null;
      this.selectedSupplier = null;
      this.selectedOfficeType = null;
      this.selectedLot = null;
      this.onlyInactive = false;
      this.$refs.officeTypeSelector.resetOfficeType();

      this.handleSearch();
    },
    setOfficeType(officeType) {
      this.selectedOfficeType = officeType;
    },

    setLot(lot) {
      this.selectedLot = lot;
    },

    setOnlyInactiveOffices(onlyInactive) {
      this.onlyInactive = onlyInactive;
    },
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
    isStatusActive() {
      return this.selectedPhase === null || this.selectedPhase === '';
    },
    getSelectedSupplier() {
      return this.selectedSupplier;
    },
    officesList() {
      return this.$store.state.offices;
    },
    getTotalItems() {
      return this.officesList.pagination?.totalItems || '0';
    },
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleSearch();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './OfficesSearchView.sass'
</style>

import Note from '../models/Note';

class NoteFactory {
  constructor() {
    this.notes = [];
  }

  getAllNote(noteData) {
    this.notes = noteData.map((note) => {
      const newNote = new Note({
        uuid: note.uuid,
        officeUuid: note.officeUuid,
        body: note.body,
        date: note.date,
      });

      return newNote;
    });

    return this.notes;
  }
}

export default new NoteFactory();

import CompanyApi from '../../api/CompanyApi';

export function getAllCompanies({ commit }) {
  const companyApi = new CompanyApi();
  const companies = companyApi.getAllCompanies();

  return companies
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function addCompany({ commit }, companyData) {
  const companyApi = new CompanyApi();
  const apiAddCompany = companyApi.addCompany(companyData);

  return apiAddCompany
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateCompany({ commit }, data) {
  const companyApi = new CompanyApi();
  const company = companyApi.updateCompany(data);

  return company
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function deleteCompany({ commit }, { uuid }) {
  const companyApi = new CompanyApi();
  const company = companyApi.deleteCompany(uuid);

  return company
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function uploadCompanyLogo({ commit }, { requestData, formData }) {
  const companyApi = new CompanyApi();
  const company = companyApi.uploadCompanyLogo(requestData, formData);

  return company
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getCompanyLogo(state, requestData) {
  const companyApi = new CompanyApi();
  const company = companyApi.getCompanyLogo(requestData);

  return company
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

<template>
  <button
    :class="getClassByType()"
    v-on:click="click(objectData)"
  >
    <img
      :src="require('@/assets/action/' + icon)"
    >
  </button>
</template>

<script>
import ACTION_TYPE from './ActionType';

export default {
  name: 'ActionIconButton',
  props: {
    icon: String,
    type: String,
    handler: {},
    objectData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getClassByType() {
      switch (this.type) {
        case ACTION_TYPE.view:
          return 'ActionIconButton ActionIconButton--view';
        case ACTION_TYPE.edit:
          return 'ActionIconButton ActionIconButton--edit';
        case ACTION_TYPE.delete:
          return 'ActionIconButton ActionIconButton--delete';
        case ACTION_TYPE.action1:
          return 'ActionIconButton ActionIconButton--action1';
        case ACTION_TYPE.action2:
          return 'ActionIconButton ActionIconButton--action2';

        default:
          return 'ActionIconButton';
      }
    },
    click(objectData) {
      this.handler(objectData);
    },
  },
};
</script>

<style lang="sass">
  @import './ActionIconButton.sass'
</style>

<template>
  <b-form-group :label="selectLabel">
    <b-form-checkbox
      v-model="checked"
      @change="changeSelection"
      :state="isValid"
      :disabled="disabled"
    />
  </b-form-group>
</template>

<script>

export default {
  name: 'SimpleCheckbox',
  props: {
    selectLabel: String,
    isChecked: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      checked: this.isChecked,
    };
  },
  methods: {
    changeSelection(e) {
      this.$emit('handlerSelect', e);
    },
  },
};
</script>

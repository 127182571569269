import UserApi from '../../api/UserApi';
import { ROLE } from '../../helpers/UserHelper';

export function getUsers({ commit }, { currentPage, itemsPerPage }) {
  const userApi = new UserApi();
  const users = userApi.getUsers(currentPage, itemsPerPage);

  return users
    .then((res) => res)
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getUser({ commit }, userUuid) {
  const userApi = new UserApi();
  const apiAddUser = userApi.getUser(userUuid);

  return apiAddUser
    .then((res) => {
      commit('setUserData', res);
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getClients({ commit }) {
  const userApi = new UserApi();
  const users = userApi.getClients();

  return users
    .then((res) => {
      commit('setClients', res.users);
      return res.users;
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function addUser({ commit }, userData) {
  const userApi = new UserApi();
  const apiAddUser = userApi.addUser(userData);

  return apiAddUser
    .then(() => {
      if (userData.role === ROLE.CLIENT) {
        getClients({ commit });
      }
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function updateUser({ commit }, data) {
  const userApi = new UserApi();
  const updateAddUser = userApi.updateAddUser(data);

  return updateAddUser
    .then(() => {
      if (data.role === ROLE.CLIENT) {
        getClients({ commit });
      }
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function deleteUser({ commit }, { uuid }) {
  const userApi = new UserApi();
  const apiDeleteUser = userApi.deleteUser(uuid);

  return apiDeleteUser
    .then(() => {
      getClients({ commit });
    })
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function uploadAvatar({ commit }, { requestData, formData }) {
  const userApi = new UserApi();
  const user = userApi.uploadAvatar(requestData, formData);

  return user
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function getAvatar(state, userUuid) {
  const userApi = new UserApi();
  const user = userApi.getAvatar(userUuid);

  return user
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function updatePassword({ commit }, passwordData) {
  const userApi = new UserApi();
  const user = userApi.updatePassword(passwordData);

  return user
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function resetPassword({ commit }, resetData) {
  const userApi = new UserApi();
  const apiAddUser = userApi.resetPassword(resetData);

  return apiAddUser
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

export function inviteUser({ commit }, userData) {
  const userApi = new UserApi();
  const user = userApi.inviteUser(userData);

  return user
    .then()
    .catch((error) => {
      commit('setErrors', error);
      return Promise.reject(error);
    });
}

<template>
  <Modal v-show="showModal">
    <FormCard
      title="Añadir Comentario"
      @accept="addComment"
      @cancel="cancelAction"
    >
      <FormCommentContent
        @handleCommentData="setCommentData"
        :commentData="this.commentData"
        :commentDataValidation="this.commentDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCommentContent
from '@/components/molecules/Comments/AddComment/FormCommentContent.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import { hasData } from '@/helpers/ValidationHelper';

export default {
  name: 'AddCommentForm',
  components: { FormCard, FormCommentContent, Modal },
  props: {
    showModal: Boolean,
    officeUuid: String,
    phaseUuid: String,
    handleClose: {},
  },
  methods: {
    addComment() {
      if (this.isValidData()) {
        const requestData = {
          officeUuid: this.officeUuid,
          phaseUuid: this.phaseUuid,
          text: this.commentData.comment,
        };
        this.blockScreen();
        this.$store.dispatch('addComment', requestData)
          .then(() => {
            this.unBlockScreen();
            this.handleClose();
          })
          .catch(() => {
            this.unBlockScreen();
            this.handleClose();
          });
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    setCommentData(commentData) {
      this.commentData = commentData;
    },
    isValidData() {
      return hasData(this.commentData?.comment);
    },
  },
  data() {
    return {
      commentData: {
        comment: null,
      },
      commentDataValidation: {
        comment: null,
      },
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <div v-if="!$store.getters.hasEvents">
      <div class="container__simple">
        No hay resultados asociados a los criterios de búsqueda.
      </div>
    </div>
    <div v-else class="container__simple">
      <b-table
        :currentPage="currentPage"
        :fields="fields"
        :items="eventsProjectData"
        :perPage="perPage"
        fixed
      >
        <template #cell(name)="data">
          <span v-if="data.item.name === 'Nuevo documento'">
            <span v-if="data.item.phaseName">
              {{data.item.name}} ~
              <a
                :href="`/offices#code=${data.item.officeCode},`
                + `phase=${data.item.body.phaseName},`
                + `documentName=${data.item.body.docId}`">
                {{getPhaseLabel(data.item.phaseName)}}
              </a>
            </span>
            <span v-else>
              {{data.item.name}}
            </span>
          </span>
          <span v-else>
            {{ data.item.phaseName
              ? `${data.item.name} ~ ${data.item.phaseName}`
              : data.item.name }}
          </span>
        </template>
        <template #cell(consumed)="data">
          <input :id="data.item.id" v-model=data.item.consumed :checked="data.item.consumed"
                 type="checkbox"
                 @change="updateConsumedEvent(data)">
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :perPage="perPage"
        :totalRows="rows"/>

    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { getPhaseLabel } from '../../../../helpers/PhaseHelper';

export default {
  name: 'EventsTable',
  data() {
    return {
      getPhaseLabel,
      perPage: 10,
      currentPage: 1,
      isEventTableLoading: false,
      eventsProjectData: [],
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'officeCode', label: 'Código de oficina', sortable: true },
        { key: 'occurredOn', label: 'Fecha', sortable: true },
        { key: 'consumed', label: 'Visto' },

      ],

    };
  },
  created() {
    this.getEvents();
  },
  computed: {
    ...mapState([
      'activeProject',
    ]),
    rows() {
      return this.eventsProjectData.length;
    },
  },
  watch: {
    activeProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getEvents();
      }
    },
  },
  methods: {
    setEventsTableLoading() {
      this.isEventTableLoading = true;
    },
    setEventsTableNotLoading() {
      this.isEventTableLoading = false;
    },
    getEvents() {
      this.setEventsTableLoading();
      const eventData = {
        projectId: this.$store.getters.getActiveProject,
      };
      this.$store.dispatch('getEvents', eventData)
        .then(() => {
          this.eventsProjectData = this.$store.getters.getEvents.map((event) => {
            const clonedEvent = event;
            if (clonedEvent.body && clonedEvent.body.documentName) {
              clonedEvent.body.docId = event.body.documentName.replace('.', '_');
            }
            return clonedEvent;
          });
          this.setEventsTableNotLoading();
        })
        .catch(
          this.setEventsTableNotLoading,
        );
    },
    updateConsumedEvent(objectData) {
      const requestData = {
        eventId: objectData.item.id,
        consumed: objectData.item.consumed,
      };
      this.$store.dispatch('updateConsumedEvents', requestData)
        .then()
        .catch();
    },
  },

};

</script>

<style lang="sass" scoped>

</style>

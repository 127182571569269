import getAxiosWithAuthHeader from '../helpers/AuthHelper';
import PhaseFactory from '../domain/services/PhaseFactory';

const OFFICES = '/offices';

class PhaseAPI {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  updatePhase(data) {
    const url = `${OFFICES}/${data.officeUuid}/${data.phase}`;

    return this.client
      .put(url, data.phaseData)
      .then(() => data.phaseData)
      .catch((error) => Promise.reject(error));
  }

  uploadPhaseDocument(requestData, formData) {
    const url = `${OFFICES}/${requestData.officeUuid}/phase/${requestData.phaseUuid}/document`;

    return this.client
      .post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      .then()
      .catch((error) => Promise.reject(error));
  }

  downloadPhaseDocument(requestData) {
    const url = `${OFFICES}/${requestData.officeUuid}/phase/${requestData.phaseUuid}/document/${requestData.documentUuid}`;

    return this.client
      .get(url,
        {
          responseType: 'blob',
        })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  getPhaseByOffice(office) {
    const url = `${OFFICES}/${office.uuid}/phases`;

    return this.client
      .get(url)
      .then((res) => PhaseFactory.getPhases(res.data?.resource, office))
      .catch((error) => Promise.reject(error));
  }

  deletePhaseDocument(requestData) {
    const url = `${OFFICES}/${requestData.officeUuid}/phase/${requestData.phaseUuid}/document/${requestData.documentUuid}`;

    return this.client
      .delete(url)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default PhaseAPI;

export default class IssueStats {
  constructor(issueStatsData) {
    this.pendingAction = issueStatsData.pendingAction;
    this.inProgress = issueStatsData.inProgress;
    this.pendingDate = issueStatsData.pendingDate;
    this.resolved = issueStatsData.resolved;
  }

  get getPendingAction() {
    return this.pendingAction;
  }

  get getInProgress() {
    return this.inProgress;
  }

  get getPendingDate() {
    return this.pendingDate;
  }

  get getResolved() {
    return this.resolved;
  }
}

export const ROLE = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  PROVIDER: 'ROLE_PROVIDER',
  CLIENT: 'ROLE_CLIENT',
};

export function getRoleString(role) {
  switch (role) {
    case ROLE.SUPER_ADMIN:
      return 'SUPER ADMIN';
    case ROLE.ADMIN:
      return 'ADMIN';
    case ROLE.PROVIDER:
      return 'PROVEEDOR';
    case ROLE.CLIENT:
      return 'CLIENTE';
    default:
      return '';
  }
}

export function getSelectRolOptions() {
  const options = [
    { value: null, text: 'Seleccionar ROL' },
  ];

  Object.entries(ROLE).forEach(([key, value]) => {
    const option = {
      value: ROLE[key],
      text: getRoleString(value),
    };

    options.push(option);
  });

  return options;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00 ${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export function getUserRole(token) {
  const userRole = parseJwt(token).roles[0];

  return userRole;
}

export function getUserUuid(token) {
  return parseJwt(token).uuid;
}

<template>
  <div class="SimpleCardContent">
    <b-row>
      <b-col>
        <p class="Text__small">{{ title }}</p>
        <p class="Text__highlight__result">{{ titleData }}</p>
      </b-col>
      <b-col>
        <p class="Text__small">{{ optionalTitle }}</p>
        <p class="Text__highlight__result">{{ optionalDataTitle }}</p>
      </b-col>
    </b-row>
    <hr>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SimpleCardContent',
  props: {
    title: String,
    titleData: [String, Number],
    optionalTitle: String || null,
    optionalDataTitle: [String, Number] || null,
  },
};
</script>

<style lang="sass">
@import './SimpleCardContent.sass'
</style>

<docs>
  ### Examples

  ```jsx
  <SimpleCardContent />
  ```
</docs>

<template>
  <b-form-group
    :label="inputLabel"
    label-class="SimpleInput__label"
  >
    <b-form-input
      :id="inputId"
      :type="inputType"
      :placeholder="inputPlaceholder"
      v-model="text"
      @input.native="onChange"
      :state="isValid"
      />
  </b-form-group>
</template>

<script>
export default {
  name: 'SimpleInput',
  props: {
    inputLabel: String,
    inputId: String,
    inputValue: [String, Number],
    inputType: String,
    inputPlaceholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      text: this.inputValue,
    };
  },
  watch: {
    inputValue(newValue) {
      this.text = newValue;
    },
  },
  methods: {
    getText(text) {
      return text !== '' ? text : null;
    },
    onChange(e) {
      this.text = e.target.value;
      this.$emit('handlerText', this.getText(this.text));
    },
  },
};
</script>

<style lang="sass">
@import './SimpleInput.sass'
</style>

<template>
  <form>
    <b-row>
      <b-col>
        <SelectorForm
          selectLabel="Estado"
          :selectFormOptions="selectFormOptions"
          @handlerSelect="onChangeStatus"
          :selectedOption="status"
          :isValid="this.installationDataValidation.status"
          :disabled="!this.$store.getters.hasProviderPermission"
        />
      </b-col>
    </b-row>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <b-form-group
          label="Fecha Inicio Instalación"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="startDate"
            v-model="startDate"
            placeholder="Fecha Inicio"
            @context="onChangeStartDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.installationDataValidation.startDate"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Fecha Fin Instalación"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="endDate"
            v-model="endDate"
            placeholder="Fecha Fin"
            @context="onChangeEndDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.installationDataValidation.endDate"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <b-form-group
          label="Fecha parada por permisos"
          :hidden="!this.$store.getters.hasProviderPermission"
        >
          <b-form-datepicker
            id="stopPermitDate"
            v-model="stopPermitDate"
            placeholder="Fecha parada por permisos"
            @context="onChangeStopPermitDate"
            :date-format-options="{
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            }"
            :state="this.installationDataValidation.stopPermitDate"
            start-weekday="1"
            :reset-button="true"
            label-reset-button="Borrar fecha seleccionada"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import SelectorForm from '@/components/atoms/Forms/SelectorForm/SelectorForm.vue';
import {
  getStatusSelectorOptionInstallation,
} from '@/helpers/StatusHelper';

export default {
  name: 'FormInstallationContent',
  components: { SelectorForm },
  props: {
    installationData: {
      type: Object,
      default: () => {},
    },
    installationDataValidation: Object,
  },
  data() {
    return {
      selectFormOptions: getStatusSelectorOptionInstallation(),
      status: this.installationData.status,
      startDate: this.installationData.startDate,
      endDate: this.installationData.endDate,
      stopPermitDate: this.installationData.stopPermitDate,
      comments: this.installationData.comments,
    };
  },
  methods: {
    onChangeStatus(data) {
      this.$emit('onChangeStatus', data);
    },
    onChangeStartDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeStartDate', data.activeYMD);
      } else {
        this.$emit('onChangeStartDate', null);
      }
    },
    onChangeEndDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeEndDate', data.activeYMD);
      } else {
        this.$emit('onChangeEndDate', null);
      }
    },
    onChangeStopPermitDate(data) {
      if (data.selectedDate !== null) {
        this.$emit('onChangeStopPermitDate', data.activeYMD);
      } else {
        this.$emit('onChangeStopPermitDate', data.activeYMD);
      }
    },
    onChangeComments(data) {
      if (data.comments !== null) {
        this.$emit('onChangeComments', data);
      }
    },
  },
};
</script>

<template>
  <div>
    <b-form-group
      label="Subir avatar (1:1, Ej: 300px x 300px)"
    >
      <b-form-file
        v-model="avatarImage"
        :state="Boolean(avatarImage)"
        placeholder="Selecciona o arrastra una imagen."
        drop-placeholder="Arrastra la imagen aquí..."
      ></b-form-file>
    </b-form-group>
  </div>
</template>

<script>

export default {
  name: 'AvatarUploader',
  data() {
    return {
      avatarImage: null,
    };
  },
  methods: {
    uploadFile() {
      const formData = new FormData();

      formData.append('file', this.avatarImage);

      const requestData = {
        userUuid: this.$store.getters.getUser.uuid,
      };

      this.blockScreen();

      this.$store.dispatch('uploadAvatar', { requestData, formData })
        .then(() => {
          this.unBlockScreen();
          this.$emit('handleGetAvatar');
        })
        .catch(() => {
          this.unBlockScreen();
          this.$emit('handleClose');
        });
    },
  },
};
</script>

export default class Auth {
  constructor(authData) {
    this.token = authData.token;
    this.refreshToken = authData.refreshToken;
  }

  get getToken() {
    return this.token;
  }

  get getRefreshToken() {
    return this.refreshToken;
  }
}

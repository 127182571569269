import getAxiosWithAuthHeader from '@/helpers/AuthHelper';
import EventFactory from '@/domain/services/EventFactory';

const EVENTS = '/events';

class EventsApi {
  constructor() {
    this.client = getAxiosWithAuthHeader();
  }

  getEvents(requestData) {
    const url = `${EVENTS}?projectId=${requestData.projectId}&limit=${requestData.limit}&offset=${requestData.offset}`;
    return this.client
      .get(url)
      .then((eventsData) => EventFactory.getEvents(eventsData.data))
      .catch((error) => Promise.reject(error));
  }

  updateConsumeEvents(requestData) {
    const url = `${EVENTS}/${requestData.eventId}`;
    const dataRequest = {
      consumed: requestData.consumed,
    };
    return this.client
      .put(url, dataRequest)
      .then()
      .catch((error) => Promise.reject(error));
  }
}

export default EventsApi;

<template>
  <div class="ForecastListView">
    <div>Añadir eventos al proyecto</div>
    <hr>
    <b-button
      v-if="$store.getters.hasAdminPermission"
      variant="primary"
      @click.prevent="handleShowAddForecastForm"
    >
      Nuevo Evento
    </b-button>
    <div class="Separator__vertical__st" />
    <div class="ForecastListView__table">
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
      >
        <template #cell(phase)="data">
          {{ data.item.phase | getPhaseLabel }}
        </template>
        <template #cell(startDate)="data">
          {{ data.item.startDate | formatDate }}
        </template>
        <template #cell(endDate)="data">
          {{ data.item.endDate | formatDate }}
        </template>
        <template #cell(acciones)="data">
          <div
            class="actionGroup"
            v-if="$store.getters.hasAdminPermission"
          >
            <ActionIconButton
              icon="edit.svg"
              type="edit"
              :handler="editForecast"
              :objectData="data.item"
            />
            <ActionIconButton
              icon="delete.svg"
              type="delete"
              :handler="deleteForecast"
              :objectData="data.item"
            />
          </div>
        </template>
      </b-table>
    </div>
    <UpdateForecastForm
      v-if="isShowedUpdateForecastForm"
      :showModal="true"
      @cancel="closeUpdateForecastForm"
      :forecastData="currentForecast"
      :handleClose="closeUpdateForecastForm"
      :handleUpdateForecasts="updateForecasts"
    />
    <b-modal id="forecast-error-modal" centered ok-only title="Error">
      <p class="my-4">La operación no pudo completarse.</p>
    </b-modal>
  </div>

</template>

<script>
import ActionIconButton from '@/components/atoms/Buttons/ActionIconButton/ActionIconButton.vue';
import UpdateForecastForm from '@/components/molecules/Forecast/UpdateForecastForm.vue';

export default {
  name: 'ForecastListView',
  components: {
    ActionIconButton,
    UpdateForecastForm,
  },
  props: {
    handleShowAddForecastForm: {},
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'phase', label: 'Fase', sortable: true },
        { key: 'lot', label: 'Lote', sortable: true },
        { key: 'startDate', label: 'Inicio', sortable: true },
        { key: 'endDate', label: 'Fin', sortable: true },
        { key: 'acciones' },
      ],
      isShowedUpdateForecastForm: false,
      currentForecast: {
        uuid: null,
        phase: null,
        lot: null,
        startDate: null,
        endDate: null,
        color: null,
      },
    };
  },
  methods: {
    editForecast(objectData) {
      this.currentForecast = {
        uuid: objectData.id,
        phase: objectData.phase,
        lot: objectData.lot,
        startDate: objectData.startDate,
        endDate: objectData.endDate,
        color: objectData.color,
      };

      this.showUpdateForecastForm();
    },
    deleteForecast(objectData) {
      this.blockScreen();

      const uuid = objectData.id;

      if (objectData.id !== 'undefined') {
        this.$store.dispatch('deleteForecast', { uuid })
          .then(() => {
            this.$emit('handleUpdateForecasts');
          })
          .catch(() => {
            this.unBlockScreen();
            this.$bvModal.show('forecast-error-modal');
          });
      }
    },
    showUpdateForecastForm() {
      this.isShowedUpdateForecastForm = true;
    },
    closeUpdateForecastForm() {
      this.isShowedUpdateForecastForm = false;
    },
    updateForecasts() {
      this.$emit('handleUpdateForecasts');
    },
  },
};
</script>

<style lang="sass">
  @import './ForecastListView.sass'
</style>

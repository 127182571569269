import NoteApi from '../../api/NoteApi';

export function getAllNotes(state, { uuid }) {
  const noteApi = new NoteApi();
  const notes = noteApi.getAllNotes(uuid);

  return notes
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function getNote(state, { uuid }) {
  const noteApi = new NoteApi();
  const notes = noteApi.getNote(uuid);

  return notes
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function addNote(state, data) {
  const noteApi = new NoteApi();
  const notes = noteApi.addNote(data);

  return notes
    .then()
    .catch((error) => Promise.reject(error));
}

export function updateNote(state, data) {
  const noteApi = new NoteApi();
  const notes = noteApi.updateNote(data);

  return notes
    .then()
    .catch((error) => Promise.reject(error));
}

export function deleteNote(state, { uuid }) {
  const noteApi = new NoteApi();
  const notes = noteApi.deleteNote(uuid);

  return notes
    .then()
    .catch((error) => Promise.reject(error));
}

<template>
  <div>
    <div class="MenuRow">
      <div class="MenuRow__icon">
        <button
          class="MenuRow__collapseBlock MenuRow__button"
          @click="changeSubMenuByIcon()"
        >
          <MenuRowIcon
            :icon="icon"
            :isSelected="isCurrentParentPath"
          />
        </button>
      </div>
      <button
        class="MenuRow__collapseBlock MenuRow__button"
        v-show="showFullMenuBar"
        @click="changeSubMenu()"
      >
        <div class="MenuRow__label">
          <p :class="
            isCurrentParentPath ?
            'MenuRow__route--active' :
            'MenuRow__route'"
          >
            {{ label }}
          </p>
        </div>
        <div class="MenuRow__collapseIcon">
          <CollapseIcon :isOpen="showSubmenu" />
        </div>
      </button>
    </div>
    <div class="MenuRow"
      v-for="menu in submenu"
      :key="menu.url"
      v-show="isSubmenuShowed"
    >
      <div class="MenuRow__empty" />
      <div
        class="MenuRow__label"
        :class="{'MenuRow__label--active': isCurrentPath(menu.path)}"
      >
        <router-link
          :to="{ name: menu.url }"
          class="link__standard"
          @click.native="menuSelected(url)"
        >
          {{ menu.label }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import CollapseIcon from '@/components/atoms/CollapseIcon/CollapseIcon.vue';
import MenuRowIcon from '@/components/atoms/MenuRow/MenuRowIcon.vue';

export default {
  name: 'MenuRaw',
  components: {
    CollapseIcon,
    MenuRowIcon,
  },
  props: {
    icon: String,
    label: String,
    submenu: Array,
    url: String,
    path: String,
  },
  data() {
    return {
      showSubmenu: false,
    };
  },
  created() {
    this.showSubmenu = this.isSelected()
      || (this.showFullMenuBar && this.showSubmenu)
      || this.isCurrentParentPath;
  },
  computed: {
    ...mapState([
      'showFullMenuBar',
    ]),
    isSubmenuShowed() {
      if (!this.showFullMenuBar) {
        return false;
      }

      return (this.showFullMenuBar && this.showSubmenu);
    },
    isCurrentParentPath() {
      for (let i = 0; this.submenu.length > i; i += 1) {
        if (this.submenu[i].path === this.$route.path) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    ...mapMutations([
      'setMenuSelected',
    ]),
    changeSubMenu() {
      this.showSubmenu = !this.showSubmenu;
    },
    changeSubMenuByIcon() {
      this.showSubmenu = true;
      this.$store.commit('showFullMenuBar');
    },
    menuSelected(url) {
      this.showSubmenu = true;
      this.setMenuSelected({ url });
    },
    isSelected() {
      return this.$store.state.menuSelected === this.url;
    },
    isCurrentPath(route) {
      return route === this.$route.path;
    },
  },
};
</script>

<style lang="sass" scoped>
  @import './MenuRow.sass'
</style>

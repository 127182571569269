export function getDecimalNumber(number) {
  let newNumber = number;

  if (typeof number === 'string') {
    newNumber = parseInt(number, 10);
  }

  return newNumber.toFixed(2);
}

export function getFormattedNumbers(number) {
  return new Intl.NumberFormat('es-ES').format(number);
}

export function getFormattedFloatNumbersFromBackEnd(number) {
  return number?.toString().replace('.', ',');
}

export function getFormattedFloatNumbersToBackEnd(number) {
  return parseFloat(number.toString().replace(',', '.'));
}

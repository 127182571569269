<template>
  <Modal v-show="showModal">
    <FormCard
      title="Actualiza tu contraseña"
      @accept="updatePassword"
      @cancel="cancelAction"
    >
      <FormUserPasswordContent
        @handleUserData="setUserData"
        :userData="this.userData"
        :userDataValidation="this.userDataValidation"
      />
    </FormCard>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import FormCard from '@/components/molecules/FormCard/FormCard.vue';
import {
  hasData, getValidationState, isEqual, getEqualValidationState,
} from '@/helpers/ValidationHelper';
import FormUserPasswordContent from './FormUserPasswordContent.vue';

export default {
  name: 'UpdateUserPasswordForm',
  components: {
    Modal,
    FormCard,
    FormUserPasswordContent,
  },
  props: {
    showModal: Boolean,
    handleClose: {},
    handleUpdateUsers: {},
  },
  data: () => ({
    userData: {
      type: Object,
      default: () => {},
    },
    userDataValidation: {
      name: null,
      surnames: null,
    },
  }),
  methods: {
    setUserData(userData) {
      this.userData = userData;
    },
    updatePassword() {
      if (this.isValidData()) {
        const userData = {
          oldPassword: this.userData.oldPassword,
          newPassword: this.userData.newPassword,
          newPasswordRepeat: this.userData.newPasswordRepeat,
        };

        this.blockScreen();

        this.$store.dispatch('updatePassword', userData)
          .then(() => {
            this.unBlockScreen();
            this.closeForm();
          })
          .catch(() => {
            this.unBlockScreen();
            this.closeForm();
          });
      } else {
        this.userDataValidation = {
          oldPassword: getValidationState(this.userData?.oldPassword),
          newPassword: getEqualValidationState(
            this.userData?.newPassword, this.userData?.newPasswordRepeat,
          ),
          newPasswordRepeat: getEqualValidationState(
            this.userData?.newPassword, this.userData?.newPasswordRepeat,
          ),
        };
      }
    },
    cancelAction() {
      this.$emit('cancel');
    },
    closeForm() {
      return this.handleClose();
    },
    isValidData() {
      return hasData(this.userData?.oldPassword)
        && hasData(this.userData?.newPassword)
        && hasData(this.userData?.newPasswordRepeat)
        && isEqual(this.userData?.newPassword, this.userData?.newPasswordRepeat);
    },
  },
};
</script>
